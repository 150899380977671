// surcharge des class bootstrap

/* =========  reprise des couleurs des boutons de base ici
*/

//$primary:       $blue;
//$secondary:     $gray-600;
//$success:       $green;
//$info:          $cyan;
//$warning:       $yellow;
//$danger:        $red;
//$light:         $gray-100;
//$dark:          $gray-800;

/* =========  mise en forme des nav
*/

.nav{
  .nav-link{
    line-height: 20px;
  }
}

/* =========  mise en forme des boutons bootstrap
*/

.font-02{
  font-family: $font-02;
}

.btn-primary{

}

.btn-secondary{
  color: $white;
}

.btn-secondary.active, .btn-secondary:hover{
  color: $primary;
}

.btn-light{
  color: $primary;
}

.btn-outline-secondary{
  background-color: $primary;
  &:hover{
    border-color: $primary;
    color: $primary;
  }
}



.link-light{
  transition: all .15s ease-in-out;
  &:hover{
    color: $secondary;
    transition: all .15s ease-in-out;
  }
}

.link-primary{
  transition: all .15s ease-in-out;
  &:hover{
    opacity: 1 !important;
    transition: all .15s ease-in-out;
  }
}

.link-secondary{
  background: url(../images/arrow-lien.svg) no-repeat right -2px;
  padding-right: 30px;
  transition: all .15s ease-in-out;
  &:hover{
    opacity: 1 !important;
    transition: all .15s ease-in-out;
  }
  &.link-secondary-inverse{
    background: url(../images/arrow-lien-inverse.svg) no-repeat left -2px;
    padding-left: 30px;
  }
}

.bg-degrade-diagonal.link-light{
  &:hover{
    color: $primary !important;
  }
}

.list-group{
  .list-group-item{
    background: none;
  }
}

.alert {
  color: $white;
}

.offcanvas-body{
  .accordion-button{
    box-shadow: unset;
  }
}

.pagination .active{
  display: flex;
  .page-link{
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#194270+0,32b4b4+50,c8e6cd+100 */
    background: #194270; /* Old browsers */
    background: -moz-linear-gradient(45deg,  #194270 0%, #32b4b4 50%, #c8e6cd 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg,  #194270 0%,#32b4b4 50%,#c8e6cd 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg,  #194270 0%,#32b4b4 50%,#c8e6cd 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#194270', endColorstr='#c8e6cd',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    border: 0;
  }
}

.blockquote{
  background-color: $primary;
  color: $white;
  padding: 20px;
  font-size: map-get($font-sizes,18);
  p{
    margin: 0 !important;
  }
}

.text-justify{
  text-align: justify;
}