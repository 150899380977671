
// variables de bootstrap par défault
// https://getbootstrap.com/docs/5.1/utilities/colors/#variables
$white:    #fff;
$gray-100: #EBF0F5;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #444444;
$gray-900: #212529;
$black:    #000;

$blue:    #194270;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #8B1F5A;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #32B4B4;
$teal:    #20c997;
$cyan:    #65afe2;

$primary:       $blue;
$primary-2:       #38689d;
$primary-3:       #10335A;
$secondary:     $green;
$secondary-2:     #C8E6CD;
$secondary-3:     #EBF0F5;
$secondary-4:     #A8BED5;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-800;

$burger:        $dark;
$body:          $gray-600;
$footer:        #10335A;

$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "primary-2":    $primary-2,
    "primary-3":    $primary-3,
    "secondary":  $secondary,
    "secondary-2":  $secondary-2,
    "secondary-3":  $secondary-3,
    "secondary-4":  $secondary-4,
    "gray-100":    $gray-100,
    "gray-800":    $gray-800,
    "pink":    $pink,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $white,
    "dark":       $dark,
    "mobile":       $gray-300,
    "body":       $body,
    "footer":       $footer,
    "error":        $gray-300,
    "burger":        $burger,
    //"new-color":  $new-color, // permet de créer des class bootstrap complète de type btn-new-color, bg-new-color, text-new-color etc...
  ),
  $theme-colors
);

/* ======= uikit overlay couleur
*/

$overlay-primary-background: rgba($gray-700, 0.6);