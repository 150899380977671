// élément qui prend toute la longueur du site, rajouter un .container dans l'élément qui a cette classe

/* ====================== Le responsive du vw-full de mobil vers desktop (vw-sm-full etc...)
 */

.vw-full{
  margin-left: -50vw;
  margin-right: -50vw;
  clear: both;
  position: relative;
  right: 50%;
  left: 50%;
  width: 100vw;
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    
    .vw#{$infix}-full{
      margin-left: -50vw;
      margin-right: -50vw;
      clear: both;
      position: relative;
      right: 50%;
      left: 50%;
      width: 100vw;
    }
    
  }
}

@include media-breakpoint-down(lg) {
  .vw-md-full-under{
    margin-left: -50vw;
    margin-right: -50vw;
    clear: both;
    position: relative;
    right: 50%;
    left: 50%;
    width: 100vw;
  }
}