.picto-dropdown{
  &:after {
    content: '';
    display: inline-block;
    width: 17px;
    height: 17px;
    margin-left: auto;
    padding-left: 5px;
    background-image: escape-svg(url("data:image/svg+xml,<svg xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' xmlns='http://www.w3.org/2000/svg'><g fill='none' fill-rule='evenodd'><path d='M0 0h25v25H0z'/><path fill='#{$white}' fill-rule='nonzero' d='m12.5 15.625-4.42-4.42 1.474-1.473 2.946 2.947 2.946-2.947 1.474 1.473z'/></g></svg>"));
    background-size: 25px;
    background-repeat: no-repeat;
  }
}

.nav-item.active .picto-dropdown{
  &:after{
    background-image: escape-svg(url("data:image/svg+xml,<svg xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' xmlns='http://www.w3.org/2000/svg'><g fill='none' fill-rule='evenodd'><path d='M0 0h25v25H0z'/><path fill='#{$secondary}' fill-rule='nonzero' d='m12.5 15.625-4.42-4.42 1.474-1.473 2.946 2.947 2.946-2.947 1.474 1.473z'/></g></svg>"));
  }
}

.picto-link-light{
  &:after {
    content: '';
    display: inline-block;
    width: 20px;
    height: 6px;
    margin-left: 7PX;
    background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='6.001' viewBox='2 9 20 6.001' xml:space='preserve'><path fill='#{$primary-2}' d='M7.224 9c1.719.039 3.419.876 4.455 2.234l.144.197c.675.977 1.933 1.596 3.199 1.568.943.021 2.057-.562 3.07-1.512l.201-.195.291-.292H17a1 1 0 0 1-.993-.883L16 10a1 1 0 0 1 .883-.993L17 9h4.02c.023 0 .047.002.07.004L21 9a1.01 1.01 0 0 1 .265.035l.047.014.055.02.059.026.055.027a1.395 1.395 0 0 1 .136.09c.063.05.12.107.17.17l-.08-.09a1.024 1.024 0 0 1 .259.441l.015.063.009.054.005.032.003.03.002.056V14a1 1 0 0 1-1.993.117L20 14v-1.586l-.293.293c-1.34 1.34-2.894 2.23-4.43 2.291l-.256.002c-1.801.039-3.616-.813-4.701-2.234l-.144-.197c-.674-.977-1.932-1.596-3.197-1.569-1.223-.027-2.481.592-3.156 1.568a1 1 0 1 1-1.645-1.137C3.195 9.959 4.975 9.042 6.738 9h.486z'/></svg>"));
    background-size: 20px;
    background-repeat: no-repeat;
  }
}

.picto-link-secondary{
  &:after {
    content: '';
    display: inline-block;
    width: 20px;
    height: 6px;
    margin-left: 7PX;
    background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='6.001' viewBox='2 9 20 6.001' xml:space='preserve'><path fill='#{$secondary}' d='M7.224 9c1.719.039 3.419.876 4.455 2.234l.144.197c.675.977 1.933 1.596 3.199 1.568.943.021 2.057-.562 3.07-1.512l.201-.195.291-.292H17a1 1 0 0 1-.993-.883L16 10a1 1 0 0 1 .883-.993L17 9h4.02c.023 0 .047.002.07.004L21 9a1.01 1.01 0 0 1 .265.035l.047.014.055.02.059.026.055.027a1.395 1.395 0 0 1 .136.09c.063.05.12.107.17.17l-.08-.09a1.024 1.024 0 0 1 .259.441l.015.063.009.054.005.032.003.03.002.056V14a1 1 0 0 1-1.993.117L20 14v-1.586l-.293.293c-1.34 1.34-2.894 2.23-4.43 2.291l-.256.002c-1.801.039-3.616-.813-4.701-2.234l-.144-.197c-.674-.977-1.932-1.596-3.197-1.569-1.223-.027-2.481.592-3.156 1.568a1 1 0 1 1-1.645-1.137C3.195 9.959 4.975 9.042 6.738 9h.486z'/></svg>"));
    background-size: 20px;
    background-repeat: no-repeat;
  }
}

.picto-primary{
  &:after {
    content: '';
    display: inline-block;
    width: 20px;
    height: 6px;
    background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='6.001' viewBox='2 9 20 6.001' xml:space='preserve'><path fill='#{$primary}' d='M7.224 9c1.719.039 3.419.876 4.455 2.234l.144.197c.675.977 1.933 1.596 3.199 1.568.943.021 2.057-.562 3.07-1.512l.201-.195.291-.292H17a1 1 0 0 1-.993-.883L16 10a1 1 0 0 1 .883-.993L17 9h4.02c.023 0 .047.002.07.004L21 9a1.01 1.01 0 0 1 .265.035l.047.014.055.02.059.026.055.027a1.395 1.395 0 0 1 .136.09c.063.05.12.107.17.17l-.08-.09a1.024 1.024 0 0 1 .259.441l.015.063.009.054.005.032.003.03.002.056V14a1 1 0 0 1-1.993.117L20 14v-1.586l-.293.293c-1.34 1.34-2.894 2.23-4.43 2.291l-.256.002c-1.801.039-3.616-.813-4.701-2.234l-.144-.197c-.674-.977-1.932-1.596-3.197-1.569-1.223-.027-2.481.592-3.156 1.568a1 1 0 1 1-1.645-1.137C3.195 9.959 4.975 9.042 6.738 9h.486z'/></svg>"));
    background-size: 20px;
    background-repeat: no-repeat;
  }
}