// backgroudn personnalisés
// https://getbootstrap.com/docs/5.1/utilities/background/

.bg-degrade-vertical{
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#194270+0,32b4b4+50,c8e6cd+100 */
  background: #194270; /* Old browsers */
  background: -moz-linear-gradient(top,  #194270 0%, #32b4b4 50%, #c8e6cd 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #194270 0%,#32b4b4 50%,#c8e6cd 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #194270 0%,#32b4b4 50%,#c8e6cd 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.bg-degrade-diagonal{
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#194270+0,32b4b4+50,c8e6cd+100 */
  background: #194270; /* Old browsers */
  background: -moz-linear-gradient(45deg,  #194270 0%, #32b4b4 50%, #c8e6cd 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(45deg,  #194270 0%,#32b4b4 50%,#c8e6cd 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(45deg,  #194270 0%,#32b4b4 50%,#c8e6cd 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#194270', endColorstr='#c8e6cd',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.bg-degrade-horizontal{
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#194270+0,32b4b4+50,c8e6cd+100 */
  background: #194270; /* Old browsers */
  background: -moz-linear-gradient(left,  #194270 0%, #32b4b4 50%, #c8e6cd 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left,  #194270 0%,#32b4b4 50%,#c8e6cd 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right,  #194270 0%,#32b4b4 50%,#c8e6cd 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#194270', endColorstr='#c8e6cd',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.bg-logo-blanc-center{
  background: url(../images/wave-logo-blanche.svg) no-repeat center bottom;
}

.bg-logo-blanc{
  background: url(../images/wave-logo-blanche.svg) no-repeat left bottom;
}

.bg-logo-blanc-offre{
  background: url(../images/forme-logo-svg.svg) no-repeat left bottom;
}

.bg-logo-blanc-offre-right{
  background: url(../images/forme-logo-svg.svg) no-repeat right bottom;
}

.bg-logo-gris{
  background: url(../images/wave-logo-gris.svg) no-repeat right bottom;
  background-size: contain;
}

.bg-logo-left{
  background: url(../images/wave-logo.svg) no-repeat right calc(100% + 40px);
  @include media-breakpoint-down(sm) {
    background-size: 180%;
  }
  @include media-breakpoint-up(sm) {
    background: url(../images/wave-logo.svg) no-repeat -100px 120px;
  }
}

.bg-logo-right{
  background: url(../images/bg-pga.png) no-repeat calc(100% + 145px) bottom;
  background-size: 200% 100%;
  @include media-breakpoint-up(sm) {
    background: url(../images/bg-pga.png) no-repeat right bottom;
    background-size: cover;
  }
  @include media-breakpoint-up(lg) {
    background: url(../images/bg-pga.png) no-repeat right top;
    background-size: contain;
  }
}

.bg-wave{
  background: url(../images/bg-wave.png) no-repeat left bottom;
  background-size: 200%;
  @include media-breakpoint-up(lg) {
    background-size: 100%;
  }
}

.bg-quotes{
  background: url(../images/quote-1.svg) no-repeat center 30px;
  @include media-breakpoint-up(lg) {
    background: url(../images/quote-1.svg) no-repeat 30px 30px, url(../images/quote-2.svg) no-repeat calc(100% - 30px) calc(100% - 30px);
  }
}

.bg-quotes-blanc{
  background: url(../images/quote-1-blanc.svg) no-repeat center 30px;
  @include media-breakpoint-up(lg) {
    background: url(../images/quote-1-blanc.svg) no-repeat 30px 30px, url(../images/quote-2-blanc.svg) no-repeat calc(100% - 30px) calc(100% - 30px);
  }
}

.arrow-right{
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6.1px 0 6.1px 6.78px;
  border-color: transparent transparent transparent $secondary;
  display: block;
}

.arrow-left{
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6.1px 6.78px 6.1px 0;
  border-color: transparent $secondary transparent transparent;
  display: block;
}