@charset "UTF-8";
/* ======= uikit overlay couleur
*/
/* ======= font size
*/
/* Pour faire l'appel de la font désiré :
  map-get($font-size,10);
 */
/* ======= fonts à déclarer
*/
/* ======= fonts weight
*/
/* ======= variables de bootsrap à réutiliser
https://getbootstrap.com/docs/5.1/utilities/text/#font-weight-and-italics
*/
/* ======= border-radius
https://getbootstrap.com/docs/5.1/utilities/borders/#variables
*/
/* ======= spacers
https://getbootstrap.com/docs/5.1/utilities/spacing/#sass
*/
/* ======= les gutters
https://getbootstrap.com/docs/5.1/layout/gutters/#change-the-gutters
*/
/* ======= position absolute
https://getbootstrap.com/docs/5.1/utilities/position/#sass
*/
/* ======= z-index
*/
/* ======= width
https://getbootstrap.com/docs/5.1/utilities/sizing/#sass
*/
/* ======= utilities de bootstrap
*/
/* ======= alerts
https://getbootstrap.com/docs/5.1/components/alerts/#variables
*/
/* ======= transitions
feinte à @hugo
*/
/* ======= shadow générique
https://getbootstrap.com/docs/5.1/utilities/shadows/#variables
*/
/* ======= card
https://getbootstrap.com/docs/5.1/components/card/#variables
*/
/* =================== Taille des containers
https://getbootstrap.com/docs/5.1/layout/containers/#sass
 */
/* =================== Nav
https://getbootstrap.com/docs/5.1/components/navs-tabs/#variables
 */
/* =================== Btn
https://getbootstrap.com/docs/5.1/components/buttons/#variables
 */
/* =================== select
https://getbootstrap.com/docs/5.1/forms/select/#variables
 */
/* ======= dropdown
https://getbootstrap.com/docs/5.1/components/dropdowns/#variables
*/
/* =================== input
https://getbootstrap.com/docs/5.1/forms/form-control/#variables
 */
/* =================== breadcrumb
https://getbootstrap.com/docs/5.1/components/breadcrumb/#variables
 */
/* =================== offcanvas
https://getbootstrap.com/docs/5.1/components/offcanvas/#variables
 */
/* =================== accordion
https://getbootstrap.com/docs/5.1/components/accordion/#variables
 */
/* =================== list-group
https://getbootstrap.com/docs/5.1/components/list-group/#variables
 */
/* =================== pagination
https://getbootstrap.com/docs/5.1/components/pagination/#variables
 */
/* =================== modal
https://getbootstrap.com/docs/5.1/components/modal/#variables
 */
/* =================== tables
https://getbootstrap.com/docs/5.1/content/tables/#variables
 */
/* =================== modal
https://getbootstrap.com/docs/5.1/components/modal/#variables
 */
/* ====== obligatoire
 */
/* ====== optionnel
 */
@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@600&family=Roboto:wght@400;500;700;900&display=swap");
:root {
  --bs-blue: #194270;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #8B1F5A;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #32B4B4;
  --bs-teal: #20c997;
  --bs-cyan: #65afe2;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #444444;
  --bs-gray-100: #EBF0F5;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #444444;
  --bs-gray-900: #212529;
  --bs-primary: #194270;
  --bs-primary-2: #38689d;
  --bs-primary-3: #10335A;
  --bs-secondary: #32B4B4;
  --bs-secondary-2: #C8E6CD;
  --bs-secondary-3: #EBF0F5;
  --bs-secondary-4: #A8BED5;
  --bs-gray-100: #EBF0F5;
  --bs-gray-800: #444444;
  --bs-pink: #8B1F5A;
  --bs-success: #32B4B4;
  --bs-info: #65afe2;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #fff;
  --bs-dark: #444444;
  --bs-mobile: #dee2e6;
  --bs-body: #6c757d;
  --bs-footer: #10335A;
  --bs-error: #dee2e6;
  --bs-burger: #444444;
  --bs-primary-rgb: 25, 66, 112;
  --bs-primary-2-rgb: 56, 104, 157;
  --bs-primary-3-rgb: 16, 51, 90;
  --bs-secondary-rgb: 50, 180, 180;
  --bs-secondary-2-rgb: 200, 230, 205;
  --bs-secondary-3-rgb: 235, 240, 245;
  --bs-secondary-4-rgb: 168, 190, 213;
  --bs-gray-100-rgb: 235, 240, 245;
  --bs-gray-800-rgb: 68, 68, 68;
  --bs-pink-rgb: 139, 31, 90;
  --bs-success-rgb: 50, 180, 180;
  --bs-info-rgb: 101, 175, 226;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 255, 255, 255;
  --bs-dark-rgb: 68, 68, 68;
  --bs-mobile-rgb: 222, 226, 230;
  --bs-body-rgb: 108, 117, 125;
  --bs-footer-rgb: 16, 51, 90;
  --bs-error-rgb: 222, 226, 230;
  --bs-burger-rgb: 68, 68, 68;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: Roboto, sans-serif;
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff; }

*,
*::before,
*::after {
  box-sizing: border-box; }

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth; } }

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25; }

hr:not([size]) {
  height: 1px; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw); }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 2.5rem; } }

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw); }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 2rem; } }

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw); }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 1.75rem; } }

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw); }
  @media (min-width: 1200px) {
    h4, .h4 {
      font-size: 1.5rem; } }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small, .small {
  font-size: 0.875em; }

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #194270;
  text-decoration: underline; }
  a:hover {
    color: #14355a; }

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

code {
  font-size: 0.875em;
  color: #8B1F5A;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.6rem; }
  kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 700; }

figure {
  margin: 0 0 1rem; }

img,
svg {
  vertical-align: middle; }

table {
  caption-side: bottom;
  border-collapse: collapse; }

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }
  select:disabled {
    opacity: 1; }

[list]::-webkit-calendar-picker-indicator {
  display: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit; }
  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem; } }
  legend + * {
    clear: left; }

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0; }

::-webkit-inner-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield; }

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::file-selector-button {
  font: inherit; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

iframe {
  border: 0; }

summary {
  display: list-item;
  cursor: pointer; }

progress {
  vertical-align: baseline; }

[hidden] {
  display: none !important; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-1 {
      font-size: 5rem; } }

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-2 {
      font-size: 4.5rem; } }

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-3 {
      font-size: 4rem; } }

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-4 {
      font-size: 3.5rem; } }

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-5 {
      font-size: 3rem; } }

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-6 {
      font-size: 2.5rem; } }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 0.875em;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }
  .blockquote > :last-child {
    margin-bottom: 0; }

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-start {
  float: left !important; }

.float-end {
  float: right !important; }

.float-none {
  float: none !important; }

.opacity-0 {
  opacity: 0 !important; }

.opacity-25 {
  opacity: 0.25 !important; }

.opacity-50 {
  opacity: 0.5 !important; }

.opacity-75 {
  opacity: 0.75 !important; }

.opacity-100 {
  opacity: 1 !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-scroll {
  overflow: scroll !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-grid {
  display: grid !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.shadow {
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.05) !important; }

.shadow-sm {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05) !important; }

.shadow-lg {
  box-shadow: 0 2rem 4rem rgba(0, 0, 0, 0.15) !important; }

.shadow-none {
  box-shadow: none !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.top-0 {
  top: 0 !important; }

.top-5 {
  top: 5% !important; }

.top-10 {
  top: 10% !important; }

.top-15 {
  top: 15% !important; }

.top-20 {
  top: 20% !important; }

.top-25 {
  top: 25% !important; }

.top-30 {
  top: 30% !important; }

.top-40 {
  top: 40% !important; }

.top-50 {
  top: 50% !important; }

.top-80 {
  top: 80% !important; }

.top-90 {
  top: 90% !important; }

.top-100 {
  top: 100% !important; }

.top-2px {
  top: 2px !important; }

.top-100px {
  top: 100px !important; }

.top-n30px {
  top: -30px !important; }

.top-n60px {
  top: -60px !important; }

.top-n130px {
  top: -130px !important; }

.top-n150px {
  top: -150px !important; }

.top-n170px {
  top: -170px !important; }

.top-n300px {
  top: -300px !important; }

.bottom-0 {
  bottom: 0 !important; }

.bottom-5 {
  bottom: 5% !important; }

.bottom-10 {
  bottom: 10% !important; }

.bottom-15 {
  bottom: 15% !important; }

.bottom-20 {
  bottom: 20% !important; }

.bottom-25 {
  bottom: 25% !important; }

.bottom-30 {
  bottom: 30% !important; }

.bottom-40 {
  bottom: 40% !important; }

.bottom-50 {
  bottom: 50% !important; }

.bottom-80 {
  bottom: 80% !important; }

.bottom-90 {
  bottom: 90% !important; }

.bottom-100 {
  bottom: 100% !important; }

.bottom-2px {
  bottom: 2px !important; }

.bottom-100px {
  bottom: 100px !important; }

.bottom-n30px {
  bottom: -30px !important; }

.bottom-n60px {
  bottom: -60px !important; }

.bottom-n130px {
  bottom: -130px !important; }

.bottom-n150px {
  bottom: -150px !important; }

.bottom-n170px {
  bottom: -170px !important; }

.bottom-n300px {
  bottom: -300px !important; }

.start-0 {
  left: 0 !important; }

.start-5 {
  left: 5% !important; }

.start-10 {
  left: 10% !important; }

.start-15 {
  left: 15% !important; }

.start-20 {
  left: 20% !important; }

.start-25 {
  left: 25% !important; }

.start-30 {
  left: 30% !important; }

.start-40 {
  left: 40% !important; }

.start-50 {
  left: 50% !important; }

.start-80 {
  left: 80% !important; }

.start-90 {
  left: 90% !important; }

.start-100 {
  left: 100% !important; }

.start-2px {
  left: 2px !important; }

.start-100px {
  left: 100px !important; }

.start-n30px {
  left: -30px !important; }

.start-n60px {
  left: -60px !important; }

.start-n130px {
  left: -130px !important; }

.start-n150px {
  left: -150px !important; }

.start-n170px {
  left: -170px !important; }

.start-n300px {
  left: -300px !important; }

.end-0 {
  right: 0 !important; }

.end-5 {
  right: 5% !important; }

.end-10 {
  right: 10% !important; }

.end-15 {
  right: 15% !important; }

.end-20 {
  right: 20% !important; }

.end-25 {
  right: 25% !important; }

.end-30 {
  right: 30% !important; }

.end-40 {
  right: 40% !important; }

.end-50 {
  right: 50% !important; }

.end-80 {
  right: 80% !important; }

.end-90 {
  right: 90% !important; }

.end-100 {
  right: 100% !important; }

.end-2px {
  right: 2px !important; }

.end-100px {
  right: 100px !important; }

.end-n30px {
  right: -30px !important; }

.end-n60px {
  right: -60px !important; }

.end-n130px {
  right: -130px !important; }

.end-n150px {
  right: -150px !important; }

.end-n170px {
  right: -170px !important; }

.end-n300px {
  right: -300px !important; }

.translate-middle {
  transform: translate(-50%, -50%) !important; }

.translate-middle-x {
  transform: translateX(-50%) !important; }

.translate-middle-y {
  transform: translateY(-50%) !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-end {
  border-right: 1px solid #dee2e6 !important; }

.border-end-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-start {
  border-left: 1px solid #dee2e6 !important; }

.border-start-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #194270 !important; }

.border-primary-2 {
  border-color: #38689d !important; }

.border-primary-3 {
  border-color: #10335A !important; }

.border-secondary {
  border-color: #32B4B4 !important; }

.border-secondary-2 {
  border-color: #C8E6CD !important; }

.border-secondary-3 {
  border-color: #EBF0F5 !important; }

.border-secondary-4 {
  border-color: #A8BED5 !important; }

.border-gray-100 {
  border-color: #EBF0F5 !important; }

.border-gray-800 {
  border-color: #444444 !important; }

.border-pink {
  border-color: #8B1F5A !important; }

.border-success {
  border-color: #32B4B4 !important; }

.border-info {
  border-color: #65afe2 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #fff !important; }

.border-dark {
  border-color: #444444 !important; }

.border-mobile {
  border-color: #dee2e6 !important; }

.border-body {
  border-color: #6c757d !important; }

.border-footer {
  border-color: #10335A !important; }

.border-error {
  border-color: #dee2e6 !important; }

.border-burger {
  border-color: #444444 !important; }

.border-white {
  border-color: #fff !important; }

.border-1 {
  border-width: 1px !important; }

.border-2 {
  border-width: 2px !important; }

.border-3 {
  border-width: 3px !important; }

.border-4 {
  border-width: 4px !important; }

.border-5 {
  border-width: 5px !important; }

.w-20 {
  width: 20% !important; }

.w-25 {
  width: 25% !important; }

.w-30 {
  width: 30% !important; }

.w-40 {
  width: 40% !important; }

.w-50 {
  width: 50% !important; }

.w-70 {
  width: 70% !important; }

.w-75 {
  width: 75% !important; }

.w-80 {
  width: 80% !important; }

.w-85 {
  width: 85% !important; }

.w-90 {
  width: 90% !important; }

.w-100 {
  width: 100% !important; }

.w-26px {
  width: 26px !important; }

.w-86px {
  width: 86px !important; }

.w-125px {
  width: 125px !important; }

.w-145px {
  width: 145px !important; }

.w-161px {
  width: 161px !important; }

.w-182px {
  width: 182px !important; }

.w-205px {
  width: 205px !important; }

.w-215px {
  width: 215px !important; }

.w-235px {
  width: 235px !important; }

.w-250px {
  width: 250px !important; }

.w-260px {
  width: 260px !important; }

.w-268px {
  width: 268px !important; }

.w-283px {
  width: 283px !important; }

.w-285px {
  width: 285px !important; }

.w-300px {
  width: 300px !important; }

.w-305px {
  width: 305px !important; }

.w-310px {
  width: 310px !important; }

.w-350px {
  width: 350px !important; }

.w-410px {
  width: 410px !important; }

.w-440px {
  width: 440px !important; }

.w-460px {
  width: 460px !important; }

.w-500px {
  width: 500px !important; }

.w-520px {
  width: 520px !important; }

.w-600px {
  width: 600px !important; }

.w-657px {
  width: 657px !important; }

.w-1015px {
  width: 1015px !important; }

.w-1070px {
  width: 1070px !important; }

.w-auto {
  width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-20 {
  height: 20% !important; }

.h-25 {
  height: 25% !important; }

.h-30 {
  height: 30% !important; }

.h-40 {
  height: 40% !important; }

.h-50 {
  height: 50% !important; }

.h-70 {
  height: 70% !important; }

.h-75 {
  height: 75% !important; }

.h-80 {
  height: 80% !important; }

.h-85 {
  height: 85% !important; }

.h-90 {
  height: 90% !important; }

.h-100 {
  height: 100% !important; }

.h-26px {
  height: 26px !important; }

.h-86px {
  height: 86px !important; }

.h-125px {
  height: 125px !important; }

.h-145px {
  height: 145px !important; }

.h-161px {
  height: 161px !important; }

.h-182px {
  height: 182px !important; }

.h-205px {
  height: 205px !important; }

.h-215px {
  height: 215px !important; }

.h-235px {
  height: 235px !important; }

.h-250px {
  height: 250px !important; }

.h-260px {
  height: 260px !important; }

.h-268px {
  height: 268px !important; }

.h-283px {
  height: 283px !important; }

.h-285px {
  height: 285px !important; }

.h-300px {
  height: 300px !important; }

.h-305px {
  height: 305px !important; }

.h-310px {
  height: 310px !important; }

.h-350px {
  height: 350px !important; }

.h-410px {
  height: 410px !important; }

.h-440px {
  height: 440px !important; }

.h-460px {
  height: 460px !important; }

.h-500px {
  height: 500px !important; }

.h-520px {
  height: 520px !important; }

.h-600px {
  height: 600px !important; }

.h-657px {
  height: 657px !important; }

.h-1015px {
  height: 1015px !important; }

.h-1070px {
  height: 1070px !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.gap-35 {
  gap: 3.5rem !important; }

.gap-34 {
  gap: 3.4rem !important; }

.gap-33 {
  gap: 3.3rem !important; }

.gap-32 {
  gap: 3.2rem !important; }

.gap-31 {
  gap: 3.1rem !important; }

.gap-30 {
  gap: 3rem !important; }

.gap-29 {
  gap: 2.9rem !important; }

.gap-28 {
  gap: 2.8rem !important; }

.gap-27 {
  gap: 2.7rem !important; }

.gap-26 {
  gap: 2.6rem !important; }

.gap-25 {
  gap: 2.5rem !important; }

.gap-24 {
  gap: 2.4rem !important; }

.gap-23 {
  gap: 2.3rem !important; }

.gap-22 {
  gap: 2.2rem !important; }

.gap-21 {
  gap: 2.1rem !important; }

.gap-20 {
  gap: 2rem !important; }

.gap-19 {
  gap: 1.9rem !important; }

.gap-18 {
  gap: 1.8rem !important; }

.gap-17 {
  gap: 1.7rem !important; }

.gap-16 {
  gap: 1.6rem !important; }

.gap-15 {
  gap: 1.5rem !important; }

.gap-14 {
  gap: 1.4rem !important; }

.gap-13 {
  gap: 1.3rem !important; }

.gap-12 {
  gap: 1.2rem !important; }

.gap-11 {
  gap: 1.1rem !important; }

.gap-10 {
  gap: 1rem !important; }

.gap-0 {
  gap: 0 !important; }

.gap-1 {
  gap: 0.25rem !important; }

.gap-2 {
  gap: 0.5rem !important; }

.gap-3 {
  gap: 1rem !important; }

.gap-4 {
  gap: 1.5rem !important; }

.gap-5 {
  gap: 0.5rem !important; }

.gap-6 {
  gap: 0.6rem !important; }

.gap-8 {
  gap: 0.8rem !important; }

.gap-40 {
  gap: 4rem !important; }

.gap-45 {
  gap: 4.5rem !important; }

.gap-50 {
  gap: 5rem !important; }

.gap-55 {
  gap: 5.5rem !important; }

.gap-60 {
  gap: 6rem !important; }

.gap-70 {
  gap: 7rem !important; }

.gap-80 {
  gap: 8rem !important; }

.gap-90 {
  gap: 9rem !important; }

.gap-100 {
  gap: 10rem !important; }

.gap-105 {
  gap: 10.5rem !important; }

.gap-110 {
  gap: 11rem !important; }

.gap-125 {
  gap: 12.5rem !important; }

.gap-130 {
  gap: 13rem !important; }

.gap-145 {
  gap: 14.5rem !important; }

.gap-170 {
  gap: 17rem !important; }

.gap-235 {
  gap: 23.5rem !important; }

.gap-n20 {
  gap: -2rem !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-35 {
  margin: 3.5rem !important; }

.m-34 {
  margin: 3.4rem !important; }

.m-33 {
  margin: 3.3rem !important; }

.m-32 {
  margin: 3.2rem !important; }

.m-31 {
  margin: 3.1rem !important; }

.m-30 {
  margin: 3rem !important; }

.m-29 {
  margin: 2.9rem !important; }

.m-28 {
  margin: 2.8rem !important; }

.m-27 {
  margin: 2.7rem !important; }

.m-26 {
  margin: 2.6rem !important; }

.m-25 {
  margin: 2.5rem !important; }

.m-24 {
  margin: 2.4rem !important; }

.m-23 {
  margin: 2.3rem !important; }

.m-22 {
  margin: 2.2rem !important; }

.m-21 {
  margin: 2.1rem !important; }

.m-20 {
  margin: 2rem !important; }

.m-19 {
  margin: 1.9rem !important; }

.m-18 {
  margin: 1.8rem !important; }

.m-17 {
  margin: 1.7rem !important; }

.m-16 {
  margin: 1.6rem !important; }

.m-15 {
  margin: 1.5rem !important; }

.m-14 {
  margin: 1.4rem !important; }

.m-13 {
  margin: 1.3rem !important; }

.m-12 {
  margin: 1.2rem !important; }

.m-11 {
  margin: 1.1rem !important; }

.m-10 {
  margin: 1rem !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.m-5 {
  margin: 0.5rem !important; }

.m-6 {
  margin: 0.6rem !important; }

.m-8 {
  margin: 0.8rem !important; }

.m-40 {
  margin: 4rem !important; }

.m-45 {
  margin: 4.5rem !important; }

.m-50 {
  margin: 5rem !important; }

.m-55 {
  margin: 5.5rem !important; }

.m-60 {
  margin: 6rem !important; }

.m-70 {
  margin: 7rem !important; }

.m-80 {
  margin: 8rem !important; }

.m-90 {
  margin: 9rem !important; }

.m-100 {
  margin: 10rem !important; }

.m-105 {
  margin: 10.5rem !important; }

.m-110 {
  margin: 11rem !important; }

.m-125 {
  margin: 12.5rem !important; }

.m-130 {
  margin: 13rem !important; }

.m-145 {
  margin: 14.5rem !important; }

.m-170 {
  margin: 17rem !important; }

.m-235 {
  margin: 23.5rem !important; }

.m-n20 {
  margin: -2rem !important; }

.m-auto {
  margin: auto !important; }

.mx-35 {
  margin-right: 3.5rem !important;
  margin-left: 3.5rem !important; }

.mx-34 {
  margin-right: 3.4rem !important;
  margin-left: 3.4rem !important; }

.mx-33 {
  margin-right: 3.3rem !important;
  margin-left: 3.3rem !important; }

.mx-32 {
  margin-right: 3.2rem !important;
  margin-left: 3.2rem !important; }

.mx-31 {
  margin-right: 3.1rem !important;
  margin-left: 3.1rem !important; }

.mx-30 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.mx-29 {
  margin-right: 2.9rem !important;
  margin-left: 2.9rem !important; }

.mx-28 {
  margin-right: 2.8rem !important;
  margin-left: 2.8rem !important; }

.mx-27 {
  margin-right: 2.7rem !important;
  margin-left: 2.7rem !important; }

.mx-26 {
  margin-right: 2.6rem !important;
  margin-left: 2.6rem !important; }

.mx-25 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important; }

.mx-24 {
  margin-right: 2.4rem !important;
  margin-left: 2.4rem !important; }

.mx-23 {
  margin-right: 2.3rem !important;
  margin-left: 2.3rem !important; }

.mx-22 {
  margin-right: 2.2rem !important;
  margin-left: 2.2rem !important; }

.mx-21 {
  margin-right: 2.1rem !important;
  margin-left: 2.1rem !important; }

.mx-20 {
  margin-right: 2rem !important;
  margin-left: 2rem !important; }

.mx-19 {
  margin-right: 1.9rem !important;
  margin-left: 1.9rem !important; }

.mx-18 {
  margin-right: 1.8rem !important;
  margin-left: 1.8rem !important; }

.mx-17 {
  margin-right: 1.7rem !important;
  margin-left: 1.7rem !important; }

.mx-16 {
  margin-right: 1.6rem !important;
  margin-left: 1.6rem !important; }

.mx-15 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-14 {
  margin-right: 1.4rem !important;
  margin-left: 1.4rem !important; }

.mx-13 {
  margin-right: 1.3rem !important;
  margin-left: 1.3rem !important; }

.mx-12 {
  margin-right: 1.2rem !important;
  margin-left: 1.2rem !important; }

.mx-11 {
  margin-right: 1.1rem !important;
  margin-left: 1.1rem !important; }

.mx-10 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-5 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.mx-6 {
  margin-right: 0.6rem !important;
  margin-left: 0.6rem !important; }

.mx-8 {
  margin-right: 0.8rem !important;
  margin-left: 0.8rem !important; }

.mx-40 {
  margin-right: 4rem !important;
  margin-left: 4rem !important; }

.mx-45 {
  margin-right: 4.5rem !important;
  margin-left: 4.5rem !important; }

.mx-50 {
  margin-right: 5rem !important;
  margin-left: 5rem !important; }

.mx-55 {
  margin-right: 5.5rem !important;
  margin-left: 5.5rem !important; }

.mx-60 {
  margin-right: 6rem !important;
  margin-left: 6rem !important; }

.mx-70 {
  margin-right: 7rem !important;
  margin-left: 7rem !important; }

.mx-80 {
  margin-right: 8rem !important;
  margin-left: 8rem !important; }

.mx-90 {
  margin-right: 9rem !important;
  margin-left: 9rem !important; }

.mx-100 {
  margin-right: 10rem !important;
  margin-left: 10rem !important; }

.mx-105 {
  margin-right: 10.5rem !important;
  margin-left: 10.5rem !important; }

.mx-110 {
  margin-right: 11rem !important;
  margin-left: 11rem !important; }

.mx-125 {
  margin-right: 12.5rem !important;
  margin-left: 12.5rem !important; }

.mx-130 {
  margin-right: 13rem !important;
  margin-left: 13rem !important; }

.mx-145 {
  margin-right: 14.5rem !important;
  margin-left: 14.5rem !important; }

.mx-170 {
  margin-right: 17rem !important;
  margin-left: 17rem !important; }

.mx-235 {
  margin-right: 23.5rem !important;
  margin-left: 23.5rem !important; }

.mx-n20 {
  margin-right: -2rem !important;
  margin-left: -2rem !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-35 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important; }

.my-34 {
  margin-top: 3.4rem !important;
  margin-bottom: 3.4rem !important; }

.my-33 {
  margin-top: 3.3rem !important;
  margin-bottom: 3.3rem !important; }

.my-32 {
  margin-top: 3.2rem !important;
  margin-bottom: 3.2rem !important; }

.my-31 {
  margin-top: 3.1rem !important;
  margin-bottom: 3.1rem !important; }

.my-30 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.my-29 {
  margin-top: 2.9rem !important;
  margin-bottom: 2.9rem !important; }

.my-28 {
  margin-top: 2.8rem !important;
  margin-bottom: 2.8rem !important; }

.my-27 {
  margin-top: 2.7rem !important;
  margin-bottom: 2.7rem !important; }

.my-26 {
  margin-top: 2.6rem !important;
  margin-bottom: 2.6rem !important; }

.my-25 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important; }

.my-24 {
  margin-top: 2.4rem !important;
  margin-bottom: 2.4rem !important; }

.my-23 {
  margin-top: 2.3rem !important;
  margin-bottom: 2.3rem !important; }

.my-22 {
  margin-top: 2.2rem !important;
  margin-bottom: 2.2rem !important; }

.my-21 {
  margin-top: 2.1rem !important;
  margin-bottom: 2.1rem !important; }

.my-20 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important; }

.my-19 {
  margin-top: 1.9rem !important;
  margin-bottom: 1.9rem !important; }

.my-18 {
  margin-top: 1.8rem !important;
  margin-bottom: 1.8rem !important; }

.my-17 {
  margin-top: 1.7rem !important;
  margin-bottom: 1.7rem !important; }

.my-16 {
  margin-top: 1.6rem !important;
  margin-bottom: 1.6rem !important; }

.my-15 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-14 {
  margin-top: 1.4rem !important;
  margin-bottom: 1.4rem !important; }

.my-13 {
  margin-top: 1.3rem !important;
  margin-bottom: 1.3rem !important; }

.my-12 {
  margin-top: 1.2rem !important;
  margin-bottom: 1.2rem !important; }

.my-11 {
  margin-top: 1.1rem !important;
  margin-bottom: 1.1rem !important; }

.my-10 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-5 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.my-6 {
  margin-top: 0.6rem !important;
  margin-bottom: 0.6rem !important; }

.my-8 {
  margin-top: 0.8rem !important;
  margin-bottom: 0.8rem !important; }

.my-40 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important; }

.my-45 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important; }

.my-50 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important; }

.my-55 {
  margin-top: 5.5rem !important;
  margin-bottom: 5.5rem !important; }

.my-60 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important; }

.my-70 {
  margin-top: 7rem !important;
  margin-bottom: 7rem !important; }

.my-80 {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important; }

.my-90 {
  margin-top: 9rem !important;
  margin-bottom: 9rem !important; }

.my-100 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important; }

.my-105 {
  margin-top: 10.5rem !important;
  margin-bottom: 10.5rem !important; }

.my-110 {
  margin-top: 11rem !important;
  margin-bottom: 11rem !important; }

.my-125 {
  margin-top: 12.5rem !important;
  margin-bottom: 12.5rem !important; }

.my-130 {
  margin-top: 13rem !important;
  margin-bottom: 13rem !important; }

.my-145 {
  margin-top: 14.5rem !important;
  margin-bottom: 14.5rem !important; }

.my-170 {
  margin-top: 17rem !important;
  margin-bottom: 17rem !important; }

.my-235 {
  margin-top: 23.5rem !important;
  margin-bottom: 23.5rem !important; }

.my-n20 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-35 {
  margin-top: 3.5rem !important; }

.mt-34 {
  margin-top: 3.4rem !important; }

.mt-33 {
  margin-top: 3.3rem !important; }

.mt-32 {
  margin-top: 3.2rem !important; }

.mt-31 {
  margin-top: 3.1rem !important; }

.mt-30 {
  margin-top: 3rem !important; }

.mt-29 {
  margin-top: 2.9rem !important; }

.mt-28 {
  margin-top: 2.8rem !important; }

.mt-27 {
  margin-top: 2.7rem !important; }

.mt-26 {
  margin-top: 2.6rem !important; }

.mt-25 {
  margin-top: 2.5rem !important; }

.mt-24 {
  margin-top: 2.4rem !important; }

.mt-23 {
  margin-top: 2.3rem !important; }

.mt-22 {
  margin-top: 2.2rem !important; }

.mt-21 {
  margin-top: 2.1rem !important; }

.mt-20 {
  margin-top: 2rem !important; }

.mt-19 {
  margin-top: 1.9rem !important; }

.mt-18 {
  margin-top: 1.8rem !important; }

.mt-17 {
  margin-top: 1.7rem !important; }

.mt-16 {
  margin-top: 1.6rem !important; }

.mt-15 {
  margin-top: 1.5rem !important; }

.mt-14 {
  margin-top: 1.4rem !important; }

.mt-13 {
  margin-top: 1.3rem !important; }

.mt-12 {
  margin-top: 1.2rem !important; }

.mt-11 {
  margin-top: 1.1rem !important; }

.mt-10 {
  margin-top: 1rem !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mt-5 {
  margin-top: 0.5rem !important; }

.mt-6 {
  margin-top: 0.6rem !important; }

.mt-8 {
  margin-top: 0.8rem !important; }

.mt-40 {
  margin-top: 4rem !important; }

.mt-45 {
  margin-top: 4.5rem !important; }

.mt-50 {
  margin-top: 5rem !important; }

.mt-55 {
  margin-top: 5.5rem !important; }

.mt-60 {
  margin-top: 6rem !important; }

.mt-70 {
  margin-top: 7rem !important; }

.mt-80 {
  margin-top: 8rem !important; }

.mt-90 {
  margin-top: 9rem !important; }

.mt-100 {
  margin-top: 10rem !important; }

.mt-105 {
  margin-top: 10.5rem !important; }

.mt-110 {
  margin-top: 11rem !important; }

.mt-125 {
  margin-top: 12.5rem !important; }

.mt-130 {
  margin-top: 13rem !important; }

.mt-145 {
  margin-top: 14.5rem !important; }

.mt-170 {
  margin-top: 17rem !important; }

.mt-235 {
  margin-top: 23.5rem !important; }

.mt-n20 {
  margin-top: -2rem !important; }

.mt-auto {
  margin-top: auto !important; }

.me-35 {
  margin-right: 3.5rem !important; }

.me-34 {
  margin-right: 3.4rem !important; }

.me-33 {
  margin-right: 3.3rem !important; }

.me-32 {
  margin-right: 3.2rem !important; }

.me-31 {
  margin-right: 3.1rem !important; }

.me-30 {
  margin-right: 3rem !important; }

.me-29 {
  margin-right: 2.9rem !important; }

.me-28 {
  margin-right: 2.8rem !important; }

.me-27 {
  margin-right: 2.7rem !important; }

.me-26 {
  margin-right: 2.6rem !important; }

.me-25 {
  margin-right: 2.5rem !important; }

.me-24 {
  margin-right: 2.4rem !important; }

.me-23 {
  margin-right: 2.3rem !important; }

.me-22 {
  margin-right: 2.2rem !important; }

.me-21 {
  margin-right: 2.1rem !important; }

.me-20 {
  margin-right: 2rem !important; }

.me-19 {
  margin-right: 1.9rem !important; }

.me-18 {
  margin-right: 1.8rem !important; }

.me-17 {
  margin-right: 1.7rem !important; }

.me-16 {
  margin-right: 1.6rem !important; }

.me-15 {
  margin-right: 1.5rem !important; }

.me-14 {
  margin-right: 1.4rem !important; }

.me-13 {
  margin-right: 1.3rem !important; }

.me-12 {
  margin-right: 1.2rem !important; }

.me-11 {
  margin-right: 1.1rem !important; }

.me-10 {
  margin-right: 1rem !important; }

.me-0 {
  margin-right: 0 !important; }

.me-1 {
  margin-right: 0.25rem !important; }

.me-2 {
  margin-right: 0.5rem !important; }

.me-3 {
  margin-right: 1rem !important; }

.me-4 {
  margin-right: 1.5rem !important; }

.me-5 {
  margin-right: 0.5rem !important; }

.me-6 {
  margin-right: 0.6rem !important; }

.me-8 {
  margin-right: 0.8rem !important; }

.me-40 {
  margin-right: 4rem !important; }

.me-45 {
  margin-right: 4.5rem !important; }

.me-50 {
  margin-right: 5rem !important; }

.me-55 {
  margin-right: 5.5rem !important; }

.me-60 {
  margin-right: 6rem !important; }

.me-70 {
  margin-right: 7rem !important; }

.me-80 {
  margin-right: 8rem !important; }

.me-90 {
  margin-right: 9rem !important; }

.me-100 {
  margin-right: 10rem !important; }

.me-105 {
  margin-right: 10.5rem !important; }

.me-110 {
  margin-right: 11rem !important; }

.me-125 {
  margin-right: 12.5rem !important; }

.me-130 {
  margin-right: 13rem !important; }

.me-145 {
  margin-right: 14.5rem !important; }

.me-170 {
  margin-right: 17rem !important; }

.me-235 {
  margin-right: 23.5rem !important; }

.me-n20 {
  margin-right: -2rem !important; }

.me-auto {
  margin-right: auto !important; }

.mb-35 {
  margin-bottom: 3.5rem !important; }

.mb-34 {
  margin-bottom: 3.4rem !important; }

.mb-33 {
  margin-bottom: 3.3rem !important; }

.mb-32 {
  margin-bottom: 3.2rem !important; }

.mb-31 {
  margin-bottom: 3.1rem !important; }

.mb-30 {
  margin-bottom: 3rem !important; }

.mb-29 {
  margin-bottom: 2.9rem !important; }

.mb-28 {
  margin-bottom: 2.8rem !important; }

.mb-27 {
  margin-bottom: 2.7rem !important; }

.mb-26 {
  margin-bottom: 2.6rem !important; }

.mb-25 {
  margin-bottom: 2.5rem !important; }

.mb-24 {
  margin-bottom: 2.4rem !important; }

.mb-23 {
  margin-bottom: 2.3rem !important; }

.mb-22 {
  margin-bottom: 2.2rem !important; }

.mb-21 {
  margin-bottom: 2.1rem !important; }

.mb-20 {
  margin-bottom: 2rem !important; }

.mb-19 {
  margin-bottom: 1.9rem !important; }

.mb-18 {
  margin-bottom: 1.8rem !important; }

.mb-17 {
  margin-bottom: 1.7rem !important; }

.mb-16 {
  margin-bottom: 1.6rem !important; }

.mb-15 {
  margin-bottom: 1.5rem !important; }

.mb-14 {
  margin-bottom: 1.4rem !important; }

.mb-13 {
  margin-bottom: 1.3rem !important; }

.mb-12 {
  margin-bottom: 1.2rem !important; }

.mb-11 {
  margin-bottom: 1.1rem !important; }

.mb-10 {
  margin-bottom: 1rem !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.mb-5 {
  margin-bottom: 0.5rem !important; }

.mb-6 {
  margin-bottom: 0.6rem !important; }

.mb-8 {
  margin-bottom: 0.8rem !important; }

.mb-40 {
  margin-bottom: 4rem !important; }

.mb-45 {
  margin-bottom: 4.5rem !important; }

.mb-50 {
  margin-bottom: 5rem !important; }

.mb-55 {
  margin-bottom: 5.5rem !important; }

.mb-60 {
  margin-bottom: 6rem !important; }

.mb-70 {
  margin-bottom: 7rem !important; }

.mb-80 {
  margin-bottom: 8rem !important; }

.mb-90 {
  margin-bottom: 9rem !important; }

.mb-100 {
  margin-bottom: 10rem !important; }

.mb-105 {
  margin-bottom: 10.5rem !important; }

.mb-110 {
  margin-bottom: 11rem !important; }

.mb-125 {
  margin-bottom: 12.5rem !important; }

.mb-130 {
  margin-bottom: 13rem !important; }

.mb-145 {
  margin-bottom: 14.5rem !important; }

.mb-170 {
  margin-bottom: 17rem !important; }

.mb-235 {
  margin-bottom: 23.5rem !important; }

.mb-n20 {
  margin-bottom: -2rem !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ms-35 {
  margin-left: 3.5rem !important; }

.ms-34 {
  margin-left: 3.4rem !important; }

.ms-33 {
  margin-left: 3.3rem !important; }

.ms-32 {
  margin-left: 3.2rem !important; }

.ms-31 {
  margin-left: 3.1rem !important; }

.ms-30 {
  margin-left: 3rem !important; }

.ms-29 {
  margin-left: 2.9rem !important; }

.ms-28 {
  margin-left: 2.8rem !important; }

.ms-27 {
  margin-left: 2.7rem !important; }

.ms-26 {
  margin-left: 2.6rem !important; }

.ms-25 {
  margin-left: 2.5rem !important; }

.ms-24 {
  margin-left: 2.4rem !important; }

.ms-23 {
  margin-left: 2.3rem !important; }

.ms-22 {
  margin-left: 2.2rem !important; }

.ms-21 {
  margin-left: 2.1rem !important; }

.ms-20 {
  margin-left: 2rem !important; }

.ms-19 {
  margin-left: 1.9rem !important; }

.ms-18 {
  margin-left: 1.8rem !important; }

.ms-17 {
  margin-left: 1.7rem !important; }

.ms-16 {
  margin-left: 1.6rem !important; }

.ms-15 {
  margin-left: 1.5rem !important; }

.ms-14 {
  margin-left: 1.4rem !important; }

.ms-13 {
  margin-left: 1.3rem !important; }

.ms-12 {
  margin-left: 1.2rem !important; }

.ms-11 {
  margin-left: 1.1rem !important; }

.ms-10 {
  margin-left: 1rem !important; }

.ms-0 {
  margin-left: 0 !important; }

.ms-1 {
  margin-left: 0.25rem !important; }

.ms-2 {
  margin-left: 0.5rem !important; }

.ms-3 {
  margin-left: 1rem !important; }

.ms-4 {
  margin-left: 1.5rem !important; }

.ms-5 {
  margin-left: 0.5rem !important; }

.ms-6 {
  margin-left: 0.6rem !important; }

.ms-8 {
  margin-left: 0.8rem !important; }

.ms-40 {
  margin-left: 4rem !important; }

.ms-45 {
  margin-left: 4.5rem !important; }

.ms-50 {
  margin-left: 5rem !important; }

.ms-55 {
  margin-left: 5.5rem !important; }

.ms-60 {
  margin-left: 6rem !important; }

.ms-70 {
  margin-left: 7rem !important; }

.ms-80 {
  margin-left: 8rem !important; }

.ms-90 {
  margin-left: 9rem !important; }

.ms-100 {
  margin-left: 10rem !important; }

.ms-105 {
  margin-left: 10.5rem !important; }

.ms-110 {
  margin-left: 11rem !important; }

.ms-125 {
  margin-left: 12.5rem !important; }

.ms-130 {
  margin-left: 13rem !important; }

.ms-145 {
  margin-left: 14.5rem !important; }

.ms-170 {
  margin-left: 17rem !important; }

.ms-235 {
  margin-left: 23.5rem !important; }

.ms-n20 {
  margin-left: -2rem !important; }

.ms-auto {
  margin-left: auto !important; }

.p-35 {
  padding: 3.5rem !important; }

.p-34 {
  padding: 3.4rem !important; }

.p-33 {
  padding: 3.3rem !important; }

.p-32 {
  padding: 3.2rem !important; }

.p-31 {
  padding: 3.1rem !important; }

.p-30 {
  padding: 3rem !important; }

.p-29 {
  padding: 2.9rem !important; }

.p-28 {
  padding: 2.8rem !important; }

.p-27 {
  padding: 2.7rem !important; }

.p-26 {
  padding: 2.6rem !important; }

.p-25 {
  padding: 2.5rem !important; }

.p-24 {
  padding: 2.4rem !important; }

.p-23 {
  padding: 2.3rem !important; }

.p-22 {
  padding: 2.2rem !important; }

.p-21 {
  padding: 2.1rem !important; }

.p-20 {
  padding: 2rem !important; }

.p-19 {
  padding: 1.9rem !important; }

.p-18 {
  padding: 1.8rem !important; }

.p-17 {
  padding: 1.7rem !important; }

.p-16 {
  padding: 1.6rem !important; }

.p-15 {
  padding: 1.5rem !important; }

.p-14 {
  padding: 1.4rem !important; }

.p-13 {
  padding: 1.3rem !important; }

.p-12 {
  padding: 1.2rem !important; }

.p-11 {
  padding: 1.1rem !important; }

.p-10 {
  padding: 1rem !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.p-5 {
  padding: 0.5rem !important; }

.p-6 {
  padding: 0.6rem !important; }

.p-8 {
  padding: 0.8rem !important; }

.p-40 {
  padding: 4rem !important; }

.p-45 {
  padding: 4.5rem !important; }

.p-50 {
  padding: 5rem !important; }

.p-55 {
  padding: 5.5rem !important; }

.p-60 {
  padding: 6rem !important; }

.p-70 {
  padding: 7rem !important; }

.p-80 {
  padding: 8rem !important; }

.p-90 {
  padding: 9rem !important; }

.p-100 {
  padding: 10rem !important; }

.p-105 {
  padding: 10.5rem !important; }

.p-110 {
  padding: 11rem !important; }

.p-125 {
  padding: 12.5rem !important; }

.p-130 {
  padding: 13rem !important; }

.p-145 {
  padding: 14.5rem !important; }

.p-170 {
  padding: 17rem !important; }

.p-235 {
  padding: 23.5rem !important; }

.p-n20 {
  padding: -2rem !important; }

.px-35 {
  padding-right: 3.5rem !important;
  padding-left: 3.5rem !important; }

.px-34 {
  padding-right: 3.4rem !important;
  padding-left: 3.4rem !important; }

.px-33 {
  padding-right: 3.3rem !important;
  padding-left: 3.3rem !important; }

.px-32 {
  padding-right: 3.2rem !important;
  padding-left: 3.2rem !important; }

.px-31 {
  padding-right: 3.1rem !important;
  padding-left: 3.1rem !important; }

.px-30 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.px-29 {
  padding-right: 2.9rem !important;
  padding-left: 2.9rem !important; }

.px-28 {
  padding-right: 2.8rem !important;
  padding-left: 2.8rem !important; }

.px-27 {
  padding-right: 2.7rem !important;
  padding-left: 2.7rem !important; }

.px-26 {
  padding-right: 2.6rem !important;
  padding-left: 2.6rem !important; }

.px-25 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important; }

.px-24 {
  padding-right: 2.4rem !important;
  padding-left: 2.4rem !important; }

.px-23 {
  padding-right: 2.3rem !important;
  padding-left: 2.3rem !important; }

.px-22 {
  padding-right: 2.2rem !important;
  padding-left: 2.2rem !important; }

.px-21 {
  padding-right: 2.1rem !important;
  padding-left: 2.1rem !important; }

.px-20 {
  padding-right: 2rem !important;
  padding-left: 2rem !important; }

.px-19 {
  padding-right: 1.9rem !important;
  padding-left: 1.9rem !important; }

.px-18 {
  padding-right: 1.8rem !important;
  padding-left: 1.8rem !important; }

.px-17 {
  padding-right: 1.7rem !important;
  padding-left: 1.7rem !important; }

.px-16 {
  padding-right: 1.6rem !important;
  padding-left: 1.6rem !important; }

.px-15 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-14 {
  padding-right: 1.4rem !important;
  padding-left: 1.4rem !important; }

.px-13 {
  padding-right: 1.3rem !important;
  padding-left: 1.3rem !important; }

.px-12 {
  padding-right: 1.2rem !important;
  padding-left: 1.2rem !important; }

.px-11 {
  padding-right: 1.1rem !important;
  padding-left: 1.1rem !important; }

.px-10 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-5 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.px-6 {
  padding-right: 0.6rem !important;
  padding-left: 0.6rem !important; }

.px-8 {
  padding-right: 0.8rem !important;
  padding-left: 0.8rem !important; }

.px-40 {
  padding-right: 4rem !important;
  padding-left: 4rem !important; }

.px-45 {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important; }

.px-50 {
  padding-right: 5rem !important;
  padding-left: 5rem !important; }

.px-55 {
  padding-right: 5.5rem !important;
  padding-left: 5.5rem !important; }

.px-60 {
  padding-right: 6rem !important;
  padding-left: 6rem !important; }

.px-70 {
  padding-right: 7rem !important;
  padding-left: 7rem !important; }

.px-80 {
  padding-right: 8rem !important;
  padding-left: 8rem !important; }

.px-90 {
  padding-right: 9rem !important;
  padding-left: 9rem !important; }

.px-100 {
  padding-right: 10rem !important;
  padding-left: 10rem !important; }

.px-105 {
  padding-right: 10.5rem !important;
  padding-left: 10.5rem !important; }

.px-110 {
  padding-right: 11rem !important;
  padding-left: 11rem !important; }

.px-125 {
  padding-right: 12.5rem !important;
  padding-left: 12.5rem !important; }

.px-130 {
  padding-right: 13rem !important;
  padding-left: 13rem !important; }

.px-145 {
  padding-right: 14.5rem !important;
  padding-left: 14.5rem !important; }

.px-170 {
  padding-right: 17rem !important;
  padding-left: 17rem !important; }

.px-235 {
  padding-right: 23.5rem !important;
  padding-left: 23.5rem !important; }

.px-n20 {
  padding-right: -2rem !important;
  padding-left: -2rem !important; }

.py-35 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important; }

.py-34 {
  padding-top: 3.4rem !important;
  padding-bottom: 3.4rem !important; }

.py-33 {
  padding-top: 3.3rem !important;
  padding-bottom: 3.3rem !important; }

.py-32 {
  padding-top: 3.2rem !important;
  padding-bottom: 3.2rem !important; }

.py-31 {
  padding-top: 3.1rem !important;
  padding-bottom: 3.1rem !important; }

.py-30 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.py-29 {
  padding-top: 2.9rem !important;
  padding-bottom: 2.9rem !important; }

.py-28 {
  padding-top: 2.8rem !important;
  padding-bottom: 2.8rem !important; }

.py-27 {
  padding-top: 2.7rem !important;
  padding-bottom: 2.7rem !important; }

.py-26 {
  padding-top: 2.6rem !important;
  padding-bottom: 2.6rem !important; }

.py-25 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important; }

.py-24 {
  padding-top: 2.4rem !important;
  padding-bottom: 2.4rem !important; }

.py-23 {
  padding-top: 2.3rem !important;
  padding-bottom: 2.3rem !important; }

.py-22 {
  padding-top: 2.2rem !important;
  padding-bottom: 2.2rem !important; }

.py-21 {
  padding-top: 2.1rem !important;
  padding-bottom: 2.1rem !important; }

.py-20 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important; }

.py-19 {
  padding-top: 1.9rem !important;
  padding-bottom: 1.9rem !important; }

.py-18 {
  padding-top: 1.8rem !important;
  padding-bottom: 1.8rem !important; }

.py-17 {
  padding-top: 1.7rem !important;
  padding-bottom: 1.7rem !important; }

.py-16 {
  padding-top: 1.6rem !important;
  padding-bottom: 1.6rem !important; }

.py-15 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-14 {
  padding-top: 1.4rem !important;
  padding-bottom: 1.4rem !important; }

.py-13 {
  padding-top: 1.3rem !important;
  padding-bottom: 1.3rem !important; }

.py-12 {
  padding-top: 1.2rem !important;
  padding-bottom: 1.2rem !important; }

.py-11 {
  padding-top: 1.1rem !important;
  padding-bottom: 1.1rem !important; }

.py-10 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-5 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.py-6 {
  padding-top: 0.6rem !important;
  padding-bottom: 0.6rem !important; }

.py-8 {
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important; }

.py-40 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important; }

.py-45 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important; }

.py-50 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important; }

.py-55 {
  padding-top: 5.5rem !important;
  padding-bottom: 5.5rem !important; }

.py-60 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important; }

.py-70 {
  padding-top: 7rem !important;
  padding-bottom: 7rem !important; }

.py-80 {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important; }

.py-90 {
  padding-top: 9rem !important;
  padding-bottom: 9rem !important; }

.py-100 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important; }

.py-105 {
  padding-top: 10.5rem !important;
  padding-bottom: 10.5rem !important; }

.py-110 {
  padding-top: 11rem !important;
  padding-bottom: 11rem !important; }

.py-125 {
  padding-top: 12.5rem !important;
  padding-bottom: 12.5rem !important; }

.py-130 {
  padding-top: 13rem !important;
  padding-bottom: 13rem !important; }

.py-145 {
  padding-top: 14.5rem !important;
  padding-bottom: 14.5rem !important; }

.py-170 {
  padding-top: 17rem !important;
  padding-bottom: 17rem !important; }

.py-235 {
  padding-top: 23.5rem !important;
  padding-bottom: 23.5rem !important; }

.py-n20 {
  padding-top: -2rem !important;
  padding-bottom: -2rem !important; }

.pt-35 {
  padding-top: 3.5rem !important; }

.pt-34 {
  padding-top: 3.4rem !important; }

.pt-33 {
  padding-top: 3.3rem !important; }

.pt-32 {
  padding-top: 3.2rem !important; }

.pt-31 {
  padding-top: 3.1rem !important; }

.pt-30 {
  padding-top: 3rem !important; }

.pt-29 {
  padding-top: 2.9rem !important; }

.pt-28 {
  padding-top: 2.8rem !important; }

.pt-27 {
  padding-top: 2.7rem !important; }

.pt-26 {
  padding-top: 2.6rem !important; }

.pt-25 {
  padding-top: 2.5rem !important; }

.pt-24 {
  padding-top: 2.4rem !important; }

.pt-23 {
  padding-top: 2.3rem !important; }

.pt-22 {
  padding-top: 2.2rem !important; }

.pt-21 {
  padding-top: 2.1rem !important; }

.pt-20 {
  padding-top: 2rem !important; }

.pt-19 {
  padding-top: 1.9rem !important; }

.pt-18 {
  padding-top: 1.8rem !important; }

.pt-17 {
  padding-top: 1.7rem !important; }

.pt-16 {
  padding-top: 1.6rem !important; }

.pt-15 {
  padding-top: 1.5rem !important; }

.pt-14 {
  padding-top: 1.4rem !important; }

.pt-13 {
  padding-top: 1.3rem !important; }

.pt-12 {
  padding-top: 1.2rem !important; }

.pt-11 {
  padding-top: 1.1rem !important; }

.pt-10 {
  padding-top: 1rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pt-5 {
  padding-top: 0.5rem !important; }

.pt-6 {
  padding-top: 0.6rem !important; }

.pt-8 {
  padding-top: 0.8rem !important; }

.pt-40 {
  padding-top: 4rem !important; }

.pt-45 {
  padding-top: 4.5rem !important; }

.pt-50 {
  padding-top: 5rem !important; }

.pt-55 {
  padding-top: 5.5rem !important; }

.pt-60 {
  padding-top: 6rem !important; }

.pt-70 {
  padding-top: 7rem !important; }

.pt-80 {
  padding-top: 8rem !important; }

.pt-90 {
  padding-top: 9rem !important; }

.pt-100 {
  padding-top: 10rem !important; }

.pt-105 {
  padding-top: 10.5rem !important; }

.pt-110 {
  padding-top: 11rem !important; }

.pt-125 {
  padding-top: 12.5rem !important; }

.pt-130 {
  padding-top: 13rem !important; }

.pt-145 {
  padding-top: 14.5rem !important; }

.pt-170 {
  padding-top: 17rem !important; }

.pt-235 {
  padding-top: 23.5rem !important; }

.pt-n20 {
  padding-top: -2rem !important; }

.pe-35 {
  padding-right: 3.5rem !important; }

.pe-34 {
  padding-right: 3.4rem !important; }

.pe-33 {
  padding-right: 3.3rem !important; }

.pe-32 {
  padding-right: 3.2rem !important; }

.pe-31 {
  padding-right: 3.1rem !important; }

.pe-30 {
  padding-right: 3rem !important; }

.pe-29 {
  padding-right: 2.9rem !important; }

.pe-28 {
  padding-right: 2.8rem !important; }

.pe-27 {
  padding-right: 2.7rem !important; }

.pe-26 {
  padding-right: 2.6rem !important; }

.pe-25 {
  padding-right: 2.5rem !important; }

.pe-24 {
  padding-right: 2.4rem !important; }

.pe-23 {
  padding-right: 2.3rem !important; }

.pe-22 {
  padding-right: 2.2rem !important; }

.pe-21 {
  padding-right: 2.1rem !important; }

.pe-20 {
  padding-right: 2rem !important; }

.pe-19 {
  padding-right: 1.9rem !important; }

.pe-18 {
  padding-right: 1.8rem !important; }

.pe-17 {
  padding-right: 1.7rem !important; }

.pe-16 {
  padding-right: 1.6rem !important; }

.pe-15 {
  padding-right: 1.5rem !important; }

.pe-14 {
  padding-right: 1.4rem !important; }

.pe-13 {
  padding-right: 1.3rem !important; }

.pe-12 {
  padding-right: 1.2rem !important; }

.pe-11 {
  padding-right: 1.1rem !important; }

.pe-10 {
  padding-right: 1rem !important; }

.pe-0 {
  padding-right: 0 !important; }

.pe-1 {
  padding-right: 0.25rem !important; }

.pe-2 {
  padding-right: 0.5rem !important; }

.pe-3 {
  padding-right: 1rem !important; }

.pe-4 {
  padding-right: 1.5rem !important; }

.pe-5 {
  padding-right: 0.5rem !important; }

.pe-6 {
  padding-right: 0.6rem !important; }

.pe-8 {
  padding-right: 0.8rem !important; }

.pe-40 {
  padding-right: 4rem !important; }

.pe-45 {
  padding-right: 4.5rem !important; }

.pe-50 {
  padding-right: 5rem !important; }

.pe-55 {
  padding-right: 5.5rem !important; }

.pe-60 {
  padding-right: 6rem !important; }

.pe-70 {
  padding-right: 7rem !important; }

.pe-80 {
  padding-right: 8rem !important; }

.pe-90 {
  padding-right: 9rem !important; }

.pe-100 {
  padding-right: 10rem !important; }

.pe-105 {
  padding-right: 10.5rem !important; }

.pe-110 {
  padding-right: 11rem !important; }

.pe-125 {
  padding-right: 12.5rem !important; }

.pe-130 {
  padding-right: 13rem !important; }

.pe-145 {
  padding-right: 14.5rem !important; }

.pe-170 {
  padding-right: 17rem !important; }

.pe-235 {
  padding-right: 23.5rem !important; }

.pe-n20 {
  padding-right: -2rem !important; }

.pb-35 {
  padding-bottom: 3.5rem !important; }

.pb-34 {
  padding-bottom: 3.4rem !important; }

.pb-33 {
  padding-bottom: 3.3rem !important; }

.pb-32 {
  padding-bottom: 3.2rem !important; }

.pb-31 {
  padding-bottom: 3.1rem !important; }

.pb-30 {
  padding-bottom: 3rem !important; }

.pb-29 {
  padding-bottom: 2.9rem !important; }

.pb-28 {
  padding-bottom: 2.8rem !important; }

.pb-27 {
  padding-bottom: 2.7rem !important; }

.pb-26 {
  padding-bottom: 2.6rem !important; }

.pb-25 {
  padding-bottom: 2.5rem !important; }

.pb-24 {
  padding-bottom: 2.4rem !important; }

.pb-23 {
  padding-bottom: 2.3rem !important; }

.pb-22 {
  padding-bottom: 2.2rem !important; }

.pb-21 {
  padding-bottom: 2.1rem !important; }

.pb-20 {
  padding-bottom: 2rem !important; }

.pb-19 {
  padding-bottom: 1.9rem !important; }

.pb-18 {
  padding-bottom: 1.8rem !important; }

.pb-17 {
  padding-bottom: 1.7rem !important; }

.pb-16 {
  padding-bottom: 1.6rem !important; }

.pb-15 {
  padding-bottom: 1.5rem !important; }

.pb-14 {
  padding-bottom: 1.4rem !important; }

.pb-13 {
  padding-bottom: 1.3rem !important; }

.pb-12 {
  padding-bottom: 1.2rem !important; }

.pb-11 {
  padding-bottom: 1.1rem !important; }

.pb-10 {
  padding-bottom: 1rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pb-5 {
  padding-bottom: 0.5rem !important; }

.pb-6 {
  padding-bottom: 0.6rem !important; }

.pb-8 {
  padding-bottom: 0.8rem !important; }

.pb-40 {
  padding-bottom: 4rem !important; }

.pb-45 {
  padding-bottom: 4.5rem !important; }

.pb-50 {
  padding-bottom: 5rem !important; }

.pb-55 {
  padding-bottom: 5.5rem !important; }

.pb-60 {
  padding-bottom: 6rem !important; }

.pb-70 {
  padding-bottom: 7rem !important; }

.pb-80 {
  padding-bottom: 8rem !important; }

.pb-90 {
  padding-bottom: 9rem !important; }

.pb-100 {
  padding-bottom: 10rem !important; }

.pb-105 {
  padding-bottom: 10.5rem !important; }

.pb-110 {
  padding-bottom: 11rem !important; }

.pb-125 {
  padding-bottom: 12.5rem !important; }

.pb-130 {
  padding-bottom: 13rem !important; }

.pb-145 {
  padding-bottom: 14.5rem !important; }

.pb-170 {
  padding-bottom: 17rem !important; }

.pb-235 {
  padding-bottom: 23.5rem !important; }

.pb-n20 {
  padding-bottom: -2rem !important; }

.ps-35 {
  padding-left: 3.5rem !important; }

.ps-34 {
  padding-left: 3.4rem !important; }

.ps-33 {
  padding-left: 3.3rem !important; }

.ps-32 {
  padding-left: 3.2rem !important; }

.ps-31 {
  padding-left: 3.1rem !important; }

.ps-30 {
  padding-left: 3rem !important; }

.ps-29 {
  padding-left: 2.9rem !important; }

.ps-28 {
  padding-left: 2.8rem !important; }

.ps-27 {
  padding-left: 2.7rem !important; }

.ps-26 {
  padding-left: 2.6rem !important; }

.ps-25 {
  padding-left: 2.5rem !important; }

.ps-24 {
  padding-left: 2.4rem !important; }

.ps-23 {
  padding-left: 2.3rem !important; }

.ps-22 {
  padding-left: 2.2rem !important; }

.ps-21 {
  padding-left: 2.1rem !important; }

.ps-20 {
  padding-left: 2rem !important; }

.ps-19 {
  padding-left: 1.9rem !important; }

.ps-18 {
  padding-left: 1.8rem !important; }

.ps-17 {
  padding-left: 1.7rem !important; }

.ps-16 {
  padding-left: 1.6rem !important; }

.ps-15 {
  padding-left: 1.5rem !important; }

.ps-14 {
  padding-left: 1.4rem !important; }

.ps-13 {
  padding-left: 1.3rem !important; }

.ps-12 {
  padding-left: 1.2rem !important; }

.ps-11 {
  padding-left: 1.1rem !important; }

.ps-10 {
  padding-left: 1rem !important; }

.ps-0 {
  padding-left: 0 !important; }

.ps-1 {
  padding-left: 0.25rem !important; }

.ps-2 {
  padding-left: 0.5rem !important; }

.ps-3 {
  padding-left: 1rem !important; }

.ps-4 {
  padding-left: 1.5rem !important; }

.ps-5 {
  padding-left: 0.5rem !important; }

.ps-6 {
  padding-left: 0.6rem !important; }

.ps-8 {
  padding-left: 0.8rem !important; }

.ps-40 {
  padding-left: 4rem !important; }

.ps-45 {
  padding-left: 4.5rem !important; }

.ps-50 {
  padding-left: 5rem !important; }

.ps-55 {
  padding-left: 5.5rem !important; }

.ps-60 {
  padding-left: 6rem !important; }

.ps-70 {
  padding-left: 7rem !important; }

.ps-80 {
  padding-left: 8rem !important; }

.ps-90 {
  padding-left: 9rem !important; }

.ps-100 {
  padding-left: 10rem !important; }

.ps-105 {
  padding-left: 10.5rem !important; }

.ps-110 {
  padding-left: 11rem !important; }

.ps-125 {
  padding-left: 12.5rem !important; }

.ps-130 {
  padding-left: 13rem !important; }

.ps-145 {
  padding-left: 14.5rem !important; }

.ps-170 {
  padding-left: 17rem !important; }

.ps-235 {
  padding-left: 23.5rem !important; }

.ps-n20 {
  padding-left: -2rem !important; }

.font-family-02 {
  font-family: "Caveat", cursive !important; }

.fs-40 {
  font-size: 4rem !important; }

.fs-39 {
  font-size: 3.9rem !important; }

.fs-38 {
  font-size: 3.8rem !important; }

.fs-37 {
  font-size: 3.7rem !important; }

.fs-36 {
  font-size: 3.6rem !important; }

.fs-35 {
  font-size: 3.5rem !important; }

.fs-34 {
  font-size: 3.4rem !important; }

.fs-33 {
  font-size: 3.3rem !important; }

.fs-32 {
  font-size: 3.2rem !important; }

.fs-31 {
  font-size: 3.1rem !important; }

.fs-30 {
  font-size: 3rem !important; }

.fs-29 {
  font-size: 2.9rem !important; }

.fs-28 {
  font-size: 2.8rem !important; }

.fs-27 {
  font-size: 2.7rem !important; }

.fs-26 {
  font-size: 2.6rem !important; }

.fs-25 {
  font-size: 2.5rem !important; }

.fs-24 {
  font-size: 2.4rem !important; }

.fs-23 {
  font-size: 2.3rem !important; }

.fs-22 {
  font-size: 2.2rem !important; }

.fs-21 {
  font-size: 2.1rem !important; }

.fs-20 {
  font-size: 2rem !important; }

.fs-19 {
  font-size: 1.9rem !important; }

.fs-18 {
  font-size: 1.8rem !important; }

.fs-17 {
  font-size: 1.7rem !important; }

.fs-16 {
  font-size: 1.6rem !important; }

.fs-15 {
  font-size: 1.5rem !important; }

.fs-14 {
  font-size: 1.4rem !important; }

.fs-13 {
  font-size: 1.3rem !important; }

.fs-12 {
  font-size: 1.2rem !important; }

.fs-11 {
  font-size: 1.1rem !important; }

.fs-10 {
  font-size: 1rem !important; }

.fs-60 {
  font-size: calc(1.725rem + 5.7vw) !important; }

.fst-italic {
  font-style: italic !important; }

.fst-normal {
  font-style: normal !important; }

.fw-light {
  font-weight: 300 !important; }

.fw-normal {
  font-weight: 400 !important; }

.fw-medium {
  font-weight: 500 !important; }

.fw-semi-bold {
  font-weight: 600 !important; }

.fw-bold {
  font-weight: 700 !important; }

.lh-35 {
  line-height: 3.5rem !important; }

.lh-34 {
  line-height: 3.4rem !important; }

.lh-33 {
  line-height: 3.3rem !important; }

.lh-32 {
  line-height: 3.2rem !important; }

.lh-31 {
  line-height: 3.1rem !important; }

.lh-30 {
  line-height: 3rem !important; }

.lh-29 {
  line-height: 2.9rem !important; }

.lh-28 {
  line-height: 2.8rem !important; }

.lh-27 {
  line-height: 2.7rem !important; }

.lh-26 {
  line-height: 2.6rem !important; }

.lh-25 {
  line-height: 2.5rem !important; }

.lh-24 {
  line-height: 2.4rem !important; }

.lh-23 {
  line-height: 2.3rem !important; }

.lh-22 {
  line-height: 2.2rem !important; }

.lh-21 {
  line-height: 2.1rem !important; }

.lh-20 {
  line-height: 2rem !important; }

.lh-19 {
  line-height: 1.9rem !important; }

.lh-18 {
  line-height: 1.8rem !important; }

.lh-17 {
  line-height: 1.7rem !important; }

.lh-16 {
  line-height: 1.6rem !important; }

.lh-15 {
  line-height: 1.5rem !important; }

.lh-14 {
  line-height: 1.4rem !important; }

.lh-13 {
  line-height: 1.3rem !important; }

.lh-12 {
  line-height: 1.2rem !important; }

.lh-11 {
  line-height: 1.1rem !important; }

.lh-10 {
  line-height: 1rem !important; }

.lh-0 {
  line-height: 0 !important; }

.lh-1 {
  line-height: 0.25rem !important; }

.lh-2 {
  line-height: 0.5rem !important; }

.lh-3 {
  line-height: 1rem !important; }

.lh-4 {
  line-height: 1.5rem !important; }

.lh-5 {
  line-height: 0.5rem !important; }

.lh-6 {
  line-height: 0.6rem !important; }

.lh-8 {
  line-height: 0.8rem !important; }

.lh-40 {
  line-height: 4rem !important; }

.lh-45 {
  line-height: 4.5rem !important; }

.lh-50 {
  line-height: 5rem !important; }

.lh-55 {
  line-height: 5.5rem !important; }

.lh-60 {
  line-height: 6rem !important; }

.lh-70 {
  line-height: 7rem !important; }

.lh-80 {
  line-height: 8rem !important; }

.lh-90 {
  line-height: 9rem !important; }

.lh-100 {
  line-height: 10rem !important; }

.lh-105 {
  line-height: 10.5rem !important; }

.lh-110 {
  line-height: 11rem !important; }

.lh-125 {
  line-height: 12.5rem !important; }

.lh-130 {
  line-height: 13rem !important; }

.lh-145 {
  line-height: 14.5rem !important; }

.lh-170 {
  line-height: 17rem !important; }

.lh-235 {
  line-height: 23.5rem !important; }

.lh-n20 {
  line-height: -2rem !important; }

.text-start {
  text-align: left !important; }

.text-end {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-decoration-line-through {
  text-decoration: line-through !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important; }

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important; }

.text-primary-2 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-2-rgb), var(--bs-text-opacity)) !important; }

.text-primary-3 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-3-rgb), var(--bs-text-opacity)) !important; }

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important; }

.text-secondary-2 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-2-rgb), var(--bs-text-opacity)) !important; }

.text-secondary-3 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-3-rgb), var(--bs-text-opacity)) !important; }

.text-secondary-4 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-4-rgb), var(--bs-text-opacity)) !important; }

.text-gray-100 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-100-rgb), var(--bs-text-opacity)) !important; }

.text-gray-800 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-800-rgb), var(--bs-text-opacity)) !important; }

.text-pink {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-pink-rgb), var(--bs-text-opacity)) !important; }

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important; }

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important; }

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important; }

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important; }

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important; }

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important; }

.text-mobile {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-mobile-rgb), var(--bs-text-opacity)) !important; }

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important; }

.text-footer {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-footer-rgb), var(--bs-text-opacity)) !important; }

.text-error {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-error-rgb), var(--bs-text-opacity)) !important; }

.text-burger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-burger-rgb), var(--bs-text-opacity)) !important; }

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important; }

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important; }

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important; }

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important; }

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important; }

.text-opacity-25 {
  --bs-text-opacity: 0.25; }

.text-opacity-50 {
  --bs-text-opacity: 0.5; }

.text-opacity-75 {
  --bs-text-opacity: 0.75; }

.text-opacity-100 {
  --bs-text-opacity: 1; }

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important; }

.bg-primary-2 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-2-rgb), var(--bs-bg-opacity)) !important; }

.bg-primary-3 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-3-rgb), var(--bs-bg-opacity)) !important; }

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important; }

.bg-secondary-2 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-2-rgb), var(--bs-bg-opacity)) !important; }

.bg-secondary-3 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-3-rgb), var(--bs-bg-opacity)) !important; }

.bg-secondary-4 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-4-rgb), var(--bs-bg-opacity)) !important; }

.bg-gray-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-100-rgb), var(--bs-bg-opacity)) !important; }

.bg-gray-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-800-rgb), var(--bs-bg-opacity)) !important; }

.bg-pink {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-pink-rgb), var(--bs-bg-opacity)) !important; }

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important; }

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important; }

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important; }

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important; }

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important; }

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important; }

.bg-mobile {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-mobile-rgb), var(--bs-bg-opacity)) !important; }

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important; }

.bg-footer {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-footer-rgb), var(--bs-bg-opacity)) !important; }

.bg-error {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-error-rgb), var(--bs-bg-opacity)) !important; }

.bg-burger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-burger-rgb), var(--bs-bg-opacity)) !important; }

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important; }

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important; }

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important; }

.bg-opacity-10 {
  --bs-bg-opacity: 0.1; }

.bg-opacity-25 {
  --bs-bg-opacity: 0.25; }

.bg-opacity-50 {
  --bs-bg-opacity: 0.5; }

.bg-opacity-75 {
  --bs-bg-opacity: 0.75; }

.bg-opacity-100 {
  --bs-bg-opacity: 1; }

.bg-gradient {
  background-image: var(--bs-gradient) !important; }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.pe-none {
  pointer-events: none !important; }

.pe-auto {
  pointer-events: auto !important; }

.rounded-20 {
  border-radius: 2rem !important; }

.rounded-19 {
  border-radius: 1.9rem !important; }

.rounded-18 {
  border-radius: 1.8rem !important; }

.rounded-17 {
  border-radius: 1.7rem !important; }

.rounded-16 {
  border-radius: 1.6rem !important; }

.rounded-15 {
  border-radius: 1.5rem !important; }

.rounded-14 {
  border-radius: 1.4rem !important; }

.rounded-13 {
  border-radius: 1.3rem !important; }

.rounded-12 {
  border-radius: 1.2rem !important; }

.rounded-11 {
  border-radius: 1.1rem !important; }

.rounded-10 {
  border-radius: 1rem !important; }

.rounded-9 {
  border-radius: 0.9rem !important; }

.rounded-8 {
  border-radius: 0.8rem !important; }

.rounded-7 {
  border-radius: 0.7rem !important; }

.rounded-6 {
  border-radius: 0.6rem !important; }

.rounded-5 {
  border-radius: 0.5rem !important; }

.rounded {
  border-radius: 2px 5px 2px 5px !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-1 {
  border-radius: 0.6rem !important; }

.rounded-2 {
  border-radius: 2px 5px 2px 5px !important; }

.rounded-3 {
  border-radius: 5px 20px 5px 20px !important; }

.rounded-4 {
  border-radius: 5px 100px 5px 100px !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-btn {
  border-radius: 5px 30px 5px 5px !important; }

.rounded-carte {
  border-radius: 10px 25px 10px 25px !important; }

.rounded-top-20 {
  border-top-left-radius: 2rem !important;
  border-top-right-radius: 2rem !important; }

.rounded-top-19 {
  border-top-left-radius: 1.9rem !important;
  border-top-right-radius: 1.9rem !important; }

.rounded-top-18 {
  border-top-left-radius: 1.8rem !important;
  border-top-right-radius: 1.8rem !important; }

.rounded-top-17 {
  border-top-left-radius: 1.7rem !important;
  border-top-right-radius: 1.7rem !important; }

.rounded-top-16 {
  border-top-left-radius: 1.6rem !important;
  border-top-right-radius: 1.6rem !important; }

.rounded-top-15 {
  border-top-left-radius: 1.5rem !important;
  border-top-right-radius: 1.5rem !important; }

.rounded-top-14 {
  border-top-left-radius: 1.4rem !important;
  border-top-right-radius: 1.4rem !important; }

.rounded-top-13 {
  border-top-left-radius: 1.3rem !important;
  border-top-right-radius: 1.3rem !important; }

.rounded-top-12 {
  border-top-left-radius: 1.2rem !important;
  border-top-right-radius: 1.2rem !important; }

.rounded-top-11 {
  border-top-left-radius: 1.1rem !important;
  border-top-right-radius: 1.1rem !important; }

.rounded-top-10 {
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important; }

.rounded-top-9 {
  border-top-left-radius: 0.9rem !important;
  border-top-right-radius: 0.9rem !important; }

.rounded-top-8 {
  border-top-left-radius: 0.8rem !important;
  border-top-right-radius: 0.8rem !important; }

.rounded-top-7 {
  border-top-left-radius: 0.7rem !important;
  border-top-right-radius: 0.7rem !important; }

.rounded-top-6 {
  border-top-left-radius: 0.6rem !important;
  border-top-right-radius: 0.6rem !important; }

.rounded-top-5 {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important; }

.rounded-top {
  border-top-left-radius: 2px 5px 2px 5px !important;
  border-top-right-radius: 2px 5px 2px 5px !important; }

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important; }

.rounded-top-1 {
  border-top-left-radius: 0.6rem !important;
  border-top-right-radius: 0.6rem !important; }

.rounded-top-2 {
  border-top-left-radius: 2px 5px 2px 5px !important;
  border-top-right-radius: 2px 5px 2px 5px !important; }

.rounded-top-3 {
  border-top-left-radius: 5px 20px 5px 20px !important;
  border-top-right-radius: 5px 20px 5px 20px !important; }

.rounded-top-4 {
  border-top-left-radius: 5px 100px 5px 100px !important;
  border-top-right-radius: 5px 100px 5px 100px !important; }

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important; }

.rounded-top-pill {
  border-top-left-radius: 50rem !important;
  border-top-right-radius: 50rem !important; }

.rounded-top-btn {
  border-top-left-radius: 5px 30px 5px 5px !important;
  border-top-right-radius: 5px 30px 5px 5px !important; }

.rounded-top-carte {
  border-top-left-radius: 10px 25px 10px 25px !important;
  border-top-right-radius: 10px 25px 10px 25px !important; }

.rounded-end-20 {
  border-top-right-radius: 2rem !important;
  border-bottom-right-radius: 2rem !important; }

.rounded-end-19 {
  border-top-right-radius: 1.9rem !important;
  border-bottom-right-radius: 1.9rem !important; }

.rounded-end-18 {
  border-top-right-radius: 1.8rem !important;
  border-bottom-right-radius: 1.8rem !important; }

.rounded-end-17 {
  border-top-right-radius: 1.7rem !important;
  border-bottom-right-radius: 1.7rem !important; }

.rounded-end-16 {
  border-top-right-radius: 1.6rem !important;
  border-bottom-right-radius: 1.6rem !important; }

.rounded-end-15 {
  border-top-right-radius: 1.5rem !important;
  border-bottom-right-radius: 1.5rem !important; }

.rounded-end-14 {
  border-top-right-radius: 1.4rem !important;
  border-bottom-right-radius: 1.4rem !important; }

.rounded-end-13 {
  border-top-right-radius: 1.3rem !important;
  border-bottom-right-radius: 1.3rem !important; }

.rounded-end-12 {
  border-top-right-radius: 1.2rem !important;
  border-bottom-right-radius: 1.2rem !important; }

.rounded-end-11 {
  border-top-right-radius: 1.1rem !important;
  border-bottom-right-radius: 1.1rem !important; }

.rounded-end-10 {
  border-top-right-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important; }

.rounded-end-9 {
  border-top-right-radius: 0.9rem !important;
  border-bottom-right-radius: 0.9rem !important; }

.rounded-end-8 {
  border-top-right-radius: 0.8rem !important;
  border-bottom-right-radius: 0.8rem !important; }

.rounded-end-7 {
  border-top-right-radius: 0.7rem !important;
  border-bottom-right-radius: 0.7rem !important; }

.rounded-end-6 {
  border-top-right-radius: 0.6rem !important;
  border-bottom-right-radius: 0.6rem !important; }

.rounded-end-5 {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important; }

.rounded-end {
  border-top-right-radius: 2px 5px 2px 5px !important;
  border-bottom-right-radius: 2px 5px 2px 5px !important; }

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important; }

.rounded-end-1 {
  border-top-right-radius: 0.6rem !important;
  border-bottom-right-radius: 0.6rem !important; }

.rounded-end-2 {
  border-top-right-radius: 2px 5px 2px 5px !important;
  border-bottom-right-radius: 2px 5px 2px 5px !important; }

.rounded-end-3 {
  border-top-right-radius: 5px 20px 5px 20px !important;
  border-bottom-right-radius: 5px 20px 5px 20px !important; }

.rounded-end-4 {
  border-top-right-radius: 5px 100px 5px 100px !important;
  border-bottom-right-radius: 5px 100px 5px 100px !important; }

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important; }

.rounded-end-pill {
  border-top-right-radius: 50rem !important;
  border-bottom-right-radius: 50rem !important; }

.rounded-end-btn {
  border-top-right-radius: 5px 30px 5px 5px !important;
  border-bottom-right-radius: 5px 30px 5px 5px !important; }

.rounded-end-carte {
  border-top-right-radius: 10px 25px 10px 25px !important;
  border-bottom-right-radius: 10px 25px 10px 25px !important; }

.rounded-bottom-20 {
  border-bottom-right-radius: 2rem !important;
  border-bottom-left-radius: 2rem !important; }

.rounded-bottom-19 {
  border-bottom-right-radius: 1.9rem !important;
  border-bottom-left-radius: 1.9rem !important; }

.rounded-bottom-18 {
  border-bottom-right-radius: 1.8rem !important;
  border-bottom-left-radius: 1.8rem !important; }

.rounded-bottom-17 {
  border-bottom-right-radius: 1.7rem !important;
  border-bottom-left-radius: 1.7rem !important; }

.rounded-bottom-16 {
  border-bottom-right-radius: 1.6rem !important;
  border-bottom-left-radius: 1.6rem !important; }

.rounded-bottom-15 {
  border-bottom-right-radius: 1.5rem !important;
  border-bottom-left-radius: 1.5rem !important; }

.rounded-bottom-14 {
  border-bottom-right-radius: 1.4rem !important;
  border-bottom-left-radius: 1.4rem !important; }

.rounded-bottom-13 {
  border-bottom-right-radius: 1.3rem !important;
  border-bottom-left-radius: 1.3rem !important; }

.rounded-bottom-12 {
  border-bottom-right-radius: 1.2rem !important;
  border-bottom-left-radius: 1.2rem !important; }

.rounded-bottom-11 {
  border-bottom-right-radius: 1.1rem !important;
  border-bottom-left-radius: 1.1rem !important; }

.rounded-bottom-10 {
  border-bottom-right-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important; }

.rounded-bottom-9 {
  border-bottom-right-radius: 0.9rem !important;
  border-bottom-left-radius: 0.9rem !important; }

.rounded-bottom-8 {
  border-bottom-right-radius: 0.8rem !important;
  border-bottom-left-radius: 0.8rem !important; }

.rounded-bottom-7 {
  border-bottom-right-radius: 0.7rem !important;
  border-bottom-left-radius: 0.7rem !important; }

.rounded-bottom-6 {
  border-bottom-right-radius: 0.6rem !important;
  border-bottom-left-radius: 0.6rem !important; }

.rounded-bottom-5 {
  border-bottom-right-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 2px 5px 2px 5px !important;
  border-bottom-left-radius: 2px 5px 2px 5px !important; }

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.rounded-bottom-1 {
  border-bottom-right-radius: 0.6rem !important;
  border-bottom-left-radius: 0.6rem !important; }

.rounded-bottom-2 {
  border-bottom-right-radius: 2px 5px 2px 5px !important;
  border-bottom-left-radius: 2px 5px 2px 5px !important; }

.rounded-bottom-3 {
  border-bottom-right-radius: 5px 20px 5px 20px !important;
  border-bottom-left-radius: 5px 20px 5px 20px !important; }

.rounded-bottom-4 {
  border-bottom-right-radius: 5px 100px 5px 100px !important;
  border-bottom-left-radius: 5px 100px 5px 100px !important; }

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important; }

.rounded-bottom-pill {
  border-bottom-right-radius: 50rem !important;
  border-bottom-left-radius: 50rem !important; }

.rounded-bottom-btn {
  border-bottom-right-radius: 5px 30px 5px 5px !important;
  border-bottom-left-radius: 5px 30px 5px 5px !important; }

.rounded-bottom-carte {
  border-bottom-right-radius: 10px 25px 10px 25px !important;
  border-bottom-left-radius: 10px 25px 10px 25px !important; }

.rounded-start-20 {
  border-bottom-left-radius: 2rem !important;
  border-top-left-radius: 2rem !important; }

.rounded-start-19 {
  border-bottom-left-radius: 1.9rem !important;
  border-top-left-radius: 1.9rem !important; }

.rounded-start-18 {
  border-bottom-left-radius: 1.8rem !important;
  border-top-left-radius: 1.8rem !important; }

.rounded-start-17 {
  border-bottom-left-radius: 1.7rem !important;
  border-top-left-radius: 1.7rem !important; }

.rounded-start-16 {
  border-bottom-left-radius: 1.6rem !important;
  border-top-left-radius: 1.6rem !important; }

.rounded-start-15 {
  border-bottom-left-radius: 1.5rem !important;
  border-top-left-radius: 1.5rem !important; }

.rounded-start-14 {
  border-bottom-left-radius: 1.4rem !important;
  border-top-left-radius: 1.4rem !important; }

.rounded-start-13 {
  border-bottom-left-radius: 1.3rem !important;
  border-top-left-radius: 1.3rem !important; }

.rounded-start-12 {
  border-bottom-left-radius: 1.2rem !important;
  border-top-left-radius: 1.2rem !important; }

.rounded-start-11 {
  border-bottom-left-radius: 1.1rem !important;
  border-top-left-radius: 1.1rem !important; }

.rounded-start-10 {
  border-bottom-left-radius: 1rem !important;
  border-top-left-radius: 1rem !important; }

.rounded-start-9 {
  border-bottom-left-radius: 0.9rem !important;
  border-top-left-radius: 0.9rem !important; }

.rounded-start-8 {
  border-bottom-left-radius: 0.8rem !important;
  border-top-left-radius: 0.8rem !important; }

.rounded-start-7 {
  border-bottom-left-radius: 0.7rem !important;
  border-top-left-radius: 0.7rem !important; }

.rounded-start-6 {
  border-bottom-left-radius: 0.6rem !important;
  border-top-left-radius: 0.6rem !important; }

.rounded-start-5 {
  border-bottom-left-radius: 0.5rem !important;
  border-top-left-radius: 0.5rem !important; }

.rounded-start {
  border-bottom-left-radius: 2px 5px 2px 5px !important;
  border-top-left-radius: 2px 5px 2px 5px !important; }

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important; }

.rounded-start-1 {
  border-bottom-left-radius: 0.6rem !important;
  border-top-left-radius: 0.6rem !important; }

.rounded-start-2 {
  border-bottom-left-radius: 2px 5px 2px 5px !important;
  border-top-left-radius: 2px 5px 2px 5px !important; }

.rounded-start-3 {
  border-bottom-left-radius: 5px 20px 5px 20px !important;
  border-top-left-radius: 5px 20px 5px 20px !important; }

.rounded-start-4 {
  border-bottom-left-radius: 5px 100px 5px 100px !important;
  border-top-left-radius: 5px 100px 5px 100px !important; }

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important; }

.rounded-start-pill {
  border-bottom-left-radius: 50rem !important;
  border-top-left-radius: 50rem !important; }

.rounded-start-btn {
  border-bottom-left-radius: 5px 30px 5px 5px !important;
  border-top-left-radius: 5px 30px 5px 5px !important; }

.rounded-start-carte {
  border-bottom-left-radius: 10px 25px 10px 25px !important;
  border-top-left-radius: 10px 25px 10px 25px !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.z-index-1 {
  z-index: 1 !important; }

.z-index-2 {
  z-index: 2 !important; }

.z-index-3 {
  z-index: 3 !important; }

.z-index-4 {
  z-index: 4 !important; }

.z-index-5 {
  z-index: 5 !important; }

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important; }
  .float-sm-end {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-grid {
    display: grid !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .position-sm-static {
    position: static !important; }
  .position-sm-relative {
    position: relative !important; }
  .position-sm-absolute {
    position: absolute !important; }
  .position-sm-fixed {
    position: fixed !important; }
  .position-sm-sticky {
    position: sticky !important; }
  .top-sm-0 {
    top: 0 !important; }
  .top-sm-5 {
    top: 5% !important; }
  .top-sm-10 {
    top: 10% !important; }
  .top-sm-15 {
    top: 15% !important; }
  .top-sm-20 {
    top: 20% !important; }
  .top-sm-25 {
    top: 25% !important; }
  .top-sm-30 {
    top: 30% !important; }
  .top-sm-40 {
    top: 40% !important; }
  .top-sm-50 {
    top: 50% !important; }
  .top-sm-80 {
    top: 80% !important; }
  .top-sm-90 {
    top: 90% !important; }
  .top-sm-100 {
    top: 100% !important; }
  .top-sm-2px {
    top: 2px !important; }
  .top-sm-100px {
    top: 100px !important; }
  .top-sm-n30px {
    top: -30px !important; }
  .top-sm-n60px {
    top: -60px !important; }
  .top-sm-n130px {
    top: -130px !important; }
  .top-sm-n150px {
    top: -150px !important; }
  .top-sm-n170px {
    top: -170px !important; }
  .top-sm-n300px {
    top: -300px !important; }
  .bottom-sm-0 {
    bottom: 0 !important; }
  .bottom-sm-5 {
    bottom: 5% !important; }
  .bottom-sm-10 {
    bottom: 10% !important; }
  .bottom-sm-15 {
    bottom: 15% !important; }
  .bottom-sm-20 {
    bottom: 20% !important; }
  .bottom-sm-25 {
    bottom: 25% !important; }
  .bottom-sm-30 {
    bottom: 30% !important; }
  .bottom-sm-40 {
    bottom: 40% !important; }
  .bottom-sm-50 {
    bottom: 50% !important; }
  .bottom-sm-80 {
    bottom: 80% !important; }
  .bottom-sm-90 {
    bottom: 90% !important; }
  .bottom-sm-100 {
    bottom: 100% !important; }
  .bottom-sm-2px {
    bottom: 2px !important; }
  .bottom-sm-100px {
    bottom: 100px !important; }
  .bottom-sm-n30px {
    bottom: -30px !important; }
  .bottom-sm-n60px {
    bottom: -60px !important; }
  .bottom-sm-n130px {
    bottom: -130px !important; }
  .bottom-sm-n150px {
    bottom: -150px !important; }
  .bottom-sm-n170px {
    bottom: -170px !important; }
  .bottom-sm-n300px {
    bottom: -300px !important; }
  .start-sm-0 {
    left: 0 !important; }
  .start-sm-5 {
    left: 5% !important; }
  .start-sm-10 {
    left: 10% !important; }
  .start-sm-15 {
    left: 15% !important; }
  .start-sm-20 {
    left: 20% !important; }
  .start-sm-25 {
    left: 25% !important; }
  .start-sm-30 {
    left: 30% !important; }
  .start-sm-40 {
    left: 40% !important; }
  .start-sm-50 {
    left: 50% !important; }
  .start-sm-80 {
    left: 80% !important; }
  .start-sm-90 {
    left: 90% !important; }
  .start-sm-100 {
    left: 100% !important; }
  .start-sm-2px {
    left: 2px !important; }
  .start-sm-100px {
    left: 100px !important; }
  .start-sm-n30px {
    left: -30px !important; }
  .start-sm-n60px {
    left: -60px !important; }
  .start-sm-n130px {
    left: -130px !important; }
  .start-sm-n150px {
    left: -150px !important; }
  .start-sm-n170px {
    left: -170px !important; }
  .start-sm-n300px {
    left: -300px !important; }
  .end-sm-0 {
    right: 0 !important; }
  .end-sm-5 {
    right: 5% !important; }
  .end-sm-10 {
    right: 10% !important; }
  .end-sm-15 {
    right: 15% !important; }
  .end-sm-20 {
    right: 20% !important; }
  .end-sm-25 {
    right: 25% !important; }
  .end-sm-30 {
    right: 30% !important; }
  .end-sm-40 {
    right: 40% !important; }
  .end-sm-50 {
    right: 50% !important; }
  .end-sm-80 {
    right: 80% !important; }
  .end-sm-90 {
    right: 90% !important; }
  .end-sm-100 {
    right: 100% !important; }
  .end-sm-2px {
    right: 2px !important; }
  .end-sm-100px {
    right: 100px !important; }
  .end-sm-n30px {
    right: -30px !important; }
  .end-sm-n60px {
    right: -60px !important; }
  .end-sm-n130px {
    right: -130px !important; }
  .end-sm-n150px {
    right: -150px !important; }
  .end-sm-n170px {
    right: -170px !important; }
  .end-sm-n300px {
    right: -300px !important; }
  .w-sm-20 {
    width: 20% !important; }
  .w-sm-25 {
    width: 25% !important; }
  .w-sm-30 {
    width: 30% !important; }
  .w-sm-40 {
    width: 40% !important; }
  .w-sm-50 {
    width: 50% !important; }
  .w-sm-70 {
    width: 70% !important; }
  .w-sm-75 {
    width: 75% !important; }
  .w-sm-80 {
    width: 80% !important; }
  .w-sm-85 {
    width: 85% !important; }
  .w-sm-90 {
    width: 90% !important; }
  .w-sm-100 {
    width: 100% !important; }
  .w-sm-26px {
    width: 26px !important; }
  .w-sm-86px {
    width: 86px !important; }
  .w-sm-125px {
    width: 125px !important; }
  .w-sm-145px {
    width: 145px !important; }
  .w-sm-161px {
    width: 161px !important; }
  .w-sm-182px {
    width: 182px !important; }
  .w-sm-205px {
    width: 205px !important; }
  .w-sm-215px {
    width: 215px !important; }
  .w-sm-235px {
    width: 235px !important; }
  .w-sm-250px {
    width: 250px !important; }
  .w-sm-260px {
    width: 260px !important; }
  .w-sm-268px {
    width: 268px !important; }
  .w-sm-283px {
    width: 283px !important; }
  .w-sm-285px {
    width: 285px !important; }
  .w-sm-300px {
    width: 300px !important; }
  .w-sm-305px {
    width: 305px !important; }
  .w-sm-310px {
    width: 310px !important; }
  .w-sm-350px {
    width: 350px !important; }
  .w-sm-410px {
    width: 410px !important; }
  .w-sm-440px {
    width: 440px !important; }
  .w-sm-460px {
    width: 460px !important; }
  .w-sm-500px {
    width: 500px !important; }
  .w-sm-520px {
    width: 520px !important; }
  .w-sm-600px {
    width: 600px !important; }
  .w-sm-657px {
    width: 657px !important; }
  .w-sm-1015px {
    width: 1015px !important; }
  .w-sm-1070px {
    width: 1070px !important; }
  .w-sm-auto {
    width: auto !important; }
  .h-sm-20 {
    height: 20% !important; }
  .h-sm-25 {
    height: 25% !important; }
  .h-sm-30 {
    height: 30% !important; }
  .h-sm-40 {
    height: 40% !important; }
  .h-sm-50 {
    height: 50% !important; }
  .h-sm-70 {
    height: 70% !important; }
  .h-sm-75 {
    height: 75% !important; }
  .h-sm-80 {
    height: 80% !important; }
  .h-sm-85 {
    height: 85% !important; }
  .h-sm-90 {
    height: 90% !important; }
  .h-sm-100 {
    height: 100% !important; }
  .h-sm-26px {
    height: 26px !important; }
  .h-sm-86px {
    height: 86px !important; }
  .h-sm-125px {
    height: 125px !important; }
  .h-sm-145px {
    height: 145px !important; }
  .h-sm-161px {
    height: 161px !important; }
  .h-sm-182px {
    height: 182px !important; }
  .h-sm-205px {
    height: 205px !important; }
  .h-sm-215px {
    height: 215px !important; }
  .h-sm-235px {
    height: 235px !important; }
  .h-sm-250px {
    height: 250px !important; }
  .h-sm-260px {
    height: 260px !important; }
  .h-sm-268px {
    height: 268px !important; }
  .h-sm-283px {
    height: 283px !important; }
  .h-sm-285px {
    height: 285px !important; }
  .h-sm-300px {
    height: 300px !important; }
  .h-sm-305px {
    height: 305px !important; }
  .h-sm-310px {
    height: 310px !important; }
  .h-sm-350px {
    height: 350px !important; }
  .h-sm-410px {
    height: 410px !important; }
  .h-sm-440px {
    height: 440px !important; }
  .h-sm-460px {
    height: 460px !important; }
  .h-sm-500px {
    height: 500px !important; }
  .h-sm-520px {
    height: 520px !important; }
  .h-sm-600px {
    height: 600px !important; }
  .h-sm-657px {
    height: 657px !important; }
  .h-sm-1015px {
    height: 1015px !important; }
  .h-sm-1070px {
    height: 1070px !important; }
  .h-sm-auto {
    height: auto !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-sm-35 {
    gap: 3.5rem !important; }
  .gap-sm-34 {
    gap: 3.4rem !important; }
  .gap-sm-33 {
    gap: 3.3rem !important; }
  .gap-sm-32 {
    gap: 3.2rem !important; }
  .gap-sm-31 {
    gap: 3.1rem !important; }
  .gap-sm-30 {
    gap: 3rem !important; }
  .gap-sm-29 {
    gap: 2.9rem !important; }
  .gap-sm-28 {
    gap: 2.8rem !important; }
  .gap-sm-27 {
    gap: 2.7rem !important; }
  .gap-sm-26 {
    gap: 2.6rem !important; }
  .gap-sm-25 {
    gap: 2.5rem !important; }
  .gap-sm-24 {
    gap: 2.4rem !important; }
  .gap-sm-23 {
    gap: 2.3rem !important; }
  .gap-sm-22 {
    gap: 2.2rem !important; }
  .gap-sm-21 {
    gap: 2.1rem !important; }
  .gap-sm-20 {
    gap: 2rem !important; }
  .gap-sm-19 {
    gap: 1.9rem !important; }
  .gap-sm-18 {
    gap: 1.8rem !important; }
  .gap-sm-17 {
    gap: 1.7rem !important; }
  .gap-sm-16 {
    gap: 1.6rem !important; }
  .gap-sm-15 {
    gap: 1.5rem !important; }
  .gap-sm-14 {
    gap: 1.4rem !important; }
  .gap-sm-13 {
    gap: 1.3rem !important; }
  .gap-sm-12 {
    gap: 1.2rem !important; }
  .gap-sm-11 {
    gap: 1.1rem !important; }
  .gap-sm-10 {
    gap: 1rem !important; }
  .gap-sm-0 {
    gap: 0 !important; }
  .gap-sm-1 {
    gap: 0.25rem !important; }
  .gap-sm-2 {
    gap: 0.5rem !important; }
  .gap-sm-3 {
    gap: 1rem !important; }
  .gap-sm-4 {
    gap: 1.5rem !important; }
  .gap-sm-5 {
    gap: 0.5rem !important; }
  .gap-sm-6 {
    gap: 0.6rem !important; }
  .gap-sm-8 {
    gap: 0.8rem !important; }
  .gap-sm-40 {
    gap: 4rem !important; }
  .gap-sm-45 {
    gap: 4.5rem !important; }
  .gap-sm-50 {
    gap: 5rem !important; }
  .gap-sm-55 {
    gap: 5.5rem !important; }
  .gap-sm-60 {
    gap: 6rem !important; }
  .gap-sm-70 {
    gap: 7rem !important; }
  .gap-sm-80 {
    gap: 8rem !important; }
  .gap-sm-90 {
    gap: 9rem !important; }
  .gap-sm-100 {
    gap: 10rem !important; }
  .gap-sm-105 {
    gap: 10.5rem !important; }
  .gap-sm-110 {
    gap: 11rem !important; }
  .gap-sm-125 {
    gap: 12.5rem !important; }
  .gap-sm-130 {
    gap: 13rem !important; }
  .gap-sm-145 {
    gap: 14.5rem !important; }
  .gap-sm-170 {
    gap: 17rem !important; }
  .gap-sm-235 {
    gap: 23.5rem !important; }
  .gap-sm-n20 {
    gap: -2rem !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-35 {
    margin: 3.5rem !important; }
  .m-sm-34 {
    margin: 3.4rem !important; }
  .m-sm-33 {
    margin: 3.3rem !important; }
  .m-sm-32 {
    margin: 3.2rem !important; }
  .m-sm-31 {
    margin: 3.1rem !important; }
  .m-sm-30 {
    margin: 3rem !important; }
  .m-sm-29 {
    margin: 2.9rem !important; }
  .m-sm-28 {
    margin: 2.8rem !important; }
  .m-sm-27 {
    margin: 2.7rem !important; }
  .m-sm-26 {
    margin: 2.6rem !important; }
  .m-sm-25 {
    margin: 2.5rem !important; }
  .m-sm-24 {
    margin: 2.4rem !important; }
  .m-sm-23 {
    margin: 2.3rem !important; }
  .m-sm-22 {
    margin: 2.2rem !important; }
  .m-sm-21 {
    margin: 2.1rem !important; }
  .m-sm-20 {
    margin: 2rem !important; }
  .m-sm-19 {
    margin: 1.9rem !important; }
  .m-sm-18 {
    margin: 1.8rem !important; }
  .m-sm-17 {
    margin: 1.7rem !important; }
  .m-sm-16 {
    margin: 1.6rem !important; }
  .m-sm-15 {
    margin: 1.5rem !important; }
  .m-sm-14 {
    margin: 1.4rem !important; }
  .m-sm-13 {
    margin: 1.3rem !important; }
  .m-sm-12 {
    margin: 1.2rem !important; }
  .m-sm-11 {
    margin: 1.1rem !important; }
  .m-sm-10 {
    margin: 1rem !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .m-sm-5 {
    margin: 0.5rem !important; }
  .m-sm-6 {
    margin: 0.6rem !important; }
  .m-sm-8 {
    margin: 0.8rem !important; }
  .m-sm-40 {
    margin: 4rem !important; }
  .m-sm-45 {
    margin: 4.5rem !important; }
  .m-sm-50 {
    margin: 5rem !important; }
  .m-sm-55 {
    margin: 5.5rem !important; }
  .m-sm-60 {
    margin: 6rem !important; }
  .m-sm-70 {
    margin: 7rem !important; }
  .m-sm-80 {
    margin: 8rem !important; }
  .m-sm-90 {
    margin: 9rem !important; }
  .m-sm-100 {
    margin: 10rem !important; }
  .m-sm-105 {
    margin: 10.5rem !important; }
  .m-sm-110 {
    margin: 11rem !important; }
  .m-sm-125 {
    margin: 12.5rem !important; }
  .m-sm-130 {
    margin: 13rem !important; }
  .m-sm-145 {
    margin: 14.5rem !important; }
  .m-sm-170 {
    margin: 17rem !important; }
  .m-sm-235 {
    margin: 23.5rem !important; }
  .m-sm-n20 {
    margin: -2rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-35 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important; }
  .mx-sm-34 {
    margin-right: 3.4rem !important;
    margin-left: 3.4rem !important; }
  .mx-sm-33 {
    margin-right: 3.3rem !important;
    margin-left: 3.3rem !important; }
  .mx-sm-32 {
    margin-right: 3.2rem !important;
    margin-left: 3.2rem !important; }
  .mx-sm-31 {
    margin-right: 3.1rem !important;
    margin-left: 3.1rem !important; }
  .mx-sm-30 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-sm-29 {
    margin-right: 2.9rem !important;
    margin-left: 2.9rem !important; }
  .mx-sm-28 {
    margin-right: 2.8rem !important;
    margin-left: 2.8rem !important; }
  .mx-sm-27 {
    margin-right: 2.7rem !important;
    margin-left: 2.7rem !important; }
  .mx-sm-26 {
    margin-right: 2.6rem !important;
    margin-left: 2.6rem !important; }
  .mx-sm-25 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important; }
  .mx-sm-24 {
    margin-right: 2.4rem !important;
    margin-left: 2.4rem !important; }
  .mx-sm-23 {
    margin-right: 2.3rem !important;
    margin-left: 2.3rem !important; }
  .mx-sm-22 {
    margin-right: 2.2rem !important;
    margin-left: 2.2rem !important; }
  .mx-sm-21 {
    margin-right: 2.1rem !important;
    margin-left: 2.1rem !important; }
  .mx-sm-20 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  .mx-sm-19 {
    margin-right: 1.9rem !important;
    margin-left: 1.9rem !important; }
  .mx-sm-18 {
    margin-right: 1.8rem !important;
    margin-left: 1.8rem !important; }
  .mx-sm-17 {
    margin-right: 1.7rem !important;
    margin-left: 1.7rem !important; }
  .mx-sm-16 {
    margin-right: 1.6rem !important;
    margin-left: 1.6rem !important; }
  .mx-sm-15 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-14 {
    margin-right: 1.4rem !important;
    margin-left: 1.4rem !important; }
  .mx-sm-13 {
    margin-right: 1.3rem !important;
    margin-left: 1.3rem !important; }
  .mx-sm-12 {
    margin-right: 1.2rem !important;
    margin-left: 1.2rem !important; }
  .mx-sm-11 {
    margin-right: 1.1rem !important;
    margin-left: 1.1rem !important; }
  .mx-sm-10 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-5 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-sm-6 {
    margin-right: 0.6rem !important;
    margin-left: 0.6rem !important; }
  .mx-sm-8 {
    margin-right: 0.8rem !important;
    margin-left: 0.8rem !important; }
  .mx-sm-40 {
    margin-right: 4rem !important;
    margin-left: 4rem !important; }
  .mx-sm-45 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important; }
  .mx-sm-50 {
    margin-right: 5rem !important;
    margin-left: 5rem !important; }
  .mx-sm-55 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important; }
  .mx-sm-60 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-sm-70 {
    margin-right: 7rem !important;
    margin-left: 7rem !important; }
  .mx-sm-80 {
    margin-right: 8rem !important;
    margin-left: 8rem !important; }
  .mx-sm-90 {
    margin-right: 9rem !important;
    margin-left: 9rem !important; }
  .mx-sm-100 {
    margin-right: 10rem !important;
    margin-left: 10rem !important; }
  .mx-sm-105 {
    margin-right: 10.5rem !important;
    margin-left: 10.5rem !important; }
  .mx-sm-110 {
    margin-right: 11rem !important;
    margin-left: 11rem !important; }
  .mx-sm-125 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important; }
  .mx-sm-130 {
    margin-right: 13rem !important;
    margin-left: 13rem !important; }
  .mx-sm-145 {
    margin-right: 14.5rem !important;
    margin-left: 14.5rem !important; }
  .mx-sm-170 {
    margin-right: 17rem !important;
    margin-left: 17rem !important; }
  .mx-sm-235 {
    margin-right: 23.5rem !important;
    margin-left: 23.5rem !important; }
  .mx-sm-n20 {
    margin-right: -2rem !important;
    margin-left: -2rem !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-35 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important; }
  .my-sm-34 {
    margin-top: 3.4rem !important;
    margin-bottom: 3.4rem !important; }
  .my-sm-33 {
    margin-top: 3.3rem !important;
    margin-bottom: 3.3rem !important; }
  .my-sm-32 {
    margin-top: 3.2rem !important;
    margin-bottom: 3.2rem !important; }
  .my-sm-31 {
    margin-top: 3.1rem !important;
    margin-bottom: 3.1rem !important; }
  .my-sm-30 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-sm-29 {
    margin-top: 2.9rem !important;
    margin-bottom: 2.9rem !important; }
  .my-sm-28 {
    margin-top: 2.8rem !important;
    margin-bottom: 2.8rem !important; }
  .my-sm-27 {
    margin-top: 2.7rem !important;
    margin-bottom: 2.7rem !important; }
  .my-sm-26 {
    margin-top: 2.6rem !important;
    margin-bottom: 2.6rem !important; }
  .my-sm-25 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .my-sm-24 {
    margin-top: 2.4rem !important;
    margin-bottom: 2.4rem !important; }
  .my-sm-23 {
    margin-top: 2.3rem !important;
    margin-bottom: 2.3rem !important; }
  .my-sm-22 {
    margin-top: 2.2rem !important;
    margin-bottom: 2.2rem !important; }
  .my-sm-21 {
    margin-top: 2.1rem !important;
    margin-bottom: 2.1rem !important; }
  .my-sm-20 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .my-sm-19 {
    margin-top: 1.9rem !important;
    margin-bottom: 1.9rem !important; }
  .my-sm-18 {
    margin-top: 1.8rem !important;
    margin-bottom: 1.8rem !important; }
  .my-sm-17 {
    margin-top: 1.7rem !important;
    margin-bottom: 1.7rem !important; }
  .my-sm-16 {
    margin-top: 1.6rem !important;
    margin-bottom: 1.6rem !important; }
  .my-sm-15 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-14 {
    margin-top: 1.4rem !important;
    margin-bottom: 1.4rem !important; }
  .my-sm-13 {
    margin-top: 1.3rem !important;
    margin-bottom: 1.3rem !important; }
  .my-sm-12 {
    margin-top: 1.2rem !important;
    margin-bottom: 1.2rem !important; }
  .my-sm-11 {
    margin-top: 1.1rem !important;
    margin-bottom: 1.1rem !important; }
  .my-sm-10 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-5 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-sm-6 {
    margin-top: 0.6rem !important;
    margin-bottom: 0.6rem !important; }
  .my-sm-8 {
    margin-top: 0.8rem !important;
    margin-bottom: 0.8rem !important; }
  .my-sm-40 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .my-sm-45 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important; }
  .my-sm-50 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .my-sm-55 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important; }
  .my-sm-60 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-sm-70 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important; }
  .my-sm-80 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important; }
  .my-sm-90 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important; }
  .my-sm-100 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important; }
  .my-sm-105 {
    margin-top: 10.5rem !important;
    margin-bottom: 10.5rem !important; }
  .my-sm-110 {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important; }
  .my-sm-125 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important; }
  .my-sm-130 {
    margin-top: 13rem !important;
    margin-bottom: 13rem !important; }
  .my-sm-145 {
    margin-top: 14.5rem !important;
    margin-bottom: 14.5rem !important; }
  .my-sm-170 {
    margin-top: 17rem !important;
    margin-bottom: 17rem !important; }
  .my-sm-235 {
    margin-top: 23.5rem !important;
    margin-bottom: 23.5rem !important; }
  .my-sm-n20 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-35 {
    margin-top: 3.5rem !important; }
  .mt-sm-34 {
    margin-top: 3.4rem !important; }
  .mt-sm-33 {
    margin-top: 3.3rem !important; }
  .mt-sm-32 {
    margin-top: 3.2rem !important; }
  .mt-sm-31 {
    margin-top: 3.1rem !important; }
  .mt-sm-30 {
    margin-top: 3rem !important; }
  .mt-sm-29 {
    margin-top: 2.9rem !important; }
  .mt-sm-28 {
    margin-top: 2.8rem !important; }
  .mt-sm-27 {
    margin-top: 2.7rem !important; }
  .mt-sm-26 {
    margin-top: 2.6rem !important; }
  .mt-sm-25 {
    margin-top: 2.5rem !important; }
  .mt-sm-24 {
    margin-top: 2.4rem !important; }
  .mt-sm-23 {
    margin-top: 2.3rem !important; }
  .mt-sm-22 {
    margin-top: 2.2rem !important; }
  .mt-sm-21 {
    margin-top: 2.1rem !important; }
  .mt-sm-20 {
    margin-top: 2rem !important; }
  .mt-sm-19 {
    margin-top: 1.9rem !important; }
  .mt-sm-18 {
    margin-top: 1.8rem !important; }
  .mt-sm-17 {
    margin-top: 1.7rem !important; }
  .mt-sm-16 {
    margin-top: 1.6rem !important; }
  .mt-sm-15 {
    margin-top: 1.5rem !important; }
  .mt-sm-14 {
    margin-top: 1.4rem !important; }
  .mt-sm-13 {
    margin-top: 1.3rem !important; }
  .mt-sm-12 {
    margin-top: 1.2rem !important; }
  .mt-sm-11 {
    margin-top: 1.1rem !important; }
  .mt-sm-10 {
    margin-top: 1rem !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mt-sm-5 {
    margin-top: 0.5rem !important; }
  .mt-sm-6 {
    margin-top: 0.6rem !important; }
  .mt-sm-8 {
    margin-top: 0.8rem !important; }
  .mt-sm-40 {
    margin-top: 4rem !important; }
  .mt-sm-45 {
    margin-top: 4.5rem !important; }
  .mt-sm-50 {
    margin-top: 5rem !important; }
  .mt-sm-55 {
    margin-top: 5.5rem !important; }
  .mt-sm-60 {
    margin-top: 6rem !important; }
  .mt-sm-70 {
    margin-top: 7rem !important; }
  .mt-sm-80 {
    margin-top: 8rem !important; }
  .mt-sm-90 {
    margin-top: 9rem !important; }
  .mt-sm-100 {
    margin-top: 10rem !important; }
  .mt-sm-105 {
    margin-top: 10.5rem !important; }
  .mt-sm-110 {
    margin-top: 11rem !important; }
  .mt-sm-125 {
    margin-top: 12.5rem !important; }
  .mt-sm-130 {
    margin-top: 13rem !important; }
  .mt-sm-145 {
    margin-top: 14.5rem !important; }
  .mt-sm-170 {
    margin-top: 17rem !important; }
  .mt-sm-235 {
    margin-top: 23.5rem !important; }
  .mt-sm-n20 {
    margin-top: -2rem !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .me-sm-35 {
    margin-right: 3.5rem !important; }
  .me-sm-34 {
    margin-right: 3.4rem !important; }
  .me-sm-33 {
    margin-right: 3.3rem !important; }
  .me-sm-32 {
    margin-right: 3.2rem !important; }
  .me-sm-31 {
    margin-right: 3.1rem !important; }
  .me-sm-30 {
    margin-right: 3rem !important; }
  .me-sm-29 {
    margin-right: 2.9rem !important; }
  .me-sm-28 {
    margin-right: 2.8rem !important; }
  .me-sm-27 {
    margin-right: 2.7rem !important; }
  .me-sm-26 {
    margin-right: 2.6rem !important; }
  .me-sm-25 {
    margin-right: 2.5rem !important; }
  .me-sm-24 {
    margin-right: 2.4rem !important; }
  .me-sm-23 {
    margin-right: 2.3rem !important; }
  .me-sm-22 {
    margin-right: 2.2rem !important; }
  .me-sm-21 {
    margin-right: 2.1rem !important; }
  .me-sm-20 {
    margin-right: 2rem !important; }
  .me-sm-19 {
    margin-right: 1.9rem !important; }
  .me-sm-18 {
    margin-right: 1.8rem !important; }
  .me-sm-17 {
    margin-right: 1.7rem !important; }
  .me-sm-16 {
    margin-right: 1.6rem !important; }
  .me-sm-15 {
    margin-right: 1.5rem !important; }
  .me-sm-14 {
    margin-right: 1.4rem !important; }
  .me-sm-13 {
    margin-right: 1.3rem !important; }
  .me-sm-12 {
    margin-right: 1.2rem !important; }
  .me-sm-11 {
    margin-right: 1.1rem !important; }
  .me-sm-10 {
    margin-right: 1rem !important; }
  .me-sm-0 {
    margin-right: 0 !important; }
  .me-sm-1 {
    margin-right: 0.25rem !important; }
  .me-sm-2 {
    margin-right: 0.5rem !important; }
  .me-sm-3 {
    margin-right: 1rem !important; }
  .me-sm-4 {
    margin-right: 1.5rem !important; }
  .me-sm-5 {
    margin-right: 0.5rem !important; }
  .me-sm-6 {
    margin-right: 0.6rem !important; }
  .me-sm-8 {
    margin-right: 0.8rem !important; }
  .me-sm-40 {
    margin-right: 4rem !important; }
  .me-sm-45 {
    margin-right: 4.5rem !important; }
  .me-sm-50 {
    margin-right: 5rem !important; }
  .me-sm-55 {
    margin-right: 5.5rem !important; }
  .me-sm-60 {
    margin-right: 6rem !important; }
  .me-sm-70 {
    margin-right: 7rem !important; }
  .me-sm-80 {
    margin-right: 8rem !important; }
  .me-sm-90 {
    margin-right: 9rem !important; }
  .me-sm-100 {
    margin-right: 10rem !important; }
  .me-sm-105 {
    margin-right: 10.5rem !important; }
  .me-sm-110 {
    margin-right: 11rem !important; }
  .me-sm-125 {
    margin-right: 12.5rem !important; }
  .me-sm-130 {
    margin-right: 13rem !important; }
  .me-sm-145 {
    margin-right: 14.5rem !important; }
  .me-sm-170 {
    margin-right: 17rem !important; }
  .me-sm-235 {
    margin-right: 23.5rem !important; }
  .me-sm-n20 {
    margin-right: -2rem !important; }
  .me-sm-auto {
    margin-right: auto !important; }
  .mb-sm-35 {
    margin-bottom: 3.5rem !important; }
  .mb-sm-34 {
    margin-bottom: 3.4rem !important; }
  .mb-sm-33 {
    margin-bottom: 3.3rem !important; }
  .mb-sm-32 {
    margin-bottom: 3.2rem !important; }
  .mb-sm-31 {
    margin-bottom: 3.1rem !important; }
  .mb-sm-30 {
    margin-bottom: 3rem !important; }
  .mb-sm-29 {
    margin-bottom: 2.9rem !important; }
  .mb-sm-28 {
    margin-bottom: 2.8rem !important; }
  .mb-sm-27 {
    margin-bottom: 2.7rem !important; }
  .mb-sm-26 {
    margin-bottom: 2.6rem !important; }
  .mb-sm-25 {
    margin-bottom: 2.5rem !important; }
  .mb-sm-24 {
    margin-bottom: 2.4rem !important; }
  .mb-sm-23 {
    margin-bottom: 2.3rem !important; }
  .mb-sm-22 {
    margin-bottom: 2.2rem !important; }
  .mb-sm-21 {
    margin-bottom: 2.1rem !important; }
  .mb-sm-20 {
    margin-bottom: 2rem !important; }
  .mb-sm-19 {
    margin-bottom: 1.9rem !important; }
  .mb-sm-18 {
    margin-bottom: 1.8rem !important; }
  .mb-sm-17 {
    margin-bottom: 1.7rem !important; }
  .mb-sm-16 {
    margin-bottom: 1.6rem !important; }
  .mb-sm-15 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-14 {
    margin-bottom: 1.4rem !important; }
  .mb-sm-13 {
    margin-bottom: 1.3rem !important; }
  .mb-sm-12 {
    margin-bottom: 1.2rem !important; }
  .mb-sm-11 {
    margin-bottom: 1.1rem !important; }
  .mb-sm-10 {
    margin-bottom: 1rem !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-5 {
    margin-bottom: 0.5rem !important; }
  .mb-sm-6 {
    margin-bottom: 0.6rem !important; }
  .mb-sm-8 {
    margin-bottom: 0.8rem !important; }
  .mb-sm-40 {
    margin-bottom: 4rem !important; }
  .mb-sm-45 {
    margin-bottom: 4.5rem !important; }
  .mb-sm-50 {
    margin-bottom: 5rem !important; }
  .mb-sm-55 {
    margin-bottom: 5.5rem !important; }
  .mb-sm-60 {
    margin-bottom: 6rem !important; }
  .mb-sm-70 {
    margin-bottom: 7rem !important; }
  .mb-sm-80 {
    margin-bottom: 8rem !important; }
  .mb-sm-90 {
    margin-bottom: 9rem !important; }
  .mb-sm-100 {
    margin-bottom: 10rem !important; }
  .mb-sm-105 {
    margin-bottom: 10.5rem !important; }
  .mb-sm-110 {
    margin-bottom: 11rem !important; }
  .mb-sm-125 {
    margin-bottom: 12.5rem !important; }
  .mb-sm-130 {
    margin-bottom: 13rem !important; }
  .mb-sm-145 {
    margin-bottom: 14.5rem !important; }
  .mb-sm-170 {
    margin-bottom: 17rem !important; }
  .mb-sm-235 {
    margin-bottom: 23.5rem !important; }
  .mb-sm-n20 {
    margin-bottom: -2rem !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ms-sm-35 {
    margin-left: 3.5rem !important; }
  .ms-sm-34 {
    margin-left: 3.4rem !important; }
  .ms-sm-33 {
    margin-left: 3.3rem !important; }
  .ms-sm-32 {
    margin-left: 3.2rem !important; }
  .ms-sm-31 {
    margin-left: 3.1rem !important; }
  .ms-sm-30 {
    margin-left: 3rem !important; }
  .ms-sm-29 {
    margin-left: 2.9rem !important; }
  .ms-sm-28 {
    margin-left: 2.8rem !important; }
  .ms-sm-27 {
    margin-left: 2.7rem !important; }
  .ms-sm-26 {
    margin-left: 2.6rem !important; }
  .ms-sm-25 {
    margin-left: 2.5rem !important; }
  .ms-sm-24 {
    margin-left: 2.4rem !important; }
  .ms-sm-23 {
    margin-left: 2.3rem !important; }
  .ms-sm-22 {
    margin-left: 2.2rem !important; }
  .ms-sm-21 {
    margin-left: 2.1rem !important; }
  .ms-sm-20 {
    margin-left: 2rem !important; }
  .ms-sm-19 {
    margin-left: 1.9rem !important; }
  .ms-sm-18 {
    margin-left: 1.8rem !important; }
  .ms-sm-17 {
    margin-left: 1.7rem !important; }
  .ms-sm-16 {
    margin-left: 1.6rem !important; }
  .ms-sm-15 {
    margin-left: 1.5rem !important; }
  .ms-sm-14 {
    margin-left: 1.4rem !important; }
  .ms-sm-13 {
    margin-left: 1.3rem !important; }
  .ms-sm-12 {
    margin-left: 1.2rem !important; }
  .ms-sm-11 {
    margin-left: 1.1rem !important; }
  .ms-sm-10 {
    margin-left: 1rem !important; }
  .ms-sm-0 {
    margin-left: 0 !important; }
  .ms-sm-1 {
    margin-left: 0.25rem !important; }
  .ms-sm-2 {
    margin-left: 0.5rem !important; }
  .ms-sm-3 {
    margin-left: 1rem !important; }
  .ms-sm-4 {
    margin-left: 1.5rem !important; }
  .ms-sm-5 {
    margin-left: 0.5rem !important; }
  .ms-sm-6 {
    margin-left: 0.6rem !important; }
  .ms-sm-8 {
    margin-left: 0.8rem !important; }
  .ms-sm-40 {
    margin-left: 4rem !important; }
  .ms-sm-45 {
    margin-left: 4.5rem !important; }
  .ms-sm-50 {
    margin-left: 5rem !important; }
  .ms-sm-55 {
    margin-left: 5.5rem !important; }
  .ms-sm-60 {
    margin-left: 6rem !important; }
  .ms-sm-70 {
    margin-left: 7rem !important; }
  .ms-sm-80 {
    margin-left: 8rem !important; }
  .ms-sm-90 {
    margin-left: 9rem !important; }
  .ms-sm-100 {
    margin-left: 10rem !important; }
  .ms-sm-105 {
    margin-left: 10.5rem !important; }
  .ms-sm-110 {
    margin-left: 11rem !important; }
  .ms-sm-125 {
    margin-left: 12.5rem !important; }
  .ms-sm-130 {
    margin-left: 13rem !important; }
  .ms-sm-145 {
    margin-left: 14.5rem !important; }
  .ms-sm-170 {
    margin-left: 17rem !important; }
  .ms-sm-235 {
    margin-left: 23.5rem !important; }
  .ms-sm-n20 {
    margin-left: -2rem !important; }
  .ms-sm-auto {
    margin-left: auto !important; }
  .p-sm-35 {
    padding: 3.5rem !important; }
  .p-sm-34 {
    padding: 3.4rem !important; }
  .p-sm-33 {
    padding: 3.3rem !important; }
  .p-sm-32 {
    padding: 3.2rem !important; }
  .p-sm-31 {
    padding: 3.1rem !important; }
  .p-sm-30 {
    padding: 3rem !important; }
  .p-sm-29 {
    padding: 2.9rem !important; }
  .p-sm-28 {
    padding: 2.8rem !important; }
  .p-sm-27 {
    padding: 2.7rem !important; }
  .p-sm-26 {
    padding: 2.6rem !important; }
  .p-sm-25 {
    padding: 2.5rem !important; }
  .p-sm-24 {
    padding: 2.4rem !important; }
  .p-sm-23 {
    padding: 2.3rem !important; }
  .p-sm-22 {
    padding: 2.2rem !important; }
  .p-sm-21 {
    padding: 2.1rem !important; }
  .p-sm-20 {
    padding: 2rem !important; }
  .p-sm-19 {
    padding: 1.9rem !important; }
  .p-sm-18 {
    padding: 1.8rem !important; }
  .p-sm-17 {
    padding: 1.7rem !important; }
  .p-sm-16 {
    padding: 1.6rem !important; }
  .p-sm-15 {
    padding: 1.5rem !important; }
  .p-sm-14 {
    padding: 1.4rem !important; }
  .p-sm-13 {
    padding: 1.3rem !important; }
  .p-sm-12 {
    padding: 1.2rem !important; }
  .p-sm-11 {
    padding: 1.1rem !important; }
  .p-sm-10 {
    padding: 1rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .p-sm-5 {
    padding: 0.5rem !important; }
  .p-sm-6 {
    padding: 0.6rem !important; }
  .p-sm-8 {
    padding: 0.8rem !important; }
  .p-sm-40 {
    padding: 4rem !important; }
  .p-sm-45 {
    padding: 4.5rem !important; }
  .p-sm-50 {
    padding: 5rem !important; }
  .p-sm-55 {
    padding: 5.5rem !important; }
  .p-sm-60 {
    padding: 6rem !important; }
  .p-sm-70 {
    padding: 7rem !important; }
  .p-sm-80 {
    padding: 8rem !important; }
  .p-sm-90 {
    padding: 9rem !important; }
  .p-sm-100 {
    padding: 10rem !important; }
  .p-sm-105 {
    padding: 10.5rem !important; }
  .p-sm-110 {
    padding: 11rem !important; }
  .p-sm-125 {
    padding: 12.5rem !important; }
  .p-sm-130 {
    padding: 13rem !important; }
  .p-sm-145 {
    padding: 14.5rem !important; }
  .p-sm-170 {
    padding: 17rem !important; }
  .p-sm-235 {
    padding: 23.5rem !important; }
  .p-sm-n20 {
    padding: -2rem !important; }
  .px-sm-35 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important; }
  .px-sm-34 {
    padding-right: 3.4rem !important;
    padding-left: 3.4rem !important; }
  .px-sm-33 {
    padding-right: 3.3rem !important;
    padding-left: 3.3rem !important; }
  .px-sm-32 {
    padding-right: 3.2rem !important;
    padding-left: 3.2rem !important; }
  .px-sm-31 {
    padding-right: 3.1rem !important;
    padding-left: 3.1rem !important; }
  .px-sm-30 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-sm-29 {
    padding-right: 2.9rem !important;
    padding-left: 2.9rem !important; }
  .px-sm-28 {
    padding-right: 2.8rem !important;
    padding-left: 2.8rem !important; }
  .px-sm-27 {
    padding-right: 2.7rem !important;
    padding-left: 2.7rem !important; }
  .px-sm-26 {
    padding-right: 2.6rem !important;
    padding-left: 2.6rem !important; }
  .px-sm-25 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important; }
  .px-sm-24 {
    padding-right: 2.4rem !important;
    padding-left: 2.4rem !important; }
  .px-sm-23 {
    padding-right: 2.3rem !important;
    padding-left: 2.3rem !important; }
  .px-sm-22 {
    padding-right: 2.2rem !important;
    padding-left: 2.2rem !important; }
  .px-sm-21 {
    padding-right: 2.1rem !important;
    padding-left: 2.1rem !important; }
  .px-sm-20 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .px-sm-19 {
    padding-right: 1.9rem !important;
    padding-left: 1.9rem !important; }
  .px-sm-18 {
    padding-right: 1.8rem !important;
    padding-left: 1.8rem !important; }
  .px-sm-17 {
    padding-right: 1.7rem !important;
    padding-left: 1.7rem !important; }
  .px-sm-16 {
    padding-right: 1.6rem !important;
    padding-left: 1.6rem !important; }
  .px-sm-15 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-14 {
    padding-right: 1.4rem !important;
    padding-left: 1.4rem !important; }
  .px-sm-13 {
    padding-right: 1.3rem !important;
    padding-left: 1.3rem !important; }
  .px-sm-12 {
    padding-right: 1.2rem !important;
    padding-left: 1.2rem !important; }
  .px-sm-11 {
    padding-right: 1.1rem !important;
    padding-left: 1.1rem !important; }
  .px-sm-10 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-5 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-sm-6 {
    padding-right: 0.6rem !important;
    padding-left: 0.6rem !important; }
  .px-sm-8 {
    padding-right: 0.8rem !important;
    padding-left: 0.8rem !important; }
  .px-sm-40 {
    padding-right: 4rem !important;
    padding-left: 4rem !important; }
  .px-sm-45 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important; }
  .px-sm-50 {
    padding-right: 5rem !important;
    padding-left: 5rem !important; }
  .px-sm-55 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important; }
  .px-sm-60 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .px-sm-70 {
    padding-right: 7rem !important;
    padding-left: 7rem !important; }
  .px-sm-80 {
    padding-right: 8rem !important;
    padding-left: 8rem !important; }
  .px-sm-90 {
    padding-right: 9rem !important;
    padding-left: 9rem !important; }
  .px-sm-100 {
    padding-right: 10rem !important;
    padding-left: 10rem !important; }
  .px-sm-105 {
    padding-right: 10.5rem !important;
    padding-left: 10.5rem !important; }
  .px-sm-110 {
    padding-right: 11rem !important;
    padding-left: 11rem !important; }
  .px-sm-125 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important; }
  .px-sm-130 {
    padding-right: 13rem !important;
    padding-left: 13rem !important; }
  .px-sm-145 {
    padding-right: 14.5rem !important;
    padding-left: 14.5rem !important; }
  .px-sm-170 {
    padding-right: 17rem !important;
    padding-left: 17rem !important; }
  .px-sm-235 {
    padding-right: 23.5rem !important;
    padding-left: 23.5rem !important; }
  .px-sm-n20 {
    padding-right: -2rem !important;
    padding-left: -2rem !important; }
  .py-sm-35 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important; }
  .py-sm-34 {
    padding-top: 3.4rem !important;
    padding-bottom: 3.4rem !important; }
  .py-sm-33 {
    padding-top: 3.3rem !important;
    padding-bottom: 3.3rem !important; }
  .py-sm-32 {
    padding-top: 3.2rem !important;
    padding-bottom: 3.2rem !important; }
  .py-sm-31 {
    padding-top: 3.1rem !important;
    padding-bottom: 3.1rem !important; }
  .py-sm-30 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-sm-29 {
    padding-top: 2.9rem !important;
    padding-bottom: 2.9rem !important; }
  .py-sm-28 {
    padding-top: 2.8rem !important;
    padding-bottom: 2.8rem !important; }
  .py-sm-27 {
    padding-top: 2.7rem !important;
    padding-bottom: 2.7rem !important; }
  .py-sm-26 {
    padding-top: 2.6rem !important;
    padding-bottom: 2.6rem !important; }
  .py-sm-25 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .py-sm-24 {
    padding-top: 2.4rem !important;
    padding-bottom: 2.4rem !important; }
  .py-sm-23 {
    padding-top: 2.3rem !important;
    padding-bottom: 2.3rem !important; }
  .py-sm-22 {
    padding-top: 2.2rem !important;
    padding-bottom: 2.2rem !important; }
  .py-sm-21 {
    padding-top: 2.1rem !important;
    padding-bottom: 2.1rem !important; }
  .py-sm-20 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .py-sm-19 {
    padding-top: 1.9rem !important;
    padding-bottom: 1.9rem !important; }
  .py-sm-18 {
    padding-top: 1.8rem !important;
    padding-bottom: 1.8rem !important; }
  .py-sm-17 {
    padding-top: 1.7rem !important;
    padding-bottom: 1.7rem !important; }
  .py-sm-16 {
    padding-top: 1.6rem !important;
    padding-bottom: 1.6rem !important; }
  .py-sm-15 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-14 {
    padding-top: 1.4rem !important;
    padding-bottom: 1.4rem !important; }
  .py-sm-13 {
    padding-top: 1.3rem !important;
    padding-bottom: 1.3rem !important; }
  .py-sm-12 {
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important; }
  .py-sm-11 {
    padding-top: 1.1rem !important;
    padding-bottom: 1.1rem !important; }
  .py-sm-10 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-5 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-sm-6 {
    padding-top: 0.6rem !important;
    padding-bottom: 0.6rem !important; }
  .py-sm-8 {
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important; }
  .py-sm-40 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .py-sm-45 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important; }
  .py-sm-50 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .py-sm-55 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important; }
  .py-sm-60 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .py-sm-70 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important; }
  .py-sm-80 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important; }
  .py-sm-90 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important; }
  .py-sm-100 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important; }
  .py-sm-105 {
    padding-top: 10.5rem !important;
    padding-bottom: 10.5rem !important; }
  .py-sm-110 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important; }
  .py-sm-125 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important; }
  .py-sm-130 {
    padding-top: 13rem !important;
    padding-bottom: 13rem !important; }
  .py-sm-145 {
    padding-top: 14.5rem !important;
    padding-bottom: 14.5rem !important; }
  .py-sm-170 {
    padding-top: 17rem !important;
    padding-bottom: 17rem !important; }
  .py-sm-235 {
    padding-top: 23.5rem !important;
    padding-bottom: 23.5rem !important; }
  .py-sm-n20 {
    padding-top: -2rem !important;
    padding-bottom: -2rem !important; }
  .pt-sm-35 {
    padding-top: 3.5rem !important; }
  .pt-sm-34 {
    padding-top: 3.4rem !important; }
  .pt-sm-33 {
    padding-top: 3.3rem !important; }
  .pt-sm-32 {
    padding-top: 3.2rem !important; }
  .pt-sm-31 {
    padding-top: 3.1rem !important; }
  .pt-sm-30 {
    padding-top: 3rem !important; }
  .pt-sm-29 {
    padding-top: 2.9rem !important; }
  .pt-sm-28 {
    padding-top: 2.8rem !important; }
  .pt-sm-27 {
    padding-top: 2.7rem !important; }
  .pt-sm-26 {
    padding-top: 2.6rem !important; }
  .pt-sm-25 {
    padding-top: 2.5rem !important; }
  .pt-sm-24 {
    padding-top: 2.4rem !important; }
  .pt-sm-23 {
    padding-top: 2.3rem !important; }
  .pt-sm-22 {
    padding-top: 2.2rem !important; }
  .pt-sm-21 {
    padding-top: 2.1rem !important; }
  .pt-sm-20 {
    padding-top: 2rem !important; }
  .pt-sm-19 {
    padding-top: 1.9rem !important; }
  .pt-sm-18 {
    padding-top: 1.8rem !important; }
  .pt-sm-17 {
    padding-top: 1.7rem !important; }
  .pt-sm-16 {
    padding-top: 1.6rem !important; }
  .pt-sm-15 {
    padding-top: 1.5rem !important; }
  .pt-sm-14 {
    padding-top: 1.4rem !important; }
  .pt-sm-13 {
    padding-top: 1.3rem !important; }
  .pt-sm-12 {
    padding-top: 1.2rem !important; }
  .pt-sm-11 {
    padding-top: 1.1rem !important; }
  .pt-sm-10 {
    padding-top: 1rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pt-sm-5 {
    padding-top: 0.5rem !important; }
  .pt-sm-6 {
    padding-top: 0.6rem !important; }
  .pt-sm-8 {
    padding-top: 0.8rem !important; }
  .pt-sm-40 {
    padding-top: 4rem !important; }
  .pt-sm-45 {
    padding-top: 4.5rem !important; }
  .pt-sm-50 {
    padding-top: 5rem !important; }
  .pt-sm-55 {
    padding-top: 5.5rem !important; }
  .pt-sm-60 {
    padding-top: 6rem !important; }
  .pt-sm-70 {
    padding-top: 7rem !important; }
  .pt-sm-80 {
    padding-top: 8rem !important; }
  .pt-sm-90 {
    padding-top: 9rem !important; }
  .pt-sm-100 {
    padding-top: 10rem !important; }
  .pt-sm-105 {
    padding-top: 10.5rem !important; }
  .pt-sm-110 {
    padding-top: 11rem !important; }
  .pt-sm-125 {
    padding-top: 12.5rem !important; }
  .pt-sm-130 {
    padding-top: 13rem !important; }
  .pt-sm-145 {
    padding-top: 14.5rem !important; }
  .pt-sm-170 {
    padding-top: 17rem !important; }
  .pt-sm-235 {
    padding-top: 23.5rem !important; }
  .pt-sm-n20 {
    padding-top: -2rem !important; }
  .pe-sm-35 {
    padding-right: 3.5rem !important; }
  .pe-sm-34 {
    padding-right: 3.4rem !important; }
  .pe-sm-33 {
    padding-right: 3.3rem !important; }
  .pe-sm-32 {
    padding-right: 3.2rem !important; }
  .pe-sm-31 {
    padding-right: 3.1rem !important; }
  .pe-sm-30 {
    padding-right: 3rem !important; }
  .pe-sm-29 {
    padding-right: 2.9rem !important; }
  .pe-sm-28 {
    padding-right: 2.8rem !important; }
  .pe-sm-27 {
    padding-right: 2.7rem !important; }
  .pe-sm-26 {
    padding-right: 2.6rem !important; }
  .pe-sm-25 {
    padding-right: 2.5rem !important; }
  .pe-sm-24 {
    padding-right: 2.4rem !important; }
  .pe-sm-23 {
    padding-right: 2.3rem !important; }
  .pe-sm-22 {
    padding-right: 2.2rem !important; }
  .pe-sm-21 {
    padding-right: 2.1rem !important; }
  .pe-sm-20 {
    padding-right: 2rem !important; }
  .pe-sm-19 {
    padding-right: 1.9rem !important; }
  .pe-sm-18 {
    padding-right: 1.8rem !important; }
  .pe-sm-17 {
    padding-right: 1.7rem !important; }
  .pe-sm-16 {
    padding-right: 1.6rem !important; }
  .pe-sm-15 {
    padding-right: 1.5rem !important; }
  .pe-sm-14 {
    padding-right: 1.4rem !important; }
  .pe-sm-13 {
    padding-right: 1.3rem !important; }
  .pe-sm-12 {
    padding-right: 1.2rem !important; }
  .pe-sm-11 {
    padding-right: 1.1rem !important; }
  .pe-sm-10 {
    padding-right: 1rem !important; }
  .pe-sm-0 {
    padding-right: 0 !important; }
  .pe-sm-1 {
    padding-right: 0.25rem !important; }
  .pe-sm-2 {
    padding-right: 0.5rem !important; }
  .pe-sm-3 {
    padding-right: 1rem !important; }
  .pe-sm-4 {
    padding-right: 1.5rem !important; }
  .pe-sm-5 {
    padding-right: 0.5rem !important; }
  .pe-sm-6 {
    padding-right: 0.6rem !important; }
  .pe-sm-8 {
    padding-right: 0.8rem !important; }
  .pe-sm-40 {
    padding-right: 4rem !important; }
  .pe-sm-45 {
    padding-right: 4.5rem !important; }
  .pe-sm-50 {
    padding-right: 5rem !important; }
  .pe-sm-55 {
    padding-right: 5.5rem !important; }
  .pe-sm-60 {
    padding-right: 6rem !important; }
  .pe-sm-70 {
    padding-right: 7rem !important; }
  .pe-sm-80 {
    padding-right: 8rem !important; }
  .pe-sm-90 {
    padding-right: 9rem !important; }
  .pe-sm-100 {
    padding-right: 10rem !important; }
  .pe-sm-105 {
    padding-right: 10.5rem !important; }
  .pe-sm-110 {
    padding-right: 11rem !important; }
  .pe-sm-125 {
    padding-right: 12.5rem !important; }
  .pe-sm-130 {
    padding-right: 13rem !important; }
  .pe-sm-145 {
    padding-right: 14.5rem !important; }
  .pe-sm-170 {
    padding-right: 17rem !important; }
  .pe-sm-235 {
    padding-right: 23.5rem !important; }
  .pe-sm-n20 {
    padding-right: -2rem !important; }
  .pb-sm-35 {
    padding-bottom: 3.5rem !important; }
  .pb-sm-34 {
    padding-bottom: 3.4rem !important; }
  .pb-sm-33 {
    padding-bottom: 3.3rem !important; }
  .pb-sm-32 {
    padding-bottom: 3.2rem !important; }
  .pb-sm-31 {
    padding-bottom: 3.1rem !important; }
  .pb-sm-30 {
    padding-bottom: 3rem !important; }
  .pb-sm-29 {
    padding-bottom: 2.9rem !important; }
  .pb-sm-28 {
    padding-bottom: 2.8rem !important; }
  .pb-sm-27 {
    padding-bottom: 2.7rem !important; }
  .pb-sm-26 {
    padding-bottom: 2.6rem !important; }
  .pb-sm-25 {
    padding-bottom: 2.5rem !important; }
  .pb-sm-24 {
    padding-bottom: 2.4rem !important; }
  .pb-sm-23 {
    padding-bottom: 2.3rem !important; }
  .pb-sm-22 {
    padding-bottom: 2.2rem !important; }
  .pb-sm-21 {
    padding-bottom: 2.1rem !important; }
  .pb-sm-20 {
    padding-bottom: 2rem !important; }
  .pb-sm-19 {
    padding-bottom: 1.9rem !important; }
  .pb-sm-18 {
    padding-bottom: 1.8rem !important; }
  .pb-sm-17 {
    padding-bottom: 1.7rem !important; }
  .pb-sm-16 {
    padding-bottom: 1.6rem !important; }
  .pb-sm-15 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-14 {
    padding-bottom: 1.4rem !important; }
  .pb-sm-13 {
    padding-bottom: 1.3rem !important; }
  .pb-sm-12 {
    padding-bottom: 1.2rem !important; }
  .pb-sm-11 {
    padding-bottom: 1.1rem !important; }
  .pb-sm-10 {
    padding-bottom: 1rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-5 {
    padding-bottom: 0.5rem !important; }
  .pb-sm-6 {
    padding-bottom: 0.6rem !important; }
  .pb-sm-8 {
    padding-bottom: 0.8rem !important; }
  .pb-sm-40 {
    padding-bottom: 4rem !important; }
  .pb-sm-45 {
    padding-bottom: 4.5rem !important; }
  .pb-sm-50 {
    padding-bottom: 5rem !important; }
  .pb-sm-55 {
    padding-bottom: 5.5rem !important; }
  .pb-sm-60 {
    padding-bottom: 6rem !important; }
  .pb-sm-70 {
    padding-bottom: 7rem !important; }
  .pb-sm-80 {
    padding-bottom: 8rem !important; }
  .pb-sm-90 {
    padding-bottom: 9rem !important; }
  .pb-sm-100 {
    padding-bottom: 10rem !important; }
  .pb-sm-105 {
    padding-bottom: 10.5rem !important; }
  .pb-sm-110 {
    padding-bottom: 11rem !important; }
  .pb-sm-125 {
    padding-bottom: 12.5rem !important; }
  .pb-sm-130 {
    padding-bottom: 13rem !important; }
  .pb-sm-145 {
    padding-bottom: 14.5rem !important; }
  .pb-sm-170 {
    padding-bottom: 17rem !important; }
  .pb-sm-235 {
    padding-bottom: 23.5rem !important; }
  .pb-sm-n20 {
    padding-bottom: -2rem !important; }
  .ps-sm-35 {
    padding-left: 3.5rem !important; }
  .ps-sm-34 {
    padding-left: 3.4rem !important; }
  .ps-sm-33 {
    padding-left: 3.3rem !important; }
  .ps-sm-32 {
    padding-left: 3.2rem !important; }
  .ps-sm-31 {
    padding-left: 3.1rem !important; }
  .ps-sm-30 {
    padding-left: 3rem !important; }
  .ps-sm-29 {
    padding-left: 2.9rem !important; }
  .ps-sm-28 {
    padding-left: 2.8rem !important; }
  .ps-sm-27 {
    padding-left: 2.7rem !important; }
  .ps-sm-26 {
    padding-left: 2.6rem !important; }
  .ps-sm-25 {
    padding-left: 2.5rem !important; }
  .ps-sm-24 {
    padding-left: 2.4rem !important; }
  .ps-sm-23 {
    padding-left: 2.3rem !important; }
  .ps-sm-22 {
    padding-left: 2.2rem !important; }
  .ps-sm-21 {
    padding-left: 2.1rem !important; }
  .ps-sm-20 {
    padding-left: 2rem !important; }
  .ps-sm-19 {
    padding-left: 1.9rem !important; }
  .ps-sm-18 {
    padding-left: 1.8rem !important; }
  .ps-sm-17 {
    padding-left: 1.7rem !important; }
  .ps-sm-16 {
    padding-left: 1.6rem !important; }
  .ps-sm-15 {
    padding-left: 1.5rem !important; }
  .ps-sm-14 {
    padding-left: 1.4rem !important; }
  .ps-sm-13 {
    padding-left: 1.3rem !important; }
  .ps-sm-12 {
    padding-left: 1.2rem !important; }
  .ps-sm-11 {
    padding-left: 1.1rem !important; }
  .ps-sm-10 {
    padding-left: 1rem !important; }
  .ps-sm-0 {
    padding-left: 0 !important; }
  .ps-sm-1 {
    padding-left: 0.25rem !important; }
  .ps-sm-2 {
    padding-left: 0.5rem !important; }
  .ps-sm-3 {
    padding-left: 1rem !important; }
  .ps-sm-4 {
    padding-left: 1.5rem !important; }
  .ps-sm-5 {
    padding-left: 0.5rem !important; }
  .ps-sm-6 {
    padding-left: 0.6rem !important; }
  .ps-sm-8 {
    padding-left: 0.8rem !important; }
  .ps-sm-40 {
    padding-left: 4rem !important; }
  .ps-sm-45 {
    padding-left: 4.5rem !important; }
  .ps-sm-50 {
    padding-left: 5rem !important; }
  .ps-sm-55 {
    padding-left: 5.5rem !important; }
  .ps-sm-60 {
    padding-left: 6rem !important; }
  .ps-sm-70 {
    padding-left: 7rem !important; }
  .ps-sm-80 {
    padding-left: 8rem !important; }
  .ps-sm-90 {
    padding-left: 9rem !important; }
  .ps-sm-100 {
    padding-left: 10rem !important; }
  .ps-sm-105 {
    padding-left: 10.5rem !important; }
  .ps-sm-110 {
    padding-left: 11rem !important; }
  .ps-sm-125 {
    padding-left: 12.5rem !important; }
  .ps-sm-130 {
    padding-left: 13rem !important; }
  .ps-sm-145 {
    padding-left: 14.5rem !important; }
  .ps-sm-170 {
    padding-left: 17rem !important; }
  .ps-sm-235 {
    padding-left: 23.5rem !important; }
  .ps-sm-n20 {
    padding-left: -2rem !important; }
  .font-sm-family-02 {
    font-family: "Caveat", cursive !important; }
  .fs-sm-40 {
    font-size: 4rem !important; }
  .fs-sm-39 {
    font-size: 3.9rem !important; }
  .fs-sm-38 {
    font-size: 3.8rem !important; }
  .fs-sm-37 {
    font-size: 3.7rem !important; }
  .fs-sm-36 {
    font-size: 3.6rem !important; }
  .fs-sm-35 {
    font-size: 3.5rem !important; }
  .fs-sm-34 {
    font-size: 3.4rem !important; }
  .fs-sm-33 {
    font-size: 3.3rem !important; }
  .fs-sm-32 {
    font-size: 3.2rem !important; }
  .fs-sm-31 {
    font-size: 3.1rem !important; }
  .fs-sm-30 {
    font-size: 3rem !important; }
  .fs-sm-29 {
    font-size: 2.9rem !important; }
  .fs-sm-28 {
    font-size: 2.8rem !important; }
  .fs-sm-27 {
    font-size: 2.7rem !important; }
  .fs-sm-26 {
    font-size: 2.6rem !important; }
  .fs-sm-25 {
    font-size: 2.5rem !important; }
  .fs-sm-24 {
    font-size: 2.4rem !important; }
  .fs-sm-23 {
    font-size: 2.3rem !important; }
  .fs-sm-22 {
    font-size: 2.2rem !important; }
  .fs-sm-21 {
    font-size: 2.1rem !important; }
  .fs-sm-20 {
    font-size: 2rem !important; }
  .fs-sm-19 {
    font-size: 1.9rem !important; }
  .fs-sm-18 {
    font-size: 1.8rem !important; }
  .fs-sm-17 {
    font-size: 1.7rem !important; }
  .fs-sm-16 {
    font-size: 1.6rem !important; }
  .fs-sm-15 {
    font-size: 1.5rem !important; }
  .fs-sm-14 {
    font-size: 1.4rem !important; }
  .fs-sm-13 {
    font-size: 1.3rem !important; }
  .fs-sm-12 {
    font-size: 1.2rem !important; }
  .fs-sm-11 {
    font-size: 1.1rem !important; }
  .fs-sm-10 {
    font-size: 1rem !important; }
  .fs-sm-60 {
    font-size: calc(1.725rem + 5.7vw) !important; }
  .text-sm-start {
    text-align: left !important; }
  .text-sm-end {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; }
  .rounded-sm-20 {
    border-radius: 2rem !important; }
  .rounded-sm-19 {
    border-radius: 1.9rem !important; }
  .rounded-sm-18 {
    border-radius: 1.8rem !important; }
  .rounded-sm-17 {
    border-radius: 1.7rem !important; }
  .rounded-sm-16 {
    border-radius: 1.6rem !important; }
  .rounded-sm-15 {
    border-radius: 1.5rem !important; }
  .rounded-sm-14 {
    border-radius: 1.4rem !important; }
  .rounded-sm-13 {
    border-radius: 1.3rem !important; }
  .rounded-sm-12 {
    border-radius: 1.2rem !important; }
  .rounded-sm-11 {
    border-radius: 1.1rem !important; }
  .rounded-sm-10 {
    border-radius: 1rem !important; }
  .rounded-sm-9 {
    border-radius: 0.9rem !important; }
  .rounded-sm-8 {
    border-radius: 0.8rem !important; }
  .rounded-sm-7 {
    border-radius: 0.7rem !important; }
  .rounded-sm-6 {
    border-radius: 0.6rem !important; }
  .rounded-sm-5 {
    border-radius: 0.5rem !important; }
  .rounded-sm {
    border-radius: 2px 5px 2px 5px !important; }
  .rounded-sm-0 {
    border-radius: 0 !important; }
  .rounded-sm-1 {
    border-radius: 0.6rem !important; }
  .rounded-sm-2 {
    border-radius: 2px 5px 2px 5px !important; }
  .rounded-sm-3 {
    border-radius: 5px 20px 5px 20px !important; }
  .rounded-sm-4 {
    border-radius: 5px 100px 5px 100px !important; }
  .rounded-sm-circle {
    border-radius: 50% !important; }
  .rounded-sm-pill {
    border-radius: 50rem !important; }
  .rounded-sm-btn {
    border-radius: 5px 30px 5px 5px !important; }
  .rounded-sm-carte {
    border-radius: 10px 25px 10px 25px !important; }
  .rounded-top-sm-20 {
    border-top-left-radius: 2rem !important;
    border-top-right-radius: 2rem !important; }
  .rounded-top-sm-19 {
    border-top-left-radius: 1.9rem !important;
    border-top-right-radius: 1.9rem !important; }
  .rounded-top-sm-18 {
    border-top-left-radius: 1.8rem !important;
    border-top-right-radius: 1.8rem !important; }
  .rounded-top-sm-17 {
    border-top-left-radius: 1.7rem !important;
    border-top-right-radius: 1.7rem !important; }
  .rounded-top-sm-16 {
    border-top-left-radius: 1.6rem !important;
    border-top-right-radius: 1.6rem !important; }
  .rounded-top-sm-15 {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important; }
  .rounded-top-sm-14 {
    border-top-left-radius: 1.4rem !important;
    border-top-right-radius: 1.4rem !important; }
  .rounded-top-sm-13 {
    border-top-left-radius: 1.3rem !important;
    border-top-right-radius: 1.3rem !important; }
  .rounded-top-sm-12 {
    border-top-left-radius: 1.2rem !important;
    border-top-right-radius: 1.2rem !important; }
  .rounded-top-sm-11 {
    border-top-left-radius: 1.1rem !important;
    border-top-right-radius: 1.1rem !important; }
  .rounded-top-sm-10 {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important; }
  .rounded-top-sm-9 {
    border-top-left-radius: 0.9rem !important;
    border-top-right-radius: 0.9rem !important; }
  .rounded-top-sm-8 {
    border-top-left-radius: 0.8rem !important;
    border-top-right-radius: 0.8rem !important; }
  .rounded-top-sm-7 {
    border-top-left-radius: 0.7rem !important;
    border-top-right-radius: 0.7rem !important; }
  .rounded-top-sm-6 {
    border-top-left-radius: 0.6rem !important;
    border-top-right-radius: 0.6rem !important; }
  .rounded-top-sm-5 {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important; }
  .rounded-top-sm {
    border-top-left-radius: 2px 5px 2px 5px !important;
    border-top-right-radius: 2px 5px 2px 5px !important; }
  .rounded-top-sm-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important; }
  .rounded-top-sm-1 {
    border-top-left-radius: 0.6rem !important;
    border-top-right-radius: 0.6rem !important; }
  .rounded-top-sm-2 {
    border-top-left-radius: 2px 5px 2px 5px !important;
    border-top-right-radius: 2px 5px 2px 5px !important; }
  .rounded-top-sm-3 {
    border-top-left-radius: 5px 20px 5px 20px !important;
    border-top-right-radius: 5px 20px 5px 20px !important; }
  .rounded-top-sm-4 {
    border-top-left-radius: 5px 100px 5px 100px !important;
    border-top-right-radius: 5px 100px 5px 100px !important; }
  .rounded-top-sm-circle {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important; }
  .rounded-top-sm-pill {
    border-top-left-radius: 50rem !important;
    border-top-right-radius: 50rem !important; }
  .rounded-top-sm-btn {
    border-top-left-radius: 5px 30px 5px 5px !important;
    border-top-right-radius: 5px 30px 5px 5px !important; }
  .rounded-top-sm-carte {
    border-top-left-radius: 10px 25px 10px 25px !important;
    border-top-right-radius: 10px 25px 10px 25px !important; }
  .rounded-end-sm-20 {
    border-top-right-radius: 2rem !important;
    border-bottom-right-radius: 2rem !important; }
  .rounded-end-sm-19 {
    border-top-right-radius: 1.9rem !important;
    border-bottom-right-radius: 1.9rem !important; }
  .rounded-end-sm-18 {
    border-top-right-radius: 1.8rem !important;
    border-bottom-right-radius: 1.8rem !important; }
  .rounded-end-sm-17 {
    border-top-right-radius: 1.7rem !important;
    border-bottom-right-radius: 1.7rem !important; }
  .rounded-end-sm-16 {
    border-top-right-radius: 1.6rem !important;
    border-bottom-right-radius: 1.6rem !important; }
  .rounded-end-sm-15 {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important; }
  .rounded-end-sm-14 {
    border-top-right-radius: 1.4rem !important;
    border-bottom-right-radius: 1.4rem !important; }
  .rounded-end-sm-13 {
    border-top-right-radius: 1.3rem !important;
    border-bottom-right-radius: 1.3rem !important; }
  .rounded-end-sm-12 {
    border-top-right-radius: 1.2rem !important;
    border-bottom-right-radius: 1.2rem !important; }
  .rounded-end-sm-11 {
    border-top-right-radius: 1.1rem !important;
    border-bottom-right-radius: 1.1rem !important; }
  .rounded-end-sm-10 {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important; }
  .rounded-end-sm-9 {
    border-top-right-radius: 0.9rem !important;
    border-bottom-right-radius: 0.9rem !important; }
  .rounded-end-sm-8 {
    border-top-right-radius: 0.8rem !important;
    border-bottom-right-radius: 0.8rem !important; }
  .rounded-end-sm-7 {
    border-top-right-radius: 0.7rem !important;
    border-bottom-right-radius: 0.7rem !important; }
  .rounded-end-sm-6 {
    border-top-right-radius: 0.6rem !important;
    border-bottom-right-radius: 0.6rem !important; }
  .rounded-end-sm-5 {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important; }
  .rounded-end-sm {
    border-top-right-radius: 2px 5px 2px 5px !important;
    border-bottom-right-radius: 2px 5px 2px 5px !important; }
  .rounded-end-sm-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important; }
  .rounded-end-sm-1 {
    border-top-right-radius: 0.6rem !important;
    border-bottom-right-radius: 0.6rem !important; }
  .rounded-end-sm-2 {
    border-top-right-radius: 2px 5px 2px 5px !important;
    border-bottom-right-radius: 2px 5px 2px 5px !important; }
  .rounded-end-sm-3 {
    border-top-right-radius: 5px 20px 5px 20px !important;
    border-bottom-right-radius: 5px 20px 5px 20px !important; }
  .rounded-end-sm-4 {
    border-top-right-radius: 5px 100px 5px 100px !important;
    border-bottom-right-radius: 5px 100px 5px 100px !important; }
  .rounded-end-sm-circle {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important; }
  .rounded-end-sm-pill {
    border-top-right-radius: 50rem !important;
    border-bottom-right-radius: 50rem !important; }
  .rounded-end-sm-btn {
    border-top-right-radius: 5px 30px 5px 5px !important;
    border-bottom-right-radius: 5px 30px 5px 5px !important; }
  .rounded-end-sm-carte {
    border-top-right-radius: 10px 25px 10px 25px !important;
    border-bottom-right-radius: 10px 25px 10px 25px !important; }
  .rounded-bottom-sm-20 {
    border-bottom-right-radius: 2rem !important;
    border-bottom-left-radius: 2rem !important; }
  .rounded-bottom-sm-19 {
    border-bottom-right-radius: 1.9rem !important;
    border-bottom-left-radius: 1.9rem !important; }
  .rounded-bottom-sm-18 {
    border-bottom-right-radius: 1.8rem !important;
    border-bottom-left-radius: 1.8rem !important; }
  .rounded-bottom-sm-17 {
    border-bottom-right-radius: 1.7rem !important;
    border-bottom-left-radius: 1.7rem !important; }
  .rounded-bottom-sm-16 {
    border-bottom-right-radius: 1.6rem !important;
    border-bottom-left-radius: 1.6rem !important; }
  .rounded-bottom-sm-15 {
    border-bottom-right-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important; }
  .rounded-bottom-sm-14 {
    border-bottom-right-radius: 1.4rem !important;
    border-bottom-left-radius: 1.4rem !important; }
  .rounded-bottom-sm-13 {
    border-bottom-right-radius: 1.3rem !important;
    border-bottom-left-radius: 1.3rem !important; }
  .rounded-bottom-sm-12 {
    border-bottom-right-radius: 1.2rem !important;
    border-bottom-left-radius: 1.2rem !important; }
  .rounded-bottom-sm-11 {
    border-bottom-right-radius: 1.1rem !important;
    border-bottom-left-radius: 1.1rem !important; }
  .rounded-bottom-sm-10 {
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important; }
  .rounded-bottom-sm-9 {
    border-bottom-right-radius: 0.9rem !important;
    border-bottom-left-radius: 0.9rem !important; }
  .rounded-bottom-sm-8 {
    border-bottom-right-radius: 0.8rem !important;
    border-bottom-left-radius: 0.8rem !important; }
  .rounded-bottom-sm-7 {
    border-bottom-right-radius: 0.7rem !important;
    border-bottom-left-radius: 0.7rem !important; }
  .rounded-bottom-sm-6 {
    border-bottom-right-radius: 0.6rem !important;
    border-bottom-left-radius: 0.6rem !important; }
  .rounded-bottom-sm-5 {
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important; }
  .rounded-bottom-sm {
    border-bottom-right-radius: 2px 5px 2px 5px !important;
    border-bottom-left-radius: 2px 5px 2px 5px !important; }
  .rounded-bottom-sm-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important; }
  .rounded-bottom-sm-1 {
    border-bottom-right-radius: 0.6rem !important;
    border-bottom-left-radius: 0.6rem !important; }
  .rounded-bottom-sm-2 {
    border-bottom-right-radius: 2px 5px 2px 5px !important;
    border-bottom-left-radius: 2px 5px 2px 5px !important; }
  .rounded-bottom-sm-3 {
    border-bottom-right-radius: 5px 20px 5px 20px !important;
    border-bottom-left-radius: 5px 20px 5px 20px !important; }
  .rounded-bottom-sm-4 {
    border-bottom-right-radius: 5px 100px 5px 100px !important;
    border-bottom-left-radius: 5px 100px 5px 100px !important; }
  .rounded-bottom-sm-circle {
    border-bottom-right-radius: 50% !important;
    border-bottom-left-radius: 50% !important; }
  .rounded-bottom-sm-pill {
    border-bottom-right-radius: 50rem !important;
    border-bottom-left-radius: 50rem !important; }
  .rounded-bottom-sm-btn {
    border-bottom-right-radius: 5px 30px 5px 5px !important;
    border-bottom-left-radius: 5px 30px 5px 5px !important; }
  .rounded-bottom-sm-carte {
    border-bottom-right-radius: 10px 25px 10px 25px !important;
    border-bottom-left-radius: 10px 25px 10px 25px !important; }
  .rounded-start-sm-20 {
    border-bottom-left-radius: 2rem !important;
    border-top-left-radius: 2rem !important; }
  .rounded-start-sm-19 {
    border-bottom-left-radius: 1.9rem !important;
    border-top-left-radius: 1.9rem !important; }
  .rounded-start-sm-18 {
    border-bottom-left-radius: 1.8rem !important;
    border-top-left-radius: 1.8rem !important; }
  .rounded-start-sm-17 {
    border-bottom-left-radius: 1.7rem !important;
    border-top-left-radius: 1.7rem !important; }
  .rounded-start-sm-16 {
    border-bottom-left-radius: 1.6rem !important;
    border-top-left-radius: 1.6rem !important; }
  .rounded-start-sm-15 {
    border-bottom-left-radius: 1.5rem !important;
    border-top-left-radius: 1.5rem !important; }
  .rounded-start-sm-14 {
    border-bottom-left-radius: 1.4rem !important;
    border-top-left-radius: 1.4rem !important; }
  .rounded-start-sm-13 {
    border-bottom-left-radius: 1.3rem !important;
    border-top-left-radius: 1.3rem !important; }
  .rounded-start-sm-12 {
    border-bottom-left-radius: 1.2rem !important;
    border-top-left-radius: 1.2rem !important; }
  .rounded-start-sm-11 {
    border-bottom-left-radius: 1.1rem !important;
    border-top-left-radius: 1.1rem !important; }
  .rounded-start-sm-10 {
    border-bottom-left-radius: 1rem !important;
    border-top-left-radius: 1rem !important; }
  .rounded-start-sm-9 {
    border-bottom-left-radius: 0.9rem !important;
    border-top-left-radius: 0.9rem !important; }
  .rounded-start-sm-8 {
    border-bottom-left-radius: 0.8rem !important;
    border-top-left-radius: 0.8rem !important; }
  .rounded-start-sm-7 {
    border-bottom-left-radius: 0.7rem !important;
    border-top-left-radius: 0.7rem !important; }
  .rounded-start-sm-6 {
    border-bottom-left-radius: 0.6rem !important;
    border-top-left-radius: 0.6rem !important; }
  .rounded-start-sm-5 {
    border-bottom-left-radius: 0.5rem !important;
    border-top-left-radius: 0.5rem !important; }
  .rounded-start-sm {
    border-bottom-left-radius: 2px 5px 2px 5px !important;
    border-top-left-radius: 2px 5px 2px 5px !important; }
  .rounded-start-sm-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important; }
  .rounded-start-sm-1 {
    border-bottom-left-radius: 0.6rem !important;
    border-top-left-radius: 0.6rem !important; }
  .rounded-start-sm-2 {
    border-bottom-left-radius: 2px 5px 2px 5px !important;
    border-top-left-radius: 2px 5px 2px 5px !important; }
  .rounded-start-sm-3 {
    border-bottom-left-radius: 5px 20px 5px 20px !important;
    border-top-left-radius: 5px 20px 5px 20px !important; }
  .rounded-start-sm-4 {
    border-bottom-left-radius: 5px 100px 5px 100px !important;
    border-top-left-radius: 5px 100px 5px 100px !important; }
  .rounded-start-sm-circle {
    border-bottom-left-radius: 50% !important;
    border-top-left-radius: 50% !important; }
  .rounded-start-sm-pill {
    border-bottom-left-radius: 50rem !important;
    border-top-left-radius: 50rem !important; }
  .rounded-start-sm-btn {
    border-bottom-left-radius: 5px 30px 5px 5px !important;
    border-top-left-radius: 5px 30px 5px 5px !important; }
  .rounded-start-sm-carte {
    border-bottom-left-radius: 10px 25px 10px 25px !important;
    border-top-left-radius: 10px 25px 10px 25px !important; }
  .z-index-sm-1 {
    z-index: 1 !important; }
  .z-index-sm-2 {
    z-index: 2 !important; }
  .z-index-sm-3 {
    z-index: 3 !important; }
  .z-index-sm-4 {
    z-index: 4 !important; }
  .z-index-sm-5 {
    z-index: 5 !important; } }

@media (min-width: 768px) {
  .float-md-start {
    float: left !important; }
  .float-md-end {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-grid {
    display: grid !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .position-md-static {
    position: static !important; }
  .position-md-relative {
    position: relative !important; }
  .position-md-absolute {
    position: absolute !important; }
  .position-md-fixed {
    position: fixed !important; }
  .position-md-sticky {
    position: sticky !important; }
  .top-md-0 {
    top: 0 !important; }
  .top-md-5 {
    top: 5% !important; }
  .top-md-10 {
    top: 10% !important; }
  .top-md-15 {
    top: 15% !important; }
  .top-md-20 {
    top: 20% !important; }
  .top-md-25 {
    top: 25% !important; }
  .top-md-30 {
    top: 30% !important; }
  .top-md-40 {
    top: 40% !important; }
  .top-md-50 {
    top: 50% !important; }
  .top-md-80 {
    top: 80% !important; }
  .top-md-90 {
    top: 90% !important; }
  .top-md-100 {
    top: 100% !important; }
  .top-md-2px {
    top: 2px !important; }
  .top-md-100px {
    top: 100px !important; }
  .top-md-n30px {
    top: -30px !important; }
  .top-md-n60px {
    top: -60px !important; }
  .top-md-n130px {
    top: -130px !important; }
  .top-md-n150px {
    top: -150px !important; }
  .top-md-n170px {
    top: -170px !important; }
  .top-md-n300px {
    top: -300px !important; }
  .bottom-md-0 {
    bottom: 0 !important; }
  .bottom-md-5 {
    bottom: 5% !important; }
  .bottom-md-10 {
    bottom: 10% !important; }
  .bottom-md-15 {
    bottom: 15% !important; }
  .bottom-md-20 {
    bottom: 20% !important; }
  .bottom-md-25 {
    bottom: 25% !important; }
  .bottom-md-30 {
    bottom: 30% !important; }
  .bottom-md-40 {
    bottom: 40% !important; }
  .bottom-md-50 {
    bottom: 50% !important; }
  .bottom-md-80 {
    bottom: 80% !important; }
  .bottom-md-90 {
    bottom: 90% !important; }
  .bottom-md-100 {
    bottom: 100% !important; }
  .bottom-md-2px {
    bottom: 2px !important; }
  .bottom-md-100px {
    bottom: 100px !important; }
  .bottom-md-n30px {
    bottom: -30px !important; }
  .bottom-md-n60px {
    bottom: -60px !important; }
  .bottom-md-n130px {
    bottom: -130px !important; }
  .bottom-md-n150px {
    bottom: -150px !important; }
  .bottom-md-n170px {
    bottom: -170px !important; }
  .bottom-md-n300px {
    bottom: -300px !important; }
  .start-md-0 {
    left: 0 !important; }
  .start-md-5 {
    left: 5% !important; }
  .start-md-10 {
    left: 10% !important; }
  .start-md-15 {
    left: 15% !important; }
  .start-md-20 {
    left: 20% !important; }
  .start-md-25 {
    left: 25% !important; }
  .start-md-30 {
    left: 30% !important; }
  .start-md-40 {
    left: 40% !important; }
  .start-md-50 {
    left: 50% !important; }
  .start-md-80 {
    left: 80% !important; }
  .start-md-90 {
    left: 90% !important; }
  .start-md-100 {
    left: 100% !important; }
  .start-md-2px {
    left: 2px !important; }
  .start-md-100px {
    left: 100px !important; }
  .start-md-n30px {
    left: -30px !important; }
  .start-md-n60px {
    left: -60px !important; }
  .start-md-n130px {
    left: -130px !important; }
  .start-md-n150px {
    left: -150px !important; }
  .start-md-n170px {
    left: -170px !important; }
  .start-md-n300px {
    left: -300px !important; }
  .end-md-0 {
    right: 0 !important; }
  .end-md-5 {
    right: 5% !important; }
  .end-md-10 {
    right: 10% !important; }
  .end-md-15 {
    right: 15% !important; }
  .end-md-20 {
    right: 20% !important; }
  .end-md-25 {
    right: 25% !important; }
  .end-md-30 {
    right: 30% !important; }
  .end-md-40 {
    right: 40% !important; }
  .end-md-50 {
    right: 50% !important; }
  .end-md-80 {
    right: 80% !important; }
  .end-md-90 {
    right: 90% !important; }
  .end-md-100 {
    right: 100% !important; }
  .end-md-2px {
    right: 2px !important; }
  .end-md-100px {
    right: 100px !important; }
  .end-md-n30px {
    right: -30px !important; }
  .end-md-n60px {
    right: -60px !important; }
  .end-md-n130px {
    right: -130px !important; }
  .end-md-n150px {
    right: -150px !important; }
  .end-md-n170px {
    right: -170px !important; }
  .end-md-n300px {
    right: -300px !important; }
  .w-md-20 {
    width: 20% !important; }
  .w-md-25 {
    width: 25% !important; }
  .w-md-30 {
    width: 30% !important; }
  .w-md-40 {
    width: 40% !important; }
  .w-md-50 {
    width: 50% !important; }
  .w-md-70 {
    width: 70% !important; }
  .w-md-75 {
    width: 75% !important; }
  .w-md-80 {
    width: 80% !important; }
  .w-md-85 {
    width: 85% !important; }
  .w-md-90 {
    width: 90% !important; }
  .w-md-100 {
    width: 100% !important; }
  .w-md-26px {
    width: 26px !important; }
  .w-md-86px {
    width: 86px !important; }
  .w-md-125px {
    width: 125px !important; }
  .w-md-145px {
    width: 145px !important; }
  .w-md-161px {
    width: 161px !important; }
  .w-md-182px {
    width: 182px !important; }
  .w-md-205px {
    width: 205px !important; }
  .w-md-215px {
    width: 215px !important; }
  .w-md-235px {
    width: 235px !important; }
  .w-md-250px {
    width: 250px !important; }
  .w-md-260px {
    width: 260px !important; }
  .w-md-268px {
    width: 268px !important; }
  .w-md-283px {
    width: 283px !important; }
  .w-md-285px {
    width: 285px !important; }
  .w-md-300px {
    width: 300px !important; }
  .w-md-305px {
    width: 305px !important; }
  .w-md-310px {
    width: 310px !important; }
  .w-md-350px {
    width: 350px !important; }
  .w-md-410px {
    width: 410px !important; }
  .w-md-440px {
    width: 440px !important; }
  .w-md-460px {
    width: 460px !important; }
  .w-md-500px {
    width: 500px !important; }
  .w-md-520px {
    width: 520px !important; }
  .w-md-600px {
    width: 600px !important; }
  .w-md-657px {
    width: 657px !important; }
  .w-md-1015px {
    width: 1015px !important; }
  .w-md-1070px {
    width: 1070px !important; }
  .w-md-auto {
    width: auto !important; }
  .h-md-20 {
    height: 20% !important; }
  .h-md-25 {
    height: 25% !important; }
  .h-md-30 {
    height: 30% !important; }
  .h-md-40 {
    height: 40% !important; }
  .h-md-50 {
    height: 50% !important; }
  .h-md-70 {
    height: 70% !important; }
  .h-md-75 {
    height: 75% !important; }
  .h-md-80 {
    height: 80% !important; }
  .h-md-85 {
    height: 85% !important; }
  .h-md-90 {
    height: 90% !important; }
  .h-md-100 {
    height: 100% !important; }
  .h-md-26px {
    height: 26px !important; }
  .h-md-86px {
    height: 86px !important; }
  .h-md-125px {
    height: 125px !important; }
  .h-md-145px {
    height: 145px !important; }
  .h-md-161px {
    height: 161px !important; }
  .h-md-182px {
    height: 182px !important; }
  .h-md-205px {
    height: 205px !important; }
  .h-md-215px {
    height: 215px !important; }
  .h-md-235px {
    height: 235px !important; }
  .h-md-250px {
    height: 250px !important; }
  .h-md-260px {
    height: 260px !important; }
  .h-md-268px {
    height: 268px !important; }
  .h-md-283px {
    height: 283px !important; }
  .h-md-285px {
    height: 285px !important; }
  .h-md-300px {
    height: 300px !important; }
  .h-md-305px {
    height: 305px !important; }
  .h-md-310px {
    height: 310px !important; }
  .h-md-350px {
    height: 350px !important; }
  .h-md-410px {
    height: 410px !important; }
  .h-md-440px {
    height: 440px !important; }
  .h-md-460px {
    height: 460px !important; }
  .h-md-500px {
    height: 500px !important; }
  .h-md-520px {
    height: 520px !important; }
  .h-md-600px {
    height: 600px !important; }
  .h-md-657px {
    height: 657px !important; }
  .h-md-1015px {
    height: 1015px !important; }
  .h-md-1070px {
    height: 1070px !important; }
  .h-md-auto {
    height: auto !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-md-35 {
    gap: 3.5rem !important; }
  .gap-md-34 {
    gap: 3.4rem !important; }
  .gap-md-33 {
    gap: 3.3rem !important; }
  .gap-md-32 {
    gap: 3.2rem !important; }
  .gap-md-31 {
    gap: 3.1rem !important; }
  .gap-md-30 {
    gap: 3rem !important; }
  .gap-md-29 {
    gap: 2.9rem !important; }
  .gap-md-28 {
    gap: 2.8rem !important; }
  .gap-md-27 {
    gap: 2.7rem !important; }
  .gap-md-26 {
    gap: 2.6rem !important; }
  .gap-md-25 {
    gap: 2.5rem !important; }
  .gap-md-24 {
    gap: 2.4rem !important; }
  .gap-md-23 {
    gap: 2.3rem !important; }
  .gap-md-22 {
    gap: 2.2rem !important; }
  .gap-md-21 {
    gap: 2.1rem !important; }
  .gap-md-20 {
    gap: 2rem !important; }
  .gap-md-19 {
    gap: 1.9rem !important; }
  .gap-md-18 {
    gap: 1.8rem !important; }
  .gap-md-17 {
    gap: 1.7rem !important; }
  .gap-md-16 {
    gap: 1.6rem !important; }
  .gap-md-15 {
    gap: 1.5rem !important; }
  .gap-md-14 {
    gap: 1.4rem !important; }
  .gap-md-13 {
    gap: 1.3rem !important; }
  .gap-md-12 {
    gap: 1.2rem !important; }
  .gap-md-11 {
    gap: 1.1rem !important; }
  .gap-md-10 {
    gap: 1rem !important; }
  .gap-md-0 {
    gap: 0 !important; }
  .gap-md-1 {
    gap: 0.25rem !important; }
  .gap-md-2 {
    gap: 0.5rem !important; }
  .gap-md-3 {
    gap: 1rem !important; }
  .gap-md-4 {
    gap: 1.5rem !important; }
  .gap-md-5 {
    gap: 0.5rem !important; }
  .gap-md-6 {
    gap: 0.6rem !important; }
  .gap-md-8 {
    gap: 0.8rem !important; }
  .gap-md-40 {
    gap: 4rem !important; }
  .gap-md-45 {
    gap: 4.5rem !important; }
  .gap-md-50 {
    gap: 5rem !important; }
  .gap-md-55 {
    gap: 5.5rem !important; }
  .gap-md-60 {
    gap: 6rem !important; }
  .gap-md-70 {
    gap: 7rem !important; }
  .gap-md-80 {
    gap: 8rem !important; }
  .gap-md-90 {
    gap: 9rem !important; }
  .gap-md-100 {
    gap: 10rem !important; }
  .gap-md-105 {
    gap: 10.5rem !important; }
  .gap-md-110 {
    gap: 11rem !important; }
  .gap-md-125 {
    gap: 12.5rem !important; }
  .gap-md-130 {
    gap: 13rem !important; }
  .gap-md-145 {
    gap: 14.5rem !important; }
  .gap-md-170 {
    gap: 17rem !important; }
  .gap-md-235 {
    gap: 23.5rem !important; }
  .gap-md-n20 {
    gap: -2rem !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-35 {
    margin: 3.5rem !important; }
  .m-md-34 {
    margin: 3.4rem !important; }
  .m-md-33 {
    margin: 3.3rem !important; }
  .m-md-32 {
    margin: 3.2rem !important; }
  .m-md-31 {
    margin: 3.1rem !important; }
  .m-md-30 {
    margin: 3rem !important; }
  .m-md-29 {
    margin: 2.9rem !important; }
  .m-md-28 {
    margin: 2.8rem !important; }
  .m-md-27 {
    margin: 2.7rem !important; }
  .m-md-26 {
    margin: 2.6rem !important; }
  .m-md-25 {
    margin: 2.5rem !important; }
  .m-md-24 {
    margin: 2.4rem !important; }
  .m-md-23 {
    margin: 2.3rem !important; }
  .m-md-22 {
    margin: 2.2rem !important; }
  .m-md-21 {
    margin: 2.1rem !important; }
  .m-md-20 {
    margin: 2rem !important; }
  .m-md-19 {
    margin: 1.9rem !important; }
  .m-md-18 {
    margin: 1.8rem !important; }
  .m-md-17 {
    margin: 1.7rem !important; }
  .m-md-16 {
    margin: 1.6rem !important; }
  .m-md-15 {
    margin: 1.5rem !important; }
  .m-md-14 {
    margin: 1.4rem !important; }
  .m-md-13 {
    margin: 1.3rem !important; }
  .m-md-12 {
    margin: 1.2rem !important; }
  .m-md-11 {
    margin: 1.1rem !important; }
  .m-md-10 {
    margin: 1rem !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .m-md-5 {
    margin: 0.5rem !important; }
  .m-md-6 {
    margin: 0.6rem !important; }
  .m-md-8 {
    margin: 0.8rem !important; }
  .m-md-40 {
    margin: 4rem !important; }
  .m-md-45 {
    margin: 4.5rem !important; }
  .m-md-50 {
    margin: 5rem !important; }
  .m-md-55 {
    margin: 5.5rem !important; }
  .m-md-60 {
    margin: 6rem !important; }
  .m-md-70 {
    margin: 7rem !important; }
  .m-md-80 {
    margin: 8rem !important; }
  .m-md-90 {
    margin: 9rem !important; }
  .m-md-100 {
    margin: 10rem !important; }
  .m-md-105 {
    margin: 10.5rem !important; }
  .m-md-110 {
    margin: 11rem !important; }
  .m-md-125 {
    margin: 12.5rem !important; }
  .m-md-130 {
    margin: 13rem !important; }
  .m-md-145 {
    margin: 14.5rem !important; }
  .m-md-170 {
    margin: 17rem !important; }
  .m-md-235 {
    margin: 23.5rem !important; }
  .m-md-n20 {
    margin: -2rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-35 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important; }
  .mx-md-34 {
    margin-right: 3.4rem !important;
    margin-left: 3.4rem !important; }
  .mx-md-33 {
    margin-right: 3.3rem !important;
    margin-left: 3.3rem !important; }
  .mx-md-32 {
    margin-right: 3.2rem !important;
    margin-left: 3.2rem !important; }
  .mx-md-31 {
    margin-right: 3.1rem !important;
    margin-left: 3.1rem !important; }
  .mx-md-30 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-md-29 {
    margin-right: 2.9rem !important;
    margin-left: 2.9rem !important; }
  .mx-md-28 {
    margin-right: 2.8rem !important;
    margin-left: 2.8rem !important; }
  .mx-md-27 {
    margin-right: 2.7rem !important;
    margin-left: 2.7rem !important; }
  .mx-md-26 {
    margin-right: 2.6rem !important;
    margin-left: 2.6rem !important; }
  .mx-md-25 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important; }
  .mx-md-24 {
    margin-right: 2.4rem !important;
    margin-left: 2.4rem !important; }
  .mx-md-23 {
    margin-right: 2.3rem !important;
    margin-left: 2.3rem !important; }
  .mx-md-22 {
    margin-right: 2.2rem !important;
    margin-left: 2.2rem !important; }
  .mx-md-21 {
    margin-right: 2.1rem !important;
    margin-left: 2.1rem !important; }
  .mx-md-20 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  .mx-md-19 {
    margin-right: 1.9rem !important;
    margin-left: 1.9rem !important; }
  .mx-md-18 {
    margin-right: 1.8rem !important;
    margin-left: 1.8rem !important; }
  .mx-md-17 {
    margin-right: 1.7rem !important;
    margin-left: 1.7rem !important; }
  .mx-md-16 {
    margin-right: 1.6rem !important;
    margin-left: 1.6rem !important; }
  .mx-md-15 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-14 {
    margin-right: 1.4rem !important;
    margin-left: 1.4rem !important; }
  .mx-md-13 {
    margin-right: 1.3rem !important;
    margin-left: 1.3rem !important; }
  .mx-md-12 {
    margin-right: 1.2rem !important;
    margin-left: 1.2rem !important; }
  .mx-md-11 {
    margin-right: 1.1rem !important;
    margin-left: 1.1rem !important; }
  .mx-md-10 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-5 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-md-6 {
    margin-right: 0.6rem !important;
    margin-left: 0.6rem !important; }
  .mx-md-8 {
    margin-right: 0.8rem !important;
    margin-left: 0.8rem !important; }
  .mx-md-40 {
    margin-right: 4rem !important;
    margin-left: 4rem !important; }
  .mx-md-45 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important; }
  .mx-md-50 {
    margin-right: 5rem !important;
    margin-left: 5rem !important; }
  .mx-md-55 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important; }
  .mx-md-60 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-md-70 {
    margin-right: 7rem !important;
    margin-left: 7rem !important; }
  .mx-md-80 {
    margin-right: 8rem !important;
    margin-left: 8rem !important; }
  .mx-md-90 {
    margin-right: 9rem !important;
    margin-left: 9rem !important; }
  .mx-md-100 {
    margin-right: 10rem !important;
    margin-left: 10rem !important; }
  .mx-md-105 {
    margin-right: 10.5rem !important;
    margin-left: 10.5rem !important; }
  .mx-md-110 {
    margin-right: 11rem !important;
    margin-left: 11rem !important; }
  .mx-md-125 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important; }
  .mx-md-130 {
    margin-right: 13rem !important;
    margin-left: 13rem !important; }
  .mx-md-145 {
    margin-right: 14.5rem !important;
    margin-left: 14.5rem !important; }
  .mx-md-170 {
    margin-right: 17rem !important;
    margin-left: 17rem !important; }
  .mx-md-235 {
    margin-right: 23.5rem !important;
    margin-left: 23.5rem !important; }
  .mx-md-n20 {
    margin-right: -2rem !important;
    margin-left: -2rem !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-35 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important; }
  .my-md-34 {
    margin-top: 3.4rem !important;
    margin-bottom: 3.4rem !important; }
  .my-md-33 {
    margin-top: 3.3rem !important;
    margin-bottom: 3.3rem !important; }
  .my-md-32 {
    margin-top: 3.2rem !important;
    margin-bottom: 3.2rem !important; }
  .my-md-31 {
    margin-top: 3.1rem !important;
    margin-bottom: 3.1rem !important; }
  .my-md-30 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-md-29 {
    margin-top: 2.9rem !important;
    margin-bottom: 2.9rem !important; }
  .my-md-28 {
    margin-top: 2.8rem !important;
    margin-bottom: 2.8rem !important; }
  .my-md-27 {
    margin-top: 2.7rem !important;
    margin-bottom: 2.7rem !important; }
  .my-md-26 {
    margin-top: 2.6rem !important;
    margin-bottom: 2.6rem !important; }
  .my-md-25 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .my-md-24 {
    margin-top: 2.4rem !important;
    margin-bottom: 2.4rem !important; }
  .my-md-23 {
    margin-top: 2.3rem !important;
    margin-bottom: 2.3rem !important; }
  .my-md-22 {
    margin-top: 2.2rem !important;
    margin-bottom: 2.2rem !important; }
  .my-md-21 {
    margin-top: 2.1rem !important;
    margin-bottom: 2.1rem !important; }
  .my-md-20 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .my-md-19 {
    margin-top: 1.9rem !important;
    margin-bottom: 1.9rem !important; }
  .my-md-18 {
    margin-top: 1.8rem !important;
    margin-bottom: 1.8rem !important; }
  .my-md-17 {
    margin-top: 1.7rem !important;
    margin-bottom: 1.7rem !important; }
  .my-md-16 {
    margin-top: 1.6rem !important;
    margin-bottom: 1.6rem !important; }
  .my-md-15 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-14 {
    margin-top: 1.4rem !important;
    margin-bottom: 1.4rem !important; }
  .my-md-13 {
    margin-top: 1.3rem !important;
    margin-bottom: 1.3rem !important; }
  .my-md-12 {
    margin-top: 1.2rem !important;
    margin-bottom: 1.2rem !important; }
  .my-md-11 {
    margin-top: 1.1rem !important;
    margin-bottom: 1.1rem !important; }
  .my-md-10 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-5 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-md-6 {
    margin-top: 0.6rem !important;
    margin-bottom: 0.6rem !important; }
  .my-md-8 {
    margin-top: 0.8rem !important;
    margin-bottom: 0.8rem !important; }
  .my-md-40 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .my-md-45 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important; }
  .my-md-50 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .my-md-55 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important; }
  .my-md-60 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-md-70 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important; }
  .my-md-80 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important; }
  .my-md-90 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important; }
  .my-md-100 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important; }
  .my-md-105 {
    margin-top: 10.5rem !important;
    margin-bottom: 10.5rem !important; }
  .my-md-110 {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important; }
  .my-md-125 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important; }
  .my-md-130 {
    margin-top: 13rem !important;
    margin-bottom: 13rem !important; }
  .my-md-145 {
    margin-top: 14.5rem !important;
    margin-bottom: 14.5rem !important; }
  .my-md-170 {
    margin-top: 17rem !important;
    margin-bottom: 17rem !important; }
  .my-md-235 {
    margin-top: 23.5rem !important;
    margin-bottom: 23.5rem !important; }
  .my-md-n20 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-35 {
    margin-top: 3.5rem !important; }
  .mt-md-34 {
    margin-top: 3.4rem !important; }
  .mt-md-33 {
    margin-top: 3.3rem !important; }
  .mt-md-32 {
    margin-top: 3.2rem !important; }
  .mt-md-31 {
    margin-top: 3.1rem !important; }
  .mt-md-30 {
    margin-top: 3rem !important; }
  .mt-md-29 {
    margin-top: 2.9rem !important; }
  .mt-md-28 {
    margin-top: 2.8rem !important; }
  .mt-md-27 {
    margin-top: 2.7rem !important; }
  .mt-md-26 {
    margin-top: 2.6rem !important; }
  .mt-md-25 {
    margin-top: 2.5rem !important; }
  .mt-md-24 {
    margin-top: 2.4rem !important; }
  .mt-md-23 {
    margin-top: 2.3rem !important; }
  .mt-md-22 {
    margin-top: 2.2rem !important; }
  .mt-md-21 {
    margin-top: 2.1rem !important; }
  .mt-md-20 {
    margin-top: 2rem !important; }
  .mt-md-19 {
    margin-top: 1.9rem !important; }
  .mt-md-18 {
    margin-top: 1.8rem !important; }
  .mt-md-17 {
    margin-top: 1.7rem !important; }
  .mt-md-16 {
    margin-top: 1.6rem !important; }
  .mt-md-15 {
    margin-top: 1.5rem !important; }
  .mt-md-14 {
    margin-top: 1.4rem !important; }
  .mt-md-13 {
    margin-top: 1.3rem !important; }
  .mt-md-12 {
    margin-top: 1.2rem !important; }
  .mt-md-11 {
    margin-top: 1.1rem !important; }
  .mt-md-10 {
    margin-top: 1rem !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mt-md-5 {
    margin-top: 0.5rem !important; }
  .mt-md-6 {
    margin-top: 0.6rem !important; }
  .mt-md-8 {
    margin-top: 0.8rem !important; }
  .mt-md-40 {
    margin-top: 4rem !important; }
  .mt-md-45 {
    margin-top: 4.5rem !important; }
  .mt-md-50 {
    margin-top: 5rem !important; }
  .mt-md-55 {
    margin-top: 5.5rem !important; }
  .mt-md-60 {
    margin-top: 6rem !important; }
  .mt-md-70 {
    margin-top: 7rem !important; }
  .mt-md-80 {
    margin-top: 8rem !important; }
  .mt-md-90 {
    margin-top: 9rem !important; }
  .mt-md-100 {
    margin-top: 10rem !important; }
  .mt-md-105 {
    margin-top: 10.5rem !important; }
  .mt-md-110 {
    margin-top: 11rem !important; }
  .mt-md-125 {
    margin-top: 12.5rem !important; }
  .mt-md-130 {
    margin-top: 13rem !important; }
  .mt-md-145 {
    margin-top: 14.5rem !important; }
  .mt-md-170 {
    margin-top: 17rem !important; }
  .mt-md-235 {
    margin-top: 23.5rem !important; }
  .mt-md-n20 {
    margin-top: -2rem !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .me-md-35 {
    margin-right: 3.5rem !important; }
  .me-md-34 {
    margin-right: 3.4rem !important; }
  .me-md-33 {
    margin-right: 3.3rem !important; }
  .me-md-32 {
    margin-right: 3.2rem !important; }
  .me-md-31 {
    margin-right: 3.1rem !important; }
  .me-md-30 {
    margin-right: 3rem !important; }
  .me-md-29 {
    margin-right: 2.9rem !important; }
  .me-md-28 {
    margin-right: 2.8rem !important; }
  .me-md-27 {
    margin-right: 2.7rem !important; }
  .me-md-26 {
    margin-right: 2.6rem !important; }
  .me-md-25 {
    margin-right: 2.5rem !important; }
  .me-md-24 {
    margin-right: 2.4rem !important; }
  .me-md-23 {
    margin-right: 2.3rem !important; }
  .me-md-22 {
    margin-right: 2.2rem !important; }
  .me-md-21 {
    margin-right: 2.1rem !important; }
  .me-md-20 {
    margin-right: 2rem !important; }
  .me-md-19 {
    margin-right: 1.9rem !important; }
  .me-md-18 {
    margin-right: 1.8rem !important; }
  .me-md-17 {
    margin-right: 1.7rem !important; }
  .me-md-16 {
    margin-right: 1.6rem !important; }
  .me-md-15 {
    margin-right: 1.5rem !important; }
  .me-md-14 {
    margin-right: 1.4rem !important; }
  .me-md-13 {
    margin-right: 1.3rem !important; }
  .me-md-12 {
    margin-right: 1.2rem !important; }
  .me-md-11 {
    margin-right: 1.1rem !important; }
  .me-md-10 {
    margin-right: 1rem !important; }
  .me-md-0 {
    margin-right: 0 !important; }
  .me-md-1 {
    margin-right: 0.25rem !important; }
  .me-md-2 {
    margin-right: 0.5rem !important; }
  .me-md-3 {
    margin-right: 1rem !important; }
  .me-md-4 {
    margin-right: 1.5rem !important; }
  .me-md-5 {
    margin-right: 0.5rem !important; }
  .me-md-6 {
    margin-right: 0.6rem !important; }
  .me-md-8 {
    margin-right: 0.8rem !important; }
  .me-md-40 {
    margin-right: 4rem !important; }
  .me-md-45 {
    margin-right: 4.5rem !important; }
  .me-md-50 {
    margin-right: 5rem !important; }
  .me-md-55 {
    margin-right: 5.5rem !important; }
  .me-md-60 {
    margin-right: 6rem !important; }
  .me-md-70 {
    margin-right: 7rem !important; }
  .me-md-80 {
    margin-right: 8rem !important; }
  .me-md-90 {
    margin-right: 9rem !important; }
  .me-md-100 {
    margin-right: 10rem !important; }
  .me-md-105 {
    margin-right: 10.5rem !important; }
  .me-md-110 {
    margin-right: 11rem !important; }
  .me-md-125 {
    margin-right: 12.5rem !important; }
  .me-md-130 {
    margin-right: 13rem !important; }
  .me-md-145 {
    margin-right: 14.5rem !important; }
  .me-md-170 {
    margin-right: 17rem !important; }
  .me-md-235 {
    margin-right: 23.5rem !important; }
  .me-md-n20 {
    margin-right: -2rem !important; }
  .me-md-auto {
    margin-right: auto !important; }
  .mb-md-35 {
    margin-bottom: 3.5rem !important; }
  .mb-md-34 {
    margin-bottom: 3.4rem !important; }
  .mb-md-33 {
    margin-bottom: 3.3rem !important; }
  .mb-md-32 {
    margin-bottom: 3.2rem !important; }
  .mb-md-31 {
    margin-bottom: 3.1rem !important; }
  .mb-md-30 {
    margin-bottom: 3rem !important; }
  .mb-md-29 {
    margin-bottom: 2.9rem !important; }
  .mb-md-28 {
    margin-bottom: 2.8rem !important; }
  .mb-md-27 {
    margin-bottom: 2.7rem !important; }
  .mb-md-26 {
    margin-bottom: 2.6rem !important; }
  .mb-md-25 {
    margin-bottom: 2.5rem !important; }
  .mb-md-24 {
    margin-bottom: 2.4rem !important; }
  .mb-md-23 {
    margin-bottom: 2.3rem !important; }
  .mb-md-22 {
    margin-bottom: 2.2rem !important; }
  .mb-md-21 {
    margin-bottom: 2.1rem !important; }
  .mb-md-20 {
    margin-bottom: 2rem !important; }
  .mb-md-19 {
    margin-bottom: 1.9rem !important; }
  .mb-md-18 {
    margin-bottom: 1.8rem !important; }
  .mb-md-17 {
    margin-bottom: 1.7rem !important; }
  .mb-md-16 {
    margin-bottom: 1.6rem !important; }
  .mb-md-15 {
    margin-bottom: 1.5rem !important; }
  .mb-md-14 {
    margin-bottom: 1.4rem !important; }
  .mb-md-13 {
    margin-bottom: 1.3rem !important; }
  .mb-md-12 {
    margin-bottom: 1.2rem !important; }
  .mb-md-11 {
    margin-bottom: 1.1rem !important; }
  .mb-md-10 {
    margin-bottom: 1rem !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .mb-md-5 {
    margin-bottom: 0.5rem !important; }
  .mb-md-6 {
    margin-bottom: 0.6rem !important; }
  .mb-md-8 {
    margin-bottom: 0.8rem !important; }
  .mb-md-40 {
    margin-bottom: 4rem !important; }
  .mb-md-45 {
    margin-bottom: 4.5rem !important; }
  .mb-md-50 {
    margin-bottom: 5rem !important; }
  .mb-md-55 {
    margin-bottom: 5.5rem !important; }
  .mb-md-60 {
    margin-bottom: 6rem !important; }
  .mb-md-70 {
    margin-bottom: 7rem !important; }
  .mb-md-80 {
    margin-bottom: 8rem !important; }
  .mb-md-90 {
    margin-bottom: 9rem !important; }
  .mb-md-100 {
    margin-bottom: 10rem !important; }
  .mb-md-105 {
    margin-bottom: 10.5rem !important; }
  .mb-md-110 {
    margin-bottom: 11rem !important; }
  .mb-md-125 {
    margin-bottom: 12.5rem !important; }
  .mb-md-130 {
    margin-bottom: 13rem !important; }
  .mb-md-145 {
    margin-bottom: 14.5rem !important; }
  .mb-md-170 {
    margin-bottom: 17rem !important; }
  .mb-md-235 {
    margin-bottom: 23.5rem !important; }
  .mb-md-n20 {
    margin-bottom: -2rem !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ms-md-35 {
    margin-left: 3.5rem !important; }
  .ms-md-34 {
    margin-left: 3.4rem !important; }
  .ms-md-33 {
    margin-left: 3.3rem !important; }
  .ms-md-32 {
    margin-left: 3.2rem !important; }
  .ms-md-31 {
    margin-left: 3.1rem !important; }
  .ms-md-30 {
    margin-left: 3rem !important; }
  .ms-md-29 {
    margin-left: 2.9rem !important; }
  .ms-md-28 {
    margin-left: 2.8rem !important; }
  .ms-md-27 {
    margin-left: 2.7rem !important; }
  .ms-md-26 {
    margin-left: 2.6rem !important; }
  .ms-md-25 {
    margin-left: 2.5rem !important; }
  .ms-md-24 {
    margin-left: 2.4rem !important; }
  .ms-md-23 {
    margin-left: 2.3rem !important; }
  .ms-md-22 {
    margin-left: 2.2rem !important; }
  .ms-md-21 {
    margin-left: 2.1rem !important; }
  .ms-md-20 {
    margin-left: 2rem !important; }
  .ms-md-19 {
    margin-left: 1.9rem !important; }
  .ms-md-18 {
    margin-left: 1.8rem !important; }
  .ms-md-17 {
    margin-left: 1.7rem !important; }
  .ms-md-16 {
    margin-left: 1.6rem !important; }
  .ms-md-15 {
    margin-left: 1.5rem !important; }
  .ms-md-14 {
    margin-left: 1.4rem !important; }
  .ms-md-13 {
    margin-left: 1.3rem !important; }
  .ms-md-12 {
    margin-left: 1.2rem !important; }
  .ms-md-11 {
    margin-left: 1.1rem !important; }
  .ms-md-10 {
    margin-left: 1rem !important; }
  .ms-md-0 {
    margin-left: 0 !important; }
  .ms-md-1 {
    margin-left: 0.25rem !important; }
  .ms-md-2 {
    margin-left: 0.5rem !important; }
  .ms-md-3 {
    margin-left: 1rem !important; }
  .ms-md-4 {
    margin-left: 1.5rem !important; }
  .ms-md-5 {
    margin-left: 0.5rem !important; }
  .ms-md-6 {
    margin-left: 0.6rem !important; }
  .ms-md-8 {
    margin-left: 0.8rem !important; }
  .ms-md-40 {
    margin-left: 4rem !important; }
  .ms-md-45 {
    margin-left: 4.5rem !important; }
  .ms-md-50 {
    margin-left: 5rem !important; }
  .ms-md-55 {
    margin-left: 5.5rem !important; }
  .ms-md-60 {
    margin-left: 6rem !important; }
  .ms-md-70 {
    margin-left: 7rem !important; }
  .ms-md-80 {
    margin-left: 8rem !important; }
  .ms-md-90 {
    margin-left: 9rem !important; }
  .ms-md-100 {
    margin-left: 10rem !important; }
  .ms-md-105 {
    margin-left: 10.5rem !important; }
  .ms-md-110 {
    margin-left: 11rem !important; }
  .ms-md-125 {
    margin-left: 12.5rem !important; }
  .ms-md-130 {
    margin-left: 13rem !important; }
  .ms-md-145 {
    margin-left: 14.5rem !important; }
  .ms-md-170 {
    margin-left: 17rem !important; }
  .ms-md-235 {
    margin-left: 23.5rem !important; }
  .ms-md-n20 {
    margin-left: -2rem !important; }
  .ms-md-auto {
    margin-left: auto !important; }
  .p-md-35 {
    padding: 3.5rem !important; }
  .p-md-34 {
    padding: 3.4rem !important; }
  .p-md-33 {
    padding: 3.3rem !important; }
  .p-md-32 {
    padding: 3.2rem !important; }
  .p-md-31 {
    padding: 3.1rem !important; }
  .p-md-30 {
    padding: 3rem !important; }
  .p-md-29 {
    padding: 2.9rem !important; }
  .p-md-28 {
    padding: 2.8rem !important; }
  .p-md-27 {
    padding: 2.7rem !important; }
  .p-md-26 {
    padding: 2.6rem !important; }
  .p-md-25 {
    padding: 2.5rem !important; }
  .p-md-24 {
    padding: 2.4rem !important; }
  .p-md-23 {
    padding: 2.3rem !important; }
  .p-md-22 {
    padding: 2.2rem !important; }
  .p-md-21 {
    padding: 2.1rem !important; }
  .p-md-20 {
    padding: 2rem !important; }
  .p-md-19 {
    padding: 1.9rem !important; }
  .p-md-18 {
    padding: 1.8rem !important; }
  .p-md-17 {
    padding: 1.7rem !important; }
  .p-md-16 {
    padding: 1.6rem !important; }
  .p-md-15 {
    padding: 1.5rem !important; }
  .p-md-14 {
    padding: 1.4rem !important; }
  .p-md-13 {
    padding: 1.3rem !important; }
  .p-md-12 {
    padding: 1.2rem !important; }
  .p-md-11 {
    padding: 1.1rem !important; }
  .p-md-10 {
    padding: 1rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .p-md-5 {
    padding: 0.5rem !important; }
  .p-md-6 {
    padding: 0.6rem !important; }
  .p-md-8 {
    padding: 0.8rem !important; }
  .p-md-40 {
    padding: 4rem !important; }
  .p-md-45 {
    padding: 4.5rem !important; }
  .p-md-50 {
    padding: 5rem !important; }
  .p-md-55 {
    padding: 5.5rem !important; }
  .p-md-60 {
    padding: 6rem !important; }
  .p-md-70 {
    padding: 7rem !important; }
  .p-md-80 {
    padding: 8rem !important; }
  .p-md-90 {
    padding: 9rem !important; }
  .p-md-100 {
    padding: 10rem !important; }
  .p-md-105 {
    padding: 10.5rem !important; }
  .p-md-110 {
    padding: 11rem !important; }
  .p-md-125 {
    padding: 12.5rem !important; }
  .p-md-130 {
    padding: 13rem !important; }
  .p-md-145 {
    padding: 14.5rem !important; }
  .p-md-170 {
    padding: 17rem !important; }
  .p-md-235 {
    padding: 23.5rem !important; }
  .p-md-n20 {
    padding: -2rem !important; }
  .px-md-35 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important; }
  .px-md-34 {
    padding-right: 3.4rem !important;
    padding-left: 3.4rem !important; }
  .px-md-33 {
    padding-right: 3.3rem !important;
    padding-left: 3.3rem !important; }
  .px-md-32 {
    padding-right: 3.2rem !important;
    padding-left: 3.2rem !important; }
  .px-md-31 {
    padding-right: 3.1rem !important;
    padding-left: 3.1rem !important; }
  .px-md-30 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-md-29 {
    padding-right: 2.9rem !important;
    padding-left: 2.9rem !important; }
  .px-md-28 {
    padding-right: 2.8rem !important;
    padding-left: 2.8rem !important; }
  .px-md-27 {
    padding-right: 2.7rem !important;
    padding-left: 2.7rem !important; }
  .px-md-26 {
    padding-right: 2.6rem !important;
    padding-left: 2.6rem !important; }
  .px-md-25 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important; }
  .px-md-24 {
    padding-right: 2.4rem !important;
    padding-left: 2.4rem !important; }
  .px-md-23 {
    padding-right: 2.3rem !important;
    padding-left: 2.3rem !important; }
  .px-md-22 {
    padding-right: 2.2rem !important;
    padding-left: 2.2rem !important; }
  .px-md-21 {
    padding-right: 2.1rem !important;
    padding-left: 2.1rem !important; }
  .px-md-20 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .px-md-19 {
    padding-right: 1.9rem !important;
    padding-left: 1.9rem !important; }
  .px-md-18 {
    padding-right: 1.8rem !important;
    padding-left: 1.8rem !important; }
  .px-md-17 {
    padding-right: 1.7rem !important;
    padding-left: 1.7rem !important; }
  .px-md-16 {
    padding-right: 1.6rem !important;
    padding-left: 1.6rem !important; }
  .px-md-15 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-14 {
    padding-right: 1.4rem !important;
    padding-left: 1.4rem !important; }
  .px-md-13 {
    padding-right: 1.3rem !important;
    padding-left: 1.3rem !important; }
  .px-md-12 {
    padding-right: 1.2rem !important;
    padding-left: 1.2rem !important; }
  .px-md-11 {
    padding-right: 1.1rem !important;
    padding-left: 1.1rem !important; }
  .px-md-10 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-5 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-md-6 {
    padding-right: 0.6rem !important;
    padding-left: 0.6rem !important; }
  .px-md-8 {
    padding-right: 0.8rem !important;
    padding-left: 0.8rem !important; }
  .px-md-40 {
    padding-right: 4rem !important;
    padding-left: 4rem !important; }
  .px-md-45 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important; }
  .px-md-50 {
    padding-right: 5rem !important;
    padding-left: 5rem !important; }
  .px-md-55 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important; }
  .px-md-60 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .px-md-70 {
    padding-right: 7rem !important;
    padding-left: 7rem !important; }
  .px-md-80 {
    padding-right: 8rem !important;
    padding-left: 8rem !important; }
  .px-md-90 {
    padding-right: 9rem !important;
    padding-left: 9rem !important; }
  .px-md-100 {
    padding-right: 10rem !important;
    padding-left: 10rem !important; }
  .px-md-105 {
    padding-right: 10.5rem !important;
    padding-left: 10.5rem !important; }
  .px-md-110 {
    padding-right: 11rem !important;
    padding-left: 11rem !important; }
  .px-md-125 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important; }
  .px-md-130 {
    padding-right: 13rem !important;
    padding-left: 13rem !important; }
  .px-md-145 {
    padding-right: 14.5rem !important;
    padding-left: 14.5rem !important; }
  .px-md-170 {
    padding-right: 17rem !important;
    padding-left: 17rem !important; }
  .px-md-235 {
    padding-right: 23.5rem !important;
    padding-left: 23.5rem !important; }
  .px-md-n20 {
    padding-right: -2rem !important;
    padding-left: -2rem !important; }
  .py-md-35 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important; }
  .py-md-34 {
    padding-top: 3.4rem !important;
    padding-bottom: 3.4rem !important; }
  .py-md-33 {
    padding-top: 3.3rem !important;
    padding-bottom: 3.3rem !important; }
  .py-md-32 {
    padding-top: 3.2rem !important;
    padding-bottom: 3.2rem !important; }
  .py-md-31 {
    padding-top: 3.1rem !important;
    padding-bottom: 3.1rem !important; }
  .py-md-30 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-md-29 {
    padding-top: 2.9rem !important;
    padding-bottom: 2.9rem !important; }
  .py-md-28 {
    padding-top: 2.8rem !important;
    padding-bottom: 2.8rem !important; }
  .py-md-27 {
    padding-top: 2.7rem !important;
    padding-bottom: 2.7rem !important; }
  .py-md-26 {
    padding-top: 2.6rem !important;
    padding-bottom: 2.6rem !important; }
  .py-md-25 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .py-md-24 {
    padding-top: 2.4rem !important;
    padding-bottom: 2.4rem !important; }
  .py-md-23 {
    padding-top: 2.3rem !important;
    padding-bottom: 2.3rem !important; }
  .py-md-22 {
    padding-top: 2.2rem !important;
    padding-bottom: 2.2rem !important; }
  .py-md-21 {
    padding-top: 2.1rem !important;
    padding-bottom: 2.1rem !important; }
  .py-md-20 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .py-md-19 {
    padding-top: 1.9rem !important;
    padding-bottom: 1.9rem !important; }
  .py-md-18 {
    padding-top: 1.8rem !important;
    padding-bottom: 1.8rem !important; }
  .py-md-17 {
    padding-top: 1.7rem !important;
    padding-bottom: 1.7rem !important; }
  .py-md-16 {
    padding-top: 1.6rem !important;
    padding-bottom: 1.6rem !important; }
  .py-md-15 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-14 {
    padding-top: 1.4rem !important;
    padding-bottom: 1.4rem !important; }
  .py-md-13 {
    padding-top: 1.3rem !important;
    padding-bottom: 1.3rem !important; }
  .py-md-12 {
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important; }
  .py-md-11 {
    padding-top: 1.1rem !important;
    padding-bottom: 1.1rem !important; }
  .py-md-10 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-5 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-md-6 {
    padding-top: 0.6rem !important;
    padding-bottom: 0.6rem !important; }
  .py-md-8 {
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important; }
  .py-md-40 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .py-md-45 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important; }
  .py-md-50 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .py-md-55 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important; }
  .py-md-60 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .py-md-70 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important; }
  .py-md-80 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important; }
  .py-md-90 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important; }
  .py-md-100 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important; }
  .py-md-105 {
    padding-top: 10.5rem !important;
    padding-bottom: 10.5rem !important; }
  .py-md-110 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important; }
  .py-md-125 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important; }
  .py-md-130 {
    padding-top: 13rem !important;
    padding-bottom: 13rem !important; }
  .py-md-145 {
    padding-top: 14.5rem !important;
    padding-bottom: 14.5rem !important; }
  .py-md-170 {
    padding-top: 17rem !important;
    padding-bottom: 17rem !important; }
  .py-md-235 {
    padding-top: 23.5rem !important;
    padding-bottom: 23.5rem !important; }
  .py-md-n20 {
    padding-top: -2rem !important;
    padding-bottom: -2rem !important; }
  .pt-md-35 {
    padding-top: 3.5rem !important; }
  .pt-md-34 {
    padding-top: 3.4rem !important; }
  .pt-md-33 {
    padding-top: 3.3rem !important; }
  .pt-md-32 {
    padding-top: 3.2rem !important; }
  .pt-md-31 {
    padding-top: 3.1rem !important; }
  .pt-md-30 {
    padding-top: 3rem !important; }
  .pt-md-29 {
    padding-top: 2.9rem !important; }
  .pt-md-28 {
    padding-top: 2.8rem !important; }
  .pt-md-27 {
    padding-top: 2.7rem !important; }
  .pt-md-26 {
    padding-top: 2.6rem !important; }
  .pt-md-25 {
    padding-top: 2.5rem !important; }
  .pt-md-24 {
    padding-top: 2.4rem !important; }
  .pt-md-23 {
    padding-top: 2.3rem !important; }
  .pt-md-22 {
    padding-top: 2.2rem !important; }
  .pt-md-21 {
    padding-top: 2.1rem !important; }
  .pt-md-20 {
    padding-top: 2rem !important; }
  .pt-md-19 {
    padding-top: 1.9rem !important; }
  .pt-md-18 {
    padding-top: 1.8rem !important; }
  .pt-md-17 {
    padding-top: 1.7rem !important; }
  .pt-md-16 {
    padding-top: 1.6rem !important; }
  .pt-md-15 {
    padding-top: 1.5rem !important; }
  .pt-md-14 {
    padding-top: 1.4rem !important; }
  .pt-md-13 {
    padding-top: 1.3rem !important; }
  .pt-md-12 {
    padding-top: 1.2rem !important; }
  .pt-md-11 {
    padding-top: 1.1rem !important; }
  .pt-md-10 {
    padding-top: 1rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pt-md-5 {
    padding-top: 0.5rem !important; }
  .pt-md-6 {
    padding-top: 0.6rem !important; }
  .pt-md-8 {
    padding-top: 0.8rem !important; }
  .pt-md-40 {
    padding-top: 4rem !important; }
  .pt-md-45 {
    padding-top: 4.5rem !important; }
  .pt-md-50 {
    padding-top: 5rem !important; }
  .pt-md-55 {
    padding-top: 5.5rem !important; }
  .pt-md-60 {
    padding-top: 6rem !important; }
  .pt-md-70 {
    padding-top: 7rem !important; }
  .pt-md-80 {
    padding-top: 8rem !important; }
  .pt-md-90 {
    padding-top: 9rem !important; }
  .pt-md-100 {
    padding-top: 10rem !important; }
  .pt-md-105 {
    padding-top: 10.5rem !important; }
  .pt-md-110 {
    padding-top: 11rem !important; }
  .pt-md-125 {
    padding-top: 12.5rem !important; }
  .pt-md-130 {
    padding-top: 13rem !important; }
  .pt-md-145 {
    padding-top: 14.5rem !important; }
  .pt-md-170 {
    padding-top: 17rem !important; }
  .pt-md-235 {
    padding-top: 23.5rem !important; }
  .pt-md-n20 {
    padding-top: -2rem !important; }
  .pe-md-35 {
    padding-right: 3.5rem !important; }
  .pe-md-34 {
    padding-right: 3.4rem !important; }
  .pe-md-33 {
    padding-right: 3.3rem !important; }
  .pe-md-32 {
    padding-right: 3.2rem !important; }
  .pe-md-31 {
    padding-right: 3.1rem !important; }
  .pe-md-30 {
    padding-right: 3rem !important; }
  .pe-md-29 {
    padding-right: 2.9rem !important; }
  .pe-md-28 {
    padding-right: 2.8rem !important; }
  .pe-md-27 {
    padding-right: 2.7rem !important; }
  .pe-md-26 {
    padding-right: 2.6rem !important; }
  .pe-md-25 {
    padding-right: 2.5rem !important; }
  .pe-md-24 {
    padding-right: 2.4rem !important; }
  .pe-md-23 {
    padding-right: 2.3rem !important; }
  .pe-md-22 {
    padding-right: 2.2rem !important; }
  .pe-md-21 {
    padding-right: 2.1rem !important; }
  .pe-md-20 {
    padding-right: 2rem !important; }
  .pe-md-19 {
    padding-right: 1.9rem !important; }
  .pe-md-18 {
    padding-right: 1.8rem !important; }
  .pe-md-17 {
    padding-right: 1.7rem !important; }
  .pe-md-16 {
    padding-right: 1.6rem !important; }
  .pe-md-15 {
    padding-right: 1.5rem !important; }
  .pe-md-14 {
    padding-right: 1.4rem !important; }
  .pe-md-13 {
    padding-right: 1.3rem !important; }
  .pe-md-12 {
    padding-right: 1.2rem !important; }
  .pe-md-11 {
    padding-right: 1.1rem !important; }
  .pe-md-10 {
    padding-right: 1rem !important; }
  .pe-md-0 {
    padding-right: 0 !important; }
  .pe-md-1 {
    padding-right: 0.25rem !important; }
  .pe-md-2 {
    padding-right: 0.5rem !important; }
  .pe-md-3 {
    padding-right: 1rem !important; }
  .pe-md-4 {
    padding-right: 1.5rem !important; }
  .pe-md-5 {
    padding-right: 0.5rem !important; }
  .pe-md-6 {
    padding-right: 0.6rem !important; }
  .pe-md-8 {
    padding-right: 0.8rem !important; }
  .pe-md-40 {
    padding-right: 4rem !important; }
  .pe-md-45 {
    padding-right: 4.5rem !important; }
  .pe-md-50 {
    padding-right: 5rem !important; }
  .pe-md-55 {
    padding-right: 5.5rem !important; }
  .pe-md-60 {
    padding-right: 6rem !important; }
  .pe-md-70 {
    padding-right: 7rem !important; }
  .pe-md-80 {
    padding-right: 8rem !important; }
  .pe-md-90 {
    padding-right: 9rem !important; }
  .pe-md-100 {
    padding-right: 10rem !important; }
  .pe-md-105 {
    padding-right: 10.5rem !important; }
  .pe-md-110 {
    padding-right: 11rem !important; }
  .pe-md-125 {
    padding-right: 12.5rem !important; }
  .pe-md-130 {
    padding-right: 13rem !important; }
  .pe-md-145 {
    padding-right: 14.5rem !important; }
  .pe-md-170 {
    padding-right: 17rem !important; }
  .pe-md-235 {
    padding-right: 23.5rem !important; }
  .pe-md-n20 {
    padding-right: -2rem !important; }
  .pb-md-35 {
    padding-bottom: 3.5rem !important; }
  .pb-md-34 {
    padding-bottom: 3.4rem !important; }
  .pb-md-33 {
    padding-bottom: 3.3rem !important; }
  .pb-md-32 {
    padding-bottom: 3.2rem !important; }
  .pb-md-31 {
    padding-bottom: 3.1rem !important; }
  .pb-md-30 {
    padding-bottom: 3rem !important; }
  .pb-md-29 {
    padding-bottom: 2.9rem !important; }
  .pb-md-28 {
    padding-bottom: 2.8rem !important; }
  .pb-md-27 {
    padding-bottom: 2.7rem !important; }
  .pb-md-26 {
    padding-bottom: 2.6rem !important; }
  .pb-md-25 {
    padding-bottom: 2.5rem !important; }
  .pb-md-24 {
    padding-bottom: 2.4rem !important; }
  .pb-md-23 {
    padding-bottom: 2.3rem !important; }
  .pb-md-22 {
    padding-bottom: 2.2rem !important; }
  .pb-md-21 {
    padding-bottom: 2.1rem !important; }
  .pb-md-20 {
    padding-bottom: 2rem !important; }
  .pb-md-19 {
    padding-bottom: 1.9rem !important; }
  .pb-md-18 {
    padding-bottom: 1.8rem !important; }
  .pb-md-17 {
    padding-bottom: 1.7rem !important; }
  .pb-md-16 {
    padding-bottom: 1.6rem !important; }
  .pb-md-15 {
    padding-bottom: 1.5rem !important; }
  .pb-md-14 {
    padding-bottom: 1.4rem !important; }
  .pb-md-13 {
    padding-bottom: 1.3rem !important; }
  .pb-md-12 {
    padding-bottom: 1.2rem !important; }
  .pb-md-11 {
    padding-bottom: 1.1rem !important; }
  .pb-md-10 {
    padding-bottom: 1rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pb-md-5 {
    padding-bottom: 0.5rem !important; }
  .pb-md-6 {
    padding-bottom: 0.6rem !important; }
  .pb-md-8 {
    padding-bottom: 0.8rem !important; }
  .pb-md-40 {
    padding-bottom: 4rem !important; }
  .pb-md-45 {
    padding-bottom: 4.5rem !important; }
  .pb-md-50 {
    padding-bottom: 5rem !important; }
  .pb-md-55 {
    padding-bottom: 5.5rem !important; }
  .pb-md-60 {
    padding-bottom: 6rem !important; }
  .pb-md-70 {
    padding-bottom: 7rem !important; }
  .pb-md-80 {
    padding-bottom: 8rem !important; }
  .pb-md-90 {
    padding-bottom: 9rem !important; }
  .pb-md-100 {
    padding-bottom: 10rem !important; }
  .pb-md-105 {
    padding-bottom: 10.5rem !important; }
  .pb-md-110 {
    padding-bottom: 11rem !important; }
  .pb-md-125 {
    padding-bottom: 12.5rem !important; }
  .pb-md-130 {
    padding-bottom: 13rem !important; }
  .pb-md-145 {
    padding-bottom: 14.5rem !important; }
  .pb-md-170 {
    padding-bottom: 17rem !important; }
  .pb-md-235 {
    padding-bottom: 23.5rem !important; }
  .pb-md-n20 {
    padding-bottom: -2rem !important; }
  .ps-md-35 {
    padding-left: 3.5rem !important; }
  .ps-md-34 {
    padding-left: 3.4rem !important; }
  .ps-md-33 {
    padding-left: 3.3rem !important; }
  .ps-md-32 {
    padding-left: 3.2rem !important; }
  .ps-md-31 {
    padding-left: 3.1rem !important; }
  .ps-md-30 {
    padding-left: 3rem !important; }
  .ps-md-29 {
    padding-left: 2.9rem !important; }
  .ps-md-28 {
    padding-left: 2.8rem !important; }
  .ps-md-27 {
    padding-left: 2.7rem !important; }
  .ps-md-26 {
    padding-left: 2.6rem !important; }
  .ps-md-25 {
    padding-left: 2.5rem !important; }
  .ps-md-24 {
    padding-left: 2.4rem !important; }
  .ps-md-23 {
    padding-left: 2.3rem !important; }
  .ps-md-22 {
    padding-left: 2.2rem !important; }
  .ps-md-21 {
    padding-left: 2.1rem !important; }
  .ps-md-20 {
    padding-left: 2rem !important; }
  .ps-md-19 {
    padding-left: 1.9rem !important; }
  .ps-md-18 {
    padding-left: 1.8rem !important; }
  .ps-md-17 {
    padding-left: 1.7rem !important; }
  .ps-md-16 {
    padding-left: 1.6rem !important; }
  .ps-md-15 {
    padding-left: 1.5rem !important; }
  .ps-md-14 {
    padding-left: 1.4rem !important; }
  .ps-md-13 {
    padding-left: 1.3rem !important; }
  .ps-md-12 {
    padding-left: 1.2rem !important; }
  .ps-md-11 {
    padding-left: 1.1rem !important; }
  .ps-md-10 {
    padding-left: 1rem !important; }
  .ps-md-0 {
    padding-left: 0 !important; }
  .ps-md-1 {
    padding-left: 0.25rem !important; }
  .ps-md-2 {
    padding-left: 0.5rem !important; }
  .ps-md-3 {
    padding-left: 1rem !important; }
  .ps-md-4 {
    padding-left: 1.5rem !important; }
  .ps-md-5 {
    padding-left: 0.5rem !important; }
  .ps-md-6 {
    padding-left: 0.6rem !important; }
  .ps-md-8 {
    padding-left: 0.8rem !important; }
  .ps-md-40 {
    padding-left: 4rem !important; }
  .ps-md-45 {
    padding-left: 4.5rem !important; }
  .ps-md-50 {
    padding-left: 5rem !important; }
  .ps-md-55 {
    padding-left: 5.5rem !important; }
  .ps-md-60 {
    padding-left: 6rem !important; }
  .ps-md-70 {
    padding-left: 7rem !important; }
  .ps-md-80 {
    padding-left: 8rem !important; }
  .ps-md-90 {
    padding-left: 9rem !important; }
  .ps-md-100 {
    padding-left: 10rem !important; }
  .ps-md-105 {
    padding-left: 10.5rem !important; }
  .ps-md-110 {
    padding-left: 11rem !important; }
  .ps-md-125 {
    padding-left: 12.5rem !important; }
  .ps-md-130 {
    padding-left: 13rem !important; }
  .ps-md-145 {
    padding-left: 14.5rem !important; }
  .ps-md-170 {
    padding-left: 17rem !important; }
  .ps-md-235 {
    padding-left: 23.5rem !important; }
  .ps-md-n20 {
    padding-left: -2rem !important; }
  .font-md-family-02 {
    font-family: "Caveat", cursive !important; }
  .fs-md-40 {
    font-size: 4rem !important; }
  .fs-md-39 {
    font-size: 3.9rem !important; }
  .fs-md-38 {
    font-size: 3.8rem !important; }
  .fs-md-37 {
    font-size: 3.7rem !important; }
  .fs-md-36 {
    font-size: 3.6rem !important; }
  .fs-md-35 {
    font-size: 3.5rem !important; }
  .fs-md-34 {
    font-size: 3.4rem !important; }
  .fs-md-33 {
    font-size: 3.3rem !important; }
  .fs-md-32 {
    font-size: 3.2rem !important; }
  .fs-md-31 {
    font-size: 3.1rem !important; }
  .fs-md-30 {
    font-size: 3rem !important; }
  .fs-md-29 {
    font-size: 2.9rem !important; }
  .fs-md-28 {
    font-size: 2.8rem !important; }
  .fs-md-27 {
    font-size: 2.7rem !important; }
  .fs-md-26 {
    font-size: 2.6rem !important; }
  .fs-md-25 {
    font-size: 2.5rem !important; }
  .fs-md-24 {
    font-size: 2.4rem !important; }
  .fs-md-23 {
    font-size: 2.3rem !important; }
  .fs-md-22 {
    font-size: 2.2rem !important; }
  .fs-md-21 {
    font-size: 2.1rem !important; }
  .fs-md-20 {
    font-size: 2rem !important; }
  .fs-md-19 {
    font-size: 1.9rem !important; }
  .fs-md-18 {
    font-size: 1.8rem !important; }
  .fs-md-17 {
    font-size: 1.7rem !important; }
  .fs-md-16 {
    font-size: 1.6rem !important; }
  .fs-md-15 {
    font-size: 1.5rem !important; }
  .fs-md-14 {
    font-size: 1.4rem !important; }
  .fs-md-13 {
    font-size: 1.3rem !important; }
  .fs-md-12 {
    font-size: 1.2rem !important; }
  .fs-md-11 {
    font-size: 1.1rem !important; }
  .fs-md-10 {
    font-size: 1rem !important; }
  .fs-md-60 {
    font-size: calc(1.725rem + 5.7vw) !important; }
  .text-md-start {
    text-align: left !important; }
  .text-md-end {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; }
  .rounded-md-20 {
    border-radius: 2rem !important; }
  .rounded-md-19 {
    border-radius: 1.9rem !important; }
  .rounded-md-18 {
    border-radius: 1.8rem !important; }
  .rounded-md-17 {
    border-radius: 1.7rem !important; }
  .rounded-md-16 {
    border-radius: 1.6rem !important; }
  .rounded-md-15 {
    border-radius: 1.5rem !important; }
  .rounded-md-14 {
    border-radius: 1.4rem !important; }
  .rounded-md-13 {
    border-radius: 1.3rem !important; }
  .rounded-md-12 {
    border-radius: 1.2rem !important; }
  .rounded-md-11 {
    border-radius: 1.1rem !important; }
  .rounded-md-10 {
    border-radius: 1rem !important; }
  .rounded-md-9 {
    border-radius: 0.9rem !important; }
  .rounded-md-8 {
    border-radius: 0.8rem !important; }
  .rounded-md-7 {
    border-radius: 0.7rem !important; }
  .rounded-md-6 {
    border-radius: 0.6rem !important; }
  .rounded-md-5 {
    border-radius: 0.5rem !important; }
  .rounded-md {
    border-radius: 2px 5px 2px 5px !important; }
  .rounded-md-0 {
    border-radius: 0 !important; }
  .rounded-md-1 {
    border-radius: 0.6rem !important; }
  .rounded-md-2 {
    border-radius: 2px 5px 2px 5px !important; }
  .rounded-md-3 {
    border-radius: 5px 20px 5px 20px !important; }
  .rounded-md-4 {
    border-radius: 5px 100px 5px 100px !important; }
  .rounded-md-circle {
    border-radius: 50% !important; }
  .rounded-md-pill {
    border-radius: 50rem !important; }
  .rounded-md-btn {
    border-radius: 5px 30px 5px 5px !important; }
  .rounded-md-carte {
    border-radius: 10px 25px 10px 25px !important; }
  .rounded-top-md-20 {
    border-top-left-radius: 2rem !important;
    border-top-right-radius: 2rem !important; }
  .rounded-top-md-19 {
    border-top-left-radius: 1.9rem !important;
    border-top-right-radius: 1.9rem !important; }
  .rounded-top-md-18 {
    border-top-left-radius: 1.8rem !important;
    border-top-right-radius: 1.8rem !important; }
  .rounded-top-md-17 {
    border-top-left-radius: 1.7rem !important;
    border-top-right-radius: 1.7rem !important; }
  .rounded-top-md-16 {
    border-top-left-radius: 1.6rem !important;
    border-top-right-radius: 1.6rem !important; }
  .rounded-top-md-15 {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important; }
  .rounded-top-md-14 {
    border-top-left-radius: 1.4rem !important;
    border-top-right-radius: 1.4rem !important; }
  .rounded-top-md-13 {
    border-top-left-radius: 1.3rem !important;
    border-top-right-radius: 1.3rem !important; }
  .rounded-top-md-12 {
    border-top-left-radius: 1.2rem !important;
    border-top-right-radius: 1.2rem !important; }
  .rounded-top-md-11 {
    border-top-left-radius: 1.1rem !important;
    border-top-right-radius: 1.1rem !important; }
  .rounded-top-md-10 {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important; }
  .rounded-top-md-9 {
    border-top-left-radius: 0.9rem !important;
    border-top-right-radius: 0.9rem !important; }
  .rounded-top-md-8 {
    border-top-left-radius: 0.8rem !important;
    border-top-right-radius: 0.8rem !important; }
  .rounded-top-md-7 {
    border-top-left-radius: 0.7rem !important;
    border-top-right-radius: 0.7rem !important; }
  .rounded-top-md-6 {
    border-top-left-radius: 0.6rem !important;
    border-top-right-radius: 0.6rem !important; }
  .rounded-top-md-5 {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important; }
  .rounded-top-md {
    border-top-left-radius: 2px 5px 2px 5px !important;
    border-top-right-radius: 2px 5px 2px 5px !important; }
  .rounded-top-md-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important; }
  .rounded-top-md-1 {
    border-top-left-radius: 0.6rem !important;
    border-top-right-radius: 0.6rem !important; }
  .rounded-top-md-2 {
    border-top-left-radius: 2px 5px 2px 5px !important;
    border-top-right-radius: 2px 5px 2px 5px !important; }
  .rounded-top-md-3 {
    border-top-left-radius: 5px 20px 5px 20px !important;
    border-top-right-radius: 5px 20px 5px 20px !important; }
  .rounded-top-md-4 {
    border-top-left-radius: 5px 100px 5px 100px !important;
    border-top-right-radius: 5px 100px 5px 100px !important; }
  .rounded-top-md-circle {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important; }
  .rounded-top-md-pill {
    border-top-left-radius: 50rem !important;
    border-top-right-radius: 50rem !important; }
  .rounded-top-md-btn {
    border-top-left-radius: 5px 30px 5px 5px !important;
    border-top-right-radius: 5px 30px 5px 5px !important; }
  .rounded-top-md-carte {
    border-top-left-radius: 10px 25px 10px 25px !important;
    border-top-right-radius: 10px 25px 10px 25px !important; }
  .rounded-end-md-20 {
    border-top-right-radius: 2rem !important;
    border-bottom-right-radius: 2rem !important; }
  .rounded-end-md-19 {
    border-top-right-radius: 1.9rem !important;
    border-bottom-right-radius: 1.9rem !important; }
  .rounded-end-md-18 {
    border-top-right-radius: 1.8rem !important;
    border-bottom-right-radius: 1.8rem !important; }
  .rounded-end-md-17 {
    border-top-right-radius: 1.7rem !important;
    border-bottom-right-radius: 1.7rem !important; }
  .rounded-end-md-16 {
    border-top-right-radius: 1.6rem !important;
    border-bottom-right-radius: 1.6rem !important; }
  .rounded-end-md-15 {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important; }
  .rounded-end-md-14 {
    border-top-right-radius: 1.4rem !important;
    border-bottom-right-radius: 1.4rem !important; }
  .rounded-end-md-13 {
    border-top-right-radius: 1.3rem !important;
    border-bottom-right-radius: 1.3rem !important; }
  .rounded-end-md-12 {
    border-top-right-radius: 1.2rem !important;
    border-bottom-right-radius: 1.2rem !important; }
  .rounded-end-md-11 {
    border-top-right-radius: 1.1rem !important;
    border-bottom-right-radius: 1.1rem !important; }
  .rounded-end-md-10 {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important; }
  .rounded-end-md-9 {
    border-top-right-radius: 0.9rem !important;
    border-bottom-right-radius: 0.9rem !important; }
  .rounded-end-md-8 {
    border-top-right-radius: 0.8rem !important;
    border-bottom-right-radius: 0.8rem !important; }
  .rounded-end-md-7 {
    border-top-right-radius: 0.7rem !important;
    border-bottom-right-radius: 0.7rem !important; }
  .rounded-end-md-6 {
    border-top-right-radius: 0.6rem !important;
    border-bottom-right-radius: 0.6rem !important; }
  .rounded-end-md-5 {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important; }
  .rounded-end-md {
    border-top-right-radius: 2px 5px 2px 5px !important;
    border-bottom-right-radius: 2px 5px 2px 5px !important; }
  .rounded-end-md-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important; }
  .rounded-end-md-1 {
    border-top-right-radius: 0.6rem !important;
    border-bottom-right-radius: 0.6rem !important; }
  .rounded-end-md-2 {
    border-top-right-radius: 2px 5px 2px 5px !important;
    border-bottom-right-radius: 2px 5px 2px 5px !important; }
  .rounded-end-md-3 {
    border-top-right-radius: 5px 20px 5px 20px !important;
    border-bottom-right-radius: 5px 20px 5px 20px !important; }
  .rounded-end-md-4 {
    border-top-right-radius: 5px 100px 5px 100px !important;
    border-bottom-right-radius: 5px 100px 5px 100px !important; }
  .rounded-end-md-circle {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important; }
  .rounded-end-md-pill {
    border-top-right-radius: 50rem !important;
    border-bottom-right-radius: 50rem !important; }
  .rounded-end-md-btn {
    border-top-right-radius: 5px 30px 5px 5px !important;
    border-bottom-right-radius: 5px 30px 5px 5px !important; }
  .rounded-end-md-carte {
    border-top-right-radius: 10px 25px 10px 25px !important;
    border-bottom-right-radius: 10px 25px 10px 25px !important; }
  .rounded-bottom-md-20 {
    border-bottom-right-radius: 2rem !important;
    border-bottom-left-radius: 2rem !important; }
  .rounded-bottom-md-19 {
    border-bottom-right-radius: 1.9rem !important;
    border-bottom-left-radius: 1.9rem !important; }
  .rounded-bottom-md-18 {
    border-bottom-right-radius: 1.8rem !important;
    border-bottom-left-radius: 1.8rem !important; }
  .rounded-bottom-md-17 {
    border-bottom-right-radius: 1.7rem !important;
    border-bottom-left-radius: 1.7rem !important; }
  .rounded-bottom-md-16 {
    border-bottom-right-radius: 1.6rem !important;
    border-bottom-left-radius: 1.6rem !important; }
  .rounded-bottom-md-15 {
    border-bottom-right-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important; }
  .rounded-bottom-md-14 {
    border-bottom-right-radius: 1.4rem !important;
    border-bottom-left-radius: 1.4rem !important; }
  .rounded-bottom-md-13 {
    border-bottom-right-radius: 1.3rem !important;
    border-bottom-left-radius: 1.3rem !important; }
  .rounded-bottom-md-12 {
    border-bottom-right-radius: 1.2rem !important;
    border-bottom-left-radius: 1.2rem !important; }
  .rounded-bottom-md-11 {
    border-bottom-right-radius: 1.1rem !important;
    border-bottom-left-radius: 1.1rem !important; }
  .rounded-bottom-md-10 {
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important; }
  .rounded-bottom-md-9 {
    border-bottom-right-radius: 0.9rem !important;
    border-bottom-left-radius: 0.9rem !important; }
  .rounded-bottom-md-8 {
    border-bottom-right-radius: 0.8rem !important;
    border-bottom-left-radius: 0.8rem !important; }
  .rounded-bottom-md-7 {
    border-bottom-right-radius: 0.7rem !important;
    border-bottom-left-radius: 0.7rem !important; }
  .rounded-bottom-md-6 {
    border-bottom-right-radius: 0.6rem !important;
    border-bottom-left-radius: 0.6rem !important; }
  .rounded-bottom-md-5 {
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important; }
  .rounded-bottom-md {
    border-bottom-right-radius: 2px 5px 2px 5px !important;
    border-bottom-left-radius: 2px 5px 2px 5px !important; }
  .rounded-bottom-md-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important; }
  .rounded-bottom-md-1 {
    border-bottom-right-radius: 0.6rem !important;
    border-bottom-left-radius: 0.6rem !important; }
  .rounded-bottom-md-2 {
    border-bottom-right-radius: 2px 5px 2px 5px !important;
    border-bottom-left-radius: 2px 5px 2px 5px !important; }
  .rounded-bottom-md-3 {
    border-bottom-right-radius: 5px 20px 5px 20px !important;
    border-bottom-left-radius: 5px 20px 5px 20px !important; }
  .rounded-bottom-md-4 {
    border-bottom-right-radius: 5px 100px 5px 100px !important;
    border-bottom-left-radius: 5px 100px 5px 100px !important; }
  .rounded-bottom-md-circle {
    border-bottom-right-radius: 50% !important;
    border-bottom-left-radius: 50% !important; }
  .rounded-bottom-md-pill {
    border-bottom-right-radius: 50rem !important;
    border-bottom-left-radius: 50rem !important; }
  .rounded-bottom-md-btn {
    border-bottom-right-radius: 5px 30px 5px 5px !important;
    border-bottom-left-radius: 5px 30px 5px 5px !important; }
  .rounded-bottom-md-carte {
    border-bottom-right-radius: 10px 25px 10px 25px !important;
    border-bottom-left-radius: 10px 25px 10px 25px !important; }
  .rounded-start-md-20 {
    border-bottom-left-radius: 2rem !important;
    border-top-left-radius: 2rem !important; }
  .rounded-start-md-19 {
    border-bottom-left-radius: 1.9rem !important;
    border-top-left-radius: 1.9rem !important; }
  .rounded-start-md-18 {
    border-bottom-left-radius: 1.8rem !important;
    border-top-left-radius: 1.8rem !important; }
  .rounded-start-md-17 {
    border-bottom-left-radius: 1.7rem !important;
    border-top-left-radius: 1.7rem !important; }
  .rounded-start-md-16 {
    border-bottom-left-radius: 1.6rem !important;
    border-top-left-radius: 1.6rem !important; }
  .rounded-start-md-15 {
    border-bottom-left-radius: 1.5rem !important;
    border-top-left-radius: 1.5rem !important; }
  .rounded-start-md-14 {
    border-bottom-left-radius: 1.4rem !important;
    border-top-left-radius: 1.4rem !important; }
  .rounded-start-md-13 {
    border-bottom-left-radius: 1.3rem !important;
    border-top-left-radius: 1.3rem !important; }
  .rounded-start-md-12 {
    border-bottom-left-radius: 1.2rem !important;
    border-top-left-radius: 1.2rem !important; }
  .rounded-start-md-11 {
    border-bottom-left-radius: 1.1rem !important;
    border-top-left-radius: 1.1rem !important; }
  .rounded-start-md-10 {
    border-bottom-left-radius: 1rem !important;
    border-top-left-radius: 1rem !important; }
  .rounded-start-md-9 {
    border-bottom-left-radius: 0.9rem !important;
    border-top-left-radius: 0.9rem !important; }
  .rounded-start-md-8 {
    border-bottom-left-radius: 0.8rem !important;
    border-top-left-radius: 0.8rem !important; }
  .rounded-start-md-7 {
    border-bottom-left-radius: 0.7rem !important;
    border-top-left-radius: 0.7rem !important; }
  .rounded-start-md-6 {
    border-bottom-left-radius: 0.6rem !important;
    border-top-left-radius: 0.6rem !important; }
  .rounded-start-md-5 {
    border-bottom-left-radius: 0.5rem !important;
    border-top-left-radius: 0.5rem !important; }
  .rounded-start-md {
    border-bottom-left-radius: 2px 5px 2px 5px !important;
    border-top-left-radius: 2px 5px 2px 5px !important; }
  .rounded-start-md-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important; }
  .rounded-start-md-1 {
    border-bottom-left-radius: 0.6rem !important;
    border-top-left-radius: 0.6rem !important; }
  .rounded-start-md-2 {
    border-bottom-left-radius: 2px 5px 2px 5px !important;
    border-top-left-radius: 2px 5px 2px 5px !important; }
  .rounded-start-md-3 {
    border-bottom-left-radius: 5px 20px 5px 20px !important;
    border-top-left-radius: 5px 20px 5px 20px !important; }
  .rounded-start-md-4 {
    border-bottom-left-radius: 5px 100px 5px 100px !important;
    border-top-left-radius: 5px 100px 5px 100px !important; }
  .rounded-start-md-circle {
    border-bottom-left-radius: 50% !important;
    border-top-left-radius: 50% !important; }
  .rounded-start-md-pill {
    border-bottom-left-radius: 50rem !important;
    border-top-left-radius: 50rem !important; }
  .rounded-start-md-btn {
    border-bottom-left-radius: 5px 30px 5px 5px !important;
    border-top-left-radius: 5px 30px 5px 5px !important; }
  .rounded-start-md-carte {
    border-bottom-left-radius: 10px 25px 10px 25px !important;
    border-top-left-radius: 10px 25px 10px 25px !important; }
  .z-index-md-1 {
    z-index: 1 !important; }
  .z-index-md-2 {
    z-index: 2 !important; }
  .z-index-md-3 {
    z-index: 3 !important; }
  .z-index-md-4 {
    z-index: 4 !important; }
  .z-index-md-5 {
    z-index: 5 !important; } }

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important; }
  .float-lg-end {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-grid {
    display: grid !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .position-lg-static {
    position: static !important; }
  .position-lg-relative {
    position: relative !important; }
  .position-lg-absolute {
    position: absolute !important; }
  .position-lg-fixed {
    position: fixed !important; }
  .position-lg-sticky {
    position: sticky !important; }
  .top-lg-0 {
    top: 0 !important; }
  .top-lg-5 {
    top: 5% !important; }
  .top-lg-10 {
    top: 10% !important; }
  .top-lg-15 {
    top: 15% !important; }
  .top-lg-20 {
    top: 20% !important; }
  .top-lg-25 {
    top: 25% !important; }
  .top-lg-30 {
    top: 30% !important; }
  .top-lg-40 {
    top: 40% !important; }
  .top-lg-50 {
    top: 50% !important; }
  .top-lg-80 {
    top: 80% !important; }
  .top-lg-90 {
    top: 90% !important; }
  .top-lg-100 {
    top: 100% !important; }
  .top-lg-2px {
    top: 2px !important; }
  .top-lg-100px {
    top: 100px !important; }
  .top-lg-n30px {
    top: -30px !important; }
  .top-lg-n60px {
    top: -60px !important; }
  .top-lg-n130px {
    top: -130px !important; }
  .top-lg-n150px {
    top: -150px !important; }
  .top-lg-n170px {
    top: -170px !important; }
  .top-lg-n300px {
    top: -300px !important; }
  .bottom-lg-0 {
    bottom: 0 !important; }
  .bottom-lg-5 {
    bottom: 5% !important; }
  .bottom-lg-10 {
    bottom: 10% !important; }
  .bottom-lg-15 {
    bottom: 15% !important; }
  .bottom-lg-20 {
    bottom: 20% !important; }
  .bottom-lg-25 {
    bottom: 25% !important; }
  .bottom-lg-30 {
    bottom: 30% !important; }
  .bottom-lg-40 {
    bottom: 40% !important; }
  .bottom-lg-50 {
    bottom: 50% !important; }
  .bottom-lg-80 {
    bottom: 80% !important; }
  .bottom-lg-90 {
    bottom: 90% !important; }
  .bottom-lg-100 {
    bottom: 100% !important; }
  .bottom-lg-2px {
    bottom: 2px !important; }
  .bottom-lg-100px {
    bottom: 100px !important; }
  .bottom-lg-n30px {
    bottom: -30px !important; }
  .bottom-lg-n60px {
    bottom: -60px !important; }
  .bottom-lg-n130px {
    bottom: -130px !important; }
  .bottom-lg-n150px {
    bottom: -150px !important; }
  .bottom-lg-n170px {
    bottom: -170px !important; }
  .bottom-lg-n300px {
    bottom: -300px !important; }
  .start-lg-0 {
    left: 0 !important; }
  .start-lg-5 {
    left: 5% !important; }
  .start-lg-10 {
    left: 10% !important; }
  .start-lg-15 {
    left: 15% !important; }
  .start-lg-20 {
    left: 20% !important; }
  .start-lg-25 {
    left: 25% !important; }
  .start-lg-30 {
    left: 30% !important; }
  .start-lg-40 {
    left: 40% !important; }
  .start-lg-50 {
    left: 50% !important; }
  .start-lg-80 {
    left: 80% !important; }
  .start-lg-90 {
    left: 90% !important; }
  .start-lg-100 {
    left: 100% !important; }
  .start-lg-2px {
    left: 2px !important; }
  .start-lg-100px {
    left: 100px !important; }
  .start-lg-n30px {
    left: -30px !important; }
  .start-lg-n60px {
    left: -60px !important; }
  .start-lg-n130px {
    left: -130px !important; }
  .start-lg-n150px {
    left: -150px !important; }
  .start-lg-n170px {
    left: -170px !important; }
  .start-lg-n300px {
    left: -300px !important; }
  .end-lg-0 {
    right: 0 !important; }
  .end-lg-5 {
    right: 5% !important; }
  .end-lg-10 {
    right: 10% !important; }
  .end-lg-15 {
    right: 15% !important; }
  .end-lg-20 {
    right: 20% !important; }
  .end-lg-25 {
    right: 25% !important; }
  .end-lg-30 {
    right: 30% !important; }
  .end-lg-40 {
    right: 40% !important; }
  .end-lg-50 {
    right: 50% !important; }
  .end-lg-80 {
    right: 80% !important; }
  .end-lg-90 {
    right: 90% !important; }
  .end-lg-100 {
    right: 100% !important; }
  .end-lg-2px {
    right: 2px !important; }
  .end-lg-100px {
    right: 100px !important; }
  .end-lg-n30px {
    right: -30px !important; }
  .end-lg-n60px {
    right: -60px !important; }
  .end-lg-n130px {
    right: -130px !important; }
  .end-lg-n150px {
    right: -150px !important; }
  .end-lg-n170px {
    right: -170px !important; }
  .end-lg-n300px {
    right: -300px !important; }
  .w-lg-20 {
    width: 20% !important; }
  .w-lg-25 {
    width: 25% !important; }
  .w-lg-30 {
    width: 30% !important; }
  .w-lg-40 {
    width: 40% !important; }
  .w-lg-50 {
    width: 50% !important; }
  .w-lg-70 {
    width: 70% !important; }
  .w-lg-75 {
    width: 75% !important; }
  .w-lg-80 {
    width: 80% !important; }
  .w-lg-85 {
    width: 85% !important; }
  .w-lg-90 {
    width: 90% !important; }
  .w-lg-100 {
    width: 100% !important; }
  .w-lg-26px {
    width: 26px !important; }
  .w-lg-86px {
    width: 86px !important; }
  .w-lg-125px {
    width: 125px !important; }
  .w-lg-145px {
    width: 145px !important; }
  .w-lg-161px {
    width: 161px !important; }
  .w-lg-182px {
    width: 182px !important; }
  .w-lg-205px {
    width: 205px !important; }
  .w-lg-215px {
    width: 215px !important; }
  .w-lg-235px {
    width: 235px !important; }
  .w-lg-250px {
    width: 250px !important; }
  .w-lg-260px {
    width: 260px !important; }
  .w-lg-268px {
    width: 268px !important; }
  .w-lg-283px {
    width: 283px !important; }
  .w-lg-285px {
    width: 285px !important; }
  .w-lg-300px {
    width: 300px !important; }
  .w-lg-305px {
    width: 305px !important; }
  .w-lg-310px {
    width: 310px !important; }
  .w-lg-350px {
    width: 350px !important; }
  .w-lg-410px {
    width: 410px !important; }
  .w-lg-440px {
    width: 440px !important; }
  .w-lg-460px {
    width: 460px !important; }
  .w-lg-500px {
    width: 500px !important; }
  .w-lg-520px {
    width: 520px !important; }
  .w-lg-600px {
    width: 600px !important; }
  .w-lg-657px {
    width: 657px !important; }
  .w-lg-1015px {
    width: 1015px !important; }
  .w-lg-1070px {
    width: 1070px !important; }
  .w-lg-auto {
    width: auto !important; }
  .h-lg-20 {
    height: 20% !important; }
  .h-lg-25 {
    height: 25% !important; }
  .h-lg-30 {
    height: 30% !important; }
  .h-lg-40 {
    height: 40% !important; }
  .h-lg-50 {
    height: 50% !important; }
  .h-lg-70 {
    height: 70% !important; }
  .h-lg-75 {
    height: 75% !important; }
  .h-lg-80 {
    height: 80% !important; }
  .h-lg-85 {
    height: 85% !important; }
  .h-lg-90 {
    height: 90% !important; }
  .h-lg-100 {
    height: 100% !important; }
  .h-lg-26px {
    height: 26px !important; }
  .h-lg-86px {
    height: 86px !important; }
  .h-lg-125px {
    height: 125px !important; }
  .h-lg-145px {
    height: 145px !important; }
  .h-lg-161px {
    height: 161px !important; }
  .h-lg-182px {
    height: 182px !important; }
  .h-lg-205px {
    height: 205px !important; }
  .h-lg-215px {
    height: 215px !important; }
  .h-lg-235px {
    height: 235px !important; }
  .h-lg-250px {
    height: 250px !important; }
  .h-lg-260px {
    height: 260px !important; }
  .h-lg-268px {
    height: 268px !important; }
  .h-lg-283px {
    height: 283px !important; }
  .h-lg-285px {
    height: 285px !important; }
  .h-lg-300px {
    height: 300px !important; }
  .h-lg-305px {
    height: 305px !important; }
  .h-lg-310px {
    height: 310px !important; }
  .h-lg-350px {
    height: 350px !important; }
  .h-lg-410px {
    height: 410px !important; }
  .h-lg-440px {
    height: 440px !important; }
  .h-lg-460px {
    height: 460px !important; }
  .h-lg-500px {
    height: 500px !important; }
  .h-lg-520px {
    height: 520px !important; }
  .h-lg-600px {
    height: 600px !important; }
  .h-lg-657px {
    height: 657px !important; }
  .h-lg-1015px {
    height: 1015px !important; }
  .h-lg-1070px {
    height: 1070px !important; }
  .h-lg-auto {
    height: auto !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-lg-35 {
    gap: 3.5rem !important; }
  .gap-lg-34 {
    gap: 3.4rem !important; }
  .gap-lg-33 {
    gap: 3.3rem !important; }
  .gap-lg-32 {
    gap: 3.2rem !important; }
  .gap-lg-31 {
    gap: 3.1rem !important; }
  .gap-lg-30 {
    gap: 3rem !important; }
  .gap-lg-29 {
    gap: 2.9rem !important; }
  .gap-lg-28 {
    gap: 2.8rem !important; }
  .gap-lg-27 {
    gap: 2.7rem !important; }
  .gap-lg-26 {
    gap: 2.6rem !important; }
  .gap-lg-25 {
    gap: 2.5rem !important; }
  .gap-lg-24 {
    gap: 2.4rem !important; }
  .gap-lg-23 {
    gap: 2.3rem !important; }
  .gap-lg-22 {
    gap: 2.2rem !important; }
  .gap-lg-21 {
    gap: 2.1rem !important; }
  .gap-lg-20 {
    gap: 2rem !important; }
  .gap-lg-19 {
    gap: 1.9rem !important; }
  .gap-lg-18 {
    gap: 1.8rem !important; }
  .gap-lg-17 {
    gap: 1.7rem !important; }
  .gap-lg-16 {
    gap: 1.6rem !important; }
  .gap-lg-15 {
    gap: 1.5rem !important; }
  .gap-lg-14 {
    gap: 1.4rem !important; }
  .gap-lg-13 {
    gap: 1.3rem !important; }
  .gap-lg-12 {
    gap: 1.2rem !important; }
  .gap-lg-11 {
    gap: 1.1rem !important; }
  .gap-lg-10 {
    gap: 1rem !important; }
  .gap-lg-0 {
    gap: 0 !important; }
  .gap-lg-1 {
    gap: 0.25rem !important; }
  .gap-lg-2 {
    gap: 0.5rem !important; }
  .gap-lg-3 {
    gap: 1rem !important; }
  .gap-lg-4 {
    gap: 1.5rem !important; }
  .gap-lg-5 {
    gap: 0.5rem !important; }
  .gap-lg-6 {
    gap: 0.6rem !important; }
  .gap-lg-8 {
    gap: 0.8rem !important; }
  .gap-lg-40 {
    gap: 4rem !important; }
  .gap-lg-45 {
    gap: 4.5rem !important; }
  .gap-lg-50 {
    gap: 5rem !important; }
  .gap-lg-55 {
    gap: 5.5rem !important; }
  .gap-lg-60 {
    gap: 6rem !important; }
  .gap-lg-70 {
    gap: 7rem !important; }
  .gap-lg-80 {
    gap: 8rem !important; }
  .gap-lg-90 {
    gap: 9rem !important; }
  .gap-lg-100 {
    gap: 10rem !important; }
  .gap-lg-105 {
    gap: 10.5rem !important; }
  .gap-lg-110 {
    gap: 11rem !important; }
  .gap-lg-125 {
    gap: 12.5rem !important; }
  .gap-lg-130 {
    gap: 13rem !important; }
  .gap-lg-145 {
    gap: 14.5rem !important; }
  .gap-lg-170 {
    gap: 17rem !important; }
  .gap-lg-235 {
    gap: 23.5rem !important; }
  .gap-lg-n20 {
    gap: -2rem !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-35 {
    margin: 3.5rem !important; }
  .m-lg-34 {
    margin: 3.4rem !important; }
  .m-lg-33 {
    margin: 3.3rem !important; }
  .m-lg-32 {
    margin: 3.2rem !important; }
  .m-lg-31 {
    margin: 3.1rem !important; }
  .m-lg-30 {
    margin: 3rem !important; }
  .m-lg-29 {
    margin: 2.9rem !important; }
  .m-lg-28 {
    margin: 2.8rem !important; }
  .m-lg-27 {
    margin: 2.7rem !important; }
  .m-lg-26 {
    margin: 2.6rem !important; }
  .m-lg-25 {
    margin: 2.5rem !important; }
  .m-lg-24 {
    margin: 2.4rem !important; }
  .m-lg-23 {
    margin: 2.3rem !important; }
  .m-lg-22 {
    margin: 2.2rem !important; }
  .m-lg-21 {
    margin: 2.1rem !important; }
  .m-lg-20 {
    margin: 2rem !important; }
  .m-lg-19 {
    margin: 1.9rem !important; }
  .m-lg-18 {
    margin: 1.8rem !important; }
  .m-lg-17 {
    margin: 1.7rem !important; }
  .m-lg-16 {
    margin: 1.6rem !important; }
  .m-lg-15 {
    margin: 1.5rem !important; }
  .m-lg-14 {
    margin: 1.4rem !important; }
  .m-lg-13 {
    margin: 1.3rem !important; }
  .m-lg-12 {
    margin: 1.2rem !important; }
  .m-lg-11 {
    margin: 1.1rem !important; }
  .m-lg-10 {
    margin: 1rem !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .m-lg-5 {
    margin: 0.5rem !important; }
  .m-lg-6 {
    margin: 0.6rem !important; }
  .m-lg-8 {
    margin: 0.8rem !important; }
  .m-lg-40 {
    margin: 4rem !important; }
  .m-lg-45 {
    margin: 4.5rem !important; }
  .m-lg-50 {
    margin: 5rem !important; }
  .m-lg-55 {
    margin: 5.5rem !important; }
  .m-lg-60 {
    margin: 6rem !important; }
  .m-lg-70 {
    margin: 7rem !important; }
  .m-lg-80 {
    margin: 8rem !important; }
  .m-lg-90 {
    margin: 9rem !important; }
  .m-lg-100 {
    margin: 10rem !important; }
  .m-lg-105 {
    margin: 10.5rem !important; }
  .m-lg-110 {
    margin: 11rem !important; }
  .m-lg-125 {
    margin: 12.5rem !important; }
  .m-lg-130 {
    margin: 13rem !important; }
  .m-lg-145 {
    margin: 14.5rem !important; }
  .m-lg-170 {
    margin: 17rem !important; }
  .m-lg-235 {
    margin: 23.5rem !important; }
  .m-lg-n20 {
    margin: -2rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-35 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important; }
  .mx-lg-34 {
    margin-right: 3.4rem !important;
    margin-left: 3.4rem !important; }
  .mx-lg-33 {
    margin-right: 3.3rem !important;
    margin-left: 3.3rem !important; }
  .mx-lg-32 {
    margin-right: 3.2rem !important;
    margin-left: 3.2rem !important; }
  .mx-lg-31 {
    margin-right: 3.1rem !important;
    margin-left: 3.1rem !important; }
  .mx-lg-30 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-lg-29 {
    margin-right: 2.9rem !important;
    margin-left: 2.9rem !important; }
  .mx-lg-28 {
    margin-right: 2.8rem !important;
    margin-left: 2.8rem !important; }
  .mx-lg-27 {
    margin-right: 2.7rem !important;
    margin-left: 2.7rem !important; }
  .mx-lg-26 {
    margin-right: 2.6rem !important;
    margin-left: 2.6rem !important; }
  .mx-lg-25 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important; }
  .mx-lg-24 {
    margin-right: 2.4rem !important;
    margin-left: 2.4rem !important; }
  .mx-lg-23 {
    margin-right: 2.3rem !important;
    margin-left: 2.3rem !important; }
  .mx-lg-22 {
    margin-right: 2.2rem !important;
    margin-left: 2.2rem !important; }
  .mx-lg-21 {
    margin-right: 2.1rem !important;
    margin-left: 2.1rem !important; }
  .mx-lg-20 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  .mx-lg-19 {
    margin-right: 1.9rem !important;
    margin-left: 1.9rem !important; }
  .mx-lg-18 {
    margin-right: 1.8rem !important;
    margin-left: 1.8rem !important; }
  .mx-lg-17 {
    margin-right: 1.7rem !important;
    margin-left: 1.7rem !important; }
  .mx-lg-16 {
    margin-right: 1.6rem !important;
    margin-left: 1.6rem !important; }
  .mx-lg-15 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-14 {
    margin-right: 1.4rem !important;
    margin-left: 1.4rem !important; }
  .mx-lg-13 {
    margin-right: 1.3rem !important;
    margin-left: 1.3rem !important; }
  .mx-lg-12 {
    margin-right: 1.2rem !important;
    margin-left: 1.2rem !important; }
  .mx-lg-11 {
    margin-right: 1.1rem !important;
    margin-left: 1.1rem !important; }
  .mx-lg-10 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-5 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-lg-6 {
    margin-right: 0.6rem !important;
    margin-left: 0.6rem !important; }
  .mx-lg-8 {
    margin-right: 0.8rem !important;
    margin-left: 0.8rem !important; }
  .mx-lg-40 {
    margin-right: 4rem !important;
    margin-left: 4rem !important; }
  .mx-lg-45 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important; }
  .mx-lg-50 {
    margin-right: 5rem !important;
    margin-left: 5rem !important; }
  .mx-lg-55 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important; }
  .mx-lg-60 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-lg-70 {
    margin-right: 7rem !important;
    margin-left: 7rem !important; }
  .mx-lg-80 {
    margin-right: 8rem !important;
    margin-left: 8rem !important; }
  .mx-lg-90 {
    margin-right: 9rem !important;
    margin-left: 9rem !important; }
  .mx-lg-100 {
    margin-right: 10rem !important;
    margin-left: 10rem !important; }
  .mx-lg-105 {
    margin-right: 10.5rem !important;
    margin-left: 10.5rem !important; }
  .mx-lg-110 {
    margin-right: 11rem !important;
    margin-left: 11rem !important; }
  .mx-lg-125 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important; }
  .mx-lg-130 {
    margin-right: 13rem !important;
    margin-left: 13rem !important; }
  .mx-lg-145 {
    margin-right: 14.5rem !important;
    margin-left: 14.5rem !important; }
  .mx-lg-170 {
    margin-right: 17rem !important;
    margin-left: 17rem !important; }
  .mx-lg-235 {
    margin-right: 23.5rem !important;
    margin-left: 23.5rem !important; }
  .mx-lg-n20 {
    margin-right: -2rem !important;
    margin-left: -2rem !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-35 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important; }
  .my-lg-34 {
    margin-top: 3.4rem !important;
    margin-bottom: 3.4rem !important; }
  .my-lg-33 {
    margin-top: 3.3rem !important;
    margin-bottom: 3.3rem !important; }
  .my-lg-32 {
    margin-top: 3.2rem !important;
    margin-bottom: 3.2rem !important; }
  .my-lg-31 {
    margin-top: 3.1rem !important;
    margin-bottom: 3.1rem !important; }
  .my-lg-30 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-lg-29 {
    margin-top: 2.9rem !important;
    margin-bottom: 2.9rem !important; }
  .my-lg-28 {
    margin-top: 2.8rem !important;
    margin-bottom: 2.8rem !important; }
  .my-lg-27 {
    margin-top: 2.7rem !important;
    margin-bottom: 2.7rem !important; }
  .my-lg-26 {
    margin-top: 2.6rem !important;
    margin-bottom: 2.6rem !important; }
  .my-lg-25 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .my-lg-24 {
    margin-top: 2.4rem !important;
    margin-bottom: 2.4rem !important; }
  .my-lg-23 {
    margin-top: 2.3rem !important;
    margin-bottom: 2.3rem !important; }
  .my-lg-22 {
    margin-top: 2.2rem !important;
    margin-bottom: 2.2rem !important; }
  .my-lg-21 {
    margin-top: 2.1rem !important;
    margin-bottom: 2.1rem !important; }
  .my-lg-20 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .my-lg-19 {
    margin-top: 1.9rem !important;
    margin-bottom: 1.9rem !important; }
  .my-lg-18 {
    margin-top: 1.8rem !important;
    margin-bottom: 1.8rem !important; }
  .my-lg-17 {
    margin-top: 1.7rem !important;
    margin-bottom: 1.7rem !important; }
  .my-lg-16 {
    margin-top: 1.6rem !important;
    margin-bottom: 1.6rem !important; }
  .my-lg-15 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-14 {
    margin-top: 1.4rem !important;
    margin-bottom: 1.4rem !important; }
  .my-lg-13 {
    margin-top: 1.3rem !important;
    margin-bottom: 1.3rem !important; }
  .my-lg-12 {
    margin-top: 1.2rem !important;
    margin-bottom: 1.2rem !important; }
  .my-lg-11 {
    margin-top: 1.1rem !important;
    margin-bottom: 1.1rem !important; }
  .my-lg-10 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-5 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-lg-6 {
    margin-top: 0.6rem !important;
    margin-bottom: 0.6rem !important; }
  .my-lg-8 {
    margin-top: 0.8rem !important;
    margin-bottom: 0.8rem !important; }
  .my-lg-40 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .my-lg-45 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important; }
  .my-lg-50 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .my-lg-55 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important; }
  .my-lg-60 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-lg-70 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important; }
  .my-lg-80 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important; }
  .my-lg-90 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important; }
  .my-lg-100 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important; }
  .my-lg-105 {
    margin-top: 10.5rem !important;
    margin-bottom: 10.5rem !important; }
  .my-lg-110 {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important; }
  .my-lg-125 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important; }
  .my-lg-130 {
    margin-top: 13rem !important;
    margin-bottom: 13rem !important; }
  .my-lg-145 {
    margin-top: 14.5rem !important;
    margin-bottom: 14.5rem !important; }
  .my-lg-170 {
    margin-top: 17rem !important;
    margin-bottom: 17rem !important; }
  .my-lg-235 {
    margin-top: 23.5rem !important;
    margin-bottom: 23.5rem !important; }
  .my-lg-n20 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-35 {
    margin-top: 3.5rem !important; }
  .mt-lg-34 {
    margin-top: 3.4rem !important; }
  .mt-lg-33 {
    margin-top: 3.3rem !important; }
  .mt-lg-32 {
    margin-top: 3.2rem !important; }
  .mt-lg-31 {
    margin-top: 3.1rem !important; }
  .mt-lg-30 {
    margin-top: 3rem !important; }
  .mt-lg-29 {
    margin-top: 2.9rem !important; }
  .mt-lg-28 {
    margin-top: 2.8rem !important; }
  .mt-lg-27 {
    margin-top: 2.7rem !important; }
  .mt-lg-26 {
    margin-top: 2.6rem !important; }
  .mt-lg-25 {
    margin-top: 2.5rem !important; }
  .mt-lg-24 {
    margin-top: 2.4rem !important; }
  .mt-lg-23 {
    margin-top: 2.3rem !important; }
  .mt-lg-22 {
    margin-top: 2.2rem !important; }
  .mt-lg-21 {
    margin-top: 2.1rem !important; }
  .mt-lg-20 {
    margin-top: 2rem !important; }
  .mt-lg-19 {
    margin-top: 1.9rem !important; }
  .mt-lg-18 {
    margin-top: 1.8rem !important; }
  .mt-lg-17 {
    margin-top: 1.7rem !important; }
  .mt-lg-16 {
    margin-top: 1.6rem !important; }
  .mt-lg-15 {
    margin-top: 1.5rem !important; }
  .mt-lg-14 {
    margin-top: 1.4rem !important; }
  .mt-lg-13 {
    margin-top: 1.3rem !important; }
  .mt-lg-12 {
    margin-top: 1.2rem !important; }
  .mt-lg-11 {
    margin-top: 1.1rem !important; }
  .mt-lg-10 {
    margin-top: 1rem !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mt-lg-5 {
    margin-top: 0.5rem !important; }
  .mt-lg-6 {
    margin-top: 0.6rem !important; }
  .mt-lg-8 {
    margin-top: 0.8rem !important; }
  .mt-lg-40 {
    margin-top: 4rem !important; }
  .mt-lg-45 {
    margin-top: 4.5rem !important; }
  .mt-lg-50 {
    margin-top: 5rem !important; }
  .mt-lg-55 {
    margin-top: 5.5rem !important; }
  .mt-lg-60 {
    margin-top: 6rem !important; }
  .mt-lg-70 {
    margin-top: 7rem !important; }
  .mt-lg-80 {
    margin-top: 8rem !important; }
  .mt-lg-90 {
    margin-top: 9rem !important; }
  .mt-lg-100 {
    margin-top: 10rem !important; }
  .mt-lg-105 {
    margin-top: 10.5rem !important; }
  .mt-lg-110 {
    margin-top: 11rem !important; }
  .mt-lg-125 {
    margin-top: 12.5rem !important; }
  .mt-lg-130 {
    margin-top: 13rem !important; }
  .mt-lg-145 {
    margin-top: 14.5rem !important; }
  .mt-lg-170 {
    margin-top: 17rem !important; }
  .mt-lg-235 {
    margin-top: 23.5rem !important; }
  .mt-lg-n20 {
    margin-top: -2rem !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .me-lg-35 {
    margin-right: 3.5rem !important; }
  .me-lg-34 {
    margin-right: 3.4rem !important; }
  .me-lg-33 {
    margin-right: 3.3rem !important; }
  .me-lg-32 {
    margin-right: 3.2rem !important; }
  .me-lg-31 {
    margin-right: 3.1rem !important; }
  .me-lg-30 {
    margin-right: 3rem !important; }
  .me-lg-29 {
    margin-right: 2.9rem !important; }
  .me-lg-28 {
    margin-right: 2.8rem !important; }
  .me-lg-27 {
    margin-right: 2.7rem !important; }
  .me-lg-26 {
    margin-right: 2.6rem !important; }
  .me-lg-25 {
    margin-right: 2.5rem !important; }
  .me-lg-24 {
    margin-right: 2.4rem !important; }
  .me-lg-23 {
    margin-right: 2.3rem !important; }
  .me-lg-22 {
    margin-right: 2.2rem !important; }
  .me-lg-21 {
    margin-right: 2.1rem !important; }
  .me-lg-20 {
    margin-right: 2rem !important; }
  .me-lg-19 {
    margin-right: 1.9rem !important; }
  .me-lg-18 {
    margin-right: 1.8rem !important; }
  .me-lg-17 {
    margin-right: 1.7rem !important; }
  .me-lg-16 {
    margin-right: 1.6rem !important; }
  .me-lg-15 {
    margin-right: 1.5rem !important; }
  .me-lg-14 {
    margin-right: 1.4rem !important; }
  .me-lg-13 {
    margin-right: 1.3rem !important; }
  .me-lg-12 {
    margin-right: 1.2rem !important; }
  .me-lg-11 {
    margin-right: 1.1rem !important; }
  .me-lg-10 {
    margin-right: 1rem !important; }
  .me-lg-0 {
    margin-right: 0 !important; }
  .me-lg-1 {
    margin-right: 0.25rem !important; }
  .me-lg-2 {
    margin-right: 0.5rem !important; }
  .me-lg-3 {
    margin-right: 1rem !important; }
  .me-lg-4 {
    margin-right: 1.5rem !important; }
  .me-lg-5 {
    margin-right: 0.5rem !important; }
  .me-lg-6 {
    margin-right: 0.6rem !important; }
  .me-lg-8 {
    margin-right: 0.8rem !important; }
  .me-lg-40 {
    margin-right: 4rem !important; }
  .me-lg-45 {
    margin-right: 4.5rem !important; }
  .me-lg-50 {
    margin-right: 5rem !important; }
  .me-lg-55 {
    margin-right: 5.5rem !important; }
  .me-lg-60 {
    margin-right: 6rem !important; }
  .me-lg-70 {
    margin-right: 7rem !important; }
  .me-lg-80 {
    margin-right: 8rem !important; }
  .me-lg-90 {
    margin-right: 9rem !important; }
  .me-lg-100 {
    margin-right: 10rem !important; }
  .me-lg-105 {
    margin-right: 10.5rem !important; }
  .me-lg-110 {
    margin-right: 11rem !important; }
  .me-lg-125 {
    margin-right: 12.5rem !important; }
  .me-lg-130 {
    margin-right: 13rem !important; }
  .me-lg-145 {
    margin-right: 14.5rem !important; }
  .me-lg-170 {
    margin-right: 17rem !important; }
  .me-lg-235 {
    margin-right: 23.5rem !important; }
  .me-lg-n20 {
    margin-right: -2rem !important; }
  .me-lg-auto {
    margin-right: auto !important; }
  .mb-lg-35 {
    margin-bottom: 3.5rem !important; }
  .mb-lg-34 {
    margin-bottom: 3.4rem !important; }
  .mb-lg-33 {
    margin-bottom: 3.3rem !important; }
  .mb-lg-32 {
    margin-bottom: 3.2rem !important; }
  .mb-lg-31 {
    margin-bottom: 3.1rem !important; }
  .mb-lg-30 {
    margin-bottom: 3rem !important; }
  .mb-lg-29 {
    margin-bottom: 2.9rem !important; }
  .mb-lg-28 {
    margin-bottom: 2.8rem !important; }
  .mb-lg-27 {
    margin-bottom: 2.7rem !important; }
  .mb-lg-26 {
    margin-bottom: 2.6rem !important; }
  .mb-lg-25 {
    margin-bottom: 2.5rem !important; }
  .mb-lg-24 {
    margin-bottom: 2.4rem !important; }
  .mb-lg-23 {
    margin-bottom: 2.3rem !important; }
  .mb-lg-22 {
    margin-bottom: 2.2rem !important; }
  .mb-lg-21 {
    margin-bottom: 2.1rem !important; }
  .mb-lg-20 {
    margin-bottom: 2rem !important; }
  .mb-lg-19 {
    margin-bottom: 1.9rem !important; }
  .mb-lg-18 {
    margin-bottom: 1.8rem !important; }
  .mb-lg-17 {
    margin-bottom: 1.7rem !important; }
  .mb-lg-16 {
    margin-bottom: 1.6rem !important; }
  .mb-lg-15 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-14 {
    margin-bottom: 1.4rem !important; }
  .mb-lg-13 {
    margin-bottom: 1.3rem !important; }
  .mb-lg-12 {
    margin-bottom: 1.2rem !important; }
  .mb-lg-11 {
    margin-bottom: 1.1rem !important; }
  .mb-lg-10 {
    margin-bottom: 1rem !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-5 {
    margin-bottom: 0.5rem !important; }
  .mb-lg-6 {
    margin-bottom: 0.6rem !important; }
  .mb-lg-8 {
    margin-bottom: 0.8rem !important; }
  .mb-lg-40 {
    margin-bottom: 4rem !important; }
  .mb-lg-45 {
    margin-bottom: 4.5rem !important; }
  .mb-lg-50 {
    margin-bottom: 5rem !important; }
  .mb-lg-55 {
    margin-bottom: 5.5rem !important; }
  .mb-lg-60 {
    margin-bottom: 6rem !important; }
  .mb-lg-70 {
    margin-bottom: 7rem !important; }
  .mb-lg-80 {
    margin-bottom: 8rem !important; }
  .mb-lg-90 {
    margin-bottom: 9rem !important; }
  .mb-lg-100 {
    margin-bottom: 10rem !important; }
  .mb-lg-105 {
    margin-bottom: 10.5rem !important; }
  .mb-lg-110 {
    margin-bottom: 11rem !important; }
  .mb-lg-125 {
    margin-bottom: 12.5rem !important; }
  .mb-lg-130 {
    margin-bottom: 13rem !important; }
  .mb-lg-145 {
    margin-bottom: 14.5rem !important; }
  .mb-lg-170 {
    margin-bottom: 17rem !important; }
  .mb-lg-235 {
    margin-bottom: 23.5rem !important; }
  .mb-lg-n20 {
    margin-bottom: -2rem !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ms-lg-35 {
    margin-left: 3.5rem !important; }
  .ms-lg-34 {
    margin-left: 3.4rem !important; }
  .ms-lg-33 {
    margin-left: 3.3rem !important; }
  .ms-lg-32 {
    margin-left: 3.2rem !important; }
  .ms-lg-31 {
    margin-left: 3.1rem !important; }
  .ms-lg-30 {
    margin-left: 3rem !important; }
  .ms-lg-29 {
    margin-left: 2.9rem !important; }
  .ms-lg-28 {
    margin-left: 2.8rem !important; }
  .ms-lg-27 {
    margin-left: 2.7rem !important; }
  .ms-lg-26 {
    margin-left: 2.6rem !important; }
  .ms-lg-25 {
    margin-left: 2.5rem !important; }
  .ms-lg-24 {
    margin-left: 2.4rem !important; }
  .ms-lg-23 {
    margin-left: 2.3rem !important; }
  .ms-lg-22 {
    margin-left: 2.2rem !important; }
  .ms-lg-21 {
    margin-left: 2.1rem !important; }
  .ms-lg-20 {
    margin-left: 2rem !important; }
  .ms-lg-19 {
    margin-left: 1.9rem !important; }
  .ms-lg-18 {
    margin-left: 1.8rem !important; }
  .ms-lg-17 {
    margin-left: 1.7rem !important; }
  .ms-lg-16 {
    margin-left: 1.6rem !important; }
  .ms-lg-15 {
    margin-left: 1.5rem !important; }
  .ms-lg-14 {
    margin-left: 1.4rem !important; }
  .ms-lg-13 {
    margin-left: 1.3rem !important; }
  .ms-lg-12 {
    margin-left: 1.2rem !important; }
  .ms-lg-11 {
    margin-left: 1.1rem !important; }
  .ms-lg-10 {
    margin-left: 1rem !important; }
  .ms-lg-0 {
    margin-left: 0 !important; }
  .ms-lg-1 {
    margin-left: 0.25rem !important; }
  .ms-lg-2 {
    margin-left: 0.5rem !important; }
  .ms-lg-3 {
    margin-left: 1rem !important; }
  .ms-lg-4 {
    margin-left: 1.5rem !important; }
  .ms-lg-5 {
    margin-left: 0.5rem !important; }
  .ms-lg-6 {
    margin-left: 0.6rem !important; }
  .ms-lg-8 {
    margin-left: 0.8rem !important; }
  .ms-lg-40 {
    margin-left: 4rem !important; }
  .ms-lg-45 {
    margin-left: 4.5rem !important; }
  .ms-lg-50 {
    margin-left: 5rem !important; }
  .ms-lg-55 {
    margin-left: 5.5rem !important; }
  .ms-lg-60 {
    margin-left: 6rem !important; }
  .ms-lg-70 {
    margin-left: 7rem !important; }
  .ms-lg-80 {
    margin-left: 8rem !important; }
  .ms-lg-90 {
    margin-left: 9rem !important; }
  .ms-lg-100 {
    margin-left: 10rem !important; }
  .ms-lg-105 {
    margin-left: 10.5rem !important; }
  .ms-lg-110 {
    margin-left: 11rem !important; }
  .ms-lg-125 {
    margin-left: 12.5rem !important; }
  .ms-lg-130 {
    margin-left: 13rem !important; }
  .ms-lg-145 {
    margin-left: 14.5rem !important; }
  .ms-lg-170 {
    margin-left: 17rem !important; }
  .ms-lg-235 {
    margin-left: 23.5rem !important; }
  .ms-lg-n20 {
    margin-left: -2rem !important; }
  .ms-lg-auto {
    margin-left: auto !important; }
  .p-lg-35 {
    padding: 3.5rem !important; }
  .p-lg-34 {
    padding: 3.4rem !important; }
  .p-lg-33 {
    padding: 3.3rem !important; }
  .p-lg-32 {
    padding: 3.2rem !important; }
  .p-lg-31 {
    padding: 3.1rem !important; }
  .p-lg-30 {
    padding: 3rem !important; }
  .p-lg-29 {
    padding: 2.9rem !important; }
  .p-lg-28 {
    padding: 2.8rem !important; }
  .p-lg-27 {
    padding: 2.7rem !important; }
  .p-lg-26 {
    padding: 2.6rem !important; }
  .p-lg-25 {
    padding: 2.5rem !important; }
  .p-lg-24 {
    padding: 2.4rem !important; }
  .p-lg-23 {
    padding: 2.3rem !important; }
  .p-lg-22 {
    padding: 2.2rem !important; }
  .p-lg-21 {
    padding: 2.1rem !important; }
  .p-lg-20 {
    padding: 2rem !important; }
  .p-lg-19 {
    padding: 1.9rem !important; }
  .p-lg-18 {
    padding: 1.8rem !important; }
  .p-lg-17 {
    padding: 1.7rem !important; }
  .p-lg-16 {
    padding: 1.6rem !important; }
  .p-lg-15 {
    padding: 1.5rem !important; }
  .p-lg-14 {
    padding: 1.4rem !important; }
  .p-lg-13 {
    padding: 1.3rem !important; }
  .p-lg-12 {
    padding: 1.2rem !important; }
  .p-lg-11 {
    padding: 1.1rem !important; }
  .p-lg-10 {
    padding: 1rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .p-lg-5 {
    padding: 0.5rem !important; }
  .p-lg-6 {
    padding: 0.6rem !important; }
  .p-lg-8 {
    padding: 0.8rem !important; }
  .p-lg-40 {
    padding: 4rem !important; }
  .p-lg-45 {
    padding: 4.5rem !important; }
  .p-lg-50 {
    padding: 5rem !important; }
  .p-lg-55 {
    padding: 5.5rem !important; }
  .p-lg-60 {
    padding: 6rem !important; }
  .p-lg-70 {
    padding: 7rem !important; }
  .p-lg-80 {
    padding: 8rem !important; }
  .p-lg-90 {
    padding: 9rem !important; }
  .p-lg-100 {
    padding: 10rem !important; }
  .p-lg-105 {
    padding: 10.5rem !important; }
  .p-lg-110 {
    padding: 11rem !important; }
  .p-lg-125 {
    padding: 12.5rem !important; }
  .p-lg-130 {
    padding: 13rem !important; }
  .p-lg-145 {
    padding: 14.5rem !important; }
  .p-lg-170 {
    padding: 17rem !important; }
  .p-lg-235 {
    padding: 23.5rem !important; }
  .p-lg-n20 {
    padding: -2rem !important; }
  .px-lg-35 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important; }
  .px-lg-34 {
    padding-right: 3.4rem !important;
    padding-left: 3.4rem !important; }
  .px-lg-33 {
    padding-right: 3.3rem !important;
    padding-left: 3.3rem !important; }
  .px-lg-32 {
    padding-right: 3.2rem !important;
    padding-left: 3.2rem !important; }
  .px-lg-31 {
    padding-right: 3.1rem !important;
    padding-left: 3.1rem !important; }
  .px-lg-30 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-lg-29 {
    padding-right: 2.9rem !important;
    padding-left: 2.9rem !important; }
  .px-lg-28 {
    padding-right: 2.8rem !important;
    padding-left: 2.8rem !important; }
  .px-lg-27 {
    padding-right: 2.7rem !important;
    padding-left: 2.7rem !important; }
  .px-lg-26 {
    padding-right: 2.6rem !important;
    padding-left: 2.6rem !important; }
  .px-lg-25 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important; }
  .px-lg-24 {
    padding-right: 2.4rem !important;
    padding-left: 2.4rem !important; }
  .px-lg-23 {
    padding-right: 2.3rem !important;
    padding-left: 2.3rem !important; }
  .px-lg-22 {
    padding-right: 2.2rem !important;
    padding-left: 2.2rem !important; }
  .px-lg-21 {
    padding-right: 2.1rem !important;
    padding-left: 2.1rem !important; }
  .px-lg-20 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .px-lg-19 {
    padding-right: 1.9rem !important;
    padding-left: 1.9rem !important; }
  .px-lg-18 {
    padding-right: 1.8rem !important;
    padding-left: 1.8rem !important; }
  .px-lg-17 {
    padding-right: 1.7rem !important;
    padding-left: 1.7rem !important; }
  .px-lg-16 {
    padding-right: 1.6rem !important;
    padding-left: 1.6rem !important; }
  .px-lg-15 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-14 {
    padding-right: 1.4rem !important;
    padding-left: 1.4rem !important; }
  .px-lg-13 {
    padding-right: 1.3rem !important;
    padding-left: 1.3rem !important; }
  .px-lg-12 {
    padding-right: 1.2rem !important;
    padding-left: 1.2rem !important; }
  .px-lg-11 {
    padding-right: 1.1rem !important;
    padding-left: 1.1rem !important; }
  .px-lg-10 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-5 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-lg-6 {
    padding-right: 0.6rem !important;
    padding-left: 0.6rem !important; }
  .px-lg-8 {
    padding-right: 0.8rem !important;
    padding-left: 0.8rem !important; }
  .px-lg-40 {
    padding-right: 4rem !important;
    padding-left: 4rem !important; }
  .px-lg-45 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important; }
  .px-lg-50 {
    padding-right: 5rem !important;
    padding-left: 5rem !important; }
  .px-lg-55 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important; }
  .px-lg-60 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .px-lg-70 {
    padding-right: 7rem !important;
    padding-left: 7rem !important; }
  .px-lg-80 {
    padding-right: 8rem !important;
    padding-left: 8rem !important; }
  .px-lg-90 {
    padding-right: 9rem !important;
    padding-left: 9rem !important; }
  .px-lg-100 {
    padding-right: 10rem !important;
    padding-left: 10rem !important; }
  .px-lg-105 {
    padding-right: 10.5rem !important;
    padding-left: 10.5rem !important; }
  .px-lg-110 {
    padding-right: 11rem !important;
    padding-left: 11rem !important; }
  .px-lg-125 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important; }
  .px-lg-130 {
    padding-right: 13rem !important;
    padding-left: 13rem !important; }
  .px-lg-145 {
    padding-right: 14.5rem !important;
    padding-left: 14.5rem !important; }
  .px-lg-170 {
    padding-right: 17rem !important;
    padding-left: 17rem !important; }
  .px-lg-235 {
    padding-right: 23.5rem !important;
    padding-left: 23.5rem !important; }
  .px-lg-n20 {
    padding-right: -2rem !important;
    padding-left: -2rem !important; }
  .py-lg-35 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important; }
  .py-lg-34 {
    padding-top: 3.4rem !important;
    padding-bottom: 3.4rem !important; }
  .py-lg-33 {
    padding-top: 3.3rem !important;
    padding-bottom: 3.3rem !important; }
  .py-lg-32 {
    padding-top: 3.2rem !important;
    padding-bottom: 3.2rem !important; }
  .py-lg-31 {
    padding-top: 3.1rem !important;
    padding-bottom: 3.1rem !important; }
  .py-lg-30 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-lg-29 {
    padding-top: 2.9rem !important;
    padding-bottom: 2.9rem !important; }
  .py-lg-28 {
    padding-top: 2.8rem !important;
    padding-bottom: 2.8rem !important; }
  .py-lg-27 {
    padding-top: 2.7rem !important;
    padding-bottom: 2.7rem !important; }
  .py-lg-26 {
    padding-top: 2.6rem !important;
    padding-bottom: 2.6rem !important; }
  .py-lg-25 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .py-lg-24 {
    padding-top: 2.4rem !important;
    padding-bottom: 2.4rem !important; }
  .py-lg-23 {
    padding-top: 2.3rem !important;
    padding-bottom: 2.3rem !important; }
  .py-lg-22 {
    padding-top: 2.2rem !important;
    padding-bottom: 2.2rem !important; }
  .py-lg-21 {
    padding-top: 2.1rem !important;
    padding-bottom: 2.1rem !important; }
  .py-lg-20 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .py-lg-19 {
    padding-top: 1.9rem !important;
    padding-bottom: 1.9rem !important; }
  .py-lg-18 {
    padding-top: 1.8rem !important;
    padding-bottom: 1.8rem !important; }
  .py-lg-17 {
    padding-top: 1.7rem !important;
    padding-bottom: 1.7rem !important; }
  .py-lg-16 {
    padding-top: 1.6rem !important;
    padding-bottom: 1.6rem !important; }
  .py-lg-15 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-14 {
    padding-top: 1.4rem !important;
    padding-bottom: 1.4rem !important; }
  .py-lg-13 {
    padding-top: 1.3rem !important;
    padding-bottom: 1.3rem !important; }
  .py-lg-12 {
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important; }
  .py-lg-11 {
    padding-top: 1.1rem !important;
    padding-bottom: 1.1rem !important; }
  .py-lg-10 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-5 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-lg-6 {
    padding-top: 0.6rem !important;
    padding-bottom: 0.6rem !important; }
  .py-lg-8 {
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important; }
  .py-lg-40 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .py-lg-45 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important; }
  .py-lg-50 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .py-lg-55 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important; }
  .py-lg-60 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .py-lg-70 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important; }
  .py-lg-80 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important; }
  .py-lg-90 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important; }
  .py-lg-100 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important; }
  .py-lg-105 {
    padding-top: 10.5rem !important;
    padding-bottom: 10.5rem !important; }
  .py-lg-110 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important; }
  .py-lg-125 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important; }
  .py-lg-130 {
    padding-top: 13rem !important;
    padding-bottom: 13rem !important; }
  .py-lg-145 {
    padding-top: 14.5rem !important;
    padding-bottom: 14.5rem !important; }
  .py-lg-170 {
    padding-top: 17rem !important;
    padding-bottom: 17rem !important; }
  .py-lg-235 {
    padding-top: 23.5rem !important;
    padding-bottom: 23.5rem !important; }
  .py-lg-n20 {
    padding-top: -2rem !important;
    padding-bottom: -2rem !important; }
  .pt-lg-35 {
    padding-top: 3.5rem !important; }
  .pt-lg-34 {
    padding-top: 3.4rem !important; }
  .pt-lg-33 {
    padding-top: 3.3rem !important; }
  .pt-lg-32 {
    padding-top: 3.2rem !important; }
  .pt-lg-31 {
    padding-top: 3.1rem !important; }
  .pt-lg-30 {
    padding-top: 3rem !important; }
  .pt-lg-29 {
    padding-top: 2.9rem !important; }
  .pt-lg-28 {
    padding-top: 2.8rem !important; }
  .pt-lg-27 {
    padding-top: 2.7rem !important; }
  .pt-lg-26 {
    padding-top: 2.6rem !important; }
  .pt-lg-25 {
    padding-top: 2.5rem !important; }
  .pt-lg-24 {
    padding-top: 2.4rem !important; }
  .pt-lg-23 {
    padding-top: 2.3rem !important; }
  .pt-lg-22 {
    padding-top: 2.2rem !important; }
  .pt-lg-21 {
    padding-top: 2.1rem !important; }
  .pt-lg-20 {
    padding-top: 2rem !important; }
  .pt-lg-19 {
    padding-top: 1.9rem !important; }
  .pt-lg-18 {
    padding-top: 1.8rem !important; }
  .pt-lg-17 {
    padding-top: 1.7rem !important; }
  .pt-lg-16 {
    padding-top: 1.6rem !important; }
  .pt-lg-15 {
    padding-top: 1.5rem !important; }
  .pt-lg-14 {
    padding-top: 1.4rem !important; }
  .pt-lg-13 {
    padding-top: 1.3rem !important; }
  .pt-lg-12 {
    padding-top: 1.2rem !important; }
  .pt-lg-11 {
    padding-top: 1.1rem !important; }
  .pt-lg-10 {
    padding-top: 1rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pt-lg-5 {
    padding-top: 0.5rem !important; }
  .pt-lg-6 {
    padding-top: 0.6rem !important; }
  .pt-lg-8 {
    padding-top: 0.8rem !important; }
  .pt-lg-40 {
    padding-top: 4rem !important; }
  .pt-lg-45 {
    padding-top: 4.5rem !important; }
  .pt-lg-50 {
    padding-top: 5rem !important; }
  .pt-lg-55 {
    padding-top: 5.5rem !important; }
  .pt-lg-60 {
    padding-top: 6rem !important; }
  .pt-lg-70 {
    padding-top: 7rem !important; }
  .pt-lg-80 {
    padding-top: 8rem !important; }
  .pt-lg-90 {
    padding-top: 9rem !important; }
  .pt-lg-100 {
    padding-top: 10rem !important; }
  .pt-lg-105 {
    padding-top: 10.5rem !important; }
  .pt-lg-110 {
    padding-top: 11rem !important; }
  .pt-lg-125 {
    padding-top: 12.5rem !important; }
  .pt-lg-130 {
    padding-top: 13rem !important; }
  .pt-lg-145 {
    padding-top: 14.5rem !important; }
  .pt-lg-170 {
    padding-top: 17rem !important; }
  .pt-lg-235 {
    padding-top: 23.5rem !important; }
  .pt-lg-n20 {
    padding-top: -2rem !important; }
  .pe-lg-35 {
    padding-right: 3.5rem !important; }
  .pe-lg-34 {
    padding-right: 3.4rem !important; }
  .pe-lg-33 {
    padding-right: 3.3rem !important; }
  .pe-lg-32 {
    padding-right: 3.2rem !important; }
  .pe-lg-31 {
    padding-right: 3.1rem !important; }
  .pe-lg-30 {
    padding-right: 3rem !important; }
  .pe-lg-29 {
    padding-right: 2.9rem !important; }
  .pe-lg-28 {
    padding-right: 2.8rem !important; }
  .pe-lg-27 {
    padding-right: 2.7rem !important; }
  .pe-lg-26 {
    padding-right: 2.6rem !important; }
  .pe-lg-25 {
    padding-right: 2.5rem !important; }
  .pe-lg-24 {
    padding-right: 2.4rem !important; }
  .pe-lg-23 {
    padding-right: 2.3rem !important; }
  .pe-lg-22 {
    padding-right: 2.2rem !important; }
  .pe-lg-21 {
    padding-right: 2.1rem !important; }
  .pe-lg-20 {
    padding-right: 2rem !important; }
  .pe-lg-19 {
    padding-right: 1.9rem !important; }
  .pe-lg-18 {
    padding-right: 1.8rem !important; }
  .pe-lg-17 {
    padding-right: 1.7rem !important; }
  .pe-lg-16 {
    padding-right: 1.6rem !important; }
  .pe-lg-15 {
    padding-right: 1.5rem !important; }
  .pe-lg-14 {
    padding-right: 1.4rem !important; }
  .pe-lg-13 {
    padding-right: 1.3rem !important; }
  .pe-lg-12 {
    padding-right: 1.2rem !important; }
  .pe-lg-11 {
    padding-right: 1.1rem !important; }
  .pe-lg-10 {
    padding-right: 1rem !important; }
  .pe-lg-0 {
    padding-right: 0 !important; }
  .pe-lg-1 {
    padding-right: 0.25rem !important; }
  .pe-lg-2 {
    padding-right: 0.5rem !important; }
  .pe-lg-3 {
    padding-right: 1rem !important; }
  .pe-lg-4 {
    padding-right: 1.5rem !important; }
  .pe-lg-5 {
    padding-right: 0.5rem !important; }
  .pe-lg-6 {
    padding-right: 0.6rem !important; }
  .pe-lg-8 {
    padding-right: 0.8rem !important; }
  .pe-lg-40 {
    padding-right: 4rem !important; }
  .pe-lg-45 {
    padding-right: 4.5rem !important; }
  .pe-lg-50 {
    padding-right: 5rem !important; }
  .pe-lg-55 {
    padding-right: 5.5rem !important; }
  .pe-lg-60 {
    padding-right: 6rem !important; }
  .pe-lg-70 {
    padding-right: 7rem !important; }
  .pe-lg-80 {
    padding-right: 8rem !important; }
  .pe-lg-90 {
    padding-right: 9rem !important; }
  .pe-lg-100 {
    padding-right: 10rem !important; }
  .pe-lg-105 {
    padding-right: 10.5rem !important; }
  .pe-lg-110 {
    padding-right: 11rem !important; }
  .pe-lg-125 {
    padding-right: 12.5rem !important; }
  .pe-lg-130 {
    padding-right: 13rem !important; }
  .pe-lg-145 {
    padding-right: 14.5rem !important; }
  .pe-lg-170 {
    padding-right: 17rem !important; }
  .pe-lg-235 {
    padding-right: 23.5rem !important; }
  .pe-lg-n20 {
    padding-right: -2rem !important; }
  .pb-lg-35 {
    padding-bottom: 3.5rem !important; }
  .pb-lg-34 {
    padding-bottom: 3.4rem !important; }
  .pb-lg-33 {
    padding-bottom: 3.3rem !important; }
  .pb-lg-32 {
    padding-bottom: 3.2rem !important; }
  .pb-lg-31 {
    padding-bottom: 3.1rem !important; }
  .pb-lg-30 {
    padding-bottom: 3rem !important; }
  .pb-lg-29 {
    padding-bottom: 2.9rem !important; }
  .pb-lg-28 {
    padding-bottom: 2.8rem !important; }
  .pb-lg-27 {
    padding-bottom: 2.7rem !important; }
  .pb-lg-26 {
    padding-bottom: 2.6rem !important; }
  .pb-lg-25 {
    padding-bottom: 2.5rem !important; }
  .pb-lg-24 {
    padding-bottom: 2.4rem !important; }
  .pb-lg-23 {
    padding-bottom: 2.3rem !important; }
  .pb-lg-22 {
    padding-bottom: 2.2rem !important; }
  .pb-lg-21 {
    padding-bottom: 2.1rem !important; }
  .pb-lg-20 {
    padding-bottom: 2rem !important; }
  .pb-lg-19 {
    padding-bottom: 1.9rem !important; }
  .pb-lg-18 {
    padding-bottom: 1.8rem !important; }
  .pb-lg-17 {
    padding-bottom: 1.7rem !important; }
  .pb-lg-16 {
    padding-bottom: 1.6rem !important; }
  .pb-lg-15 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-14 {
    padding-bottom: 1.4rem !important; }
  .pb-lg-13 {
    padding-bottom: 1.3rem !important; }
  .pb-lg-12 {
    padding-bottom: 1.2rem !important; }
  .pb-lg-11 {
    padding-bottom: 1.1rem !important; }
  .pb-lg-10 {
    padding-bottom: 1rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-5 {
    padding-bottom: 0.5rem !important; }
  .pb-lg-6 {
    padding-bottom: 0.6rem !important; }
  .pb-lg-8 {
    padding-bottom: 0.8rem !important; }
  .pb-lg-40 {
    padding-bottom: 4rem !important; }
  .pb-lg-45 {
    padding-bottom: 4.5rem !important; }
  .pb-lg-50 {
    padding-bottom: 5rem !important; }
  .pb-lg-55 {
    padding-bottom: 5.5rem !important; }
  .pb-lg-60 {
    padding-bottom: 6rem !important; }
  .pb-lg-70 {
    padding-bottom: 7rem !important; }
  .pb-lg-80 {
    padding-bottom: 8rem !important; }
  .pb-lg-90 {
    padding-bottom: 9rem !important; }
  .pb-lg-100 {
    padding-bottom: 10rem !important; }
  .pb-lg-105 {
    padding-bottom: 10.5rem !important; }
  .pb-lg-110 {
    padding-bottom: 11rem !important; }
  .pb-lg-125 {
    padding-bottom: 12.5rem !important; }
  .pb-lg-130 {
    padding-bottom: 13rem !important; }
  .pb-lg-145 {
    padding-bottom: 14.5rem !important; }
  .pb-lg-170 {
    padding-bottom: 17rem !important; }
  .pb-lg-235 {
    padding-bottom: 23.5rem !important; }
  .pb-lg-n20 {
    padding-bottom: -2rem !important; }
  .ps-lg-35 {
    padding-left: 3.5rem !important; }
  .ps-lg-34 {
    padding-left: 3.4rem !important; }
  .ps-lg-33 {
    padding-left: 3.3rem !important; }
  .ps-lg-32 {
    padding-left: 3.2rem !important; }
  .ps-lg-31 {
    padding-left: 3.1rem !important; }
  .ps-lg-30 {
    padding-left: 3rem !important; }
  .ps-lg-29 {
    padding-left: 2.9rem !important; }
  .ps-lg-28 {
    padding-left: 2.8rem !important; }
  .ps-lg-27 {
    padding-left: 2.7rem !important; }
  .ps-lg-26 {
    padding-left: 2.6rem !important; }
  .ps-lg-25 {
    padding-left: 2.5rem !important; }
  .ps-lg-24 {
    padding-left: 2.4rem !important; }
  .ps-lg-23 {
    padding-left: 2.3rem !important; }
  .ps-lg-22 {
    padding-left: 2.2rem !important; }
  .ps-lg-21 {
    padding-left: 2.1rem !important; }
  .ps-lg-20 {
    padding-left: 2rem !important; }
  .ps-lg-19 {
    padding-left: 1.9rem !important; }
  .ps-lg-18 {
    padding-left: 1.8rem !important; }
  .ps-lg-17 {
    padding-left: 1.7rem !important; }
  .ps-lg-16 {
    padding-left: 1.6rem !important; }
  .ps-lg-15 {
    padding-left: 1.5rem !important; }
  .ps-lg-14 {
    padding-left: 1.4rem !important; }
  .ps-lg-13 {
    padding-left: 1.3rem !important; }
  .ps-lg-12 {
    padding-left: 1.2rem !important; }
  .ps-lg-11 {
    padding-left: 1.1rem !important; }
  .ps-lg-10 {
    padding-left: 1rem !important; }
  .ps-lg-0 {
    padding-left: 0 !important; }
  .ps-lg-1 {
    padding-left: 0.25rem !important; }
  .ps-lg-2 {
    padding-left: 0.5rem !important; }
  .ps-lg-3 {
    padding-left: 1rem !important; }
  .ps-lg-4 {
    padding-left: 1.5rem !important; }
  .ps-lg-5 {
    padding-left: 0.5rem !important; }
  .ps-lg-6 {
    padding-left: 0.6rem !important; }
  .ps-lg-8 {
    padding-left: 0.8rem !important; }
  .ps-lg-40 {
    padding-left: 4rem !important; }
  .ps-lg-45 {
    padding-left: 4.5rem !important; }
  .ps-lg-50 {
    padding-left: 5rem !important; }
  .ps-lg-55 {
    padding-left: 5.5rem !important; }
  .ps-lg-60 {
    padding-left: 6rem !important; }
  .ps-lg-70 {
    padding-left: 7rem !important; }
  .ps-lg-80 {
    padding-left: 8rem !important; }
  .ps-lg-90 {
    padding-left: 9rem !important; }
  .ps-lg-100 {
    padding-left: 10rem !important; }
  .ps-lg-105 {
    padding-left: 10.5rem !important; }
  .ps-lg-110 {
    padding-left: 11rem !important; }
  .ps-lg-125 {
    padding-left: 12.5rem !important; }
  .ps-lg-130 {
    padding-left: 13rem !important; }
  .ps-lg-145 {
    padding-left: 14.5rem !important; }
  .ps-lg-170 {
    padding-left: 17rem !important; }
  .ps-lg-235 {
    padding-left: 23.5rem !important; }
  .ps-lg-n20 {
    padding-left: -2rem !important; }
  .font-lg-family-02 {
    font-family: "Caveat", cursive !important; }
  .fs-lg-40 {
    font-size: 4rem !important; }
  .fs-lg-39 {
    font-size: 3.9rem !important; }
  .fs-lg-38 {
    font-size: 3.8rem !important; }
  .fs-lg-37 {
    font-size: 3.7rem !important; }
  .fs-lg-36 {
    font-size: 3.6rem !important; }
  .fs-lg-35 {
    font-size: 3.5rem !important; }
  .fs-lg-34 {
    font-size: 3.4rem !important; }
  .fs-lg-33 {
    font-size: 3.3rem !important; }
  .fs-lg-32 {
    font-size: 3.2rem !important; }
  .fs-lg-31 {
    font-size: 3.1rem !important; }
  .fs-lg-30 {
    font-size: 3rem !important; }
  .fs-lg-29 {
    font-size: 2.9rem !important; }
  .fs-lg-28 {
    font-size: 2.8rem !important; }
  .fs-lg-27 {
    font-size: 2.7rem !important; }
  .fs-lg-26 {
    font-size: 2.6rem !important; }
  .fs-lg-25 {
    font-size: 2.5rem !important; }
  .fs-lg-24 {
    font-size: 2.4rem !important; }
  .fs-lg-23 {
    font-size: 2.3rem !important; }
  .fs-lg-22 {
    font-size: 2.2rem !important; }
  .fs-lg-21 {
    font-size: 2.1rem !important; }
  .fs-lg-20 {
    font-size: 2rem !important; }
  .fs-lg-19 {
    font-size: 1.9rem !important; }
  .fs-lg-18 {
    font-size: 1.8rem !important; }
  .fs-lg-17 {
    font-size: 1.7rem !important; }
  .fs-lg-16 {
    font-size: 1.6rem !important; }
  .fs-lg-15 {
    font-size: 1.5rem !important; }
  .fs-lg-14 {
    font-size: 1.4rem !important; }
  .fs-lg-13 {
    font-size: 1.3rem !important; }
  .fs-lg-12 {
    font-size: 1.2rem !important; }
  .fs-lg-11 {
    font-size: 1.1rem !important; }
  .fs-lg-10 {
    font-size: 1rem !important; }
  .fs-lg-60 {
    font-size: calc(1.725rem + 5.7vw) !important; }
  .text-lg-start {
    text-align: left !important; }
  .text-lg-end {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; }
  .rounded-lg-20 {
    border-radius: 2rem !important; }
  .rounded-lg-19 {
    border-radius: 1.9rem !important; }
  .rounded-lg-18 {
    border-radius: 1.8rem !important; }
  .rounded-lg-17 {
    border-radius: 1.7rem !important; }
  .rounded-lg-16 {
    border-radius: 1.6rem !important; }
  .rounded-lg-15 {
    border-radius: 1.5rem !important; }
  .rounded-lg-14 {
    border-radius: 1.4rem !important; }
  .rounded-lg-13 {
    border-radius: 1.3rem !important; }
  .rounded-lg-12 {
    border-radius: 1.2rem !important; }
  .rounded-lg-11 {
    border-radius: 1.1rem !important; }
  .rounded-lg-10 {
    border-radius: 1rem !important; }
  .rounded-lg-9 {
    border-radius: 0.9rem !important; }
  .rounded-lg-8 {
    border-radius: 0.8rem !important; }
  .rounded-lg-7 {
    border-radius: 0.7rem !important; }
  .rounded-lg-6 {
    border-radius: 0.6rem !important; }
  .rounded-lg-5 {
    border-radius: 0.5rem !important; }
  .rounded-lg {
    border-radius: 2px 5px 2px 5px !important; }
  .rounded-lg-0 {
    border-radius: 0 !important; }
  .rounded-lg-1 {
    border-radius: 0.6rem !important; }
  .rounded-lg-2 {
    border-radius: 2px 5px 2px 5px !important; }
  .rounded-lg-3 {
    border-radius: 5px 20px 5px 20px !important; }
  .rounded-lg-4 {
    border-radius: 5px 100px 5px 100px !important; }
  .rounded-lg-circle {
    border-radius: 50% !important; }
  .rounded-lg-pill {
    border-radius: 50rem !important; }
  .rounded-lg-btn {
    border-radius: 5px 30px 5px 5px !important; }
  .rounded-lg-carte {
    border-radius: 10px 25px 10px 25px !important; }
  .rounded-top-lg-20 {
    border-top-left-radius: 2rem !important;
    border-top-right-radius: 2rem !important; }
  .rounded-top-lg-19 {
    border-top-left-radius: 1.9rem !important;
    border-top-right-radius: 1.9rem !important; }
  .rounded-top-lg-18 {
    border-top-left-radius: 1.8rem !important;
    border-top-right-radius: 1.8rem !important; }
  .rounded-top-lg-17 {
    border-top-left-radius: 1.7rem !important;
    border-top-right-radius: 1.7rem !important; }
  .rounded-top-lg-16 {
    border-top-left-radius: 1.6rem !important;
    border-top-right-radius: 1.6rem !important; }
  .rounded-top-lg-15 {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important; }
  .rounded-top-lg-14 {
    border-top-left-radius: 1.4rem !important;
    border-top-right-radius: 1.4rem !important; }
  .rounded-top-lg-13 {
    border-top-left-radius: 1.3rem !important;
    border-top-right-radius: 1.3rem !important; }
  .rounded-top-lg-12 {
    border-top-left-radius: 1.2rem !important;
    border-top-right-radius: 1.2rem !important; }
  .rounded-top-lg-11 {
    border-top-left-radius: 1.1rem !important;
    border-top-right-radius: 1.1rem !important; }
  .rounded-top-lg-10 {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important; }
  .rounded-top-lg-9 {
    border-top-left-radius: 0.9rem !important;
    border-top-right-radius: 0.9rem !important; }
  .rounded-top-lg-8 {
    border-top-left-radius: 0.8rem !important;
    border-top-right-radius: 0.8rem !important; }
  .rounded-top-lg-7 {
    border-top-left-radius: 0.7rem !important;
    border-top-right-radius: 0.7rem !important; }
  .rounded-top-lg-6 {
    border-top-left-radius: 0.6rem !important;
    border-top-right-radius: 0.6rem !important; }
  .rounded-top-lg-5 {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important; }
  .rounded-top-lg {
    border-top-left-radius: 2px 5px 2px 5px !important;
    border-top-right-radius: 2px 5px 2px 5px !important; }
  .rounded-top-lg-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important; }
  .rounded-top-lg-1 {
    border-top-left-radius: 0.6rem !important;
    border-top-right-radius: 0.6rem !important; }
  .rounded-top-lg-2 {
    border-top-left-radius: 2px 5px 2px 5px !important;
    border-top-right-radius: 2px 5px 2px 5px !important; }
  .rounded-top-lg-3 {
    border-top-left-radius: 5px 20px 5px 20px !important;
    border-top-right-radius: 5px 20px 5px 20px !important; }
  .rounded-top-lg-4 {
    border-top-left-radius: 5px 100px 5px 100px !important;
    border-top-right-radius: 5px 100px 5px 100px !important; }
  .rounded-top-lg-circle {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important; }
  .rounded-top-lg-pill {
    border-top-left-radius: 50rem !important;
    border-top-right-radius: 50rem !important; }
  .rounded-top-lg-btn {
    border-top-left-radius: 5px 30px 5px 5px !important;
    border-top-right-radius: 5px 30px 5px 5px !important; }
  .rounded-top-lg-carte {
    border-top-left-radius: 10px 25px 10px 25px !important;
    border-top-right-radius: 10px 25px 10px 25px !important; }
  .rounded-end-lg-20 {
    border-top-right-radius: 2rem !important;
    border-bottom-right-radius: 2rem !important; }
  .rounded-end-lg-19 {
    border-top-right-radius: 1.9rem !important;
    border-bottom-right-radius: 1.9rem !important; }
  .rounded-end-lg-18 {
    border-top-right-radius: 1.8rem !important;
    border-bottom-right-radius: 1.8rem !important; }
  .rounded-end-lg-17 {
    border-top-right-radius: 1.7rem !important;
    border-bottom-right-radius: 1.7rem !important; }
  .rounded-end-lg-16 {
    border-top-right-radius: 1.6rem !important;
    border-bottom-right-radius: 1.6rem !important; }
  .rounded-end-lg-15 {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important; }
  .rounded-end-lg-14 {
    border-top-right-radius: 1.4rem !important;
    border-bottom-right-radius: 1.4rem !important; }
  .rounded-end-lg-13 {
    border-top-right-radius: 1.3rem !important;
    border-bottom-right-radius: 1.3rem !important; }
  .rounded-end-lg-12 {
    border-top-right-radius: 1.2rem !important;
    border-bottom-right-radius: 1.2rem !important; }
  .rounded-end-lg-11 {
    border-top-right-radius: 1.1rem !important;
    border-bottom-right-radius: 1.1rem !important; }
  .rounded-end-lg-10 {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important; }
  .rounded-end-lg-9 {
    border-top-right-radius: 0.9rem !important;
    border-bottom-right-radius: 0.9rem !important; }
  .rounded-end-lg-8 {
    border-top-right-radius: 0.8rem !important;
    border-bottom-right-radius: 0.8rem !important; }
  .rounded-end-lg-7 {
    border-top-right-radius: 0.7rem !important;
    border-bottom-right-radius: 0.7rem !important; }
  .rounded-end-lg-6 {
    border-top-right-radius: 0.6rem !important;
    border-bottom-right-radius: 0.6rem !important; }
  .rounded-end-lg-5 {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important; }
  .rounded-end-lg {
    border-top-right-radius: 2px 5px 2px 5px !important;
    border-bottom-right-radius: 2px 5px 2px 5px !important; }
  .rounded-end-lg-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important; }
  .rounded-end-lg-1 {
    border-top-right-radius: 0.6rem !important;
    border-bottom-right-radius: 0.6rem !important; }
  .rounded-end-lg-2 {
    border-top-right-radius: 2px 5px 2px 5px !important;
    border-bottom-right-radius: 2px 5px 2px 5px !important; }
  .rounded-end-lg-3 {
    border-top-right-radius: 5px 20px 5px 20px !important;
    border-bottom-right-radius: 5px 20px 5px 20px !important; }
  .rounded-end-lg-4 {
    border-top-right-radius: 5px 100px 5px 100px !important;
    border-bottom-right-radius: 5px 100px 5px 100px !important; }
  .rounded-end-lg-circle {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important; }
  .rounded-end-lg-pill {
    border-top-right-radius: 50rem !important;
    border-bottom-right-radius: 50rem !important; }
  .rounded-end-lg-btn {
    border-top-right-radius: 5px 30px 5px 5px !important;
    border-bottom-right-radius: 5px 30px 5px 5px !important; }
  .rounded-end-lg-carte {
    border-top-right-radius: 10px 25px 10px 25px !important;
    border-bottom-right-radius: 10px 25px 10px 25px !important; }
  .rounded-bottom-lg-20 {
    border-bottom-right-radius: 2rem !important;
    border-bottom-left-radius: 2rem !important; }
  .rounded-bottom-lg-19 {
    border-bottom-right-radius: 1.9rem !important;
    border-bottom-left-radius: 1.9rem !important; }
  .rounded-bottom-lg-18 {
    border-bottom-right-radius: 1.8rem !important;
    border-bottom-left-radius: 1.8rem !important; }
  .rounded-bottom-lg-17 {
    border-bottom-right-radius: 1.7rem !important;
    border-bottom-left-radius: 1.7rem !important; }
  .rounded-bottom-lg-16 {
    border-bottom-right-radius: 1.6rem !important;
    border-bottom-left-radius: 1.6rem !important; }
  .rounded-bottom-lg-15 {
    border-bottom-right-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important; }
  .rounded-bottom-lg-14 {
    border-bottom-right-radius: 1.4rem !important;
    border-bottom-left-radius: 1.4rem !important; }
  .rounded-bottom-lg-13 {
    border-bottom-right-radius: 1.3rem !important;
    border-bottom-left-radius: 1.3rem !important; }
  .rounded-bottom-lg-12 {
    border-bottom-right-radius: 1.2rem !important;
    border-bottom-left-radius: 1.2rem !important; }
  .rounded-bottom-lg-11 {
    border-bottom-right-radius: 1.1rem !important;
    border-bottom-left-radius: 1.1rem !important; }
  .rounded-bottom-lg-10 {
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important; }
  .rounded-bottom-lg-9 {
    border-bottom-right-radius: 0.9rem !important;
    border-bottom-left-radius: 0.9rem !important; }
  .rounded-bottom-lg-8 {
    border-bottom-right-radius: 0.8rem !important;
    border-bottom-left-radius: 0.8rem !important; }
  .rounded-bottom-lg-7 {
    border-bottom-right-radius: 0.7rem !important;
    border-bottom-left-radius: 0.7rem !important; }
  .rounded-bottom-lg-6 {
    border-bottom-right-radius: 0.6rem !important;
    border-bottom-left-radius: 0.6rem !important; }
  .rounded-bottom-lg-5 {
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important; }
  .rounded-bottom-lg {
    border-bottom-right-radius: 2px 5px 2px 5px !important;
    border-bottom-left-radius: 2px 5px 2px 5px !important; }
  .rounded-bottom-lg-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important; }
  .rounded-bottom-lg-1 {
    border-bottom-right-radius: 0.6rem !important;
    border-bottom-left-radius: 0.6rem !important; }
  .rounded-bottom-lg-2 {
    border-bottom-right-radius: 2px 5px 2px 5px !important;
    border-bottom-left-radius: 2px 5px 2px 5px !important; }
  .rounded-bottom-lg-3 {
    border-bottom-right-radius: 5px 20px 5px 20px !important;
    border-bottom-left-radius: 5px 20px 5px 20px !important; }
  .rounded-bottom-lg-4 {
    border-bottom-right-radius: 5px 100px 5px 100px !important;
    border-bottom-left-radius: 5px 100px 5px 100px !important; }
  .rounded-bottom-lg-circle {
    border-bottom-right-radius: 50% !important;
    border-bottom-left-radius: 50% !important; }
  .rounded-bottom-lg-pill {
    border-bottom-right-radius: 50rem !important;
    border-bottom-left-radius: 50rem !important; }
  .rounded-bottom-lg-btn {
    border-bottom-right-radius: 5px 30px 5px 5px !important;
    border-bottom-left-radius: 5px 30px 5px 5px !important; }
  .rounded-bottom-lg-carte {
    border-bottom-right-radius: 10px 25px 10px 25px !important;
    border-bottom-left-radius: 10px 25px 10px 25px !important; }
  .rounded-start-lg-20 {
    border-bottom-left-radius: 2rem !important;
    border-top-left-radius: 2rem !important; }
  .rounded-start-lg-19 {
    border-bottom-left-radius: 1.9rem !important;
    border-top-left-radius: 1.9rem !important; }
  .rounded-start-lg-18 {
    border-bottom-left-radius: 1.8rem !important;
    border-top-left-radius: 1.8rem !important; }
  .rounded-start-lg-17 {
    border-bottom-left-radius: 1.7rem !important;
    border-top-left-radius: 1.7rem !important; }
  .rounded-start-lg-16 {
    border-bottom-left-radius: 1.6rem !important;
    border-top-left-radius: 1.6rem !important; }
  .rounded-start-lg-15 {
    border-bottom-left-radius: 1.5rem !important;
    border-top-left-radius: 1.5rem !important; }
  .rounded-start-lg-14 {
    border-bottom-left-radius: 1.4rem !important;
    border-top-left-radius: 1.4rem !important; }
  .rounded-start-lg-13 {
    border-bottom-left-radius: 1.3rem !important;
    border-top-left-radius: 1.3rem !important; }
  .rounded-start-lg-12 {
    border-bottom-left-radius: 1.2rem !important;
    border-top-left-radius: 1.2rem !important; }
  .rounded-start-lg-11 {
    border-bottom-left-radius: 1.1rem !important;
    border-top-left-radius: 1.1rem !important; }
  .rounded-start-lg-10 {
    border-bottom-left-radius: 1rem !important;
    border-top-left-radius: 1rem !important; }
  .rounded-start-lg-9 {
    border-bottom-left-radius: 0.9rem !important;
    border-top-left-radius: 0.9rem !important; }
  .rounded-start-lg-8 {
    border-bottom-left-radius: 0.8rem !important;
    border-top-left-radius: 0.8rem !important; }
  .rounded-start-lg-7 {
    border-bottom-left-radius: 0.7rem !important;
    border-top-left-radius: 0.7rem !important; }
  .rounded-start-lg-6 {
    border-bottom-left-radius: 0.6rem !important;
    border-top-left-radius: 0.6rem !important; }
  .rounded-start-lg-5 {
    border-bottom-left-radius: 0.5rem !important;
    border-top-left-radius: 0.5rem !important; }
  .rounded-start-lg {
    border-bottom-left-radius: 2px 5px 2px 5px !important;
    border-top-left-radius: 2px 5px 2px 5px !important; }
  .rounded-start-lg-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important; }
  .rounded-start-lg-1 {
    border-bottom-left-radius: 0.6rem !important;
    border-top-left-radius: 0.6rem !important; }
  .rounded-start-lg-2 {
    border-bottom-left-radius: 2px 5px 2px 5px !important;
    border-top-left-radius: 2px 5px 2px 5px !important; }
  .rounded-start-lg-3 {
    border-bottom-left-radius: 5px 20px 5px 20px !important;
    border-top-left-radius: 5px 20px 5px 20px !important; }
  .rounded-start-lg-4 {
    border-bottom-left-radius: 5px 100px 5px 100px !important;
    border-top-left-radius: 5px 100px 5px 100px !important; }
  .rounded-start-lg-circle {
    border-bottom-left-radius: 50% !important;
    border-top-left-radius: 50% !important; }
  .rounded-start-lg-pill {
    border-bottom-left-radius: 50rem !important;
    border-top-left-radius: 50rem !important; }
  .rounded-start-lg-btn {
    border-bottom-left-radius: 5px 30px 5px 5px !important;
    border-top-left-radius: 5px 30px 5px 5px !important; }
  .rounded-start-lg-carte {
    border-bottom-left-radius: 10px 25px 10px 25px !important;
    border-top-left-radius: 10px 25px 10px 25px !important; }
  .z-index-lg-1 {
    z-index: 1 !important; }
  .z-index-lg-2 {
    z-index: 2 !important; }
  .z-index-lg-3 {
    z-index: 3 !important; }
  .z-index-lg-4 {
    z-index: 4 !important; }
  .z-index-lg-5 {
    z-index: 5 !important; } }

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important; }
  .float-xl-end {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-grid {
    display: grid !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .position-xl-static {
    position: static !important; }
  .position-xl-relative {
    position: relative !important; }
  .position-xl-absolute {
    position: absolute !important; }
  .position-xl-fixed {
    position: fixed !important; }
  .position-xl-sticky {
    position: sticky !important; }
  .top-xl-0 {
    top: 0 !important; }
  .top-xl-5 {
    top: 5% !important; }
  .top-xl-10 {
    top: 10% !important; }
  .top-xl-15 {
    top: 15% !important; }
  .top-xl-20 {
    top: 20% !important; }
  .top-xl-25 {
    top: 25% !important; }
  .top-xl-30 {
    top: 30% !important; }
  .top-xl-40 {
    top: 40% !important; }
  .top-xl-50 {
    top: 50% !important; }
  .top-xl-80 {
    top: 80% !important; }
  .top-xl-90 {
    top: 90% !important; }
  .top-xl-100 {
    top: 100% !important; }
  .top-xl-2px {
    top: 2px !important; }
  .top-xl-100px {
    top: 100px !important; }
  .top-xl-n30px {
    top: -30px !important; }
  .top-xl-n60px {
    top: -60px !important; }
  .top-xl-n130px {
    top: -130px !important; }
  .top-xl-n150px {
    top: -150px !important; }
  .top-xl-n170px {
    top: -170px !important; }
  .top-xl-n300px {
    top: -300px !important; }
  .bottom-xl-0 {
    bottom: 0 !important; }
  .bottom-xl-5 {
    bottom: 5% !important; }
  .bottom-xl-10 {
    bottom: 10% !important; }
  .bottom-xl-15 {
    bottom: 15% !important; }
  .bottom-xl-20 {
    bottom: 20% !important; }
  .bottom-xl-25 {
    bottom: 25% !important; }
  .bottom-xl-30 {
    bottom: 30% !important; }
  .bottom-xl-40 {
    bottom: 40% !important; }
  .bottom-xl-50 {
    bottom: 50% !important; }
  .bottom-xl-80 {
    bottom: 80% !important; }
  .bottom-xl-90 {
    bottom: 90% !important; }
  .bottom-xl-100 {
    bottom: 100% !important; }
  .bottom-xl-2px {
    bottom: 2px !important; }
  .bottom-xl-100px {
    bottom: 100px !important; }
  .bottom-xl-n30px {
    bottom: -30px !important; }
  .bottom-xl-n60px {
    bottom: -60px !important; }
  .bottom-xl-n130px {
    bottom: -130px !important; }
  .bottom-xl-n150px {
    bottom: -150px !important; }
  .bottom-xl-n170px {
    bottom: -170px !important; }
  .bottom-xl-n300px {
    bottom: -300px !important; }
  .start-xl-0 {
    left: 0 !important; }
  .start-xl-5 {
    left: 5% !important; }
  .start-xl-10 {
    left: 10% !important; }
  .start-xl-15 {
    left: 15% !important; }
  .start-xl-20 {
    left: 20% !important; }
  .start-xl-25 {
    left: 25% !important; }
  .start-xl-30 {
    left: 30% !important; }
  .start-xl-40 {
    left: 40% !important; }
  .start-xl-50 {
    left: 50% !important; }
  .start-xl-80 {
    left: 80% !important; }
  .start-xl-90 {
    left: 90% !important; }
  .start-xl-100 {
    left: 100% !important; }
  .start-xl-2px {
    left: 2px !important; }
  .start-xl-100px {
    left: 100px !important; }
  .start-xl-n30px {
    left: -30px !important; }
  .start-xl-n60px {
    left: -60px !important; }
  .start-xl-n130px {
    left: -130px !important; }
  .start-xl-n150px {
    left: -150px !important; }
  .start-xl-n170px {
    left: -170px !important; }
  .start-xl-n300px {
    left: -300px !important; }
  .end-xl-0 {
    right: 0 !important; }
  .end-xl-5 {
    right: 5% !important; }
  .end-xl-10 {
    right: 10% !important; }
  .end-xl-15 {
    right: 15% !important; }
  .end-xl-20 {
    right: 20% !important; }
  .end-xl-25 {
    right: 25% !important; }
  .end-xl-30 {
    right: 30% !important; }
  .end-xl-40 {
    right: 40% !important; }
  .end-xl-50 {
    right: 50% !important; }
  .end-xl-80 {
    right: 80% !important; }
  .end-xl-90 {
    right: 90% !important; }
  .end-xl-100 {
    right: 100% !important; }
  .end-xl-2px {
    right: 2px !important; }
  .end-xl-100px {
    right: 100px !important; }
  .end-xl-n30px {
    right: -30px !important; }
  .end-xl-n60px {
    right: -60px !important; }
  .end-xl-n130px {
    right: -130px !important; }
  .end-xl-n150px {
    right: -150px !important; }
  .end-xl-n170px {
    right: -170px !important; }
  .end-xl-n300px {
    right: -300px !important; }
  .w-xl-20 {
    width: 20% !important; }
  .w-xl-25 {
    width: 25% !important; }
  .w-xl-30 {
    width: 30% !important; }
  .w-xl-40 {
    width: 40% !important; }
  .w-xl-50 {
    width: 50% !important; }
  .w-xl-70 {
    width: 70% !important; }
  .w-xl-75 {
    width: 75% !important; }
  .w-xl-80 {
    width: 80% !important; }
  .w-xl-85 {
    width: 85% !important; }
  .w-xl-90 {
    width: 90% !important; }
  .w-xl-100 {
    width: 100% !important; }
  .w-xl-26px {
    width: 26px !important; }
  .w-xl-86px {
    width: 86px !important; }
  .w-xl-125px {
    width: 125px !important; }
  .w-xl-145px {
    width: 145px !important; }
  .w-xl-161px {
    width: 161px !important; }
  .w-xl-182px {
    width: 182px !important; }
  .w-xl-205px {
    width: 205px !important; }
  .w-xl-215px {
    width: 215px !important; }
  .w-xl-235px {
    width: 235px !important; }
  .w-xl-250px {
    width: 250px !important; }
  .w-xl-260px {
    width: 260px !important; }
  .w-xl-268px {
    width: 268px !important; }
  .w-xl-283px {
    width: 283px !important; }
  .w-xl-285px {
    width: 285px !important; }
  .w-xl-300px {
    width: 300px !important; }
  .w-xl-305px {
    width: 305px !important; }
  .w-xl-310px {
    width: 310px !important; }
  .w-xl-350px {
    width: 350px !important; }
  .w-xl-410px {
    width: 410px !important; }
  .w-xl-440px {
    width: 440px !important; }
  .w-xl-460px {
    width: 460px !important; }
  .w-xl-500px {
    width: 500px !important; }
  .w-xl-520px {
    width: 520px !important; }
  .w-xl-600px {
    width: 600px !important; }
  .w-xl-657px {
    width: 657px !important; }
  .w-xl-1015px {
    width: 1015px !important; }
  .w-xl-1070px {
    width: 1070px !important; }
  .w-xl-auto {
    width: auto !important; }
  .h-xl-20 {
    height: 20% !important; }
  .h-xl-25 {
    height: 25% !important; }
  .h-xl-30 {
    height: 30% !important; }
  .h-xl-40 {
    height: 40% !important; }
  .h-xl-50 {
    height: 50% !important; }
  .h-xl-70 {
    height: 70% !important; }
  .h-xl-75 {
    height: 75% !important; }
  .h-xl-80 {
    height: 80% !important; }
  .h-xl-85 {
    height: 85% !important; }
  .h-xl-90 {
    height: 90% !important; }
  .h-xl-100 {
    height: 100% !important; }
  .h-xl-26px {
    height: 26px !important; }
  .h-xl-86px {
    height: 86px !important; }
  .h-xl-125px {
    height: 125px !important; }
  .h-xl-145px {
    height: 145px !important; }
  .h-xl-161px {
    height: 161px !important; }
  .h-xl-182px {
    height: 182px !important; }
  .h-xl-205px {
    height: 205px !important; }
  .h-xl-215px {
    height: 215px !important; }
  .h-xl-235px {
    height: 235px !important; }
  .h-xl-250px {
    height: 250px !important; }
  .h-xl-260px {
    height: 260px !important; }
  .h-xl-268px {
    height: 268px !important; }
  .h-xl-283px {
    height: 283px !important; }
  .h-xl-285px {
    height: 285px !important; }
  .h-xl-300px {
    height: 300px !important; }
  .h-xl-305px {
    height: 305px !important; }
  .h-xl-310px {
    height: 310px !important; }
  .h-xl-350px {
    height: 350px !important; }
  .h-xl-410px {
    height: 410px !important; }
  .h-xl-440px {
    height: 440px !important; }
  .h-xl-460px {
    height: 460px !important; }
  .h-xl-500px {
    height: 500px !important; }
  .h-xl-520px {
    height: 520px !important; }
  .h-xl-600px {
    height: 600px !important; }
  .h-xl-657px {
    height: 657px !important; }
  .h-xl-1015px {
    height: 1015px !important; }
  .h-xl-1070px {
    height: 1070px !important; }
  .h-xl-auto {
    height: auto !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-xl-35 {
    gap: 3.5rem !important; }
  .gap-xl-34 {
    gap: 3.4rem !important; }
  .gap-xl-33 {
    gap: 3.3rem !important; }
  .gap-xl-32 {
    gap: 3.2rem !important; }
  .gap-xl-31 {
    gap: 3.1rem !important; }
  .gap-xl-30 {
    gap: 3rem !important; }
  .gap-xl-29 {
    gap: 2.9rem !important; }
  .gap-xl-28 {
    gap: 2.8rem !important; }
  .gap-xl-27 {
    gap: 2.7rem !important; }
  .gap-xl-26 {
    gap: 2.6rem !important; }
  .gap-xl-25 {
    gap: 2.5rem !important; }
  .gap-xl-24 {
    gap: 2.4rem !important; }
  .gap-xl-23 {
    gap: 2.3rem !important; }
  .gap-xl-22 {
    gap: 2.2rem !important; }
  .gap-xl-21 {
    gap: 2.1rem !important; }
  .gap-xl-20 {
    gap: 2rem !important; }
  .gap-xl-19 {
    gap: 1.9rem !important; }
  .gap-xl-18 {
    gap: 1.8rem !important; }
  .gap-xl-17 {
    gap: 1.7rem !important; }
  .gap-xl-16 {
    gap: 1.6rem !important; }
  .gap-xl-15 {
    gap: 1.5rem !important; }
  .gap-xl-14 {
    gap: 1.4rem !important; }
  .gap-xl-13 {
    gap: 1.3rem !important; }
  .gap-xl-12 {
    gap: 1.2rem !important; }
  .gap-xl-11 {
    gap: 1.1rem !important; }
  .gap-xl-10 {
    gap: 1rem !important; }
  .gap-xl-0 {
    gap: 0 !important; }
  .gap-xl-1 {
    gap: 0.25rem !important; }
  .gap-xl-2 {
    gap: 0.5rem !important; }
  .gap-xl-3 {
    gap: 1rem !important; }
  .gap-xl-4 {
    gap: 1.5rem !important; }
  .gap-xl-5 {
    gap: 0.5rem !important; }
  .gap-xl-6 {
    gap: 0.6rem !important; }
  .gap-xl-8 {
    gap: 0.8rem !important; }
  .gap-xl-40 {
    gap: 4rem !important; }
  .gap-xl-45 {
    gap: 4.5rem !important; }
  .gap-xl-50 {
    gap: 5rem !important; }
  .gap-xl-55 {
    gap: 5.5rem !important; }
  .gap-xl-60 {
    gap: 6rem !important; }
  .gap-xl-70 {
    gap: 7rem !important; }
  .gap-xl-80 {
    gap: 8rem !important; }
  .gap-xl-90 {
    gap: 9rem !important; }
  .gap-xl-100 {
    gap: 10rem !important; }
  .gap-xl-105 {
    gap: 10.5rem !important; }
  .gap-xl-110 {
    gap: 11rem !important; }
  .gap-xl-125 {
    gap: 12.5rem !important; }
  .gap-xl-130 {
    gap: 13rem !important; }
  .gap-xl-145 {
    gap: 14.5rem !important; }
  .gap-xl-170 {
    gap: 17rem !important; }
  .gap-xl-235 {
    gap: 23.5rem !important; }
  .gap-xl-n20 {
    gap: -2rem !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-35 {
    margin: 3.5rem !important; }
  .m-xl-34 {
    margin: 3.4rem !important; }
  .m-xl-33 {
    margin: 3.3rem !important; }
  .m-xl-32 {
    margin: 3.2rem !important; }
  .m-xl-31 {
    margin: 3.1rem !important; }
  .m-xl-30 {
    margin: 3rem !important; }
  .m-xl-29 {
    margin: 2.9rem !important; }
  .m-xl-28 {
    margin: 2.8rem !important; }
  .m-xl-27 {
    margin: 2.7rem !important; }
  .m-xl-26 {
    margin: 2.6rem !important; }
  .m-xl-25 {
    margin: 2.5rem !important; }
  .m-xl-24 {
    margin: 2.4rem !important; }
  .m-xl-23 {
    margin: 2.3rem !important; }
  .m-xl-22 {
    margin: 2.2rem !important; }
  .m-xl-21 {
    margin: 2.1rem !important; }
  .m-xl-20 {
    margin: 2rem !important; }
  .m-xl-19 {
    margin: 1.9rem !important; }
  .m-xl-18 {
    margin: 1.8rem !important; }
  .m-xl-17 {
    margin: 1.7rem !important; }
  .m-xl-16 {
    margin: 1.6rem !important; }
  .m-xl-15 {
    margin: 1.5rem !important; }
  .m-xl-14 {
    margin: 1.4rem !important; }
  .m-xl-13 {
    margin: 1.3rem !important; }
  .m-xl-12 {
    margin: 1.2rem !important; }
  .m-xl-11 {
    margin: 1.1rem !important; }
  .m-xl-10 {
    margin: 1rem !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .m-xl-5 {
    margin: 0.5rem !important; }
  .m-xl-6 {
    margin: 0.6rem !important; }
  .m-xl-8 {
    margin: 0.8rem !important; }
  .m-xl-40 {
    margin: 4rem !important; }
  .m-xl-45 {
    margin: 4.5rem !important; }
  .m-xl-50 {
    margin: 5rem !important; }
  .m-xl-55 {
    margin: 5.5rem !important; }
  .m-xl-60 {
    margin: 6rem !important; }
  .m-xl-70 {
    margin: 7rem !important; }
  .m-xl-80 {
    margin: 8rem !important; }
  .m-xl-90 {
    margin: 9rem !important; }
  .m-xl-100 {
    margin: 10rem !important; }
  .m-xl-105 {
    margin: 10.5rem !important; }
  .m-xl-110 {
    margin: 11rem !important; }
  .m-xl-125 {
    margin: 12.5rem !important; }
  .m-xl-130 {
    margin: 13rem !important; }
  .m-xl-145 {
    margin: 14.5rem !important; }
  .m-xl-170 {
    margin: 17rem !important; }
  .m-xl-235 {
    margin: 23.5rem !important; }
  .m-xl-n20 {
    margin: -2rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-35 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important; }
  .mx-xl-34 {
    margin-right: 3.4rem !important;
    margin-left: 3.4rem !important; }
  .mx-xl-33 {
    margin-right: 3.3rem !important;
    margin-left: 3.3rem !important; }
  .mx-xl-32 {
    margin-right: 3.2rem !important;
    margin-left: 3.2rem !important; }
  .mx-xl-31 {
    margin-right: 3.1rem !important;
    margin-left: 3.1rem !important; }
  .mx-xl-30 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xl-29 {
    margin-right: 2.9rem !important;
    margin-left: 2.9rem !important; }
  .mx-xl-28 {
    margin-right: 2.8rem !important;
    margin-left: 2.8rem !important; }
  .mx-xl-27 {
    margin-right: 2.7rem !important;
    margin-left: 2.7rem !important; }
  .mx-xl-26 {
    margin-right: 2.6rem !important;
    margin-left: 2.6rem !important; }
  .mx-xl-25 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important; }
  .mx-xl-24 {
    margin-right: 2.4rem !important;
    margin-left: 2.4rem !important; }
  .mx-xl-23 {
    margin-right: 2.3rem !important;
    margin-left: 2.3rem !important; }
  .mx-xl-22 {
    margin-right: 2.2rem !important;
    margin-left: 2.2rem !important; }
  .mx-xl-21 {
    margin-right: 2.1rem !important;
    margin-left: 2.1rem !important; }
  .mx-xl-20 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  .mx-xl-19 {
    margin-right: 1.9rem !important;
    margin-left: 1.9rem !important; }
  .mx-xl-18 {
    margin-right: 1.8rem !important;
    margin-left: 1.8rem !important; }
  .mx-xl-17 {
    margin-right: 1.7rem !important;
    margin-left: 1.7rem !important; }
  .mx-xl-16 {
    margin-right: 1.6rem !important;
    margin-left: 1.6rem !important; }
  .mx-xl-15 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-14 {
    margin-right: 1.4rem !important;
    margin-left: 1.4rem !important; }
  .mx-xl-13 {
    margin-right: 1.3rem !important;
    margin-left: 1.3rem !important; }
  .mx-xl-12 {
    margin-right: 1.2rem !important;
    margin-left: 1.2rem !important; }
  .mx-xl-11 {
    margin-right: 1.1rem !important;
    margin-left: 1.1rem !important; }
  .mx-xl-10 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-5 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xl-6 {
    margin-right: 0.6rem !important;
    margin-left: 0.6rem !important; }
  .mx-xl-8 {
    margin-right: 0.8rem !important;
    margin-left: 0.8rem !important; }
  .mx-xl-40 {
    margin-right: 4rem !important;
    margin-left: 4rem !important; }
  .mx-xl-45 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important; }
  .mx-xl-50 {
    margin-right: 5rem !important;
    margin-left: 5rem !important; }
  .mx-xl-55 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important; }
  .mx-xl-60 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-xl-70 {
    margin-right: 7rem !important;
    margin-left: 7rem !important; }
  .mx-xl-80 {
    margin-right: 8rem !important;
    margin-left: 8rem !important; }
  .mx-xl-90 {
    margin-right: 9rem !important;
    margin-left: 9rem !important; }
  .mx-xl-100 {
    margin-right: 10rem !important;
    margin-left: 10rem !important; }
  .mx-xl-105 {
    margin-right: 10.5rem !important;
    margin-left: 10.5rem !important; }
  .mx-xl-110 {
    margin-right: 11rem !important;
    margin-left: 11rem !important; }
  .mx-xl-125 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important; }
  .mx-xl-130 {
    margin-right: 13rem !important;
    margin-left: 13rem !important; }
  .mx-xl-145 {
    margin-right: 14.5rem !important;
    margin-left: 14.5rem !important; }
  .mx-xl-170 {
    margin-right: 17rem !important;
    margin-left: 17rem !important; }
  .mx-xl-235 {
    margin-right: 23.5rem !important;
    margin-left: 23.5rem !important; }
  .mx-xl-n20 {
    margin-right: -2rem !important;
    margin-left: -2rem !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-35 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important; }
  .my-xl-34 {
    margin-top: 3.4rem !important;
    margin-bottom: 3.4rem !important; }
  .my-xl-33 {
    margin-top: 3.3rem !important;
    margin-bottom: 3.3rem !important; }
  .my-xl-32 {
    margin-top: 3.2rem !important;
    margin-bottom: 3.2rem !important; }
  .my-xl-31 {
    margin-top: 3.1rem !important;
    margin-bottom: 3.1rem !important; }
  .my-xl-30 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xl-29 {
    margin-top: 2.9rem !important;
    margin-bottom: 2.9rem !important; }
  .my-xl-28 {
    margin-top: 2.8rem !important;
    margin-bottom: 2.8rem !important; }
  .my-xl-27 {
    margin-top: 2.7rem !important;
    margin-bottom: 2.7rem !important; }
  .my-xl-26 {
    margin-top: 2.6rem !important;
    margin-bottom: 2.6rem !important; }
  .my-xl-25 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .my-xl-24 {
    margin-top: 2.4rem !important;
    margin-bottom: 2.4rem !important; }
  .my-xl-23 {
    margin-top: 2.3rem !important;
    margin-bottom: 2.3rem !important; }
  .my-xl-22 {
    margin-top: 2.2rem !important;
    margin-bottom: 2.2rem !important; }
  .my-xl-21 {
    margin-top: 2.1rem !important;
    margin-bottom: 2.1rem !important; }
  .my-xl-20 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .my-xl-19 {
    margin-top: 1.9rem !important;
    margin-bottom: 1.9rem !important; }
  .my-xl-18 {
    margin-top: 1.8rem !important;
    margin-bottom: 1.8rem !important; }
  .my-xl-17 {
    margin-top: 1.7rem !important;
    margin-bottom: 1.7rem !important; }
  .my-xl-16 {
    margin-top: 1.6rem !important;
    margin-bottom: 1.6rem !important; }
  .my-xl-15 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-14 {
    margin-top: 1.4rem !important;
    margin-bottom: 1.4rem !important; }
  .my-xl-13 {
    margin-top: 1.3rem !important;
    margin-bottom: 1.3rem !important; }
  .my-xl-12 {
    margin-top: 1.2rem !important;
    margin-bottom: 1.2rem !important; }
  .my-xl-11 {
    margin-top: 1.1rem !important;
    margin-bottom: 1.1rem !important; }
  .my-xl-10 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-5 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xl-6 {
    margin-top: 0.6rem !important;
    margin-bottom: 0.6rem !important; }
  .my-xl-8 {
    margin-top: 0.8rem !important;
    margin-bottom: 0.8rem !important; }
  .my-xl-40 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .my-xl-45 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important; }
  .my-xl-50 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .my-xl-55 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important; }
  .my-xl-60 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-xl-70 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important; }
  .my-xl-80 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important; }
  .my-xl-90 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important; }
  .my-xl-100 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important; }
  .my-xl-105 {
    margin-top: 10.5rem !important;
    margin-bottom: 10.5rem !important; }
  .my-xl-110 {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important; }
  .my-xl-125 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important; }
  .my-xl-130 {
    margin-top: 13rem !important;
    margin-bottom: 13rem !important; }
  .my-xl-145 {
    margin-top: 14.5rem !important;
    margin-bottom: 14.5rem !important; }
  .my-xl-170 {
    margin-top: 17rem !important;
    margin-bottom: 17rem !important; }
  .my-xl-235 {
    margin-top: 23.5rem !important;
    margin-bottom: 23.5rem !important; }
  .my-xl-n20 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-35 {
    margin-top: 3.5rem !important; }
  .mt-xl-34 {
    margin-top: 3.4rem !important; }
  .mt-xl-33 {
    margin-top: 3.3rem !important; }
  .mt-xl-32 {
    margin-top: 3.2rem !important; }
  .mt-xl-31 {
    margin-top: 3.1rem !important; }
  .mt-xl-30 {
    margin-top: 3rem !important; }
  .mt-xl-29 {
    margin-top: 2.9rem !important; }
  .mt-xl-28 {
    margin-top: 2.8rem !important; }
  .mt-xl-27 {
    margin-top: 2.7rem !important; }
  .mt-xl-26 {
    margin-top: 2.6rem !important; }
  .mt-xl-25 {
    margin-top: 2.5rem !important; }
  .mt-xl-24 {
    margin-top: 2.4rem !important; }
  .mt-xl-23 {
    margin-top: 2.3rem !important; }
  .mt-xl-22 {
    margin-top: 2.2rem !important; }
  .mt-xl-21 {
    margin-top: 2.1rem !important; }
  .mt-xl-20 {
    margin-top: 2rem !important; }
  .mt-xl-19 {
    margin-top: 1.9rem !important; }
  .mt-xl-18 {
    margin-top: 1.8rem !important; }
  .mt-xl-17 {
    margin-top: 1.7rem !important; }
  .mt-xl-16 {
    margin-top: 1.6rem !important; }
  .mt-xl-15 {
    margin-top: 1.5rem !important; }
  .mt-xl-14 {
    margin-top: 1.4rem !important; }
  .mt-xl-13 {
    margin-top: 1.3rem !important; }
  .mt-xl-12 {
    margin-top: 1.2rem !important; }
  .mt-xl-11 {
    margin-top: 1.1rem !important; }
  .mt-xl-10 {
    margin-top: 1rem !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mt-xl-5 {
    margin-top: 0.5rem !important; }
  .mt-xl-6 {
    margin-top: 0.6rem !important; }
  .mt-xl-8 {
    margin-top: 0.8rem !important; }
  .mt-xl-40 {
    margin-top: 4rem !important; }
  .mt-xl-45 {
    margin-top: 4.5rem !important; }
  .mt-xl-50 {
    margin-top: 5rem !important; }
  .mt-xl-55 {
    margin-top: 5.5rem !important; }
  .mt-xl-60 {
    margin-top: 6rem !important; }
  .mt-xl-70 {
    margin-top: 7rem !important; }
  .mt-xl-80 {
    margin-top: 8rem !important; }
  .mt-xl-90 {
    margin-top: 9rem !important; }
  .mt-xl-100 {
    margin-top: 10rem !important; }
  .mt-xl-105 {
    margin-top: 10.5rem !important; }
  .mt-xl-110 {
    margin-top: 11rem !important; }
  .mt-xl-125 {
    margin-top: 12.5rem !important; }
  .mt-xl-130 {
    margin-top: 13rem !important; }
  .mt-xl-145 {
    margin-top: 14.5rem !important; }
  .mt-xl-170 {
    margin-top: 17rem !important; }
  .mt-xl-235 {
    margin-top: 23.5rem !important; }
  .mt-xl-n20 {
    margin-top: -2rem !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .me-xl-35 {
    margin-right: 3.5rem !important; }
  .me-xl-34 {
    margin-right: 3.4rem !important; }
  .me-xl-33 {
    margin-right: 3.3rem !important; }
  .me-xl-32 {
    margin-right: 3.2rem !important; }
  .me-xl-31 {
    margin-right: 3.1rem !important; }
  .me-xl-30 {
    margin-right: 3rem !important; }
  .me-xl-29 {
    margin-right: 2.9rem !important; }
  .me-xl-28 {
    margin-right: 2.8rem !important; }
  .me-xl-27 {
    margin-right: 2.7rem !important; }
  .me-xl-26 {
    margin-right: 2.6rem !important; }
  .me-xl-25 {
    margin-right: 2.5rem !important; }
  .me-xl-24 {
    margin-right: 2.4rem !important; }
  .me-xl-23 {
    margin-right: 2.3rem !important; }
  .me-xl-22 {
    margin-right: 2.2rem !important; }
  .me-xl-21 {
    margin-right: 2.1rem !important; }
  .me-xl-20 {
    margin-right: 2rem !important; }
  .me-xl-19 {
    margin-right: 1.9rem !important; }
  .me-xl-18 {
    margin-right: 1.8rem !important; }
  .me-xl-17 {
    margin-right: 1.7rem !important; }
  .me-xl-16 {
    margin-right: 1.6rem !important; }
  .me-xl-15 {
    margin-right: 1.5rem !important; }
  .me-xl-14 {
    margin-right: 1.4rem !important; }
  .me-xl-13 {
    margin-right: 1.3rem !important; }
  .me-xl-12 {
    margin-right: 1.2rem !important; }
  .me-xl-11 {
    margin-right: 1.1rem !important; }
  .me-xl-10 {
    margin-right: 1rem !important; }
  .me-xl-0 {
    margin-right: 0 !important; }
  .me-xl-1 {
    margin-right: 0.25rem !important; }
  .me-xl-2 {
    margin-right: 0.5rem !important; }
  .me-xl-3 {
    margin-right: 1rem !important; }
  .me-xl-4 {
    margin-right: 1.5rem !important; }
  .me-xl-5 {
    margin-right: 0.5rem !important; }
  .me-xl-6 {
    margin-right: 0.6rem !important; }
  .me-xl-8 {
    margin-right: 0.8rem !important; }
  .me-xl-40 {
    margin-right: 4rem !important; }
  .me-xl-45 {
    margin-right: 4.5rem !important; }
  .me-xl-50 {
    margin-right: 5rem !important; }
  .me-xl-55 {
    margin-right: 5.5rem !important; }
  .me-xl-60 {
    margin-right: 6rem !important; }
  .me-xl-70 {
    margin-right: 7rem !important; }
  .me-xl-80 {
    margin-right: 8rem !important; }
  .me-xl-90 {
    margin-right: 9rem !important; }
  .me-xl-100 {
    margin-right: 10rem !important; }
  .me-xl-105 {
    margin-right: 10.5rem !important; }
  .me-xl-110 {
    margin-right: 11rem !important; }
  .me-xl-125 {
    margin-right: 12.5rem !important; }
  .me-xl-130 {
    margin-right: 13rem !important; }
  .me-xl-145 {
    margin-right: 14.5rem !important; }
  .me-xl-170 {
    margin-right: 17rem !important; }
  .me-xl-235 {
    margin-right: 23.5rem !important; }
  .me-xl-n20 {
    margin-right: -2rem !important; }
  .me-xl-auto {
    margin-right: auto !important; }
  .mb-xl-35 {
    margin-bottom: 3.5rem !important; }
  .mb-xl-34 {
    margin-bottom: 3.4rem !important; }
  .mb-xl-33 {
    margin-bottom: 3.3rem !important; }
  .mb-xl-32 {
    margin-bottom: 3.2rem !important; }
  .mb-xl-31 {
    margin-bottom: 3.1rem !important; }
  .mb-xl-30 {
    margin-bottom: 3rem !important; }
  .mb-xl-29 {
    margin-bottom: 2.9rem !important; }
  .mb-xl-28 {
    margin-bottom: 2.8rem !important; }
  .mb-xl-27 {
    margin-bottom: 2.7rem !important; }
  .mb-xl-26 {
    margin-bottom: 2.6rem !important; }
  .mb-xl-25 {
    margin-bottom: 2.5rem !important; }
  .mb-xl-24 {
    margin-bottom: 2.4rem !important; }
  .mb-xl-23 {
    margin-bottom: 2.3rem !important; }
  .mb-xl-22 {
    margin-bottom: 2.2rem !important; }
  .mb-xl-21 {
    margin-bottom: 2.1rem !important; }
  .mb-xl-20 {
    margin-bottom: 2rem !important; }
  .mb-xl-19 {
    margin-bottom: 1.9rem !important; }
  .mb-xl-18 {
    margin-bottom: 1.8rem !important; }
  .mb-xl-17 {
    margin-bottom: 1.7rem !important; }
  .mb-xl-16 {
    margin-bottom: 1.6rem !important; }
  .mb-xl-15 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-14 {
    margin-bottom: 1.4rem !important; }
  .mb-xl-13 {
    margin-bottom: 1.3rem !important; }
  .mb-xl-12 {
    margin-bottom: 1.2rem !important; }
  .mb-xl-11 {
    margin-bottom: 1.1rem !important; }
  .mb-xl-10 {
    margin-bottom: 1rem !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-5 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-6 {
    margin-bottom: 0.6rem !important; }
  .mb-xl-8 {
    margin-bottom: 0.8rem !important; }
  .mb-xl-40 {
    margin-bottom: 4rem !important; }
  .mb-xl-45 {
    margin-bottom: 4.5rem !important; }
  .mb-xl-50 {
    margin-bottom: 5rem !important; }
  .mb-xl-55 {
    margin-bottom: 5.5rem !important; }
  .mb-xl-60 {
    margin-bottom: 6rem !important; }
  .mb-xl-70 {
    margin-bottom: 7rem !important; }
  .mb-xl-80 {
    margin-bottom: 8rem !important; }
  .mb-xl-90 {
    margin-bottom: 9rem !important; }
  .mb-xl-100 {
    margin-bottom: 10rem !important; }
  .mb-xl-105 {
    margin-bottom: 10.5rem !important; }
  .mb-xl-110 {
    margin-bottom: 11rem !important; }
  .mb-xl-125 {
    margin-bottom: 12.5rem !important; }
  .mb-xl-130 {
    margin-bottom: 13rem !important; }
  .mb-xl-145 {
    margin-bottom: 14.5rem !important; }
  .mb-xl-170 {
    margin-bottom: 17rem !important; }
  .mb-xl-235 {
    margin-bottom: 23.5rem !important; }
  .mb-xl-n20 {
    margin-bottom: -2rem !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ms-xl-35 {
    margin-left: 3.5rem !important; }
  .ms-xl-34 {
    margin-left: 3.4rem !important; }
  .ms-xl-33 {
    margin-left: 3.3rem !important; }
  .ms-xl-32 {
    margin-left: 3.2rem !important; }
  .ms-xl-31 {
    margin-left: 3.1rem !important; }
  .ms-xl-30 {
    margin-left: 3rem !important; }
  .ms-xl-29 {
    margin-left: 2.9rem !important; }
  .ms-xl-28 {
    margin-left: 2.8rem !important; }
  .ms-xl-27 {
    margin-left: 2.7rem !important; }
  .ms-xl-26 {
    margin-left: 2.6rem !important; }
  .ms-xl-25 {
    margin-left: 2.5rem !important; }
  .ms-xl-24 {
    margin-left: 2.4rem !important; }
  .ms-xl-23 {
    margin-left: 2.3rem !important; }
  .ms-xl-22 {
    margin-left: 2.2rem !important; }
  .ms-xl-21 {
    margin-left: 2.1rem !important; }
  .ms-xl-20 {
    margin-left: 2rem !important; }
  .ms-xl-19 {
    margin-left: 1.9rem !important; }
  .ms-xl-18 {
    margin-left: 1.8rem !important; }
  .ms-xl-17 {
    margin-left: 1.7rem !important; }
  .ms-xl-16 {
    margin-left: 1.6rem !important; }
  .ms-xl-15 {
    margin-left: 1.5rem !important; }
  .ms-xl-14 {
    margin-left: 1.4rem !important; }
  .ms-xl-13 {
    margin-left: 1.3rem !important; }
  .ms-xl-12 {
    margin-left: 1.2rem !important; }
  .ms-xl-11 {
    margin-left: 1.1rem !important; }
  .ms-xl-10 {
    margin-left: 1rem !important; }
  .ms-xl-0 {
    margin-left: 0 !important; }
  .ms-xl-1 {
    margin-left: 0.25rem !important; }
  .ms-xl-2 {
    margin-left: 0.5rem !important; }
  .ms-xl-3 {
    margin-left: 1rem !important; }
  .ms-xl-4 {
    margin-left: 1.5rem !important; }
  .ms-xl-5 {
    margin-left: 0.5rem !important; }
  .ms-xl-6 {
    margin-left: 0.6rem !important; }
  .ms-xl-8 {
    margin-left: 0.8rem !important; }
  .ms-xl-40 {
    margin-left: 4rem !important; }
  .ms-xl-45 {
    margin-left: 4.5rem !important; }
  .ms-xl-50 {
    margin-left: 5rem !important; }
  .ms-xl-55 {
    margin-left: 5.5rem !important; }
  .ms-xl-60 {
    margin-left: 6rem !important; }
  .ms-xl-70 {
    margin-left: 7rem !important; }
  .ms-xl-80 {
    margin-left: 8rem !important; }
  .ms-xl-90 {
    margin-left: 9rem !important; }
  .ms-xl-100 {
    margin-left: 10rem !important; }
  .ms-xl-105 {
    margin-left: 10.5rem !important; }
  .ms-xl-110 {
    margin-left: 11rem !important; }
  .ms-xl-125 {
    margin-left: 12.5rem !important; }
  .ms-xl-130 {
    margin-left: 13rem !important; }
  .ms-xl-145 {
    margin-left: 14.5rem !important; }
  .ms-xl-170 {
    margin-left: 17rem !important; }
  .ms-xl-235 {
    margin-left: 23.5rem !important; }
  .ms-xl-n20 {
    margin-left: -2rem !important; }
  .ms-xl-auto {
    margin-left: auto !important; }
  .p-xl-35 {
    padding: 3.5rem !important; }
  .p-xl-34 {
    padding: 3.4rem !important; }
  .p-xl-33 {
    padding: 3.3rem !important; }
  .p-xl-32 {
    padding: 3.2rem !important; }
  .p-xl-31 {
    padding: 3.1rem !important; }
  .p-xl-30 {
    padding: 3rem !important; }
  .p-xl-29 {
    padding: 2.9rem !important; }
  .p-xl-28 {
    padding: 2.8rem !important; }
  .p-xl-27 {
    padding: 2.7rem !important; }
  .p-xl-26 {
    padding: 2.6rem !important; }
  .p-xl-25 {
    padding: 2.5rem !important; }
  .p-xl-24 {
    padding: 2.4rem !important; }
  .p-xl-23 {
    padding: 2.3rem !important; }
  .p-xl-22 {
    padding: 2.2rem !important; }
  .p-xl-21 {
    padding: 2.1rem !important; }
  .p-xl-20 {
    padding: 2rem !important; }
  .p-xl-19 {
    padding: 1.9rem !important; }
  .p-xl-18 {
    padding: 1.8rem !important; }
  .p-xl-17 {
    padding: 1.7rem !important; }
  .p-xl-16 {
    padding: 1.6rem !important; }
  .p-xl-15 {
    padding: 1.5rem !important; }
  .p-xl-14 {
    padding: 1.4rem !important; }
  .p-xl-13 {
    padding: 1.3rem !important; }
  .p-xl-12 {
    padding: 1.2rem !important; }
  .p-xl-11 {
    padding: 1.1rem !important; }
  .p-xl-10 {
    padding: 1rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .p-xl-5 {
    padding: 0.5rem !important; }
  .p-xl-6 {
    padding: 0.6rem !important; }
  .p-xl-8 {
    padding: 0.8rem !important; }
  .p-xl-40 {
    padding: 4rem !important; }
  .p-xl-45 {
    padding: 4.5rem !important; }
  .p-xl-50 {
    padding: 5rem !important; }
  .p-xl-55 {
    padding: 5.5rem !important; }
  .p-xl-60 {
    padding: 6rem !important; }
  .p-xl-70 {
    padding: 7rem !important; }
  .p-xl-80 {
    padding: 8rem !important; }
  .p-xl-90 {
    padding: 9rem !important; }
  .p-xl-100 {
    padding: 10rem !important; }
  .p-xl-105 {
    padding: 10.5rem !important; }
  .p-xl-110 {
    padding: 11rem !important; }
  .p-xl-125 {
    padding: 12.5rem !important; }
  .p-xl-130 {
    padding: 13rem !important; }
  .p-xl-145 {
    padding: 14.5rem !important; }
  .p-xl-170 {
    padding: 17rem !important; }
  .p-xl-235 {
    padding: 23.5rem !important; }
  .p-xl-n20 {
    padding: -2rem !important; }
  .px-xl-35 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important; }
  .px-xl-34 {
    padding-right: 3.4rem !important;
    padding-left: 3.4rem !important; }
  .px-xl-33 {
    padding-right: 3.3rem !important;
    padding-left: 3.3rem !important; }
  .px-xl-32 {
    padding-right: 3.2rem !important;
    padding-left: 3.2rem !important; }
  .px-xl-31 {
    padding-right: 3.1rem !important;
    padding-left: 3.1rem !important; }
  .px-xl-30 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-xl-29 {
    padding-right: 2.9rem !important;
    padding-left: 2.9rem !important; }
  .px-xl-28 {
    padding-right: 2.8rem !important;
    padding-left: 2.8rem !important; }
  .px-xl-27 {
    padding-right: 2.7rem !important;
    padding-left: 2.7rem !important; }
  .px-xl-26 {
    padding-right: 2.6rem !important;
    padding-left: 2.6rem !important; }
  .px-xl-25 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important; }
  .px-xl-24 {
    padding-right: 2.4rem !important;
    padding-left: 2.4rem !important; }
  .px-xl-23 {
    padding-right: 2.3rem !important;
    padding-left: 2.3rem !important; }
  .px-xl-22 {
    padding-right: 2.2rem !important;
    padding-left: 2.2rem !important; }
  .px-xl-21 {
    padding-right: 2.1rem !important;
    padding-left: 2.1rem !important; }
  .px-xl-20 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .px-xl-19 {
    padding-right: 1.9rem !important;
    padding-left: 1.9rem !important; }
  .px-xl-18 {
    padding-right: 1.8rem !important;
    padding-left: 1.8rem !important; }
  .px-xl-17 {
    padding-right: 1.7rem !important;
    padding-left: 1.7rem !important; }
  .px-xl-16 {
    padding-right: 1.6rem !important;
    padding-left: 1.6rem !important; }
  .px-xl-15 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-14 {
    padding-right: 1.4rem !important;
    padding-left: 1.4rem !important; }
  .px-xl-13 {
    padding-right: 1.3rem !important;
    padding-left: 1.3rem !important; }
  .px-xl-12 {
    padding-right: 1.2rem !important;
    padding-left: 1.2rem !important; }
  .px-xl-11 {
    padding-right: 1.1rem !important;
    padding-left: 1.1rem !important; }
  .px-xl-10 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-5 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xl-6 {
    padding-right: 0.6rem !important;
    padding-left: 0.6rem !important; }
  .px-xl-8 {
    padding-right: 0.8rem !important;
    padding-left: 0.8rem !important; }
  .px-xl-40 {
    padding-right: 4rem !important;
    padding-left: 4rem !important; }
  .px-xl-45 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important; }
  .px-xl-50 {
    padding-right: 5rem !important;
    padding-left: 5rem !important; }
  .px-xl-55 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important; }
  .px-xl-60 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .px-xl-70 {
    padding-right: 7rem !important;
    padding-left: 7rem !important; }
  .px-xl-80 {
    padding-right: 8rem !important;
    padding-left: 8rem !important; }
  .px-xl-90 {
    padding-right: 9rem !important;
    padding-left: 9rem !important; }
  .px-xl-100 {
    padding-right: 10rem !important;
    padding-left: 10rem !important; }
  .px-xl-105 {
    padding-right: 10.5rem !important;
    padding-left: 10.5rem !important; }
  .px-xl-110 {
    padding-right: 11rem !important;
    padding-left: 11rem !important; }
  .px-xl-125 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important; }
  .px-xl-130 {
    padding-right: 13rem !important;
    padding-left: 13rem !important; }
  .px-xl-145 {
    padding-right: 14.5rem !important;
    padding-left: 14.5rem !important; }
  .px-xl-170 {
    padding-right: 17rem !important;
    padding-left: 17rem !important; }
  .px-xl-235 {
    padding-right: 23.5rem !important;
    padding-left: 23.5rem !important; }
  .px-xl-n20 {
    padding-right: -2rem !important;
    padding-left: -2rem !important; }
  .py-xl-35 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important; }
  .py-xl-34 {
    padding-top: 3.4rem !important;
    padding-bottom: 3.4rem !important; }
  .py-xl-33 {
    padding-top: 3.3rem !important;
    padding-bottom: 3.3rem !important; }
  .py-xl-32 {
    padding-top: 3.2rem !important;
    padding-bottom: 3.2rem !important; }
  .py-xl-31 {
    padding-top: 3.1rem !important;
    padding-bottom: 3.1rem !important; }
  .py-xl-30 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-xl-29 {
    padding-top: 2.9rem !important;
    padding-bottom: 2.9rem !important; }
  .py-xl-28 {
    padding-top: 2.8rem !important;
    padding-bottom: 2.8rem !important; }
  .py-xl-27 {
    padding-top: 2.7rem !important;
    padding-bottom: 2.7rem !important; }
  .py-xl-26 {
    padding-top: 2.6rem !important;
    padding-bottom: 2.6rem !important; }
  .py-xl-25 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .py-xl-24 {
    padding-top: 2.4rem !important;
    padding-bottom: 2.4rem !important; }
  .py-xl-23 {
    padding-top: 2.3rem !important;
    padding-bottom: 2.3rem !important; }
  .py-xl-22 {
    padding-top: 2.2rem !important;
    padding-bottom: 2.2rem !important; }
  .py-xl-21 {
    padding-top: 2.1rem !important;
    padding-bottom: 2.1rem !important; }
  .py-xl-20 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .py-xl-19 {
    padding-top: 1.9rem !important;
    padding-bottom: 1.9rem !important; }
  .py-xl-18 {
    padding-top: 1.8rem !important;
    padding-bottom: 1.8rem !important; }
  .py-xl-17 {
    padding-top: 1.7rem !important;
    padding-bottom: 1.7rem !important; }
  .py-xl-16 {
    padding-top: 1.6rem !important;
    padding-bottom: 1.6rem !important; }
  .py-xl-15 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-14 {
    padding-top: 1.4rem !important;
    padding-bottom: 1.4rem !important; }
  .py-xl-13 {
    padding-top: 1.3rem !important;
    padding-bottom: 1.3rem !important; }
  .py-xl-12 {
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important; }
  .py-xl-11 {
    padding-top: 1.1rem !important;
    padding-bottom: 1.1rem !important; }
  .py-xl-10 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-5 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xl-6 {
    padding-top: 0.6rem !important;
    padding-bottom: 0.6rem !important; }
  .py-xl-8 {
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important; }
  .py-xl-40 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .py-xl-45 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important; }
  .py-xl-50 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .py-xl-55 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important; }
  .py-xl-60 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .py-xl-70 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important; }
  .py-xl-80 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important; }
  .py-xl-90 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important; }
  .py-xl-100 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important; }
  .py-xl-105 {
    padding-top: 10.5rem !important;
    padding-bottom: 10.5rem !important; }
  .py-xl-110 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important; }
  .py-xl-125 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important; }
  .py-xl-130 {
    padding-top: 13rem !important;
    padding-bottom: 13rem !important; }
  .py-xl-145 {
    padding-top: 14.5rem !important;
    padding-bottom: 14.5rem !important; }
  .py-xl-170 {
    padding-top: 17rem !important;
    padding-bottom: 17rem !important; }
  .py-xl-235 {
    padding-top: 23.5rem !important;
    padding-bottom: 23.5rem !important; }
  .py-xl-n20 {
    padding-top: -2rem !important;
    padding-bottom: -2rem !important; }
  .pt-xl-35 {
    padding-top: 3.5rem !important; }
  .pt-xl-34 {
    padding-top: 3.4rem !important; }
  .pt-xl-33 {
    padding-top: 3.3rem !important; }
  .pt-xl-32 {
    padding-top: 3.2rem !important; }
  .pt-xl-31 {
    padding-top: 3.1rem !important; }
  .pt-xl-30 {
    padding-top: 3rem !important; }
  .pt-xl-29 {
    padding-top: 2.9rem !important; }
  .pt-xl-28 {
    padding-top: 2.8rem !important; }
  .pt-xl-27 {
    padding-top: 2.7rem !important; }
  .pt-xl-26 {
    padding-top: 2.6rem !important; }
  .pt-xl-25 {
    padding-top: 2.5rem !important; }
  .pt-xl-24 {
    padding-top: 2.4rem !important; }
  .pt-xl-23 {
    padding-top: 2.3rem !important; }
  .pt-xl-22 {
    padding-top: 2.2rem !important; }
  .pt-xl-21 {
    padding-top: 2.1rem !important; }
  .pt-xl-20 {
    padding-top: 2rem !important; }
  .pt-xl-19 {
    padding-top: 1.9rem !important; }
  .pt-xl-18 {
    padding-top: 1.8rem !important; }
  .pt-xl-17 {
    padding-top: 1.7rem !important; }
  .pt-xl-16 {
    padding-top: 1.6rem !important; }
  .pt-xl-15 {
    padding-top: 1.5rem !important; }
  .pt-xl-14 {
    padding-top: 1.4rem !important; }
  .pt-xl-13 {
    padding-top: 1.3rem !important; }
  .pt-xl-12 {
    padding-top: 1.2rem !important; }
  .pt-xl-11 {
    padding-top: 1.1rem !important; }
  .pt-xl-10 {
    padding-top: 1rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pt-xl-5 {
    padding-top: 0.5rem !important; }
  .pt-xl-6 {
    padding-top: 0.6rem !important; }
  .pt-xl-8 {
    padding-top: 0.8rem !important; }
  .pt-xl-40 {
    padding-top: 4rem !important; }
  .pt-xl-45 {
    padding-top: 4.5rem !important; }
  .pt-xl-50 {
    padding-top: 5rem !important; }
  .pt-xl-55 {
    padding-top: 5.5rem !important; }
  .pt-xl-60 {
    padding-top: 6rem !important; }
  .pt-xl-70 {
    padding-top: 7rem !important; }
  .pt-xl-80 {
    padding-top: 8rem !important; }
  .pt-xl-90 {
    padding-top: 9rem !important; }
  .pt-xl-100 {
    padding-top: 10rem !important; }
  .pt-xl-105 {
    padding-top: 10.5rem !important; }
  .pt-xl-110 {
    padding-top: 11rem !important; }
  .pt-xl-125 {
    padding-top: 12.5rem !important; }
  .pt-xl-130 {
    padding-top: 13rem !important; }
  .pt-xl-145 {
    padding-top: 14.5rem !important; }
  .pt-xl-170 {
    padding-top: 17rem !important; }
  .pt-xl-235 {
    padding-top: 23.5rem !important; }
  .pt-xl-n20 {
    padding-top: -2rem !important; }
  .pe-xl-35 {
    padding-right: 3.5rem !important; }
  .pe-xl-34 {
    padding-right: 3.4rem !important; }
  .pe-xl-33 {
    padding-right: 3.3rem !important; }
  .pe-xl-32 {
    padding-right: 3.2rem !important; }
  .pe-xl-31 {
    padding-right: 3.1rem !important; }
  .pe-xl-30 {
    padding-right: 3rem !important; }
  .pe-xl-29 {
    padding-right: 2.9rem !important; }
  .pe-xl-28 {
    padding-right: 2.8rem !important; }
  .pe-xl-27 {
    padding-right: 2.7rem !important; }
  .pe-xl-26 {
    padding-right: 2.6rem !important; }
  .pe-xl-25 {
    padding-right: 2.5rem !important; }
  .pe-xl-24 {
    padding-right: 2.4rem !important; }
  .pe-xl-23 {
    padding-right: 2.3rem !important; }
  .pe-xl-22 {
    padding-right: 2.2rem !important; }
  .pe-xl-21 {
    padding-right: 2.1rem !important; }
  .pe-xl-20 {
    padding-right: 2rem !important; }
  .pe-xl-19 {
    padding-right: 1.9rem !important; }
  .pe-xl-18 {
    padding-right: 1.8rem !important; }
  .pe-xl-17 {
    padding-right: 1.7rem !important; }
  .pe-xl-16 {
    padding-right: 1.6rem !important; }
  .pe-xl-15 {
    padding-right: 1.5rem !important; }
  .pe-xl-14 {
    padding-right: 1.4rem !important; }
  .pe-xl-13 {
    padding-right: 1.3rem !important; }
  .pe-xl-12 {
    padding-right: 1.2rem !important; }
  .pe-xl-11 {
    padding-right: 1.1rem !important; }
  .pe-xl-10 {
    padding-right: 1rem !important; }
  .pe-xl-0 {
    padding-right: 0 !important; }
  .pe-xl-1 {
    padding-right: 0.25rem !important; }
  .pe-xl-2 {
    padding-right: 0.5rem !important; }
  .pe-xl-3 {
    padding-right: 1rem !important; }
  .pe-xl-4 {
    padding-right: 1.5rem !important; }
  .pe-xl-5 {
    padding-right: 0.5rem !important; }
  .pe-xl-6 {
    padding-right: 0.6rem !important; }
  .pe-xl-8 {
    padding-right: 0.8rem !important; }
  .pe-xl-40 {
    padding-right: 4rem !important; }
  .pe-xl-45 {
    padding-right: 4.5rem !important; }
  .pe-xl-50 {
    padding-right: 5rem !important; }
  .pe-xl-55 {
    padding-right: 5.5rem !important; }
  .pe-xl-60 {
    padding-right: 6rem !important; }
  .pe-xl-70 {
    padding-right: 7rem !important; }
  .pe-xl-80 {
    padding-right: 8rem !important; }
  .pe-xl-90 {
    padding-right: 9rem !important; }
  .pe-xl-100 {
    padding-right: 10rem !important; }
  .pe-xl-105 {
    padding-right: 10.5rem !important; }
  .pe-xl-110 {
    padding-right: 11rem !important; }
  .pe-xl-125 {
    padding-right: 12.5rem !important; }
  .pe-xl-130 {
    padding-right: 13rem !important; }
  .pe-xl-145 {
    padding-right: 14.5rem !important; }
  .pe-xl-170 {
    padding-right: 17rem !important; }
  .pe-xl-235 {
    padding-right: 23.5rem !important; }
  .pe-xl-n20 {
    padding-right: -2rem !important; }
  .pb-xl-35 {
    padding-bottom: 3.5rem !important; }
  .pb-xl-34 {
    padding-bottom: 3.4rem !important; }
  .pb-xl-33 {
    padding-bottom: 3.3rem !important; }
  .pb-xl-32 {
    padding-bottom: 3.2rem !important; }
  .pb-xl-31 {
    padding-bottom: 3.1rem !important; }
  .pb-xl-30 {
    padding-bottom: 3rem !important; }
  .pb-xl-29 {
    padding-bottom: 2.9rem !important; }
  .pb-xl-28 {
    padding-bottom: 2.8rem !important; }
  .pb-xl-27 {
    padding-bottom: 2.7rem !important; }
  .pb-xl-26 {
    padding-bottom: 2.6rem !important; }
  .pb-xl-25 {
    padding-bottom: 2.5rem !important; }
  .pb-xl-24 {
    padding-bottom: 2.4rem !important; }
  .pb-xl-23 {
    padding-bottom: 2.3rem !important; }
  .pb-xl-22 {
    padding-bottom: 2.2rem !important; }
  .pb-xl-21 {
    padding-bottom: 2.1rem !important; }
  .pb-xl-20 {
    padding-bottom: 2rem !important; }
  .pb-xl-19 {
    padding-bottom: 1.9rem !important; }
  .pb-xl-18 {
    padding-bottom: 1.8rem !important; }
  .pb-xl-17 {
    padding-bottom: 1.7rem !important; }
  .pb-xl-16 {
    padding-bottom: 1.6rem !important; }
  .pb-xl-15 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-14 {
    padding-bottom: 1.4rem !important; }
  .pb-xl-13 {
    padding-bottom: 1.3rem !important; }
  .pb-xl-12 {
    padding-bottom: 1.2rem !important; }
  .pb-xl-11 {
    padding-bottom: 1.1rem !important; }
  .pb-xl-10 {
    padding-bottom: 1rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-5 {
    padding-bottom: 0.5rem !important; }
  .pb-xl-6 {
    padding-bottom: 0.6rem !important; }
  .pb-xl-8 {
    padding-bottom: 0.8rem !important; }
  .pb-xl-40 {
    padding-bottom: 4rem !important; }
  .pb-xl-45 {
    padding-bottom: 4.5rem !important; }
  .pb-xl-50 {
    padding-bottom: 5rem !important; }
  .pb-xl-55 {
    padding-bottom: 5.5rem !important; }
  .pb-xl-60 {
    padding-bottom: 6rem !important; }
  .pb-xl-70 {
    padding-bottom: 7rem !important; }
  .pb-xl-80 {
    padding-bottom: 8rem !important; }
  .pb-xl-90 {
    padding-bottom: 9rem !important; }
  .pb-xl-100 {
    padding-bottom: 10rem !important; }
  .pb-xl-105 {
    padding-bottom: 10.5rem !important; }
  .pb-xl-110 {
    padding-bottom: 11rem !important; }
  .pb-xl-125 {
    padding-bottom: 12.5rem !important; }
  .pb-xl-130 {
    padding-bottom: 13rem !important; }
  .pb-xl-145 {
    padding-bottom: 14.5rem !important; }
  .pb-xl-170 {
    padding-bottom: 17rem !important; }
  .pb-xl-235 {
    padding-bottom: 23.5rem !important; }
  .pb-xl-n20 {
    padding-bottom: -2rem !important; }
  .ps-xl-35 {
    padding-left: 3.5rem !important; }
  .ps-xl-34 {
    padding-left: 3.4rem !important; }
  .ps-xl-33 {
    padding-left: 3.3rem !important; }
  .ps-xl-32 {
    padding-left: 3.2rem !important; }
  .ps-xl-31 {
    padding-left: 3.1rem !important; }
  .ps-xl-30 {
    padding-left: 3rem !important; }
  .ps-xl-29 {
    padding-left: 2.9rem !important; }
  .ps-xl-28 {
    padding-left: 2.8rem !important; }
  .ps-xl-27 {
    padding-left: 2.7rem !important; }
  .ps-xl-26 {
    padding-left: 2.6rem !important; }
  .ps-xl-25 {
    padding-left: 2.5rem !important; }
  .ps-xl-24 {
    padding-left: 2.4rem !important; }
  .ps-xl-23 {
    padding-left: 2.3rem !important; }
  .ps-xl-22 {
    padding-left: 2.2rem !important; }
  .ps-xl-21 {
    padding-left: 2.1rem !important; }
  .ps-xl-20 {
    padding-left: 2rem !important; }
  .ps-xl-19 {
    padding-left: 1.9rem !important; }
  .ps-xl-18 {
    padding-left: 1.8rem !important; }
  .ps-xl-17 {
    padding-left: 1.7rem !important; }
  .ps-xl-16 {
    padding-left: 1.6rem !important; }
  .ps-xl-15 {
    padding-left: 1.5rem !important; }
  .ps-xl-14 {
    padding-left: 1.4rem !important; }
  .ps-xl-13 {
    padding-left: 1.3rem !important; }
  .ps-xl-12 {
    padding-left: 1.2rem !important; }
  .ps-xl-11 {
    padding-left: 1.1rem !important; }
  .ps-xl-10 {
    padding-left: 1rem !important; }
  .ps-xl-0 {
    padding-left: 0 !important; }
  .ps-xl-1 {
    padding-left: 0.25rem !important; }
  .ps-xl-2 {
    padding-left: 0.5rem !important; }
  .ps-xl-3 {
    padding-left: 1rem !important; }
  .ps-xl-4 {
    padding-left: 1.5rem !important; }
  .ps-xl-5 {
    padding-left: 0.5rem !important; }
  .ps-xl-6 {
    padding-left: 0.6rem !important; }
  .ps-xl-8 {
    padding-left: 0.8rem !important; }
  .ps-xl-40 {
    padding-left: 4rem !important; }
  .ps-xl-45 {
    padding-left: 4.5rem !important; }
  .ps-xl-50 {
    padding-left: 5rem !important; }
  .ps-xl-55 {
    padding-left: 5.5rem !important; }
  .ps-xl-60 {
    padding-left: 6rem !important; }
  .ps-xl-70 {
    padding-left: 7rem !important; }
  .ps-xl-80 {
    padding-left: 8rem !important; }
  .ps-xl-90 {
    padding-left: 9rem !important; }
  .ps-xl-100 {
    padding-left: 10rem !important; }
  .ps-xl-105 {
    padding-left: 10.5rem !important; }
  .ps-xl-110 {
    padding-left: 11rem !important; }
  .ps-xl-125 {
    padding-left: 12.5rem !important; }
  .ps-xl-130 {
    padding-left: 13rem !important; }
  .ps-xl-145 {
    padding-left: 14.5rem !important; }
  .ps-xl-170 {
    padding-left: 17rem !important; }
  .ps-xl-235 {
    padding-left: 23.5rem !important; }
  .ps-xl-n20 {
    padding-left: -2rem !important; }
  .font-xl-family-02 {
    font-family: "Caveat", cursive !important; }
  .fs-xl-40 {
    font-size: 4rem !important; }
  .fs-xl-39 {
    font-size: 3.9rem !important; }
  .fs-xl-38 {
    font-size: 3.8rem !important; }
  .fs-xl-37 {
    font-size: 3.7rem !important; }
  .fs-xl-36 {
    font-size: 3.6rem !important; }
  .fs-xl-35 {
    font-size: 3.5rem !important; }
  .fs-xl-34 {
    font-size: 3.4rem !important; }
  .fs-xl-33 {
    font-size: 3.3rem !important; }
  .fs-xl-32 {
    font-size: 3.2rem !important; }
  .fs-xl-31 {
    font-size: 3.1rem !important; }
  .fs-xl-30 {
    font-size: 3rem !important; }
  .fs-xl-29 {
    font-size: 2.9rem !important; }
  .fs-xl-28 {
    font-size: 2.8rem !important; }
  .fs-xl-27 {
    font-size: 2.7rem !important; }
  .fs-xl-26 {
    font-size: 2.6rem !important; }
  .fs-xl-25 {
    font-size: 2.5rem !important; }
  .fs-xl-24 {
    font-size: 2.4rem !important; }
  .fs-xl-23 {
    font-size: 2.3rem !important; }
  .fs-xl-22 {
    font-size: 2.2rem !important; }
  .fs-xl-21 {
    font-size: 2.1rem !important; }
  .fs-xl-20 {
    font-size: 2rem !important; }
  .fs-xl-19 {
    font-size: 1.9rem !important; }
  .fs-xl-18 {
    font-size: 1.8rem !important; }
  .fs-xl-17 {
    font-size: 1.7rem !important; }
  .fs-xl-16 {
    font-size: 1.6rem !important; }
  .fs-xl-15 {
    font-size: 1.5rem !important; }
  .fs-xl-14 {
    font-size: 1.4rem !important; }
  .fs-xl-13 {
    font-size: 1.3rem !important; }
  .fs-xl-12 {
    font-size: 1.2rem !important; }
  .fs-xl-11 {
    font-size: 1.1rem !important; }
  .fs-xl-10 {
    font-size: 1rem !important; }
  .fs-xl-60 {
    font-size: calc(1.725rem + 5.7vw) !important; }
  .text-xl-start {
    text-align: left !important; }
  .text-xl-end {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; }
  .rounded-xl-20 {
    border-radius: 2rem !important; }
  .rounded-xl-19 {
    border-radius: 1.9rem !important; }
  .rounded-xl-18 {
    border-radius: 1.8rem !important; }
  .rounded-xl-17 {
    border-radius: 1.7rem !important; }
  .rounded-xl-16 {
    border-radius: 1.6rem !important; }
  .rounded-xl-15 {
    border-radius: 1.5rem !important; }
  .rounded-xl-14 {
    border-radius: 1.4rem !important; }
  .rounded-xl-13 {
    border-radius: 1.3rem !important; }
  .rounded-xl-12 {
    border-radius: 1.2rem !important; }
  .rounded-xl-11 {
    border-radius: 1.1rem !important; }
  .rounded-xl-10 {
    border-radius: 1rem !important; }
  .rounded-xl-9 {
    border-radius: 0.9rem !important; }
  .rounded-xl-8 {
    border-radius: 0.8rem !important; }
  .rounded-xl-7 {
    border-radius: 0.7rem !important; }
  .rounded-xl-6 {
    border-radius: 0.6rem !important; }
  .rounded-xl-5 {
    border-radius: 0.5rem !important; }
  .rounded-xl {
    border-radius: 2px 5px 2px 5px !important; }
  .rounded-xl-0 {
    border-radius: 0 !important; }
  .rounded-xl-1 {
    border-radius: 0.6rem !important; }
  .rounded-xl-2 {
    border-radius: 2px 5px 2px 5px !important; }
  .rounded-xl-3 {
    border-radius: 5px 20px 5px 20px !important; }
  .rounded-xl-4 {
    border-radius: 5px 100px 5px 100px !important; }
  .rounded-xl-circle {
    border-radius: 50% !important; }
  .rounded-xl-pill {
    border-radius: 50rem !important; }
  .rounded-xl-btn {
    border-radius: 5px 30px 5px 5px !important; }
  .rounded-xl-carte {
    border-radius: 10px 25px 10px 25px !important; }
  .rounded-top-xl-20 {
    border-top-left-radius: 2rem !important;
    border-top-right-radius: 2rem !important; }
  .rounded-top-xl-19 {
    border-top-left-radius: 1.9rem !important;
    border-top-right-radius: 1.9rem !important; }
  .rounded-top-xl-18 {
    border-top-left-radius: 1.8rem !important;
    border-top-right-radius: 1.8rem !important; }
  .rounded-top-xl-17 {
    border-top-left-radius: 1.7rem !important;
    border-top-right-radius: 1.7rem !important; }
  .rounded-top-xl-16 {
    border-top-left-radius: 1.6rem !important;
    border-top-right-radius: 1.6rem !important; }
  .rounded-top-xl-15 {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important; }
  .rounded-top-xl-14 {
    border-top-left-radius: 1.4rem !important;
    border-top-right-radius: 1.4rem !important; }
  .rounded-top-xl-13 {
    border-top-left-radius: 1.3rem !important;
    border-top-right-radius: 1.3rem !important; }
  .rounded-top-xl-12 {
    border-top-left-radius: 1.2rem !important;
    border-top-right-radius: 1.2rem !important; }
  .rounded-top-xl-11 {
    border-top-left-radius: 1.1rem !important;
    border-top-right-radius: 1.1rem !important; }
  .rounded-top-xl-10 {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important; }
  .rounded-top-xl-9 {
    border-top-left-radius: 0.9rem !important;
    border-top-right-radius: 0.9rem !important; }
  .rounded-top-xl-8 {
    border-top-left-radius: 0.8rem !important;
    border-top-right-radius: 0.8rem !important; }
  .rounded-top-xl-7 {
    border-top-left-radius: 0.7rem !important;
    border-top-right-radius: 0.7rem !important; }
  .rounded-top-xl-6 {
    border-top-left-radius: 0.6rem !important;
    border-top-right-radius: 0.6rem !important; }
  .rounded-top-xl-5 {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important; }
  .rounded-top-xl {
    border-top-left-radius: 2px 5px 2px 5px !important;
    border-top-right-radius: 2px 5px 2px 5px !important; }
  .rounded-top-xl-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important; }
  .rounded-top-xl-1 {
    border-top-left-radius: 0.6rem !important;
    border-top-right-radius: 0.6rem !important; }
  .rounded-top-xl-2 {
    border-top-left-radius: 2px 5px 2px 5px !important;
    border-top-right-radius: 2px 5px 2px 5px !important; }
  .rounded-top-xl-3 {
    border-top-left-radius: 5px 20px 5px 20px !important;
    border-top-right-radius: 5px 20px 5px 20px !important; }
  .rounded-top-xl-4 {
    border-top-left-radius: 5px 100px 5px 100px !important;
    border-top-right-radius: 5px 100px 5px 100px !important; }
  .rounded-top-xl-circle {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important; }
  .rounded-top-xl-pill {
    border-top-left-radius: 50rem !important;
    border-top-right-radius: 50rem !important; }
  .rounded-top-xl-btn {
    border-top-left-radius: 5px 30px 5px 5px !important;
    border-top-right-radius: 5px 30px 5px 5px !important; }
  .rounded-top-xl-carte {
    border-top-left-radius: 10px 25px 10px 25px !important;
    border-top-right-radius: 10px 25px 10px 25px !important; }
  .rounded-end-xl-20 {
    border-top-right-radius: 2rem !important;
    border-bottom-right-radius: 2rem !important; }
  .rounded-end-xl-19 {
    border-top-right-radius: 1.9rem !important;
    border-bottom-right-radius: 1.9rem !important; }
  .rounded-end-xl-18 {
    border-top-right-radius: 1.8rem !important;
    border-bottom-right-radius: 1.8rem !important; }
  .rounded-end-xl-17 {
    border-top-right-radius: 1.7rem !important;
    border-bottom-right-radius: 1.7rem !important; }
  .rounded-end-xl-16 {
    border-top-right-radius: 1.6rem !important;
    border-bottom-right-radius: 1.6rem !important; }
  .rounded-end-xl-15 {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important; }
  .rounded-end-xl-14 {
    border-top-right-radius: 1.4rem !important;
    border-bottom-right-radius: 1.4rem !important; }
  .rounded-end-xl-13 {
    border-top-right-radius: 1.3rem !important;
    border-bottom-right-radius: 1.3rem !important; }
  .rounded-end-xl-12 {
    border-top-right-radius: 1.2rem !important;
    border-bottom-right-radius: 1.2rem !important; }
  .rounded-end-xl-11 {
    border-top-right-radius: 1.1rem !important;
    border-bottom-right-radius: 1.1rem !important; }
  .rounded-end-xl-10 {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important; }
  .rounded-end-xl-9 {
    border-top-right-radius: 0.9rem !important;
    border-bottom-right-radius: 0.9rem !important; }
  .rounded-end-xl-8 {
    border-top-right-radius: 0.8rem !important;
    border-bottom-right-radius: 0.8rem !important; }
  .rounded-end-xl-7 {
    border-top-right-radius: 0.7rem !important;
    border-bottom-right-radius: 0.7rem !important; }
  .rounded-end-xl-6 {
    border-top-right-radius: 0.6rem !important;
    border-bottom-right-radius: 0.6rem !important; }
  .rounded-end-xl-5 {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important; }
  .rounded-end-xl {
    border-top-right-radius: 2px 5px 2px 5px !important;
    border-bottom-right-radius: 2px 5px 2px 5px !important; }
  .rounded-end-xl-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important; }
  .rounded-end-xl-1 {
    border-top-right-radius: 0.6rem !important;
    border-bottom-right-radius: 0.6rem !important; }
  .rounded-end-xl-2 {
    border-top-right-radius: 2px 5px 2px 5px !important;
    border-bottom-right-radius: 2px 5px 2px 5px !important; }
  .rounded-end-xl-3 {
    border-top-right-radius: 5px 20px 5px 20px !important;
    border-bottom-right-radius: 5px 20px 5px 20px !important; }
  .rounded-end-xl-4 {
    border-top-right-radius: 5px 100px 5px 100px !important;
    border-bottom-right-radius: 5px 100px 5px 100px !important; }
  .rounded-end-xl-circle {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important; }
  .rounded-end-xl-pill {
    border-top-right-radius: 50rem !important;
    border-bottom-right-radius: 50rem !important; }
  .rounded-end-xl-btn {
    border-top-right-radius: 5px 30px 5px 5px !important;
    border-bottom-right-radius: 5px 30px 5px 5px !important; }
  .rounded-end-xl-carte {
    border-top-right-radius: 10px 25px 10px 25px !important;
    border-bottom-right-radius: 10px 25px 10px 25px !important; }
  .rounded-bottom-xl-20 {
    border-bottom-right-radius: 2rem !important;
    border-bottom-left-radius: 2rem !important; }
  .rounded-bottom-xl-19 {
    border-bottom-right-radius: 1.9rem !important;
    border-bottom-left-radius: 1.9rem !important; }
  .rounded-bottom-xl-18 {
    border-bottom-right-radius: 1.8rem !important;
    border-bottom-left-radius: 1.8rem !important; }
  .rounded-bottom-xl-17 {
    border-bottom-right-radius: 1.7rem !important;
    border-bottom-left-radius: 1.7rem !important; }
  .rounded-bottom-xl-16 {
    border-bottom-right-radius: 1.6rem !important;
    border-bottom-left-radius: 1.6rem !important; }
  .rounded-bottom-xl-15 {
    border-bottom-right-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important; }
  .rounded-bottom-xl-14 {
    border-bottom-right-radius: 1.4rem !important;
    border-bottom-left-radius: 1.4rem !important; }
  .rounded-bottom-xl-13 {
    border-bottom-right-radius: 1.3rem !important;
    border-bottom-left-radius: 1.3rem !important; }
  .rounded-bottom-xl-12 {
    border-bottom-right-radius: 1.2rem !important;
    border-bottom-left-radius: 1.2rem !important; }
  .rounded-bottom-xl-11 {
    border-bottom-right-radius: 1.1rem !important;
    border-bottom-left-radius: 1.1rem !important; }
  .rounded-bottom-xl-10 {
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important; }
  .rounded-bottom-xl-9 {
    border-bottom-right-radius: 0.9rem !important;
    border-bottom-left-radius: 0.9rem !important; }
  .rounded-bottom-xl-8 {
    border-bottom-right-radius: 0.8rem !important;
    border-bottom-left-radius: 0.8rem !important; }
  .rounded-bottom-xl-7 {
    border-bottom-right-radius: 0.7rem !important;
    border-bottom-left-radius: 0.7rem !important; }
  .rounded-bottom-xl-6 {
    border-bottom-right-radius: 0.6rem !important;
    border-bottom-left-radius: 0.6rem !important; }
  .rounded-bottom-xl-5 {
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important; }
  .rounded-bottom-xl {
    border-bottom-right-radius: 2px 5px 2px 5px !important;
    border-bottom-left-radius: 2px 5px 2px 5px !important; }
  .rounded-bottom-xl-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important; }
  .rounded-bottom-xl-1 {
    border-bottom-right-radius: 0.6rem !important;
    border-bottom-left-radius: 0.6rem !important; }
  .rounded-bottom-xl-2 {
    border-bottom-right-radius: 2px 5px 2px 5px !important;
    border-bottom-left-radius: 2px 5px 2px 5px !important; }
  .rounded-bottom-xl-3 {
    border-bottom-right-radius: 5px 20px 5px 20px !important;
    border-bottom-left-radius: 5px 20px 5px 20px !important; }
  .rounded-bottom-xl-4 {
    border-bottom-right-radius: 5px 100px 5px 100px !important;
    border-bottom-left-radius: 5px 100px 5px 100px !important; }
  .rounded-bottom-xl-circle {
    border-bottom-right-radius: 50% !important;
    border-bottom-left-radius: 50% !important; }
  .rounded-bottom-xl-pill {
    border-bottom-right-radius: 50rem !important;
    border-bottom-left-radius: 50rem !important; }
  .rounded-bottom-xl-btn {
    border-bottom-right-radius: 5px 30px 5px 5px !important;
    border-bottom-left-radius: 5px 30px 5px 5px !important; }
  .rounded-bottom-xl-carte {
    border-bottom-right-radius: 10px 25px 10px 25px !important;
    border-bottom-left-radius: 10px 25px 10px 25px !important; }
  .rounded-start-xl-20 {
    border-bottom-left-radius: 2rem !important;
    border-top-left-radius: 2rem !important; }
  .rounded-start-xl-19 {
    border-bottom-left-radius: 1.9rem !important;
    border-top-left-radius: 1.9rem !important; }
  .rounded-start-xl-18 {
    border-bottom-left-radius: 1.8rem !important;
    border-top-left-radius: 1.8rem !important; }
  .rounded-start-xl-17 {
    border-bottom-left-radius: 1.7rem !important;
    border-top-left-radius: 1.7rem !important; }
  .rounded-start-xl-16 {
    border-bottom-left-radius: 1.6rem !important;
    border-top-left-radius: 1.6rem !important; }
  .rounded-start-xl-15 {
    border-bottom-left-radius: 1.5rem !important;
    border-top-left-radius: 1.5rem !important; }
  .rounded-start-xl-14 {
    border-bottom-left-radius: 1.4rem !important;
    border-top-left-radius: 1.4rem !important; }
  .rounded-start-xl-13 {
    border-bottom-left-radius: 1.3rem !important;
    border-top-left-radius: 1.3rem !important; }
  .rounded-start-xl-12 {
    border-bottom-left-radius: 1.2rem !important;
    border-top-left-radius: 1.2rem !important; }
  .rounded-start-xl-11 {
    border-bottom-left-radius: 1.1rem !important;
    border-top-left-radius: 1.1rem !important; }
  .rounded-start-xl-10 {
    border-bottom-left-radius: 1rem !important;
    border-top-left-radius: 1rem !important; }
  .rounded-start-xl-9 {
    border-bottom-left-radius: 0.9rem !important;
    border-top-left-radius: 0.9rem !important; }
  .rounded-start-xl-8 {
    border-bottom-left-radius: 0.8rem !important;
    border-top-left-radius: 0.8rem !important; }
  .rounded-start-xl-7 {
    border-bottom-left-radius: 0.7rem !important;
    border-top-left-radius: 0.7rem !important; }
  .rounded-start-xl-6 {
    border-bottom-left-radius: 0.6rem !important;
    border-top-left-radius: 0.6rem !important; }
  .rounded-start-xl-5 {
    border-bottom-left-radius: 0.5rem !important;
    border-top-left-radius: 0.5rem !important; }
  .rounded-start-xl {
    border-bottom-left-radius: 2px 5px 2px 5px !important;
    border-top-left-radius: 2px 5px 2px 5px !important; }
  .rounded-start-xl-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important; }
  .rounded-start-xl-1 {
    border-bottom-left-radius: 0.6rem !important;
    border-top-left-radius: 0.6rem !important; }
  .rounded-start-xl-2 {
    border-bottom-left-radius: 2px 5px 2px 5px !important;
    border-top-left-radius: 2px 5px 2px 5px !important; }
  .rounded-start-xl-3 {
    border-bottom-left-radius: 5px 20px 5px 20px !important;
    border-top-left-radius: 5px 20px 5px 20px !important; }
  .rounded-start-xl-4 {
    border-bottom-left-radius: 5px 100px 5px 100px !important;
    border-top-left-radius: 5px 100px 5px 100px !important; }
  .rounded-start-xl-circle {
    border-bottom-left-radius: 50% !important;
    border-top-left-radius: 50% !important; }
  .rounded-start-xl-pill {
    border-bottom-left-radius: 50rem !important;
    border-top-left-radius: 50rem !important; }
  .rounded-start-xl-btn {
    border-bottom-left-radius: 5px 30px 5px 5px !important;
    border-top-left-radius: 5px 30px 5px 5px !important; }
  .rounded-start-xl-carte {
    border-bottom-left-radius: 10px 25px 10px 25px !important;
    border-top-left-radius: 10px 25px 10px 25px !important; }
  .z-index-xl-1 {
    z-index: 1 !important; }
  .z-index-xl-2 {
    z-index: 2 !important; }
  .z-index-xl-3 {
    z-index: 3 !important; }
  .z-index-xl-4 {
    z-index: 4 !important; }
  .z-index-xl-5 {
    z-index: 5 !important; } }

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important; }
  .float-xxl-end {
    float: right !important; }
  .float-xxl-none {
    float: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-grid {
    display: grid !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .d-xxl-none {
    display: none !important; }
  .position-xxl-static {
    position: static !important; }
  .position-xxl-relative {
    position: relative !important; }
  .position-xxl-absolute {
    position: absolute !important; }
  .position-xxl-fixed {
    position: fixed !important; }
  .position-xxl-sticky {
    position: sticky !important; }
  .top-xxl-0 {
    top: 0 !important; }
  .top-xxl-5 {
    top: 5% !important; }
  .top-xxl-10 {
    top: 10% !important; }
  .top-xxl-15 {
    top: 15% !important; }
  .top-xxl-20 {
    top: 20% !important; }
  .top-xxl-25 {
    top: 25% !important; }
  .top-xxl-30 {
    top: 30% !important; }
  .top-xxl-40 {
    top: 40% !important; }
  .top-xxl-50 {
    top: 50% !important; }
  .top-xxl-80 {
    top: 80% !important; }
  .top-xxl-90 {
    top: 90% !important; }
  .top-xxl-100 {
    top: 100% !important; }
  .top-xxl-2px {
    top: 2px !important; }
  .top-xxl-100px {
    top: 100px !important; }
  .top-xxl-n30px {
    top: -30px !important; }
  .top-xxl-n60px {
    top: -60px !important; }
  .top-xxl-n130px {
    top: -130px !important; }
  .top-xxl-n150px {
    top: -150px !important; }
  .top-xxl-n170px {
    top: -170px !important; }
  .top-xxl-n300px {
    top: -300px !important; }
  .bottom-xxl-0 {
    bottom: 0 !important; }
  .bottom-xxl-5 {
    bottom: 5% !important; }
  .bottom-xxl-10 {
    bottom: 10% !important; }
  .bottom-xxl-15 {
    bottom: 15% !important; }
  .bottom-xxl-20 {
    bottom: 20% !important; }
  .bottom-xxl-25 {
    bottom: 25% !important; }
  .bottom-xxl-30 {
    bottom: 30% !important; }
  .bottom-xxl-40 {
    bottom: 40% !important; }
  .bottom-xxl-50 {
    bottom: 50% !important; }
  .bottom-xxl-80 {
    bottom: 80% !important; }
  .bottom-xxl-90 {
    bottom: 90% !important; }
  .bottom-xxl-100 {
    bottom: 100% !important; }
  .bottom-xxl-2px {
    bottom: 2px !important; }
  .bottom-xxl-100px {
    bottom: 100px !important; }
  .bottom-xxl-n30px {
    bottom: -30px !important; }
  .bottom-xxl-n60px {
    bottom: -60px !important; }
  .bottom-xxl-n130px {
    bottom: -130px !important; }
  .bottom-xxl-n150px {
    bottom: -150px !important; }
  .bottom-xxl-n170px {
    bottom: -170px !important; }
  .bottom-xxl-n300px {
    bottom: -300px !important; }
  .start-xxl-0 {
    left: 0 !important; }
  .start-xxl-5 {
    left: 5% !important; }
  .start-xxl-10 {
    left: 10% !important; }
  .start-xxl-15 {
    left: 15% !important; }
  .start-xxl-20 {
    left: 20% !important; }
  .start-xxl-25 {
    left: 25% !important; }
  .start-xxl-30 {
    left: 30% !important; }
  .start-xxl-40 {
    left: 40% !important; }
  .start-xxl-50 {
    left: 50% !important; }
  .start-xxl-80 {
    left: 80% !important; }
  .start-xxl-90 {
    left: 90% !important; }
  .start-xxl-100 {
    left: 100% !important; }
  .start-xxl-2px {
    left: 2px !important; }
  .start-xxl-100px {
    left: 100px !important; }
  .start-xxl-n30px {
    left: -30px !important; }
  .start-xxl-n60px {
    left: -60px !important; }
  .start-xxl-n130px {
    left: -130px !important; }
  .start-xxl-n150px {
    left: -150px !important; }
  .start-xxl-n170px {
    left: -170px !important; }
  .start-xxl-n300px {
    left: -300px !important; }
  .end-xxl-0 {
    right: 0 !important; }
  .end-xxl-5 {
    right: 5% !important; }
  .end-xxl-10 {
    right: 10% !important; }
  .end-xxl-15 {
    right: 15% !important; }
  .end-xxl-20 {
    right: 20% !important; }
  .end-xxl-25 {
    right: 25% !important; }
  .end-xxl-30 {
    right: 30% !important; }
  .end-xxl-40 {
    right: 40% !important; }
  .end-xxl-50 {
    right: 50% !important; }
  .end-xxl-80 {
    right: 80% !important; }
  .end-xxl-90 {
    right: 90% !important; }
  .end-xxl-100 {
    right: 100% !important; }
  .end-xxl-2px {
    right: 2px !important; }
  .end-xxl-100px {
    right: 100px !important; }
  .end-xxl-n30px {
    right: -30px !important; }
  .end-xxl-n60px {
    right: -60px !important; }
  .end-xxl-n130px {
    right: -130px !important; }
  .end-xxl-n150px {
    right: -150px !important; }
  .end-xxl-n170px {
    right: -170px !important; }
  .end-xxl-n300px {
    right: -300px !important; }
  .w-xxl-20 {
    width: 20% !important; }
  .w-xxl-25 {
    width: 25% !important; }
  .w-xxl-30 {
    width: 30% !important; }
  .w-xxl-40 {
    width: 40% !important; }
  .w-xxl-50 {
    width: 50% !important; }
  .w-xxl-70 {
    width: 70% !important; }
  .w-xxl-75 {
    width: 75% !important; }
  .w-xxl-80 {
    width: 80% !important; }
  .w-xxl-85 {
    width: 85% !important; }
  .w-xxl-90 {
    width: 90% !important; }
  .w-xxl-100 {
    width: 100% !important; }
  .w-xxl-26px {
    width: 26px !important; }
  .w-xxl-86px {
    width: 86px !important; }
  .w-xxl-125px {
    width: 125px !important; }
  .w-xxl-145px {
    width: 145px !important; }
  .w-xxl-161px {
    width: 161px !important; }
  .w-xxl-182px {
    width: 182px !important; }
  .w-xxl-205px {
    width: 205px !important; }
  .w-xxl-215px {
    width: 215px !important; }
  .w-xxl-235px {
    width: 235px !important; }
  .w-xxl-250px {
    width: 250px !important; }
  .w-xxl-260px {
    width: 260px !important; }
  .w-xxl-268px {
    width: 268px !important; }
  .w-xxl-283px {
    width: 283px !important; }
  .w-xxl-285px {
    width: 285px !important; }
  .w-xxl-300px {
    width: 300px !important; }
  .w-xxl-305px {
    width: 305px !important; }
  .w-xxl-310px {
    width: 310px !important; }
  .w-xxl-350px {
    width: 350px !important; }
  .w-xxl-410px {
    width: 410px !important; }
  .w-xxl-440px {
    width: 440px !important; }
  .w-xxl-460px {
    width: 460px !important; }
  .w-xxl-500px {
    width: 500px !important; }
  .w-xxl-520px {
    width: 520px !important; }
  .w-xxl-600px {
    width: 600px !important; }
  .w-xxl-657px {
    width: 657px !important; }
  .w-xxl-1015px {
    width: 1015px !important; }
  .w-xxl-1070px {
    width: 1070px !important; }
  .w-xxl-auto {
    width: auto !important; }
  .h-xxl-20 {
    height: 20% !important; }
  .h-xxl-25 {
    height: 25% !important; }
  .h-xxl-30 {
    height: 30% !important; }
  .h-xxl-40 {
    height: 40% !important; }
  .h-xxl-50 {
    height: 50% !important; }
  .h-xxl-70 {
    height: 70% !important; }
  .h-xxl-75 {
    height: 75% !important; }
  .h-xxl-80 {
    height: 80% !important; }
  .h-xxl-85 {
    height: 85% !important; }
  .h-xxl-90 {
    height: 90% !important; }
  .h-xxl-100 {
    height: 100% !important; }
  .h-xxl-26px {
    height: 26px !important; }
  .h-xxl-86px {
    height: 86px !important; }
  .h-xxl-125px {
    height: 125px !important; }
  .h-xxl-145px {
    height: 145px !important; }
  .h-xxl-161px {
    height: 161px !important; }
  .h-xxl-182px {
    height: 182px !important; }
  .h-xxl-205px {
    height: 205px !important; }
  .h-xxl-215px {
    height: 215px !important; }
  .h-xxl-235px {
    height: 235px !important; }
  .h-xxl-250px {
    height: 250px !important; }
  .h-xxl-260px {
    height: 260px !important; }
  .h-xxl-268px {
    height: 268px !important; }
  .h-xxl-283px {
    height: 283px !important; }
  .h-xxl-285px {
    height: 285px !important; }
  .h-xxl-300px {
    height: 300px !important; }
  .h-xxl-305px {
    height: 305px !important; }
  .h-xxl-310px {
    height: 310px !important; }
  .h-xxl-350px {
    height: 350px !important; }
  .h-xxl-410px {
    height: 410px !important; }
  .h-xxl-440px {
    height: 440px !important; }
  .h-xxl-460px {
    height: 460px !important; }
  .h-xxl-500px {
    height: 500px !important; }
  .h-xxl-520px {
    height: 520px !important; }
  .h-xxl-600px {
    height: 600px !important; }
  .h-xxl-657px {
    height: 657px !important; }
  .h-xxl-1015px {
    height: 1015px !important; }
  .h-xxl-1070px {
    height: 1070px !important; }
  .h-xxl-auto {
    height: auto !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-xxl-35 {
    gap: 3.5rem !important; }
  .gap-xxl-34 {
    gap: 3.4rem !important; }
  .gap-xxl-33 {
    gap: 3.3rem !important; }
  .gap-xxl-32 {
    gap: 3.2rem !important; }
  .gap-xxl-31 {
    gap: 3.1rem !important; }
  .gap-xxl-30 {
    gap: 3rem !important; }
  .gap-xxl-29 {
    gap: 2.9rem !important; }
  .gap-xxl-28 {
    gap: 2.8rem !important; }
  .gap-xxl-27 {
    gap: 2.7rem !important; }
  .gap-xxl-26 {
    gap: 2.6rem !important; }
  .gap-xxl-25 {
    gap: 2.5rem !important; }
  .gap-xxl-24 {
    gap: 2.4rem !important; }
  .gap-xxl-23 {
    gap: 2.3rem !important; }
  .gap-xxl-22 {
    gap: 2.2rem !important; }
  .gap-xxl-21 {
    gap: 2.1rem !important; }
  .gap-xxl-20 {
    gap: 2rem !important; }
  .gap-xxl-19 {
    gap: 1.9rem !important; }
  .gap-xxl-18 {
    gap: 1.8rem !important; }
  .gap-xxl-17 {
    gap: 1.7rem !important; }
  .gap-xxl-16 {
    gap: 1.6rem !important; }
  .gap-xxl-15 {
    gap: 1.5rem !important; }
  .gap-xxl-14 {
    gap: 1.4rem !important; }
  .gap-xxl-13 {
    gap: 1.3rem !important; }
  .gap-xxl-12 {
    gap: 1.2rem !important; }
  .gap-xxl-11 {
    gap: 1.1rem !important; }
  .gap-xxl-10 {
    gap: 1rem !important; }
  .gap-xxl-0 {
    gap: 0 !important; }
  .gap-xxl-1 {
    gap: 0.25rem !important; }
  .gap-xxl-2 {
    gap: 0.5rem !important; }
  .gap-xxl-3 {
    gap: 1rem !important; }
  .gap-xxl-4 {
    gap: 1.5rem !important; }
  .gap-xxl-5 {
    gap: 0.5rem !important; }
  .gap-xxl-6 {
    gap: 0.6rem !important; }
  .gap-xxl-8 {
    gap: 0.8rem !important; }
  .gap-xxl-40 {
    gap: 4rem !important; }
  .gap-xxl-45 {
    gap: 4.5rem !important; }
  .gap-xxl-50 {
    gap: 5rem !important; }
  .gap-xxl-55 {
    gap: 5.5rem !important; }
  .gap-xxl-60 {
    gap: 6rem !important; }
  .gap-xxl-70 {
    gap: 7rem !important; }
  .gap-xxl-80 {
    gap: 8rem !important; }
  .gap-xxl-90 {
    gap: 9rem !important; }
  .gap-xxl-100 {
    gap: 10rem !important; }
  .gap-xxl-105 {
    gap: 10.5rem !important; }
  .gap-xxl-110 {
    gap: 11rem !important; }
  .gap-xxl-125 {
    gap: 12.5rem !important; }
  .gap-xxl-130 {
    gap: 13rem !important; }
  .gap-xxl-145 {
    gap: 14.5rem !important; }
  .gap-xxl-170 {
    gap: 17rem !important; }
  .gap-xxl-235 {
    gap: 23.5rem !important; }
  .gap-xxl-n20 {
    gap: -2rem !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .order-xxl-first {
    order: -1 !important; }
  .order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5 !important; }
  .order-xxl-last {
    order: 6 !important; }
  .m-xxl-35 {
    margin: 3.5rem !important; }
  .m-xxl-34 {
    margin: 3.4rem !important; }
  .m-xxl-33 {
    margin: 3.3rem !important; }
  .m-xxl-32 {
    margin: 3.2rem !important; }
  .m-xxl-31 {
    margin: 3.1rem !important; }
  .m-xxl-30 {
    margin: 3rem !important; }
  .m-xxl-29 {
    margin: 2.9rem !important; }
  .m-xxl-28 {
    margin: 2.8rem !important; }
  .m-xxl-27 {
    margin: 2.7rem !important; }
  .m-xxl-26 {
    margin: 2.6rem !important; }
  .m-xxl-25 {
    margin: 2.5rem !important; }
  .m-xxl-24 {
    margin: 2.4rem !important; }
  .m-xxl-23 {
    margin: 2.3rem !important; }
  .m-xxl-22 {
    margin: 2.2rem !important; }
  .m-xxl-21 {
    margin: 2.1rem !important; }
  .m-xxl-20 {
    margin: 2rem !important; }
  .m-xxl-19 {
    margin: 1.9rem !important; }
  .m-xxl-18 {
    margin: 1.8rem !important; }
  .m-xxl-17 {
    margin: 1.7rem !important; }
  .m-xxl-16 {
    margin: 1.6rem !important; }
  .m-xxl-15 {
    margin: 1.5rem !important; }
  .m-xxl-14 {
    margin: 1.4rem !important; }
  .m-xxl-13 {
    margin: 1.3rem !important; }
  .m-xxl-12 {
    margin: 1.2rem !important; }
  .m-xxl-11 {
    margin: 1.1rem !important; }
  .m-xxl-10 {
    margin: 1rem !important; }
  .m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .m-xxl-5 {
    margin: 0.5rem !important; }
  .m-xxl-6 {
    margin: 0.6rem !important; }
  .m-xxl-8 {
    margin: 0.8rem !important; }
  .m-xxl-40 {
    margin: 4rem !important; }
  .m-xxl-45 {
    margin: 4.5rem !important; }
  .m-xxl-50 {
    margin: 5rem !important; }
  .m-xxl-55 {
    margin: 5.5rem !important; }
  .m-xxl-60 {
    margin: 6rem !important; }
  .m-xxl-70 {
    margin: 7rem !important; }
  .m-xxl-80 {
    margin: 8rem !important; }
  .m-xxl-90 {
    margin: 9rem !important; }
  .m-xxl-100 {
    margin: 10rem !important; }
  .m-xxl-105 {
    margin: 10.5rem !important; }
  .m-xxl-110 {
    margin: 11rem !important; }
  .m-xxl-125 {
    margin: 12.5rem !important; }
  .m-xxl-130 {
    margin: 13rem !important; }
  .m-xxl-145 {
    margin: 14.5rem !important; }
  .m-xxl-170 {
    margin: 17rem !important; }
  .m-xxl-235 {
    margin: 23.5rem !important; }
  .m-xxl-n20 {
    margin: -2rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mx-xxl-35 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important; }
  .mx-xxl-34 {
    margin-right: 3.4rem !important;
    margin-left: 3.4rem !important; }
  .mx-xxl-33 {
    margin-right: 3.3rem !important;
    margin-left: 3.3rem !important; }
  .mx-xxl-32 {
    margin-right: 3.2rem !important;
    margin-left: 3.2rem !important; }
  .mx-xxl-31 {
    margin-right: 3.1rem !important;
    margin-left: 3.1rem !important; }
  .mx-xxl-30 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xxl-29 {
    margin-right: 2.9rem !important;
    margin-left: 2.9rem !important; }
  .mx-xxl-28 {
    margin-right: 2.8rem !important;
    margin-left: 2.8rem !important; }
  .mx-xxl-27 {
    margin-right: 2.7rem !important;
    margin-left: 2.7rem !important; }
  .mx-xxl-26 {
    margin-right: 2.6rem !important;
    margin-left: 2.6rem !important; }
  .mx-xxl-25 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important; }
  .mx-xxl-24 {
    margin-right: 2.4rem !important;
    margin-left: 2.4rem !important; }
  .mx-xxl-23 {
    margin-right: 2.3rem !important;
    margin-left: 2.3rem !important; }
  .mx-xxl-22 {
    margin-right: 2.2rem !important;
    margin-left: 2.2rem !important; }
  .mx-xxl-21 {
    margin-right: 2.1rem !important;
    margin-left: 2.1rem !important; }
  .mx-xxl-20 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  .mx-xxl-19 {
    margin-right: 1.9rem !important;
    margin-left: 1.9rem !important; }
  .mx-xxl-18 {
    margin-right: 1.8rem !important;
    margin-left: 1.8rem !important; }
  .mx-xxl-17 {
    margin-right: 1.7rem !important;
    margin-left: 1.7rem !important; }
  .mx-xxl-16 {
    margin-right: 1.6rem !important;
    margin-left: 1.6rem !important; }
  .mx-xxl-15 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xxl-14 {
    margin-right: 1.4rem !important;
    margin-left: 1.4rem !important; }
  .mx-xxl-13 {
    margin-right: 1.3rem !important;
    margin-left: 1.3rem !important; }
  .mx-xxl-12 {
    margin-right: 1.2rem !important;
    margin-left: 1.2rem !important; }
  .mx-xxl-11 {
    margin-right: 1.1rem !important;
    margin-left: 1.1rem !important; }
  .mx-xxl-10 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xxl-5 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xxl-6 {
    margin-right: 0.6rem !important;
    margin-left: 0.6rem !important; }
  .mx-xxl-8 {
    margin-right: 0.8rem !important;
    margin-left: 0.8rem !important; }
  .mx-xxl-40 {
    margin-right: 4rem !important;
    margin-left: 4rem !important; }
  .mx-xxl-45 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important; }
  .mx-xxl-50 {
    margin-right: 5rem !important;
    margin-left: 5rem !important; }
  .mx-xxl-55 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important; }
  .mx-xxl-60 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-xxl-70 {
    margin-right: 7rem !important;
    margin-left: 7rem !important; }
  .mx-xxl-80 {
    margin-right: 8rem !important;
    margin-left: 8rem !important; }
  .mx-xxl-90 {
    margin-right: 9rem !important;
    margin-left: 9rem !important; }
  .mx-xxl-100 {
    margin-right: 10rem !important;
    margin-left: 10rem !important; }
  .mx-xxl-105 {
    margin-right: 10.5rem !important;
    margin-left: 10.5rem !important; }
  .mx-xxl-110 {
    margin-right: 11rem !important;
    margin-left: 11rem !important; }
  .mx-xxl-125 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important; }
  .mx-xxl-130 {
    margin-right: 13rem !important;
    margin-left: 13rem !important; }
  .mx-xxl-145 {
    margin-right: 14.5rem !important;
    margin-left: 14.5rem !important; }
  .mx-xxl-170 {
    margin-right: 17rem !important;
    margin-left: 17rem !important; }
  .mx-xxl-235 {
    margin-right: 23.5rem !important;
    margin-left: 23.5rem !important; }
  .mx-xxl-n20 {
    margin-right: -2rem !important;
    margin-left: -2rem !important; }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxl-35 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important; }
  .my-xxl-34 {
    margin-top: 3.4rem !important;
    margin-bottom: 3.4rem !important; }
  .my-xxl-33 {
    margin-top: 3.3rem !important;
    margin-bottom: 3.3rem !important; }
  .my-xxl-32 {
    margin-top: 3.2rem !important;
    margin-bottom: 3.2rem !important; }
  .my-xxl-31 {
    margin-top: 3.1rem !important;
    margin-bottom: 3.1rem !important; }
  .my-xxl-30 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xxl-29 {
    margin-top: 2.9rem !important;
    margin-bottom: 2.9rem !important; }
  .my-xxl-28 {
    margin-top: 2.8rem !important;
    margin-bottom: 2.8rem !important; }
  .my-xxl-27 {
    margin-top: 2.7rem !important;
    margin-bottom: 2.7rem !important; }
  .my-xxl-26 {
    margin-top: 2.6rem !important;
    margin-bottom: 2.6rem !important; }
  .my-xxl-25 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .my-xxl-24 {
    margin-top: 2.4rem !important;
    margin-bottom: 2.4rem !important; }
  .my-xxl-23 {
    margin-top: 2.3rem !important;
    margin-bottom: 2.3rem !important; }
  .my-xxl-22 {
    margin-top: 2.2rem !important;
    margin-bottom: 2.2rem !important; }
  .my-xxl-21 {
    margin-top: 2.1rem !important;
    margin-bottom: 2.1rem !important; }
  .my-xxl-20 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .my-xxl-19 {
    margin-top: 1.9rem !important;
    margin-bottom: 1.9rem !important; }
  .my-xxl-18 {
    margin-top: 1.8rem !important;
    margin-bottom: 1.8rem !important; }
  .my-xxl-17 {
    margin-top: 1.7rem !important;
    margin-bottom: 1.7rem !important; }
  .my-xxl-16 {
    margin-top: 1.6rem !important;
    margin-bottom: 1.6rem !important; }
  .my-xxl-15 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xxl-14 {
    margin-top: 1.4rem !important;
    margin-bottom: 1.4rem !important; }
  .my-xxl-13 {
    margin-top: 1.3rem !important;
    margin-bottom: 1.3rem !important; }
  .my-xxl-12 {
    margin-top: 1.2rem !important;
    margin-bottom: 1.2rem !important; }
  .my-xxl-11 {
    margin-top: 1.1rem !important;
    margin-bottom: 1.1rem !important; }
  .my-xxl-10 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xxl-5 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xxl-6 {
    margin-top: 0.6rem !important;
    margin-bottom: 0.6rem !important; }
  .my-xxl-8 {
    margin-top: 0.8rem !important;
    margin-bottom: 0.8rem !important; }
  .my-xxl-40 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .my-xxl-45 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important; }
  .my-xxl-50 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .my-xxl-55 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important; }
  .my-xxl-60 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-xxl-70 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important; }
  .my-xxl-80 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important; }
  .my-xxl-90 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important; }
  .my-xxl-100 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important; }
  .my-xxl-105 {
    margin-top: 10.5rem !important;
    margin-bottom: 10.5rem !important; }
  .my-xxl-110 {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important; }
  .my-xxl-125 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important; }
  .my-xxl-130 {
    margin-top: 13rem !important;
    margin-bottom: 13rem !important; }
  .my-xxl-145 {
    margin-top: 14.5rem !important;
    margin-bottom: 14.5rem !important; }
  .my-xxl-170 {
    margin-top: 17rem !important;
    margin-bottom: 17rem !important; }
  .my-xxl-235 {
    margin-top: 23.5rem !important;
    margin-bottom: 23.5rem !important; }
  .my-xxl-n20 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important; }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxl-35 {
    margin-top: 3.5rem !important; }
  .mt-xxl-34 {
    margin-top: 3.4rem !important; }
  .mt-xxl-33 {
    margin-top: 3.3rem !important; }
  .mt-xxl-32 {
    margin-top: 3.2rem !important; }
  .mt-xxl-31 {
    margin-top: 3.1rem !important; }
  .mt-xxl-30 {
    margin-top: 3rem !important; }
  .mt-xxl-29 {
    margin-top: 2.9rem !important; }
  .mt-xxl-28 {
    margin-top: 2.8rem !important; }
  .mt-xxl-27 {
    margin-top: 2.7rem !important; }
  .mt-xxl-26 {
    margin-top: 2.6rem !important; }
  .mt-xxl-25 {
    margin-top: 2.5rem !important; }
  .mt-xxl-24 {
    margin-top: 2.4rem !important; }
  .mt-xxl-23 {
    margin-top: 2.3rem !important; }
  .mt-xxl-22 {
    margin-top: 2.2rem !important; }
  .mt-xxl-21 {
    margin-top: 2.1rem !important; }
  .mt-xxl-20 {
    margin-top: 2rem !important; }
  .mt-xxl-19 {
    margin-top: 1.9rem !important; }
  .mt-xxl-18 {
    margin-top: 1.8rem !important; }
  .mt-xxl-17 {
    margin-top: 1.7rem !important; }
  .mt-xxl-16 {
    margin-top: 1.6rem !important; }
  .mt-xxl-15 {
    margin-top: 1.5rem !important; }
  .mt-xxl-14 {
    margin-top: 1.4rem !important; }
  .mt-xxl-13 {
    margin-top: 1.3rem !important; }
  .mt-xxl-12 {
    margin-top: 1.2rem !important; }
  .mt-xxl-11 {
    margin-top: 1.1rem !important; }
  .mt-xxl-10 {
    margin-top: 1rem !important; }
  .mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-1 {
    margin-top: 0.25rem !important; }
  .mt-xxl-2 {
    margin-top: 0.5rem !important; }
  .mt-xxl-3 {
    margin-top: 1rem !important; }
  .mt-xxl-4 {
    margin-top: 1.5rem !important; }
  .mt-xxl-5 {
    margin-top: 0.5rem !important; }
  .mt-xxl-6 {
    margin-top: 0.6rem !important; }
  .mt-xxl-8 {
    margin-top: 0.8rem !important; }
  .mt-xxl-40 {
    margin-top: 4rem !important; }
  .mt-xxl-45 {
    margin-top: 4.5rem !important; }
  .mt-xxl-50 {
    margin-top: 5rem !important; }
  .mt-xxl-55 {
    margin-top: 5.5rem !important; }
  .mt-xxl-60 {
    margin-top: 6rem !important; }
  .mt-xxl-70 {
    margin-top: 7rem !important; }
  .mt-xxl-80 {
    margin-top: 8rem !important; }
  .mt-xxl-90 {
    margin-top: 9rem !important; }
  .mt-xxl-100 {
    margin-top: 10rem !important; }
  .mt-xxl-105 {
    margin-top: 10.5rem !important; }
  .mt-xxl-110 {
    margin-top: 11rem !important; }
  .mt-xxl-125 {
    margin-top: 12.5rem !important; }
  .mt-xxl-130 {
    margin-top: 13rem !important; }
  .mt-xxl-145 {
    margin-top: 14.5rem !important; }
  .mt-xxl-170 {
    margin-top: 17rem !important; }
  .mt-xxl-235 {
    margin-top: 23.5rem !important; }
  .mt-xxl-n20 {
    margin-top: -2rem !important; }
  .mt-xxl-auto {
    margin-top: auto !important; }
  .me-xxl-35 {
    margin-right: 3.5rem !important; }
  .me-xxl-34 {
    margin-right: 3.4rem !important; }
  .me-xxl-33 {
    margin-right: 3.3rem !important; }
  .me-xxl-32 {
    margin-right: 3.2rem !important; }
  .me-xxl-31 {
    margin-right: 3.1rem !important; }
  .me-xxl-30 {
    margin-right: 3rem !important; }
  .me-xxl-29 {
    margin-right: 2.9rem !important; }
  .me-xxl-28 {
    margin-right: 2.8rem !important; }
  .me-xxl-27 {
    margin-right: 2.7rem !important; }
  .me-xxl-26 {
    margin-right: 2.6rem !important; }
  .me-xxl-25 {
    margin-right: 2.5rem !important; }
  .me-xxl-24 {
    margin-right: 2.4rem !important; }
  .me-xxl-23 {
    margin-right: 2.3rem !important; }
  .me-xxl-22 {
    margin-right: 2.2rem !important; }
  .me-xxl-21 {
    margin-right: 2.1rem !important; }
  .me-xxl-20 {
    margin-right: 2rem !important; }
  .me-xxl-19 {
    margin-right: 1.9rem !important; }
  .me-xxl-18 {
    margin-right: 1.8rem !important; }
  .me-xxl-17 {
    margin-right: 1.7rem !important; }
  .me-xxl-16 {
    margin-right: 1.6rem !important; }
  .me-xxl-15 {
    margin-right: 1.5rem !important; }
  .me-xxl-14 {
    margin-right: 1.4rem !important; }
  .me-xxl-13 {
    margin-right: 1.3rem !important; }
  .me-xxl-12 {
    margin-right: 1.2rem !important; }
  .me-xxl-11 {
    margin-right: 1.1rem !important; }
  .me-xxl-10 {
    margin-right: 1rem !important; }
  .me-xxl-0 {
    margin-right: 0 !important; }
  .me-xxl-1 {
    margin-right: 0.25rem !important; }
  .me-xxl-2 {
    margin-right: 0.5rem !important; }
  .me-xxl-3 {
    margin-right: 1rem !important; }
  .me-xxl-4 {
    margin-right: 1.5rem !important; }
  .me-xxl-5 {
    margin-right: 0.5rem !important; }
  .me-xxl-6 {
    margin-right: 0.6rem !important; }
  .me-xxl-8 {
    margin-right: 0.8rem !important; }
  .me-xxl-40 {
    margin-right: 4rem !important; }
  .me-xxl-45 {
    margin-right: 4.5rem !important; }
  .me-xxl-50 {
    margin-right: 5rem !important; }
  .me-xxl-55 {
    margin-right: 5.5rem !important; }
  .me-xxl-60 {
    margin-right: 6rem !important; }
  .me-xxl-70 {
    margin-right: 7rem !important; }
  .me-xxl-80 {
    margin-right: 8rem !important; }
  .me-xxl-90 {
    margin-right: 9rem !important; }
  .me-xxl-100 {
    margin-right: 10rem !important; }
  .me-xxl-105 {
    margin-right: 10.5rem !important; }
  .me-xxl-110 {
    margin-right: 11rem !important; }
  .me-xxl-125 {
    margin-right: 12.5rem !important; }
  .me-xxl-130 {
    margin-right: 13rem !important; }
  .me-xxl-145 {
    margin-right: 14.5rem !important; }
  .me-xxl-170 {
    margin-right: 17rem !important; }
  .me-xxl-235 {
    margin-right: 23.5rem !important; }
  .me-xxl-n20 {
    margin-right: -2rem !important; }
  .me-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-35 {
    margin-bottom: 3.5rem !important; }
  .mb-xxl-34 {
    margin-bottom: 3.4rem !important; }
  .mb-xxl-33 {
    margin-bottom: 3.3rem !important; }
  .mb-xxl-32 {
    margin-bottom: 3.2rem !important; }
  .mb-xxl-31 {
    margin-bottom: 3.1rem !important; }
  .mb-xxl-30 {
    margin-bottom: 3rem !important; }
  .mb-xxl-29 {
    margin-bottom: 2.9rem !important; }
  .mb-xxl-28 {
    margin-bottom: 2.8rem !important; }
  .mb-xxl-27 {
    margin-bottom: 2.7rem !important; }
  .mb-xxl-26 {
    margin-bottom: 2.6rem !important; }
  .mb-xxl-25 {
    margin-bottom: 2.5rem !important; }
  .mb-xxl-24 {
    margin-bottom: 2.4rem !important; }
  .mb-xxl-23 {
    margin-bottom: 2.3rem !important; }
  .mb-xxl-22 {
    margin-bottom: 2.2rem !important; }
  .mb-xxl-21 {
    margin-bottom: 2.1rem !important; }
  .mb-xxl-20 {
    margin-bottom: 2rem !important; }
  .mb-xxl-19 {
    margin-bottom: 1.9rem !important; }
  .mb-xxl-18 {
    margin-bottom: 1.8rem !important; }
  .mb-xxl-17 {
    margin-bottom: 1.7rem !important; }
  .mb-xxl-16 {
    margin-bottom: 1.6rem !important; }
  .mb-xxl-15 {
    margin-bottom: 1.5rem !important; }
  .mb-xxl-14 {
    margin-bottom: 1.4rem !important; }
  .mb-xxl-13 {
    margin-bottom: 1.3rem !important; }
  .mb-xxl-12 {
    margin-bottom: 1.2rem !important; }
  .mb-xxl-11 {
    margin-bottom: 1.1rem !important; }
  .mb-xxl-10 {
    margin-bottom: 1rem !important; }
  .mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xxl-3 {
    margin-bottom: 1rem !important; }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xxl-5 {
    margin-bottom: 0.5rem !important; }
  .mb-xxl-6 {
    margin-bottom: 0.6rem !important; }
  .mb-xxl-8 {
    margin-bottom: 0.8rem !important; }
  .mb-xxl-40 {
    margin-bottom: 4rem !important; }
  .mb-xxl-45 {
    margin-bottom: 4.5rem !important; }
  .mb-xxl-50 {
    margin-bottom: 5rem !important; }
  .mb-xxl-55 {
    margin-bottom: 5.5rem !important; }
  .mb-xxl-60 {
    margin-bottom: 6rem !important; }
  .mb-xxl-70 {
    margin-bottom: 7rem !important; }
  .mb-xxl-80 {
    margin-bottom: 8rem !important; }
  .mb-xxl-90 {
    margin-bottom: 9rem !important; }
  .mb-xxl-100 {
    margin-bottom: 10rem !important; }
  .mb-xxl-105 {
    margin-bottom: 10.5rem !important; }
  .mb-xxl-110 {
    margin-bottom: 11rem !important; }
  .mb-xxl-125 {
    margin-bottom: 12.5rem !important; }
  .mb-xxl-130 {
    margin-bottom: 13rem !important; }
  .mb-xxl-145 {
    margin-bottom: 14.5rem !important; }
  .mb-xxl-170 {
    margin-bottom: 17rem !important; }
  .mb-xxl-235 {
    margin-bottom: 23.5rem !important; }
  .mb-xxl-n20 {
    margin-bottom: -2rem !important; }
  .mb-xxl-auto {
    margin-bottom: auto !important; }
  .ms-xxl-35 {
    margin-left: 3.5rem !important; }
  .ms-xxl-34 {
    margin-left: 3.4rem !important; }
  .ms-xxl-33 {
    margin-left: 3.3rem !important; }
  .ms-xxl-32 {
    margin-left: 3.2rem !important; }
  .ms-xxl-31 {
    margin-left: 3.1rem !important; }
  .ms-xxl-30 {
    margin-left: 3rem !important; }
  .ms-xxl-29 {
    margin-left: 2.9rem !important; }
  .ms-xxl-28 {
    margin-left: 2.8rem !important; }
  .ms-xxl-27 {
    margin-left: 2.7rem !important; }
  .ms-xxl-26 {
    margin-left: 2.6rem !important; }
  .ms-xxl-25 {
    margin-left: 2.5rem !important; }
  .ms-xxl-24 {
    margin-left: 2.4rem !important; }
  .ms-xxl-23 {
    margin-left: 2.3rem !important; }
  .ms-xxl-22 {
    margin-left: 2.2rem !important; }
  .ms-xxl-21 {
    margin-left: 2.1rem !important; }
  .ms-xxl-20 {
    margin-left: 2rem !important; }
  .ms-xxl-19 {
    margin-left: 1.9rem !important; }
  .ms-xxl-18 {
    margin-left: 1.8rem !important; }
  .ms-xxl-17 {
    margin-left: 1.7rem !important; }
  .ms-xxl-16 {
    margin-left: 1.6rem !important; }
  .ms-xxl-15 {
    margin-left: 1.5rem !important; }
  .ms-xxl-14 {
    margin-left: 1.4rem !important; }
  .ms-xxl-13 {
    margin-left: 1.3rem !important; }
  .ms-xxl-12 {
    margin-left: 1.2rem !important; }
  .ms-xxl-11 {
    margin-left: 1.1rem !important; }
  .ms-xxl-10 {
    margin-left: 1rem !important; }
  .ms-xxl-0 {
    margin-left: 0 !important; }
  .ms-xxl-1 {
    margin-left: 0.25rem !important; }
  .ms-xxl-2 {
    margin-left: 0.5rem !important; }
  .ms-xxl-3 {
    margin-left: 1rem !important; }
  .ms-xxl-4 {
    margin-left: 1.5rem !important; }
  .ms-xxl-5 {
    margin-left: 0.5rem !important; }
  .ms-xxl-6 {
    margin-left: 0.6rem !important; }
  .ms-xxl-8 {
    margin-left: 0.8rem !important; }
  .ms-xxl-40 {
    margin-left: 4rem !important; }
  .ms-xxl-45 {
    margin-left: 4.5rem !important; }
  .ms-xxl-50 {
    margin-left: 5rem !important; }
  .ms-xxl-55 {
    margin-left: 5.5rem !important; }
  .ms-xxl-60 {
    margin-left: 6rem !important; }
  .ms-xxl-70 {
    margin-left: 7rem !important; }
  .ms-xxl-80 {
    margin-left: 8rem !important; }
  .ms-xxl-90 {
    margin-left: 9rem !important; }
  .ms-xxl-100 {
    margin-left: 10rem !important; }
  .ms-xxl-105 {
    margin-left: 10.5rem !important; }
  .ms-xxl-110 {
    margin-left: 11rem !important; }
  .ms-xxl-125 {
    margin-left: 12.5rem !important; }
  .ms-xxl-130 {
    margin-left: 13rem !important; }
  .ms-xxl-145 {
    margin-left: 14.5rem !important; }
  .ms-xxl-170 {
    margin-left: 17rem !important; }
  .ms-xxl-235 {
    margin-left: 23.5rem !important; }
  .ms-xxl-n20 {
    margin-left: -2rem !important; }
  .ms-xxl-auto {
    margin-left: auto !important; }
  .p-xxl-35 {
    padding: 3.5rem !important; }
  .p-xxl-34 {
    padding: 3.4rem !important; }
  .p-xxl-33 {
    padding: 3.3rem !important; }
  .p-xxl-32 {
    padding: 3.2rem !important; }
  .p-xxl-31 {
    padding: 3.1rem !important; }
  .p-xxl-30 {
    padding: 3rem !important; }
  .p-xxl-29 {
    padding: 2.9rem !important; }
  .p-xxl-28 {
    padding: 2.8rem !important; }
  .p-xxl-27 {
    padding: 2.7rem !important; }
  .p-xxl-26 {
    padding: 2.6rem !important; }
  .p-xxl-25 {
    padding: 2.5rem !important; }
  .p-xxl-24 {
    padding: 2.4rem !important; }
  .p-xxl-23 {
    padding: 2.3rem !important; }
  .p-xxl-22 {
    padding: 2.2rem !important; }
  .p-xxl-21 {
    padding: 2.1rem !important; }
  .p-xxl-20 {
    padding: 2rem !important; }
  .p-xxl-19 {
    padding: 1.9rem !important; }
  .p-xxl-18 {
    padding: 1.8rem !important; }
  .p-xxl-17 {
    padding: 1.7rem !important; }
  .p-xxl-16 {
    padding: 1.6rem !important; }
  .p-xxl-15 {
    padding: 1.5rem !important; }
  .p-xxl-14 {
    padding: 1.4rem !important; }
  .p-xxl-13 {
    padding: 1.3rem !important; }
  .p-xxl-12 {
    padding: 1.2rem !important; }
  .p-xxl-11 {
    padding: 1.1rem !important; }
  .p-xxl-10 {
    padding: 1rem !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .p-xxl-5 {
    padding: 0.5rem !important; }
  .p-xxl-6 {
    padding: 0.6rem !important; }
  .p-xxl-8 {
    padding: 0.8rem !important; }
  .p-xxl-40 {
    padding: 4rem !important; }
  .p-xxl-45 {
    padding: 4.5rem !important; }
  .p-xxl-50 {
    padding: 5rem !important; }
  .p-xxl-55 {
    padding: 5.5rem !important; }
  .p-xxl-60 {
    padding: 6rem !important; }
  .p-xxl-70 {
    padding: 7rem !important; }
  .p-xxl-80 {
    padding: 8rem !important; }
  .p-xxl-90 {
    padding: 9rem !important; }
  .p-xxl-100 {
    padding: 10rem !important; }
  .p-xxl-105 {
    padding: 10.5rem !important; }
  .p-xxl-110 {
    padding: 11rem !important; }
  .p-xxl-125 {
    padding: 12.5rem !important; }
  .p-xxl-130 {
    padding: 13rem !important; }
  .p-xxl-145 {
    padding: 14.5rem !important; }
  .p-xxl-170 {
    padding: 17rem !important; }
  .p-xxl-235 {
    padding: 23.5rem !important; }
  .p-xxl-n20 {
    padding: -2rem !important; }
  .px-xxl-35 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important; }
  .px-xxl-34 {
    padding-right: 3.4rem !important;
    padding-left: 3.4rem !important; }
  .px-xxl-33 {
    padding-right: 3.3rem !important;
    padding-left: 3.3rem !important; }
  .px-xxl-32 {
    padding-right: 3.2rem !important;
    padding-left: 3.2rem !important; }
  .px-xxl-31 {
    padding-right: 3.1rem !important;
    padding-left: 3.1rem !important; }
  .px-xxl-30 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-xxl-29 {
    padding-right: 2.9rem !important;
    padding-left: 2.9rem !important; }
  .px-xxl-28 {
    padding-right: 2.8rem !important;
    padding-left: 2.8rem !important; }
  .px-xxl-27 {
    padding-right: 2.7rem !important;
    padding-left: 2.7rem !important; }
  .px-xxl-26 {
    padding-right: 2.6rem !important;
    padding-left: 2.6rem !important; }
  .px-xxl-25 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important; }
  .px-xxl-24 {
    padding-right: 2.4rem !important;
    padding-left: 2.4rem !important; }
  .px-xxl-23 {
    padding-right: 2.3rem !important;
    padding-left: 2.3rem !important; }
  .px-xxl-22 {
    padding-right: 2.2rem !important;
    padding-left: 2.2rem !important; }
  .px-xxl-21 {
    padding-right: 2.1rem !important;
    padding-left: 2.1rem !important; }
  .px-xxl-20 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .px-xxl-19 {
    padding-right: 1.9rem !important;
    padding-left: 1.9rem !important; }
  .px-xxl-18 {
    padding-right: 1.8rem !important;
    padding-left: 1.8rem !important; }
  .px-xxl-17 {
    padding-right: 1.7rem !important;
    padding-left: 1.7rem !important; }
  .px-xxl-16 {
    padding-right: 1.6rem !important;
    padding-left: 1.6rem !important; }
  .px-xxl-15 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xxl-14 {
    padding-right: 1.4rem !important;
    padding-left: 1.4rem !important; }
  .px-xxl-13 {
    padding-right: 1.3rem !important;
    padding-left: 1.3rem !important; }
  .px-xxl-12 {
    padding-right: 1.2rem !important;
    padding-left: 1.2rem !important; }
  .px-xxl-11 {
    padding-right: 1.1rem !important;
    padding-left: 1.1rem !important; }
  .px-xxl-10 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xxl-5 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xxl-6 {
    padding-right: 0.6rem !important;
    padding-left: 0.6rem !important; }
  .px-xxl-8 {
    padding-right: 0.8rem !important;
    padding-left: 0.8rem !important; }
  .px-xxl-40 {
    padding-right: 4rem !important;
    padding-left: 4rem !important; }
  .px-xxl-45 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important; }
  .px-xxl-50 {
    padding-right: 5rem !important;
    padding-left: 5rem !important; }
  .px-xxl-55 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important; }
  .px-xxl-60 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .px-xxl-70 {
    padding-right: 7rem !important;
    padding-left: 7rem !important; }
  .px-xxl-80 {
    padding-right: 8rem !important;
    padding-left: 8rem !important; }
  .px-xxl-90 {
    padding-right: 9rem !important;
    padding-left: 9rem !important; }
  .px-xxl-100 {
    padding-right: 10rem !important;
    padding-left: 10rem !important; }
  .px-xxl-105 {
    padding-right: 10.5rem !important;
    padding-left: 10.5rem !important; }
  .px-xxl-110 {
    padding-right: 11rem !important;
    padding-left: 11rem !important; }
  .px-xxl-125 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important; }
  .px-xxl-130 {
    padding-right: 13rem !important;
    padding-left: 13rem !important; }
  .px-xxl-145 {
    padding-right: 14.5rem !important;
    padding-left: 14.5rem !important; }
  .px-xxl-170 {
    padding-right: 17rem !important;
    padding-left: 17rem !important; }
  .px-xxl-235 {
    padding-right: 23.5rem !important;
    padding-left: 23.5rem !important; }
  .px-xxl-n20 {
    padding-right: -2rem !important;
    padding-left: -2rem !important; }
  .py-xxl-35 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important; }
  .py-xxl-34 {
    padding-top: 3.4rem !important;
    padding-bottom: 3.4rem !important; }
  .py-xxl-33 {
    padding-top: 3.3rem !important;
    padding-bottom: 3.3rem !important; }
  .py-xxl-32 {
    padding-top: 3.2rem !important;
    padding-bottom: 3.2rem !important; }
  .py-xxl-31 {
    padding-top: 3.1rem !important;
    padding-bottom: 3.1rem !important; }
  .py-xxl-30 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-xxl-29 {
    padding-top: 2.9rem !important;
    padding-bottom: 2.9rem !important; }
  .py-xxl-28 {
    padding-top: 2.8rem !important;
    padding-bottom: 2.8rem !important; }
  .py-xxl-27 {
    padding-top: 2.7rem !important;
    padding-bottom: 2.7rem !important; }
  .py-xxl-26 {
    padding-top: 2.6rem !important;
    padding-bottom: 2.6rem !important; }
  .py-xxl-25 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .py-xxl-24 {
    padding-top: 2.4rem !important;
    padding-bottom: 2.4rem !important; }
  .py-xxl-23 {
    padding-top: 2.3rem !important;
    padding-bottom: 2.3rem !important; }
  .py-xxl-22 {
    padding-top: 2.2rem !important;
    padding-bottom: 2.2rem !important; }
  .py-xxl-21 {
    padding-top: 2.1rem !important;
    padding-bottom: 2.1rem !important; }
  .py-xxl-20 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .py-xxl-19 {
    padding-top: 1.9rem !important;
    padding-bottom: 1.9rem !important; }
  .py-xxl-18 {
    padding-top: 1.8rem !important;
    padding-bottom: 1.8rem !important; }
  .py-xxl-17 {
    padding-top: 1.7rem !important;
    padding-bottom: 1.7rem !important; }
  .py-xxl-16 {
    padding-top: 1.6rem !important;
    padding-bottom: 1.6rem !important; }
  .py-xxl-15 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xxl-14 {
    padding-top: 1.4rem !important;
    padding-bottom: 1.4rem !important; }
  .py-xxl-13 {
    padding-top: 1.3rem !important;
    padding-bottom: 1.3rem !important; }
  .py-xxl-12 {
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important; }
  .py-xxl-11 {
    padding-top: 1.1rem !important;
    padding-bottom: 1.1rem !important; }
  .py-xxl-10 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xxl-5 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xxl-6 {
    padding-top: 0.6rem !important;
    padding-bottom: 0.6rem !important; }
  .py-xxl-8 {
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important; }
  .py-xxl-40 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .py-xxl-45 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important; }
  .py-xxl-50 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .py-xxl-55 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important; }
  .py-xxl-60 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .py-xxl-70 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important; }
  .py-xxl-80 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important; }
  .py-xxl-90 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important; }
  .py-xxl-100 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important; }
  .py-xxl-105 {
    padding-top: 10.5rem !important;
    padding-bottom: 10.5rem !important; }
  .py-xxl-110 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important; }
  .py-xxl-125 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important; }
  .py-xxl-130 {
    padding-top: 13rem !important;
    padding-bottom: 13rem !important; }
  .py-xxl-145 {
    padding-top: 14.5rem !important;
    padding-bottom: 14.5rem !important; }
  .py-xxl-170 {
    padding-top: 17rem !important;
    padding-bottom: 17rem !important; }
  .py-xxl-235 {
    padding-top: 23.5rem !important;
    padding-bottom: 23.5rem !important; }
  .py-xxl-n20 {
    padding-top: -2rem !important;
    padding-bottom: -2rem !important; }
  .pt-xxl-35 {
    padding-top: 3.5rem !important; }
  .pt-xxl-34 {
    padding-top: 3.4rem !important; }
  .pt-xxl-33 {
    padding-top: 3.3rem !important; }
  .pt-xxl-32 {
    padding-top: 3.2rem !important; }
  .pt-xxl-31 {
    padding-top: 3.1rem !important; }
  .pt-xxl-30 {
    padding-top: 3rem !important; }
  .pt-xxl-29 {
    padding-top: 2.9rem !important; }
  .pt-xxl-28 {
    padding-top: 2.8rem !important; }
  .pt-xxl-27 {
    padding-top: 2.7rem !important; }
  .pt-xxl-26 {
    padding-top: 2.6rem !important; }
  .pt-xxl-25 {
    padding-top: 2.5rem !important; }
  .pt-xxl-24 {
    padding-top: 2.4rem !important; }
  .pt-xxl-23 {
    padding-top: 2.3rem !important; }
  .pt-xxl-22 {
    padding-top: 2.2rem !important; }
  .pt-xxl-21 {
    padding-top: 2.1rem !important; }
  .pt-xxl-20 {
    padding-top: 2rem !important; }
  .pt-xxl-19 {
    padding-top: 1.9rem !important; }
  .pt-xxl-18 {
    padding-top: 1.8rem !important; }
  .pt-xxl-17 {
    padding-top: 1.7rem !important; }
  .pt-xxl-16 {
    padding-top: 1.6rem !important; }
  .pt-xxl-15 {
    padding-top: 1.5rem !important; }
  .pt-xxl-14 {
    padding-top: 1.4rem !important; }
  .pt-xxl-13 {
    padding-top: 1.3rem !important; }
  .pt-xxl-12 {
    padding-top: 1.2rem !important; }
  .pt-xxl-11 {
    padding-top: 1.1rem !important; }
  .pt-xxl-10 {
    padding-top: 1rem !important; }
  .pt-xxl-0 {
    padding-top: 0 !important; }
  .pt-xxl-1 {
    padding-top: 0.25rem !important; }
  .pt-xxl-2 {
    padding-top: 0.5rem !important; }
  .pt-xxl-3 {
    padding-top: 1rem !important; }
  .pt-xxl-4 {
    padding-top: 1.5rem !important; }
  .pt-xxl-5 {
    padding-top: 0.5rem !important; }
  .pt-xxl-6 {
    padding-top: 0.6rem !important; }
  .pt-xxl-8 {
    padding-top: 0.8rem !important; }
  .pt-xxl-40 {
    padding-top: 4rem !important; }
  .pt-xxl-45 {
    padding-top: 4.5rem !important; }
  .pt-xxl-50 {
    padding-top: 5rem !important; }
  .pt-xxl-55 {
    padding-top: 5.5rem !important; }
  .pt-xxl-60 {
    padding-top: 6rem !important; }
  .pt-xxl-70 {
    padding-top: 7rem !important; }
  .pt-xxl-80 {
    padding-top: 8rem !important; }
  .pt-xxl-90 {
    padding-top: 9rem !important; }
  .pt-xxl-100 {
    padding-top: 10rem !important; }
  .pt-xxl-105 {
    padding-top: 10.5rem !important; }
  .pt-xxl-110 {
    padding-top: 11rem !important; }
  .pt-xxl-125 {
    padding-top: 12.5rem !important; }
  .pt-xxl-130 {
    padding-top: 13rem !important; }
  .pt-xxl-145 {
    padding-top: 14.5rem !important; }
  .pt-xxl-170 {
    padding-top: 17rem !important; }
  .pt-xxl-235 {
    padding-top: 23.5rem !important; }
  .pt-xxl-n20 {
    padding-top: -2rem !important; }
  .pe-xxl-35 {
    padding-right: 3.5rem !important; }
  .pe-xxl-34 {
    padding-right: 3.4rem !important; }
  .pe-xxl-33 {
    padding-right: 3.3rem !important; }
  .pe-xxl-32 {
    padding-right: 3.2rem !important; }
  .pe-xxl-31 {
    padding-right: 3.1rem !important; }
  .pe-xxl-30 {
    padding-right: 3rem !important; }
  .pe-xxl-29 {
    padding-right: 2.9rem !important; }
  .pe-xxl-28 {
    padding-right: 2.8rem !important; }
  .pe-xxl-27 {
    padding-right: 2.7rem !important; }
  .pe-xxl-26 {
    padding-right: 2.6rem !important; }
  .pe-xxl-25 {
    padding-right: 2.5rem !important; }
  .pe-xxl-24 {
    padding-right: 2.4rem !important; }
  .pe-xxl-23 {
    padding-right: 2.3rem !important; }
  .pe-xxl-22 {
    padding-right: 2.2rem !important; }
  .pe-xxl-21 {
    padding-right: 2.1rem !important; }
  .pe-xxl-20 {
    padding-right: 2rem !important; }
  .pe-xxl-19 {
    padding-right: 1.9rem !important; }
  .pe-xxl-18 {
    padding-right: 1.8rem !important; }
  .pe-xxl-17 {
    padding-right: 1.7rem !important; }
  .pe-xxl-16 {
    padding-right: 1.6rem !important; }
  .pe-xxl-15 {
    padding-right: 1.5rem !important; }
  .pe-xxl-14 {
    padding-right: 1.4rem !important; }
  .pe-xxl-13 {
    padding-right: 1.3rem !important; }
  .pe-xxl-12 {
    padding-right: 1.2rem !important; }
  .pe-xxl-11 {
    padding-right: 1.1rem !important; }
  .pe-xxl-10 {
    padding-right: 1rem !important; }
  .pe-xxl-0 {
    padding-right: 0 !important; }
  .pe-xxl-1 {
    padding-right: 0.25rem !important; }
  .pe-xxl-2 {
    padding-right: 0.5rem !important; }
  .pe-xxl-3 {
    padding-right: 1rem !important; }
  .pe-xxl-4 {
    padding-right: 1.5rem !important; }
  .pe-xxl-5 {
    padding-right: 0.5rem !important; }
  .pe-xxl-6 {
    padding-right: 0.6rem !important; }
  .pe-xxl-8 {
    padding-right: 0.8rem !important; }
  .pe-xxl-40 {
    padding-right: 4rem !important; }
  .pe-xxl-45 {
    padding-right: 4.5rem !important; }
  .pe-xxl-50 {
    padding-right: 5rem !important; }
  .pe-xxl-55 {
    padding-right: 5.5rem !important; }
  .pe-xxl-60 {
    padding-right: 6rem !important; }
  .pe-xxl-70 {
    padding-right: 7rem !important; }
  .pe-xxl-80 {
    padding-right: 8rem !important; }
  .pe-xxl-90 {
    padding-right: 9rem !important; }
  .pe-xxl-100 {
    padding-right: 10rem !important; }
  .pe-xxl-105 {
    padding-right: 10.5rem !important; }
  .pe-xxl-110 {
    padding-right: 11rem !important; }
  .pe-xxl-125 {
    padding-right: 12.5rem !important; }
  .pe-xxl-130 {
    padding-right: 13rem !important; }
  .pe-xxl-145 {
    padding-right: 14.5rem !important; }
  .pe-xxl-170 {
    padding-right: 17rem !important; }
  .pe-xxl-235 {
    padding-right: 23.5rem !important; }
  .pe-xxl-n20 {
    padding-right: -2rem !important; }
  .pb-xxl-35 {
    padding-bottom: 3.5rem !important; }
  .pb-xxl-34 {
    padding-bottom: 3.4rem !important; }
  .pb-xxl-33 {
    padding-bottom: 3.3rem !important; }
  .pb-xxl-32 {
    padding-bottom: 3.2rem !important; }
  .pb-xxl-31 {
    padding-bottom: 3.1rem !important; }
  .pb-xxl-30 {
    padding-bottom: 3rem !important; }
  .pb-xxl-29 {
    padding-bottom: 2.9rem !important; }
  .pb-xxl-28 {
    padding-bottom: 2.8rem !important; }
  .pb-xxl-27 {
    padding-bottom: 2.7rem !important; }
  .pb-xxl-26 {
    padding-bottom: 2.6rem !important; }
  .pb-xxl-25 {
    padding-bottom: 2.5rem !important; }
  .pb-xxl-24 {
    padding-bottom: 2.4rem !important; }
  .pb-xxl-23 {
    padding-bottom: 2.3rem !important; }
  .pb-xxl-22 {
    padding-bottom: 2.2rem !important; }
  .pb-xxl-21 {
    padding-bottom: 2.1rem !important; }
  .pb-xxl-20 {
    padding-bottom: 2rem !important; }
  .pb-xxl-19 {
    padding-bottom: 1.9rem !important; }
  .pb-xxl-18 {
    padding-bottom: 1.8rem !important; }
  .pb-xxl-17 {
    padding-bottom: 1.7rem !important; }
  .pb-xxl-16 {
    padding-bottom: 1.6rem !important; }
  .pb-xxl-15 {
    padding-bottom: 1.5rem !important; }
  .pb-xxl-14 {
    padding-bottom: 1.4rem !important; }
  .pb-xxl-13 {
    padding-bottom: 1.3rem !important; }
  .pb-xxl-12 {
    padding-bottom: 1.2rem !important; }
  .pb-xxl-11 {
    padding-bottom: 1.1rem !important; }
  .pb-xxl-10 {
    padding-bottom: 1rem !important; }
  .pb-xxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xxl-3 {
    padding-bottom: 1rem !important; }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xxl-5 {
    padding-bottom: 0.5rem !important; }
  .pb-xxl-6 {
    padding-bottom: 0.6rem !important; }
  .pb-xxl-8 {
    padding-bottom: 0.8rem !important; }
  .pb-xxl-40 {
    padding-bottom: 4rem !important; }
  .pb-xxl-45 {
    padding-bottom: 4.5rem !important; }
  .pb-xxl-50 {
    padding-bottom: 5rem !important; }
  .pb-xxl-55 {
    padding-bottom: 5.5rem !important; }
  .pb-xxl-60 {
    padding-bottom: 6rem !important; }
  .pb-xxl-70 {
    padding-bottom: 7rem !important; }
  .pb-xxl-80 {
    padding-bottom: 8rem !important; }
  .pb-xxl-90 {
    padding-bottom: 9rem !important; }
  .pb-xxl-100 {
    padding-bottom: 10rem !important; }
  .pb-xxl-105 {
    padding-bottom: 10.5rem !important; }
  .pb-xxl-110 {
    padding-bottom: 11rem !important; }
  .pb-xxl-125 {
    padding-bottom: 12.5rem !important; }
  .pb-xxl-130 {
    padding-bottom: 13rem !important; }
  .pb-xxl-145 {
    padding-bottom: 14.5rem !important; }
  .pb-xxl-170 {
    padding-bottom: 17rem !important; }
  .pb-xxl-235 {
    padding-bottom: 23.5rem !important; }
  .pb-xxl-n20 {
    padding-bottom: -2rem !important; }
  .ps-xxl-35 {
    padding-left: 3.5rem !important; }
  .ps-xxl-34 {
    padding-left: 3.4rem !important; }
  .ps-xxl-33 {
    padding-left: 3.3rem !important; }
  .ps-xxl-32 {
    padding-left: 3.2rem !important; }
  .ps-xxl-31 {
    padding-left: 3.1rem !important; }
  .ps-xxl-30 {
    padding-left: 3rem !important; }
  .ps-xxl-29 {
    padding-left: 2.9rem !important; }
  .ps-xxl-28 {
    padding-left: 2.8rem !important; }
  .ps-xxl-27 {
    padding-left: 2.7rem !important; }
  .ps-xxl-26 {
    padding-left: 2.6rem !important; }
  .ps-xxl-25 {
    padding-left: 2.5rem !important; }
  .ps-xxl-24 {
    padding-left: 2.4rem !important; }
  .ps-xxl-23 {
    padding-left: 2.3rem !important; }
  .ps-xxl-22 {
    padding-left: 2.2rem !important; }
  .ps-xxl-21 {
    padding-left: 2.1rem !important; }
  .ps-xxl-20 {
    padding-left: 2rem !important; }
  .ps-xxl-19 {
    padding-left: 1.9rem !important; }
  .ps-xxl-18 {
    padding-left: 1.8rem !important; }
  .ps-xxl-17 {
    padding-left: 1.7rem !important; }
  .ps-xxl-16 {
    padding-left: 1.6rem !important; }
  .ps-xxl-15 {
    padding-left: 1.5rem !important; }
  .ps-xxl-14 {
    padding-left: 1.4rem !important; }
  .ps-xxl-13 {
    padding-left: 1.3rem !important; }
  .ps-xxl-12 {
    padding-left: 1.2rem !important; }
  .ps-xxl-11 {
    padding-left: 1.1rem !important; }
  .ps-xxl-10 {
    padding-left: 1rem !important; }
  .ps-xxl-0 {
    padding-left: 0 !important; }
  .ps-xxl-1 {
    padding-left: 0.25rem !important; }
  .ps-xxl-2 {
    padding-left: 0.5rem !important; }
  .ps-xxl-3 {
    padding-left: 1rem !important; }
  .ps-xxl-4 {
    padding-left: 1.5rem !important; }
  .ps-xxl-5 {
    padding-left: 0.5rem !important; }
  .ps-xxl-6 {
    padding-left: 0.6rem !important; }
  .ps-xxl-8 {
    padding-left: 0.8rem !important; }
  .ps-xxl-40 {
    padding-left: 4rem !important; }
  .ps-xxl-45 {
    padding-left: 4.5rem !important; }
  .ps-xxl-50 {
    padding-left: 5rem !important; }
  .ps-xxl-55 {
    padding-left: 5.5rem !important; }
  .ps-xxl-60 {
    padding-left: 6rem !important; }
  .ps-xxl-70 {
    padding-left: 7rem !important; }
  .ps-xxl-80 {
    padding-left: 8rem !important; }
  .ps-xxl-90 {
    padding-left: 9rem !important; }
  .ps-xxl-100 {
    padding-left: 10rem !important; }
  .ps-xxl-105 {
    padding-left: 10.5rem !important; }
  .ps-xxl-110 {
    padding-left: 11rem !important; }
  .ps-xxl-125 {
    padding-left: 12.5rem !important; }
  .ps-xxl-130 {
    padding-left: 13rem !important; }
  .ps-xxl-145 {
    padding-left: 14.5rem !important; }
  .ps-xxl-170 {
    padding-left: 17rem !important; }
  .ps-xxl-235 {
    padding-left: 23.5rem !important; }
  .ps-xxl-n20 {
    padding-left: -2rem !important; }
  .font-xxl-family-02 {
    font-family: "Caveat", cursive !important; }
  .fs-xxl-40 {
    font-size: 4rem !important; }
  .fs-xxl-39 {
    font-size: 3.9rem !important; }
  .fs-xxl-38 {
    font-size: 3.8rem !important; }
  .fs-xxl-37 {
    font-size: 3.7rem !important; }
  .fs-xxl-36 {
    font-size: 3.6rem !important; }
  .fs-xxl-35 {
    font-size: 3.5rem !important; }
  .fs-xxl-34 {
    font-size: 3.4rem !important; }
  .fs-xxl-33 {
    font-size: 3.3rem !important; }
  .fs-xxl-32 {
    font-size: 3.2rem !important; }
  .fs-xxl-31 {
    font-size: 3.1rem !important; }
  .fs-xxl-30 {
    font-size: 3rem !important; }
  .fs-xxl-29 {
    font-size: 2.9rem !important; }
  .fs-xxl-28 {
    font-size: 2.8rem !important; }
  .fs-xxl-27 {
    font-size: 2.7rem !important; }
  .fs-xxl-26 {
    font-size: 2.6rem !important; }
  .fs-xxl-25 {
    font-size: 2.5rem !important; }
  .fs-xxl-24 {
    font-size: 2.4rem !important; }
  .fs-xxl-23 {
    font-size: 2.3rem !important; }
  .fs-xxl-22 {
    font-size: 2.2rem !important; }
  .fs-xxl-21 {
    font-size: 2.1rem !important; }
  .fs-xxl-20 {
    font-size: 2rem !important; }
  .fs-xxl-19 {
    font-size: 1.9rem !important; }
  .fs-xxl-18 {
    font-size: 1.8rem !important; }
  .fs-xxl-17 {
    font-size: 1.7rem !important; }
  .fs-xxl-16 {
    font-size: 1.6rem !important; }
  .fs-xxl-15 {
    font-size: 1.5rem !important; }
  .fs-xxl-14 {
    font-size: 1.4rem !important; }
  .fs-xxl-13 {
    font-size: 1.3rem !important; }
  .fs-xxl-12 {
    font-size: 1.2rem !important; }
  .fs-xxl-11 {
    font-size: 1.1rem !important; }
  .fs-xxl-10 {
    font-size: 1rem !important; }
  .fs-xxl-60 {
    font-size: calc(1.725rem + 5.7vw) !important; }
  .text-xxl-start {
    text-align: left !important; }
  .text-xxl-end {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; }
  .rounded-xxl-20 {
    border-radius: 2rem !important; }
  .rounded-xxl-19 {
    border-radius: 1.9rem !important; }
  .rounded-xxl-18 {
    border-radius: 1.8rem !important; }
  .rounded-xxl-17 {
    border-radius: 1.7rem !important; }
  .rounded-xxl-16 {
    border-radius: 1.6rem !important; }
  .rounded-xxl-15 {
    border-radius: 1.5rem !important; }
  .rounded-xxl-14 {
    border-radius: 1.4rem !important; }
  .rounded-xxl-13 {
    border-radius: 1.3rem !important; }
  .rounded-xxl-12 {
    border-radius: 1.2rem !important; }
  .rounded-xxl-11 {
    border-radius: 1.1rem !important; }
  .rounded-xxl-10 {
    border-radius: 1rem !important; }
  .rounded-xxl-9 {
    border-radius: 0.9rem !important; }
  .rounded-xxl-8 {
    border-radius: 0.8rem !important; }
  .rounded-xxl-7 {
    border-radius: 0.7rem !important; }
  .rounded-xxl-6 {
    border-radius: 0.6rem !important; }
  .rounded-xxl-5 {
    border-radius: 0.5rem !important; }
  .rounded-xxl {
    border-radius: 2px 5px 2px 5px !important; }
  .rounded-xxl-0 {
    border-radius: 0 !important; }
  .rounded-xxl-1 {
    border-radius: 0.6rem !important; }
  .rounded-xxl-2 {
    border-radius: 2px 5px 2px 5px !important; }
  .rounded-xxl-3 {
    border-radius: 5px 20px 5px 20px !important; }
  .rounded-xxl-4 {
    border-radius: 5px 100px 5px 100px !important; }
  .rounded-xxl-circle {
    border-radius: 50% !important; }
  .rounded-xxl-pill {
    border-radius: 50rem !important; }
  .rounded-xxl-btn {
    border-radius: 5px 30px 5px 5px !important; }
  .rounded-xxl-carte {
    border-radius: 10px 25px 10px 25px !important; }
  .rounded-top-xxl-20 {
    border-top-left-radius: 2rem !important;
    border-top-right-radius: 2rem !important; }
  .rounded-top-xxl-19 {
    border-top-left-radius: 1.9rem !important;
    border-top-right-radius: 1.9rem !important; }
  .rounded-top-xxl-18 {
    border-top-left-radius: 1.8rem !important;
    border-top-right-radius: 1.8rem !important; }
  .rounded-top-xxl-17 {
    border-top-left-radius: 1.7rem !important;
    border-top-right-radius: 1.7rem !important; }
  .rounded-top-xxl-16 {
    border-top-left-radius: 1.6rem !important;
    border-top-right-radius: 1.6rem !important; }
  .rounded-top-xxl-15 {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important; }
  .rounded-top-xxl-14 {
    border-top-left-radius: 1.4rem !important;
    border-top-right-radius: 1.4rem !important; }
  .rounded-top-xxl-13 {
    border-top-left-radius: 1.3rem !important;
    border-top-right-radius: 1.3rem !important; }
  .rounded-top-xxl-12 {
    border-top-left-radius: 1.2rem !important;
    border-top-right-radius: 1.2rem !important; }
  .rounded-top-xxl-11 {
    border-top-left-radius: 1.1rem !important;
    border-top-right-radius: 1.1rem !important; }
  .rounded-top-xxl-10 {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important; }
  .rounded-top-xxl-9 {
    border-top-left-radius: 0.9rem !important;
    border-top-right-radius: 0.9rem !important; }
  .rounded-top-xxl-8 {
    border-top-left-radius: 0.8rem !important;
    border-top-right-radius: 0.8rem !important; }
  .rounded-top-xxl-7 {
    border-top-left-radius: 0.7rem !important;
    border-top-right-radius: 0.7rem !important; }
  .rounded-top-xxl-6 {
    border-top-left-radius: 0.6rem !important;
    border-top-right-radius: 0.6rem !important; }
  .rounded-top-xxl-5 {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important; }
  .rounded-top-xxl {
    border-top-left-radius: 2px 5px 2px 5px !important;
    border-top-right-radius: 2px 5px 2px 5px !important; }
  .rounded-top-xxl-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important; }
  .rounded-top-xxl-1 {
    border-top-left-radius: 0.6rem !important;
    border-top-right-radius: 0.6rem !important; }
  .rounded-top-xxl-2 {
    border-top-left-radius: 2px 5px 2px 5px !important;
    border-top-right-radius: 2px 5px 2px 5px !important; }
  .rounded-top-xxl-3 {
    border-top-left-radius: 5px 20px 5px 20px !important;
    border-top-right-radius: 5px 20px 5px 20px !important; }
  .rounded-top-xxl-4 {
    border-top-left-radius: 5px 100px 5px 100px !important;
    border-top-right-radius: 5px 100px 5px 100px !important; }
  .rounded-top-xxl-circle {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important; }
  .rounded-top-xxl-pill {
    border-top-left-radius: 50rem !important;
    border-top-right-radius: 50rem !important; }
  .rounded-top-xxl-btn {
    border-top-left-radius: 5px 30px 5px 5px !important;
    border-top-right-radius: 5px 30px 5px 5px !important; }
  .rounded-top-xxl-carte {
    border-top-left-radius: 10px 25px 10px 25px !important;
    border-top-right-radius: 10px 25px 10px 25px !important; }
  .rounded-end-xxl-20 {
    border-top-right-radius: 2rem !important;
    border-bottom-right-radius: 2rem !important; }
  .rounded-end-xxl-19 {
    border-top-right-radius: 1.9rem !important;
    border-bottom-right-radius: 1.9rem !important; }
  .rounded-end-xxl-18 {
    border-top-right-radius: 1.8rem !important;
    border-bottom-right-radius: 1.8rem !important; }
  .rounded-end-xxl-17 {
    border-top-right-radius: 1.7rem !important;
    border-bottom-right-radius: 1.7rem !important; }
  .rounded-end-xxl-16 {
    border-top-right-radius: 1.6rem !important;
    border-bottom-right-radius: 1.6rem !important; }
  .rounded-end-xxl-15 {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important; }
  .rounded-end-xxl-14 {
    border-top-right-radius: 1.4rem !important;
    border-bottom-right-radius: 1.4rem !important; }
  .rounded-end-xxl-13 {
    border-top-right-radius: 1.3rem !important;
    border-bottom-right-radius: 1.3rem !important; }
  .rounded-end-xxl-12 {
    border-top-right-radius: 1.2rem !important;
    border-bottom-right-radius: 1.2rem !important; }
  .rounded-end-xxl-11 {
    border-top-right-radius: 1.1rem !important;
    border-bottom-right-radius: 1.1rem !important; }
  .rounded-end-xxl-10 {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important; }
  .rounded-end-xxl-9 {
    border-top-right-radius: 0.9rem !important;
    border-bottom-right-radius: 0.9rem !important; }
  .rounded-end-xxl-8 {
    border-top-right-radius: 0.8rem !important;
    border-bottom-right-radius: 0.8rem !important; }
  .rounded-end-xxl-7 {
    border-top-right-radius: 0.7rem !important;
    border-bottom-right-radius: 0.7rem !important; }
  .rounded-end-xxl-6 {
    border-top-right-radius: 0.6rem !important;
    border-bottom-right-radius: 0.6rem !important; }
  .rounded-end-xxl-5 {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important; }
  .rounded-end-xxl {
    border-top-right-radius: 2px 5px 2px 5px !important;
    border-bottom-right-radius: 2px 5px 2px 5px !important; }
  .rounded-end-xxl-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important; }
  .rounded-end-xxl-1 {
    border-top-right-radius: 0.6rem !important;
    border-bottom-right-radius: 0.6rem !important; }
  .rounded-end-xxl-2 {
    border-top-right-radius: 2px 5px 2px 5px !important;
    border-bottom-right-radius: 2px 5px 2px 5px !important; }
  .rounded-end-xxl-3 {
    border-top-right-radius: 5px 20px 5px 20px !important;
    border-bottom-right-radius: 5px 20px 5px 20px !important; }
  .rounded-end-xxl-4 {
    border-top-right-radius: 5px 100px 5px 100px !important;
    border-bottom-right-radius: 5px 100px 5px 100px !important; }
  .rounded-end-xxl-circle {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important; }
  .rounded-end-xxl-pill {
    border-top-right-radius: 50rem !important;
    border-bottom-right-radius: 50rem !important; }
  .rounded-end-xxl-btn {
    border-top-right-radius: 5px 30px 5px 5px !important;
    border-bottom-right-radius: 5px 30px 5px 5px !important; }
  .rounded-end-xxl-carte {
    border-top-right-radius: 10px 25px 10px 25px !important;
    border-bottom-right-radius: 10px 25px 10px 25px !important; }
  .rounded-bottom-xxl-20 {
    border-bottom-right-radius: 2rem !important;
    border-bottom-left-radius: 2rem !important; }
  .rounded-bottom-xxl-19 {
    border-bottom-right-radius: 1.9rem !important;
    border-bottom-left-radius: 1.9rem !important; }
  .rounded-bottom-xxl-18 {
    border-bottom-right-radius: 1.8rem !important;
    border-bottom-left-radius: 1.8rem !important; }
  .rounded-bottom-xxl-17 {
    border-bottom-right-radius: 1.7rem !important;
    border-bottom-left-radius: 1.7rem !important; }
  .rounded-bottom-xxl-16 {
    border-bottom-right-radius: 1.6rem !important;
    border-bottom-left-radius: 1.6rem !important; }
  .rounded-bottom-xxl-15 {
    border-bottom-right-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important; }
  .rounded-bottom-xxl-14 {
    border-bottom-right-radius: 1.4rem !important;
    border-bottom-left-radius: 1.4rem !important; }
  .rounded-bottom-xxl-13 {
    border-bottom-right-radius: 1.3rem !important;
    border-bottom-left-radius: 1.3rem !important; }
  .rounded-bottom-xxl-12 {
    border-bottom-right-radius: 1.2rem !important;
    border-bottom-left-radius: 1.2rem !important; }
  .rounded-bottom-xxl-11 {
    border-bottom-right-radius: 1.1rem !important;
    border-bottom-left-radius: 1.1rem !important; }
  .rounded-bottom-xxl-10 {
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important; }
  .rounded-bottom-xxl-9 {
    border-bottom-right-radius: 0.9rem !important;
    border-bottom-left-radius: 0.9rem !important; }
  .rounded-bottom-xxl-8 {
    border-bottom-right-radius: 0.8rem !important;
    border-bottom-left-radius: 0.8rem !important; }
  .rounded-bottom-xxl-7 {
    border-bottom-right-radius: 0.7rem !important;
    border-bottom-left-radius: 0.7rem !important; }
  .rounded-bottom-xxl-6 {
    border-bottom-right-radius: 0.6rem !important;
    border-bottom-left-radius: 0.6rem !important; }
  .rounded-bottom-xxl-5 {
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important; }
  .rounded-bottom-xxl {
    border-bottom-right-radius: 2px 5px 2px 5px !important;
    border-bottom-left-radius: 2px 5px 2px 5px !important; }
  .rounded-bottom-xxl-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important; }
  .rounded-bottom-xxl-1 {
    border-bottom-right-radius: 0.6rem !important;
    border-bottom-left-radius: 0.6rem !important; }
  .rounded-bottom-xxl-2 {
    border-bottom-right-radius: 2px 5px 2px 5px !important;
    border-bottom-left-radius: 2px 5px 2px 5px !important; }
  .rounded-bottom-xxl-3 {
    border-bottom-right-radius: 5px 20px 5px 20px !important;
    border-bottom-left-radius: 5px 20px 5px 20px !important; }
  .rounded-bottom-xxl-4 {
    border-bottom-right-radius: 5px 100px 5px 100px !important;
    border-bottom-left-radius: 5px 100px 5px 100px !important; }
  .rounded-bottom-xxl-circle {
    border-bottom-right-radius: 50% !important;
    border-bottom-left-radius: 50% !important; }
  .rounded-bottom-xxl-pill {
    border-bottom-right-radius: 50rem !important;
    border-bottom-left-radius: 50rem !important; }
  .rounded-bottom-xxl-btn {
    border-bottom-right-radius: 5px 30px 5px 5px !important;
    border-bottom-left-radius: 5px 30px 5px 5px !important; }
  .rounded-bottom-xxl-carte {
    border-bottom-right-radius: 10px 25px 10px 25px !important;
    border-bottom-left-radius: 10px 25px 10px 25px !important; }
  .rounded-start-xxl-20 {
    border-bottom-left-radius: 2rem !important;
    border-top-left-radius: 2rem !important; }
  .rounded-start-xxl-19 {
    border-bottom-left-radius: 1.9rem !important;
    border-top-left-radius: 1.9rem !important; }
  .rounded-start-xxl-18 {
    border-bottom-left-radius: 1.8rem !important;
    border-top-left-radius: 1.8rem !important; }
  .rounded-start-xxl-17 {
    border-bottom-left-radius: 1.7rem !important;
    border-top-left-radius: 1.7rem !important; }
  .rounded-start-xxl-16 {
    border-bottom-left-radius: 1.6rem !important;
    border-top-left-radius: 1.6rem !important; }
  .rounded-start-xxl-15 {
    border-bottom-left-radius: 1.5rem !important;
    border-top-left-radius: 1.5rem !important; }
  .rounded-start-xxl-14 {
    border-bottom-left-radius: 1.4rem !important;
    border-top-left-radius: 1.4rem !important; }
  .rounded-start-xxl-13 {
    border-bottom-left-radius: 1.3rem !important;
    border-top-left-radius: 1.3rem !important; }
  .rounded-start-xxl-12 {
    border-bottom-left-radius: 1.2rem !important;
    border-top-left-radius: 1.2rem !important; }
  .rounded-start-xxl-11 {
    border-bottom-left-radius: 1.1rem !important;
    border-top-left-radius: 1.1rem !important; }
  .rounded-start-xxl-10 {
    border-bottom-left-radius: 1rem !important;
    border-top-left-radius: 1rem !important; }
  .rounded-start-xxl-9 {
    border-bottom-left-radius: 0.9rem !important;
    border-top-left-radius: 0.9rem !important; }
  .rounded-start-xxl-8 {
    border-bottom-left-radius: 0.8rem !important;
    border-top-left-radius: 0.8rem !important; }
  .rounded-start-xxl-7 {
    border-bottom-left-radius: 0.7rem !important;
    border-top-left-radius: 0.7rem !important; }
  .rounded-start-xxl-6 {
    border-bottom-left-radius: 0.6rem !important;
    border-top-left-radius: 0.6rem !important; }
  .rounded-start-xxl-5 {
    border-bottom-left-radius: 0.5rem !important;
    border-top-left-radius: 0.5rem !important; }
  .rounded-start-xxl {
    border-bottom-left-radius: 2px 5px 2px 5px !important;
    border-top-left-radius: 2px 5px 2px 5px !important; }
  .rounded-start-xxl-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important; }
  .rounded-start-xxl-1 {
    border-bottom-left-radius: 0.6rem !important;
    border-top-left-radius: 0.6rem !important; }
  .rounded-start-xxl-2 {
    border-bottom-left-radius: 2px 5px 2px 5px !important;
    border-top-left-radius: 2px 5px 2px 5px !important; }
  .rounded-start-xxl-3 {
    border-bottom-left-radius: 5px 20px 5px 20px !important;
    border-top-left-radius: 5px 20px 5px 20px !important; }
  .rounded-start-xxl-4 {
    border-bottom-left-radius: 5px 100px 5px 100px !important;
    border-top-left-radius: 5px 100px 5px 100px !important; }
  .rounded-start-xxl-circle {
    border-bottom-left-radius: 50% !important;
    border-top-left-radius: 50% !important; }
  .rounded-start-xxl-pill {
    border-bottom-left-radius: 50rem !important;
    border-top-left-radius: 50rem !important; }
  .rounded-start-xxl-btn {
    border-bottom-left-radius: 5px 30px 5px 5px !important;
    border-top-left-radius: 5px 30px 5px 5px !important; }
  .rounded-start-xxl-carte {
    border-bottom-left-radius: 10px 25px 10px 25px !important;
    border-top-left-radius: 10px 25px 10px 25px !important; }
  .z-index-xxl-1 {
    z-index: 1 !important; }
  .z-index-xxl-2 {
    z-index: 2 !important; }
  .z-index-xxl-3 {
    z-index: 3 !important; }
  .z-index-xxl-4 {
    z-index: 4 !important; }
  .z-index-xxl-5 {
    z-index: 5 !important; } }

@media (min-width: 1200px) {
  .fs-60 {
    font-size: 6rem !important; }
  .fs-sm-60 {
    font-size: 6rem !important; }
  .fs-md-60 {
    font-size: 6rem !important; }
  .fs-lg-60 {
    font-size: 6rem !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-grid {
    display: grid !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 1.5rem);
  padding-left: var(--bs-gutter-x, 1.5rem);
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px; } }

.row {
  --bs-gutter-x: 3rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x)); }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y); }

.col {
  flex: 1 0 0%; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.g-0,
.gx-0 {
  --bs-gutter-x: 0; }

.g-0,
.gy-0 {
  --bs-gutter-y: 0; }

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem; }

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem; }

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem; }

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem; }

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem; }

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem; }

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem; }

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem; }

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem; }

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem; }

.g-8,
.gx-8 {
  --bs-gutter-x: 8rem; }

.g-8,
.gy-8 {
  --bs-gutter-y: 8rem; }

.g-20,
.gx-20 {
  --bs-gutter-x: 2rem; }

.g-20,
.gy-20 {
  --bs-gutter-y: 2rem; }

.g-23,
.gx-23 {
  --bs-gutter-x: 2.3rem; }

.g-23,
.gy-23 {
  --bs-gutter-y: 2.3rem; }

.g-30,
.gx-30 {
  --bs-gutter-x: 3rem; }

.g-30,
.gy-30 {
  --bs-gutter-y: 3rem; }

.g-40,
.gx-40 {
  --bs-gutter-x: 4rem; }

.g-40,
.gy-40 {
  --bs-gutter-y: 4rem; }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0; }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0; }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem; }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem; }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem; }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem; }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem; }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem; }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem; }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem; }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem; }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem; }
  .g-sm-8,
  .gx-sm-8 {
    --bs-gutter-x: 8rem; }
  .g-sm-8,
  .gy-sm-8 {
    --bs-gutter-y: 8rem; }
  .g-sm-20,
  .gx-sm-20 {
    --bs-gutter-x: 2rem; }
  .g-sm-20,
  .gy-sm-20 {
    --bs-gutter-y: 2rem; }
  .g-sm-23,
  .gx-sm-23 {
    --bs-gutter-x: 2.3rem; }
  .g-sm-23,
  .gy-sm-23 {
    --bs-gutter-y: 2.3rem; }
  .g-sm-30,
  .gx-sm-30 {
    --bs-gutter-x: 3rem; }
  .g-sm-30,
  .gy-sm-30 {
    --bs-gutter-y: 3rem; }
  .g-sm-40,
  .gx-sm-40 {
    --bs-gutter-x: 4rem; }
  .g-sm-40,
  .gy-sm-40 {
    --bs-gutter-y: 4rem; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem; }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem; }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem; }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem; }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem; }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem; }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem; }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem; }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem; }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem; }
  .g-md-8,
  .gx-md-8 {
    --bs-gutter-x: 8rem; }
  .g-md-8,
  .gy-md-8 {
    --bs-gutter-y: 8rem; }
  .g-md-20,
  .gx-md-20 {
    --bs-gutter-x: 2rem; }
  .g-md-20,
  .gy-md-20 {
    --bs-gutter-y: 2rem; }
  .g-md-23,
  .gx-md-23 {
    --bs-gutter-x: 2.3rem; }
  .g-md-23,
  .gy-md-23 {
    --bs-gutter-y: 2.3rem; }
  .g-md-30,
  .gx-md-30 {
    --bs-gutter-x: 3rem; }
  .g-md-30,
  .gy-md-30 {
    --bs-gutter-y: 3rem; }
  .g-md-40,
  .gx-md-40 {
    --bs-gutter-x: 4rem; }
  .g-md-40,
  .gy-md-40 {
    --bs-gutter-y: 4rem; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0; }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0; }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem; }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem; }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem; }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem; }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem; }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem; }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem; }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem; }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem; }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem; }
  .g-lg-8,
  .gx-lg-8 {
    --bs-gutter-x: 8rem; }
  .g-lg-8,
  .gy-lg-8 {
    --bs-gutter-y: 8rem; }
  .g-lg-20,
  .gx-lg-20 {
    --bs-gutter-x: 2rem; }
  .g-lg-20,
  .gy-lg-20 {
    --bs-gutter-y: 2rem; }
  .g-lg-23,
  .gx-lg-23 {
    --bs-gutter-x: 2.3rem; }
  .g-lg-23,
  .gy-lg-23 {
    --bs-gutter-y: 2.3rem; }
  .g-lg-30,
  .gx-lg-30 {
    --bs-gutter-x: 3rem; }
  .g-lg-30,
  .gy-lg-30 {
    --bs-gutter-y: 3rem; }
  .g-lg-40,
  .gx-lg-40 {
    --bs-gutter-x: 4rem; }
  .g-lg-40,
  .gy-lg-40 {
    --bs-gutter-y: 4rem; } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0; }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0; }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem; }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem; }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem; }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem; }
  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 8rem; }
  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 8rem; }
  .g-xl-20,
  .gx-xl-20 {
    --bs-gutter-x: 2rem; }
  .g-xl-20,
  .gy-xl-20 {
    --bs-gutter-y: 2rem; }
  .g-xl-23,
  .gx-xl-23 {
    --bs-gutter-x: 2.3rem; }
  .g-xl-23,
  .gy-xl-23 {
    --bs-gutter-y: 2.3rem; }
  .g-xl-30,
  .gx-xl-30 {
    --bs-gutter-x: 3rem; }
  .g-xl-30,
  .gy-xl-30 {
    --bs-gutter-y: 3rem; }
  .g-xl-40,
  .gx-xl-40 {
    --bs-gutter-x: 4rem; }
  .g-xl-40,
  .gy-xl-40 {
    --bs-gutter-y: 4rem; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0; }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0; }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem; }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem; }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem; }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem; }
  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 8rem; }
  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 8rem; }
  .g-xxl-20,
  .gx-xxl-20 {
    --bs-gutter-x: 2rem; }
  .g-xxl-20,
  .gy-xxl-20 {
    --bs-gutter-y: 2rem; }
  .g-xxl-23,
  .gx-xxl-23 {
    --bs-gutter-x: 2.3rem; }
  .g-xxl-23,
  .gy-xxl-23 {
    --bs-gutter-y: 2.3rem; }
  .g-xxl-30,
  .gx-xxl-30 {
    --bs-gutter-x: 3rem; }
  .g-xxl-30,
  .gy-xxl-30 {
    --bs-gutter-y: 3rem; }
  .g-xxl-40,
  .gx-xxl-40 {
    --bs-gutter-x: 4rem; }
  .g-xxl-40,
  .gy-xxl-40 {
    --bs-gutter-y: 4rem; } }

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 2px 5px 2px 5px; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3rem; }
  .alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem; }

.alert-primary {
  color: #a3b3c6;
  background-color: #194270;
  border-color: #194270; }
  .alert-primary .alert-link {
    color: #828f9e; }

.alert-primary-2 {
  color: #afc3d8;
  background-color: #38689d;
  border-color: #38689d; }
  .alert-primary-2 .alert-link {
    color: #8c9cad; }

.alert-primary-3 {
  color: #9fadbd;
  background-color: #10335a;
  border-color: #10335a; }
  .alert-primary-3 .alert-link {
    color: #7f8a97; }

.alert-secondary {
  color: #144848;
  background-color: #32b4b4;
  border-color: #32b4b4; }
  .alert-secondary .alert-link {
    color: #103a3a; }

.alert-secondary-2 {
  color: #505c52;
  background-color: #c8e6cd;
  border-color: #c8e6cd; }
  .alert-secondary-2 .alert-link {
    color: #404a42; }

.alert-secondary-3 {
  color: #5e6062;
  background-color: #ebf0f5;
  border-color: #ebf0f5; }
  .alert-secondary-3 .alert-link {
    color: #4b4d4e; }

.alert-secondary-4 {
  color: #434c55;
  background-color: #a8bed5;
  border-color: #a8bed5; }
  .alert-secondary-4 .alert-link {
    color: #363d44; }

.alert-gray-100 {
  color: #5e6062;
  background-color: #ebf0f5;
  border-color: #ebf0f5; }
  .alert-gray-100 .alert-link {
    color: #4b4d4e; }

.alert-gray-800 {
  color: #b4b4b4;
  background-color: #444444;
  border-color: #444444; }
  .alert-gray-800 .alert-link {
    color: #909090; }

.alert-pink {
  color: #d1a5bd;
  background-color: #8b1f5a;
  border-color: #8b1f5a; }
  .alert-pink .alert-link {
    color: #a78497; }

.alert-success {
  color: #144848;
  background-color: #32b4b4;
  border-color: #32b4b4; }
  .alert-success .alert-link {
    color: #103a3a; }

.alert-info {
  color: #28465a;
  background-color: #65afe2;
  border-color: #65afe2; }
  .alert-info .alert-link {
    color: #203848; }

.alert-warning {
  color: #664d03;
  background-color: #ffc107;
  border-color: #ffc107; }
  .alert-warning .alert-link {
    color: #523e02; }

.alert-danger {
  color: #f1aeb5;
  background-color: #dc3545;
  border-color: #dc3545; }
  .alert-danger .alert-link {
    color: #c18b91; }

.alert-light {
  color: #666666;
  background-color: white;
  border-color: white; }
  .alert-light .alert-link {
    color: #525252; }

.alert-dark {
  color: #b4b4b4;
  background-color: #444444;
  border-color: #444444; }
  .alert-dark .alert-link {
    color: #909090; }

.alert-mobile {
  color: #595a5c;
  background-color: #dee2e6;
  border-color: #dee2e6; }
  .alert-mobile .alert-link {
    color: #47484a; }

.alert-body {
  color: #c4c8cb;
  background-color: #6c757d;
  border-color: #6c757d; }
  .alert-body .alert-link {
    color: #9da0a2; }

.alert-footer {
  color: #9fadbd;
  background-color: #10335a;
  border-color: #10335a; }
  .alert-footer .alert-link {
    color: #7f8a97; }

.alert-error {
  color: #595a5c;
  background-color: #dee2e6;
  border-color: #dee2e6; }
  .alert-error .alert-link {
    color: #47484a; }

.alert-burger {
  color: #b4b4b4;
  background-color: #444444;
  border-color: #444444; }
  .alert-burger .alert-link {
    color: #909090; }

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 2px 5px 2px 5px; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.form-label {
  margin-bottom: 0.5rem; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem; }

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.5rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control[type="file"] {
    overflow: hidden; }
    .form-control[type="file"]:not(:disabled):not([readonly]) {
      cursor: pointer; }
  .form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #8ca1b8;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(25, 66, 112, 0.25); }
  .form-control::-webkit-date-and-time-value {
    height: 1.5em; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }
  .form-control::file-selector-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    margin-inline-end: 0.75rem;
    color: #212529;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control::file-selector-button {
        transition: none; } }
  .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #dde0e3; }
  .form-control::-webkit-file-upload-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    margin-inline-end: 0.75rem;
    color: #212529;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control::-webkit-file-upload-button {
        transition: none; } }
  .form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #dde0e3; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.6rem; }
  .form-control-sm::file-selector-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    margin-inline-end: 0.5rem; }
  .form-control-sm::-webkit-file-upload-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    margin-inline-end: 0.5rem; }

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 5px 20px 5px 20px; }
  .form-control-lg::file-selector-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem; }
  .form-control-lg::-webkit-file-upload-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem; }

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px); }

textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px); }

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px); }

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.375rem; }
  .form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer; }
  .form-control-color::-moz-color-swatch {
    height: 1.5em;
    border-radius: 0.5rem; }
  .form-control-color::-webkit-color-swatch {
    height: 1.5em;
    border-radius: 0.5rem; }

.form-select {
  display: block;
  width: 100%;
  padding: 1.3rem 6rem 1.3rem 2rem;
  -moz-padding-start: calc(2rem - 3px);
  font-family: "Roboto", sans-serif;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23194270' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 2rem center;
  background-size: 16px 12px;
  border: 0 solid #ced4da;
  border-radius: 2px 5px 2px 5px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none; }
  @media (prefers-reduced-motion: reduce) {
    .form-select {
      transition: none; } }
  .form-select:focus {
    border-color: #8ca1b8;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(25, 66, 112, 0.25); }
  .form-select[multiple], .form-select[size]:not([size="1"]) {
    padding-right: 2rem;
    background-image: none; }
  .form-select:disabled {
    background-color: #e9ecef; }
  .form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #212529; }

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.6rem; }

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 5px 20px 5px 20px; }

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem; }
  .form-check .form-check-input {
    float: left;
    margin-left: -1.5em; }

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact; }
  .form-check-input[type="checkbox"] {
    border-radius: 0.25em; }
  .form-check-input[type="radio"] {
    border-radius: 50%; }
  .form-check-input:active {
    filter: brightness(90%); }
  .form-check-input:focus {
    border-color: #8ca1b8;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(25, 66, 112, 0.25); }
  .form-check-input:checked {
    background-color: #194270;
    border-color: #194270; }
    .form-check-input:checked[type="checkbox"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"); }
    .form-check-input:checked[type="radio"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-check-input[type="checkbox"]:indeterminate {
    background-color: #194270;
    border-color: #194270;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"); }
  .form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5; }
  .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    opacity: 0.5; }

.form-switch {
  padding-left: 2.5em; }
  .form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-switch .form-check-input {
        transition: none; } }
    .form-switch .form-check-input:focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%238ca1b8'/%3e%3c/svg%3e"); }
    .form-switch .form-check-input:checked {
      background-position: right center;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.form-check-inline {
  display: inline-block;
  margin-right: 1rem; }

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }
  .btn-check[disabled] + .btn, .btn-check:disabled + .btn {
    pointer-events: none;
    filter: none;
    opacity: 0.65; }

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .form-range:focus {
    outline: 0; }
    .form-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(25, 66, 112, 0.25); }
    .form-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(25, 66, 112, 0.25); }
  .form-range::-moz-focus-outer {
    border: 0; }
  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #194270;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-webkit-slider-thumb {
        transition: none; } }
    .form-range::-webkit-slider-thumb:active {
      background-color: #bac6d4; }
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075); }
  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #194270;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-moz-range-thumb {
        transition: none; } }
    .form-range::-moz-range-thumb:active {
      background-color: #bac6d4; }
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075); }
  .form-range:disabled {
    pointer-events: none; }
    .form-range:disabled::-webkit-slider-thumb {
      background-color: #adb5bd; }
    .form-range:disabled::-moz-range-thumb {
      background-color: #adb5bd; }

.form-floating {
  position: relative; }
  .form-floating > .form-control,
  .form-floating > .form-select {
    height: calc(3.5rem + 2px);
    line-height: 1.25; }
  .form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem 0.75rem;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-floating > label {
        transition: none; } }
  .form-floating > .form-control {
    padding: 1rem 0.75rem; }
    .form-floating > .form-control::placeholder {
      color: transparent; }
    .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
    .form-floating > .form-control:-webkit-autofill {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
  .form-floating > .form-select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem; }
  .form-floating > .form-control:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .form-floating > .form-select ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control:-webkit-autofill ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; }
  .input-group > .form-control:focus,
  .input-group > .form-select:focus {
    z-index: 3; }
  .input-group .btn {
    position: relative;
    z-index: 2; }
    .input-group .btn:focus {
      z-index: 3; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.5rem; }

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 5px 20px 5px 20px; }

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.6rem; }

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 8rem; }

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #32B4B4; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #000;
  background-color: rgba(50, 180, 180, 0.9);
  border-radius: 2px 5px 2px 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #32B4B4;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2332B4B4' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #32B4B4;
    box-shadow: 0 0 0 0.25rem rgba(50, 180, 180, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #32B4B4; }
  .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
    padding-right: 11rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23194270' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2332B4B4' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right 2rem center, center right 6rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:valid:focus, .form-select.is-valid:focus {
    border-color: #32B4B4;
    box-shadow: 0 0 0 0.25rem rgba(50, 180, 180, 0.25); }

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #32B4B4; }
  .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: #32B4B4; }
  .was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0.25rem rgba(50, 180, 180, 0.25); }
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: #32B4B4; }

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em; }

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid, .was-validated
.input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1; }
  .was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus, .was-validated
  .input-group .form-select:valid:focus,
  .input-group .form-select.is-valid:focus {
    z-index: 3; }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 2px 5px 2px 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
    padding-right: 11rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23194270' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-position: right 2rem center, center right 6rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
    background-color: #dc3545; }
  .was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: #dc3545; }

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em; }

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid, .was-validated
.input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2; }
  .was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus, .was-validated
  .input-group .form-select:invalid:focus,
  .input-group .form-select.is-invalid:focus {
    z-index: 3; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
  .btn-group > .btn-check:checked + .btn,
  .btn-group > .btn-check:focus + .btn,
  .btn-group > .btn:hover,
  .btn-group > .btn:focus,
  .btn-group > .btn:active,
  .btn-group > .btn.active,
  .btn-group-vertical > .btn-check:checked + .btn,
  .btn-group-vertical > .btn-check:focus + .btn,
  .btn-group-vertical > .btn:hover,
  .btn-group-vertical > .btn:focus,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn.active {
    z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 21px;
  padding-left: 21px; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropend .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropstart .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .btn-group.show .dropdown-toggle.btn-link {
    box-shadow: none; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn ~ .btn,
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1.5rem;
  color: #fff;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .nav-link {
      transition: none; } }
  .nav-link:hover, .nav-link:focus {
    color: #32B4B4; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: none;
    border: 1px solid transparent;
    border-top-left-radius: 2px 5px 2px 5px;
    border-top-right-radius: 2px 5px 2px 5px; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6;
      isolation: isolate; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #32B4B4;
  background-color: none; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }
  .navbar > .container,
  .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static; }

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 2px 5px 2px 5px;
  transition: box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
      transition: none; } }
  .navbar-toggler:hover {
    text-decoration: none; }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0.25rem; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%; }

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto; }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .offcanvas-header {
      display: none; }
    .navbar-expand-sm .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-sm .offcanvas-top,
    .navbar-expand-sm .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-sm .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .offcanvas-header {
      display: none; }
    .navbar-expand-md .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-md .offcanvas-top,
    .navbar-expand-md .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-md .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .offcanvas-header {
      display: none; }
    .navbar-expand-lg .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-lg .offcanvas-top,
    .navbar-expand-lg .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-lg .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .offcanvas-header {
      display: none; }
    .navbar-expand-xl .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-xl .offcanvas-top,
    .navbar-expand-xl .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-xl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; }
    .navbar-expand-xxl .offcanvas-header {
      display: none; }
    .navbar-expand-xxl .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-xxl .offcanvas-top,
    .navbar-expand-xxl .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-xxl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .offcanvas-header {
    display: none; }
  .navbar-expand .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none; }
  .navbar-expand .offcanvas-top,
  .navbar-expand .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0; }
  .navbar-expand .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55); }
  .navbar-light .navbar-text a,
  .navbar-light .navbar-text a:hover,
  .navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55); }
  .navbar-dark .navbar-text a,
  .navbar-dark .navbar-text a:hover,
  .navbar-dark .navbar-text a:focus {
    color: #fff; }

.btn {
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  line-height: 18px;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 16px 28px;
  font-size: 1.5rem;
  border-radius: 2px 5px 2px 5px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529; }
  .btn-check:focus + .btn, .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(25, 66, 112, 0.25); }
  .btn-check:checked + .btn,
  .btn-check:active + .btn, .btn:active, .btn.active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
    .btn-check:checked + .btn:focus,
    .btn-check:active + .btn:focus, .btn:active:focus, .btn.active:focus {
      box-shadow: 0 0 0 0.25rem rgba(25, 66, 112, 0.25), inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .btn:disabled, .btn.disabled,
  fieldset:disabled .btn {
    pointer-events: none;
    opacity: 0.65;
    box-shadow: none; }

.btn-primary {
  color: #fff;
  background-color: #194270;
  border-color: #194270;
  box-shadow: unset; }
  .btn-primary:hover {
    color: #fff;
    background-color: #15385f;
    border-color: #14355a; }
  .btn-check:focus + .btn-primary, .btn-primary:focus {
    color: #fff;
    background-color: #15385f;
    border-color: #14355a;
    box-shadow: unset, 0 0 0 0.25rem rgba(60, 94, 133, 0.5); }
  .btn-check:checked + .btn-primary,
  .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #14355a;
    border-color: #133254; }
    .btn-check:checked + .btn-primary:focus,
    .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(60, 94, 133, 0.5); }
  .btn-primary:disabled, .btn-primary.disabled {
    color: #fff;
    background-color: #194270;
    border-color: #194270; }

.btn-primary-2 {
  color: #fff;
  background-color: #38689d;
  border-color: #38689d;
  box-shadow: unset; }
  .btn-primary-2:hover {
    color: #fff;
    background-color: #305885;
    border-color: #2d537e; }
  .btn-check:focus + .btn-primary-2, .btn-primary-2:focus {
    color: #fff;
    background-color: #305885;
    border-color: #2d537e;
    box-shadow: unset, 0 0 0 0.25rem rgba(86, 127, 172, 0.5); }
  .btn-check:checked + .btn-primary-2,
  .btn-check:active + .btn-primary-2, .btn-primary-2:active, .btn-primary-2.active,
  .show > .btn-primary-2.dropdown-toggle {
    color: #fff;
    background-color: #2d537e;
    border-color: #2a4e76; }
    .btn-check:checked + .btn-primary-2:focus,
    .btn-check:active + .btn-primary-2:focus, .btn-primary-2:active:focus, .btn-primary-2.active:focus,
    .show > .btn-primary-2.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(86, 127, 172, 0.5); }
  .btn-primary-2:disabled, .btn-primary-2.disabled {
    color: #fff;
    background-color: #38689d;
    border-color: #38689d; }

.btn-primary-3 {
  color: #fff;
  background-color: #10335A;
  border-color: #10335A;
  box-shadow: unset; }
  .btn-primary-3:hover {
    color: #fff;
    background-color: #0e2b4d;
    border-color: #0d2948; }
  .btn-check:focus + .btn-primary-3, .btn-primary-3:focus {
    color: #fff;
    background-color: #0e2b4d;
    border-color: #0d2948;
    box-shadow: unset, 0 0 0 0.25rem rgba(52, 82, 115, 0.5); }
  .btn-check:checked + .btn-primary-3,
  .btn-check:active + .btn-primary-3, .btn-primary-3:active, .btn-primary-3.active,
  .show > .btn-primary-3.dropdown-toggle {
    color: #fff;
    background-color: #0d2948;
    border-color: #0c2644; }
    .btn-check:checked + .btn-primary-3:focus,
    .btn-check:active + .btn-primary-3:focus, .btn-primary-3:active:focus, .btn-primary-3.active:focus,
    .show > .btn-primary-3.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(52, 82, 115, 0.5); }
  .btn-primary-3:disabled, .btn-primary-3.disabled {
    color: #fff;
    background-color: #10335A;
    border-color: #10335A; }

.btn-secondary {
  color: #000;
  background-color: #32B4B4;
  border-color: #32B4B4;
  box-shadow: unset; }
  .btn-secondary:hover {
    color: #000;
    background-color: #51bfbf;
    border-color: #47bcbc; }
  .btn-check:focus + .btn-secondary, .btn-secondary:focus {
    color: #000;
    background-color: #51bfbf;
    border-color: #47bcbc;
    box-shadow: unset, 0 0 0 0.25rem rgba(43, 153, 153, 0.5); }
  .btn-check:checked + .btn-secondary,
  .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active,
  .show > .btn-secondary.dropdown-toggle {
    color: #000;
    background-color: #5bc3c3;
    border-color: #47bcbc; }
    .btn-check:checked + .btn-secondary:focus,
    .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(43, 153, 153, 0.5); }
  .btn-secondary:disabled, .btn-secondary.disabled {
    color: #000;
    background-color: #32B4B4;
    border-color: #32B4B4; }

.btn-secondary-2 {
  color: #000;
  background-color: #C8E6CD;
  border-color: #C8E6CD;
  box-shadow: unset; }
  .btn-secondary-2:hover {
    color: #000;
    background-color: #d0ead5;
    border-color: #cee9d2; }
  .btn-check:focus + .btn-secondary-2, .btn-secondary-2:focus {
    color: #000;
    background-color: #d0ead5;
    border-color: #cee9d2;
    box-shadow: unset, 0 0 0 0.25rem rgba(170, 196, 174, 0.5); }
  .btn-check:checked + .btn-secondary-2,
  .btn-check:active + .btn-secondary-2, .btn-secondary-2:active, .btn-secondary-2.active,
  .show > .btn-secondary-2.dropdown-toggle {
    color: #000;
    background-color: #d3ebd7;
    border-color: #cee9d2; }
    .btn-check:checked + .btn-secondary-2:focus,
    .btn-check:active + .btn-secondary-2:focus, .btn-secondary-2:active:focus, .btn-secondary-2.active:focus,
    .show > .btn-secondary-2.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(170, 196, 174, 0.5); }
  .btn-secondary-2:disabled, .btn-secondary-2.disabled {
    color: #000;
    background-color: #C8E6CD;
    border-color: #C8E6CD; }

.btn-secondary-3 {
  color: #000;
  background-color: #EBF0F5;
  border-color: #EBF0F5;
  box-shadow: unset; }
  .btn-secondary-3:hover {
    color: #000;
    background-color: #eef2f7;
    border-color: #edf2f6; }
  .btn-check:focus + .btn-secondary-3, .btn-secondary-3:focus {
    color: #000;
    background-color: #eef2f7;
    border-color: #edf2f6;
    box-shadow: unset, 0 0 0 0.25rem rgba(200, 204, 208, 0.5); }
  .btn-check:checked + .btn-secondary-3,
  .btn-check:active + .btn-secondary-3, .btn-secondary-3:active, .btn-secondary-3.active,
  .show > .btn-secondary-3.dropdown-toggle {
    color: #000;
    background-color: #eff3f7;
    border-color: #edf2f6; }
    .btn-check:checked + .btn-secondary-3:focus,
    .btn-check:active + .btn-secondary-3:focus, .btn-secondary-3:active:focus, .btn-secondary-3.active:focus,
    .show > .btn-secondary-3.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(200, 204, 208, 0.5); }
  .btn-secondary-3:disabled, .btn-secondary-3.disabled {
    color: #000;
    background-color: #EBF0F5;
    border-color: #EBF0F5; }

.btn-secondary-4 {
  color: #000;
  background-color: #A8BED5;
  border-color: #A8BED5;
  box-shadow: unset; }
  .btn-secondary-4:hover {
    color: #000;
    background-color: #b5c8db;
    border-color: #b1c5d9; }
  .btn-check:focus + .btn-secondary-4, .btn-secondary-4:focus {
    color: #000;
    background-color: #b5c8db;
    border-color: #b1c5d9;
    box-shadow: unset, 0 0 0 0.25rem rgba(143, 162, 181, 0.5); }
  .btn-check:checked + .btn-secondary-4,
  .btn-check:active + .btn-secondary-4, .btn-secondary-4:active, .btn-secondary-4.active,
  .show > .btn-secondary-4.dropdown-toggle {
    color: #000;
    background-color: #b9cbdd;
    border-color: #b1c5d9; }
    .btn-check:checked + .btn-secondary-4:focus,
    .btn-check:active + .btn-secondary-4:focus, .btn-secondary-4:active:focus, .btn-secondary-4.active:focus,
    .show > .btn-secondary-4.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(143, 162, 181, 0.5); }
  .btn-secondary-4:disabled, .btn-secondary-4.disabled {
    color: #000;
    background-color: #A8BED5;
    border-color: #A8BED5; }

.btn-gray-100 {
  color: #000;
  background-color: #EBF0F5;
  border-color: #EBF0F5;
  box-shadow: unset; }
  .btn-gray-100:hover {
    color: #000;
    background-color: #eef2f7;
    border-color: #edf2f6; }
  .btn-check:focus + .btn-gray-100, .btn-gray-100:focus {
    color: #000;
    background-color: #eef2f7;
    border-color: #edf2f6;
    box-shadow: unset, 0 0 0 0.25rem rgba(200, 204, 208, 0.5); }
  .btn-check:checked + .btn-gray-100,
  .btn-check:active + .btn-gray-100, .btn-gray-100:active, .btn-gray-100.active,
  .show > .btn-gray-100.dropdown-toggle {
    color: #000;
    background-color: #eff3f7;
    border-color: #edf2f6; }
    .btn-check:checked + .btn-gray-100:focus,
    .btn-check:active + .btn-gray-100:focus, .btn-gray-100:active:focus, .btn-gray-100.active:focus,
    .show > .btn-gray-100.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(200, 204, 208, 0.5); }
  .btn-gray-100:disabled, .btn-gray-100.disabled {
    color: #000;
    background-color: #EBF0F5;
    border-color: #EBF0F5; }

.btn-gray-800 {
  color: #fff;
  background-color: #444444;
  border-color: #444444;
  box-shadow: unset; }
  .btn-gray-800:hover {
    color: #fff;
    background-color: #3a3a3a;
    border-color: #363636; }
  .btn-check:focus + .btn-gray-800, .btn-gray-800:focus {
    color: #fff;
    background-color: #3a3a3a;
    border-color: #363636;
    box-shadow: unset, 0 0 0 0.25rem rgba(96, 96, 96, 0.5); }
  .btn-check:checked + .btn-gray-800,
  .btn-check:active + .btn-gray-800, .btn-gray-800:active, .btn-gray-800.active,
  .show > .btn-gray-800.dropdown-toggle {
    color: #fff;
    background-color: #363636;
    border-color: #333333; }
    .btn-check:checked + .btn-gray-800:focus,
    .btn-check:active + .btn-gray-800:focus, .btn-gray-800:active:focus, .btn-gray-800.active:focus,
    .show > .btn-gray-800.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(96, 96, 96, 0.5); }
  .btn-gray-800:disabled, .btn-gray-800.disabled {
    color: #fff;
    background-color: #444444;
    border-color: #444444; }

.btn-pink {
  color: #fff;
  background-color: #8B1F5A;
  border-color: #8B1F5A;
  box-shadow: unset; }
  .btn-pink:hover {
    color: #fff;
    background-color: #761a4d;
    border-color: #6f1948; }
  .btn-check:focus + .btn-pink, .btn-pink:focus {
    color: #fff;
    background-color: #761a4d;
    border-color: #6f1948;
    box-shadow: unset, 0 0 0 0.25rem rgba(156, 65, 115, 0.5); }
  .btn-check:checked + .btn-pink,
  .btn-check:active + .btn-pink, .btn-pink:active, .btn-pink.active,
  .show > .btn-pink.dropdown-toggle {
    color: #fff;
    background-color: #6f1948;
    border-color: #681744; }
    .btn-check:checked + .btn-pink:focus,
    .btn-check:active + .btn-pink:focus, .btn-pink:active:focus, .btn-pink.active:focus,
    .show > .btn-pink.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(156, 65, 115, 0.5); }
  .btn-pink:disabled, .btn-pink.disabled {
    color: #fff;
    background-color: #8B1F5A;
    border-color: #8B1F5A; }

.btn-success {
  color: #000;
  background-color: #32B4B4;
  border-color: #32B4B4;
  box-shadow: unset; }
  .btn-success:hover {
    color: #000;
    background-color: #51bfbf;
    border-color: #47bcbc; }
  .btn-check:focus + .btn-success, .btn-success:focus {
    color: #000;
    background-color: #51bfbf;
    border-color: #47bcbc;
    box-shadow: unset, 0 0 0 0.25rem rgba(43, 153, 153, 0.5); }
  .btn-check:checked + .btn-success,
  .btn-check:active + .btn-success, .btn-success:active, .btn-success.active,
  .show > .btn-success.dropdown-toggle {
    color: #000;
    background-color: #5bc3c3;
    border-color: #47bcbc; }
    .btn-check:checked + .btn-success:focus,
    .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(43, 153, 153, 0.5); }
  .btn-success:disabled, .btn-success.disabled {
    color: #000;
    background-color: #32B4B4;
    border-color: #32B4B4; }

.btn-info {
  color: #000;
  background-color: #65afe2;
  border-color: #65afe2;
  box-shadow: unset; }
  .btn-info:hover {
    color: #000;
    background-color: #7cbbe6;
    border-color: #74b7e5; }
  .btn-check:focus + .btn-info, .btn-info:focus {
    color: #000;
    background-color: #7cbbe6;
    border-color: #74b7e5;
    box-shadow: unset, 0 0 0 0.25rem rgba(86, 149, 192, 0.5); }
  .btn-check:checked + .btn-info,
  .btn-check:active + .btn-info, .btn-info:active, .btn-info.active,
  .show > .btn-info.dropdown-toggle {
    color: #000;
    background-color: #84bfe8;
    border-color: #74b7e5; }
    .btn-check:checked + .btn-info:focus,
    .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(86, 149, 192, 0.5); }
  .btn-info:disabled, .btn-info.disabled {
    color: #000;
    background-color: #65afe2;
    border-color: #65afe2; }

.btn-warning {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
  box-shadow: unset; }
  .btn-warning:hover {
    color: #000;
    background-color: #ffca2c;
    border-color: #ffc720; }
  .btn-check:focus + .btn-warning, .btn-warning:focus {
    color: #000;
    background-color: #ffca2c;
    border-color: #ffc720;
    box-shadow: unset, 0 0 0 0.25rem rgba(217, 164, 6, 0.5); }
  .btn-check:checked + .btn-warning,
  .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active,
  .show > .btn-warning.dropdown-toggle {
    color: #000;
    background-color: #ffcd39;
    border-color: #ffc720; }
    .btn-check:checked + .btn-warning:focus,
    .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(217, 164, 6, 0.5); }
  .btn-warning:disabled, .btn-warning.disabled {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107; }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  box-shadow: unset; }
  .btn-danger:hover {
    color: #fff;
    background-color: #bb2d3b;
    border-color: #b02a37; }
  .btn-check:focus + .btn-danger, .btn-danger:focus {
    color: #fff;
    background-color: #bb2d3b;
    border-color: #b02a37;
    box-shadow: unset, 0 0 0 0.25rem rgba(225, 83, 97, 0.5); }
  .btn-check:checked + .btn-danger,
  .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #b02a37;
    border-color: #a52834; }
    .btn-check:checked + .btn-danger:focus,
    .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(225, 83, 97, 0.5); }
  .btn-danger:disabled, .btn-danger.disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }

.btn-light {
  color: #000;
  background-color: #fff;
  border-color: #fff;
  box-shadow: unset; }
  .btn-light:hover {
    color: #000;
    background-color: white;
    border-color: white; }
  .btn-check:focus + .btn-light, .btn-light:focus {
    color: #000;
    background-color: white;
    border-color: white;
    box-shadow: unset, 0 0 0 0.25rem rgba(217, 217, 217, 0.5); }
  .btn-check:checked + .btn-light,
  .btn-check:active + .btn-light, .btn-light:active, .btn-light.active,
  .show > .btn-light.dropdown-toggle {
    color: #000;
    background-color: white;
    border-color: white; }
    .btn-check:checked + .btn-light:focus,
    .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(217, 217, 217, 0.5); }
  .btn-light:disabled, .btn-light.disabled {
    color: #000;
    background-color: #fff;
    border-color: #fff; }

.btn-dark {
  color: #fff;
  background-color: #444444;
  border-color: #444444;
  box-shadow: unset; }
  .btn-dark:hover {
    color: #fff;
    background-color: #3a3a3a;
    border-color: #363636; }
  .btn-check:focus + .btn-dark, .btn-dark:focus {
    color: #fff;
    background-color: #3a3a3a;
    border-color: #363636;
    box-shadow: unset, 0 0 0 0.25rem rgba(96, 96, 96, 0.5); }
  .btn-check:checked + .btn-dark,
  .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #363636;
    border-color: #333333; }
    .btn-check:checked + .btn-dark:focus,
    .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(96, 96, 96, 0.5); }
  .btn-dark:disabled, .btn-dark.disabled {
    color: #fff;
    background-color: #444444;
    border-color: #444444; }

.btn-mobile {
  color: #000;
  background-color: #dee2e6;
  border-color: #dee2e6;
  box-shadow: unset; }
  .btn-mobile:hover {
    color: #000;
    background-color: #e3e6ea;
    border-color: #e1e5e9; }
  .btn-check:focus + .btn-mobile, .btn-mobile:focus {
    color: #000;
    background-color: #e3e6ea;
    border-color: #e1e5e9;
    box-shadow: unset, 0 0 0 0.25rem rgba(189, 192, 196, 0.5); }
  .btn-check:checked + .btn-mobile,
  .btn-check:active + .btn-mobile, .btn-mobile:active, .btn-mobile.active,
  .show > .btn-mobile.dropdown-toggle {
    color: #000;
    background-color: #e5e8eb;
    border-color: #e1e5e9; }
    .btn-check:checked + .btn-mobile:focus,
    .btn-check:active + .btn-mobile:focus, .btn-mobile:active:focus, .btn-mobile.active:focus,
    .show > .btn-mobile.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(189, 192, 196, 0.5); }
  .btn-mobile:disabled, .btn-mobile.disabled {
    color: #000;
    background-color: #dee2e6;
    border-color: #dee2e6; }

.btn-body {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  box-shadow: unset; }
  .btn-body:hover {
    color: #fff;
    background-color: #5c636a;
    border-color: #565e64; }
  .btn-check:focus + .btn-body, .btn-body:focus {
    color: #fff;
    background-color: #5c636a;
    border-color: #565e64;
    box-shadow: unset, 0 0 0 0.25rem rgba(130, 138, 145, 0.5); }
  .btn-check:checked + .btn-body,
  .btn-check:active + .btn-body, .btn-body:active, .btn-body.active,
  .show > .btn-body.dropdown-toggle {
    color: #fff;
    background-color: #565e64;
    border-color: #51585e; }
    .btn-check:checked + .btn-body:focus,
    .btn-check:active + .btn-body:focus, .btn-body:active:focus, .btn-body.active:focus,
    .show > .btn-body.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(130, 138, 145, 0.5); }
  .btn-body:disabled, .btn-body.disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }

.btn-footer {
  color: #fff;
  background-color: #10335A;
  border-color: #10335A;
  box-shadow: unset; }
  .btn-footer:hover {
    color: #fff;
    background-color: #0e2b4d;
    border-color: #0d2948; }
  .btn-check:focus + .btn-footer, .btn-footer:focus {
    color: #fff;
    background-color: #0e2b4d;
    border-color: #0d2948;
    box-shadow: unset, 0 0 0 0.25rem rgba(52, 82, 115, 0.5); }
  .btn-check:checked + .btn-footer,
  .btn-check:active + .btn-footer, .btn-footer:active, .btn-footer.active,
  .show > .btn-footer.dropdown-toggle {
    color: #fff;
    background-color: #0d2948;
    border-color: #0c2644; }
    .btn-check:checked + .btn-footer:focus,
    .btn-check:active + .btn-footer:focus, .btn-footer:active:focus, .btn-footer.active:focus,
    .show > .btn-footer.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(52, 82, 115, 0.5); }
  .btn-footer:disabled, .btn-footer.disabled {
    color: #fff;
    background-color: #10335A;
    border-color: #10335A; }

.btn-error {
  color: #000;
  background-color: #dee2e6;
  border-color: #dee2e6;
  box-shadow: unset; }
  .btn-error:hover {
    color: #000;
    background-color: #e3e6ea;
    border-color: #e1e5e9; }
  .btn-check:focus + .btn-error, .btn-error:focus {
    color: #000;
    background-color: #e3e6ea;
    border-color: #e1e5e9;
    box-shadow: unset, 0 0 0 0.25rem rgba(189, 192, 196, 0.5); }
  .btn-check:checked + .btn-error,
  .btn-check:active + .btn-error, .btn-error:active, .btn-error.active,
  .show > .btn-error.dropdown-toggle {
    color: #000;
    background-color: #e5e8eb;
    border-color: #e1e5e9; }
    .btn-check:checked + .btn-error:focus,
    .btn-check:active + .btn-error:focus, .btn-error:active:focus, .btn-error.active:focus,
    .show > .btn-error.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(189, 192, 196, 0.5); }
  .btn-error:disabled, .btn-error.disabled {
    color: #000;
    background-color: #dee2e6;
    border-color: #dee2e6; }

.btn-burger {
  color: #fff;
  background-color: #444444;
  border-color: #444444;
  box-shadow: unset; }
  .btn-burger:hover {
    color: #fff;
    background-color: #3a3a3a;
    border-color: #363636; }
  .btn-check:focus + .btn-burger, .btn-burger:focus {
    color: #fff;
    background-color: #3a3a3a;
    border-color: #363636;
    box-shadow: unset, 0 0 0 0.25rem rgba(96, 96, 96, 0.5); }
  .btn-check:checked + .btn-burger,
  .btn-check:active + .btn-burger, .btn-burger:active, .btn-burger.active,
  .show > .btn-burger.dropdown-toggle {
    color: #fff;
    background-color: #363636;
    border-color: #333333; }
    .btn-check:checked + .btn-burger:focus,
    .btn-check:active + .btn-burger:focus, .btn-burger:active:focus, .btn-burger.active:focus,
    .show > .btn-burger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(96, 96, 96, 0.5); }
  .btn-burger:disabled, .btn-burger.disabled {
    color: #fff;
    background-color: #444444;
    border-color: #444444; }

.btn-outline-primary {
  color: #194270;
  border-color: #194270; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #194270;
    border-color: #194270; }
  .btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
    box-shadow: 0 0 0 0.25rem rgba(25, 66, 112, 0.5); }
  .btn-check:checked + .btn-outline-primary,
  .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
    color: #fff;
    background-color: #194270;
    border-color: #194270; }
    .btn-check:checked + .btn-outline-primary:focus,
    .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(25, 66, 112, 0.5); }
  .btn-outline-primary:disabled, .btn-outline-primary.disabled {
    color: #194270;
    background-color: transparent; }

.btn-outline-primary-2 {
  color: #38689d;
  border-color: #38689d; }
  .btn-outline-primary-2:hover {
    color: #fff;
    background-color: #38689d;
    border-color: #38689d; }
  .btn-check:focus + .btn-outline-primary-2, .btn-outline-primary-2:focus {
    box-shadow: 0 0 0 0.25rem rgba(56, 104, 157, 0.5); }
  .btn-check:checked + .btn-outline-primary-2,
  .btn-check:active + .btn-outline-primary-2, .btn-outline-primary-2:active, .btn-outline-primary-2.active, .btn-outline-primary-2.dropdown-toggle.show {
    color: #fff;
    background-color: #38689d;
    border-color: #38689d; }
    .btn-check:checked + .btn-outline-primary-2:focus,
    .btn-check:active + .btn-outline-primary-2:focus, .btn-outline-primary-2:active:focus, .btn-outline-primary-2.active:focus, .btn-outline-primary-2.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(56, 104, 157, 0.5); }
  .btn-outline-primary-2:disabled, .btn-outline-primary-2.disabled {
    color: #38689d;
    background-color: transparent; }

.btn-outline-primary-3 {
  color: #10335A;
  border-color: #10335A; }
  .btn-outline-primary-3:hover {
    color: #fff;
    background-color: #10335A;
    border-color: #10335A; }
  .btn-check:focus + .btn-outline-primary-3, .btn-outline-primary-3:focus {
    box-shadow: 0 0 0 0.25rem rgba(16, 51, 90, 0.5); }
  .btn-check:checked + .btn-outline-primary-3,
  .btn-check:active + .btn-outline-primary-3, .btn-outline-primary-3:active, .btn-outline-primary-3.active, .btn-outline-primary-3.dropdown-toggle.show {
    color: #fff;
    background-color: #10335A;
    border-color: #10335A; }
    .btn-check:checked + .btn-outline-primary-3:focus,
    .btn-check:active + .btn-outline-primary-3:focus, .btn-outline-primary-3:active:focus, .btn-outline-primary-3.active:focus, .btn-outline-primary-3.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(16, 51, 90, 0.5); }
  .btn-outline-primary-3:disabled, .btn-outline-primary-3.disabled {
    color: #10335A;
    background-color: transparent; }

.btn-outline-secondary {
  color: #32B4B4;
  border-color: #32B4B4; }
  .btn-outline-secondary:hover {
    color: #000;
    background-color: #32B4B4;
    border-color: #32B4B4; }
  .btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
    box-shadow: 0 0 0 0.25rem rgba(50, 180, 180, 0.5); }
  .btn-check:checked + .btn-outline-secondary,
  .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
    color: #000;
    background-color: #32B4B4;
    border-color: #32B4B4; }
    .btn-check:checked + .btn-outline-secondary:focus,
    .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(50, 180, 180, 0.5); }
  .btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
    color: #32B4B4;
    background-color: transparent; }

.btn-outline-secondary-2 {
  color: #C8E6CD;
  border-color: #C8E6CD; }
  .btn-outline-secondary-2:hover {
    color: #000;
    background-color: #C8E6CD;
    border-color: #C8E6CD; }
  .btn-check:focus + .btn-outline-secondary-2, .btn-outline-secondary-2:focus {
    box-shadow: 0 0 0 0.25rem rgba(200, 230, 205, 0.5); }
  .btn-check:checked + .btn-outline-secondary-2,
  .btn-check:active + .btn-outline-secondary-2, .btn-outline-secondary-2:active, .btn-outline-secondary-2.active, .btn-outline-secondary-2.dropdown-toggle.show {
    color: #000;
    background-color: #C8E6CD;
    border-color: #C8E6CD; }
    .btn-check:checked + .btn-outline-secondary-2:focus,
    .btn-check:active + .btn-outline-secondary-2:focus, .btn-outline-secondary-2:active:focus, .btn-outline-secondary-2.active:focus, .btn-outline-secondary-2.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(200, 230, 205, 0.5); }
  .btn-outline-secondary-2:disabled, .btn-outline-secondary-2.disabled {
    color: #C8E6CD;
    background-color: transparent; }

.btn-outline-secondary-3 {
  color: #EBF0F5;
  border-color: #EBF0F5; }
  .btn-outline-secondary-3:hover {
    color: #000;
    background-color: #EBF0F5;
    border-color: #EBF0F5; }
  .btn-check:focus + .btn-outline-secondary-3, .btn-outline-secondary-3:focus {
    box-shadow: 0 0 0 0.25rem rgba(235, 240, 245, 0.5); }
  .btn-check:checked + .btn-outline-secondary-3,
  .btn-check:active + .btn-outline-secondary-3, .btn-outline-secondary-3:active, .btn-outline-secondary-3.active, .btn-outline-secondary-3.dropdown-toggle.show {
    color: #000;
    background-color: #EBF0F5;
    border-color: #EBF0F5; }
    .btn-check:checked + .btn-outline-secondary-3:focus,
    .btn-check:active + .btn-outline-secondary-3:focus, .btn-outline-secondary-3:active:focus, .btn-outline-secondary-3.active:focus, .btn-outline-secondary-3.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(235, 240, 245, 0.5); }
  .btn-outline-secondary-3:disabled, .btn-outline-secondary-3.disabled {
    color: #EBF0F5;
    background-color: transparent; }

.btn-outline-secondary-4 {
  color: #A8BED5;
  border-color: #A8BED5; }
  .btn-outline-secondary-4:hover {
    color: #000;
    background-color: #A8BED5;
    border-color: #A8BED5; }
  .btn-check:focus + .btn-outline-secondary-4, .btn-outline-secondary-4:focus {
    box-shadow: 0 0 0 0.25rem rgba(168, 190, 213, 0.5); }
  .btn-check:checked + .btn-outline-secondary-4,
  .btn-check:active + .btn-outline-secondary-4, .btn-outline-secondary-4:active, .btn-outline-secondary-4.active, .btn-outline-secondary-4.dropdown-toggle.show {
    color: #000;
    background-color: #A8BED5;
    border-color: #A8BED5; }
    .btn-check:checked + .btn-outline-secondary-4:focus,
    .btn-check:active + .btn-outline-secondary-4:focus, .btn-outline-secondary-4:active:focus, .btn-outline-secondary-4.active:focus, .btn-outline-secondary-4.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(168, 190, 213, 0.5); }
  .btn-outline-secondary-4:disabled, .btn-outline-secondary-4.disabled {
    color: #A8BED5;
    background-color: transparent; }

.btn-outline-gray-100 {
  color: #EBF0F5;
  border-color: #EBF0F5; }
  .btn-outline-gray-100:hover {
    color: #000;
    background-color: #EBF0F5;
    border-color: #EBF0F5; }
  .btn-check:focus + .btn-outline-gray-100, .btn-outline-gray-100:focus {
    box-shadow: 0 0 0 0.25rem rgba(235, 240, 245, 0.5); }
  .btn-check:checked + .btn-outline-gray-100,
  .btn-check:active + .btn-outline-gray-100, .btn-outline-gray-100:active, .btn-outline-gray-100.active, .btn-outline-gray-100.dropdown-toggle.show {
    color: #000;
    background-color: #EBF0F5;
    border-color: #EBF0F5; }
    .btn-check:checked + .btn-outline-gray-100:focus,
    .btn-check:active + .btn-outline-gray-100:focus, .btn-outline-gray-100:active:focus, .btn-outline-gray-100.active:focus, .btn-outline-gray-100.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(235, 240, 245, 0.5); }
  .btn-outline-gray-100:disabled, .btn-outline-gray-100.disabled {
    color: #EBF0F5;
    background-color: transparent; }

.btn-outline-gray-800 {
  color: #444444;
  border-color: #444444; }
  .btn-outline-gray-800:hover {
    color: #fff;
    background-color: #444444;
    border-color: #444444; }
  .btn-check:focus + .btn-outline-gray-800, .btn-outline-gray-800:focus {
    box-shadow: 0 0 0 0.25rem rgba(68, 68, 68, 0.5); }
  .btn-check:checked + .btn-outline-gray-800,
  .btn-check:active + .btn-outline-gray-800, .btn-outline-gray-800:active, .btn-outline-gray-800.active, .btn-outline-gray-800.dropdown-toggle.show {
    color: #fff;
    background-color: #444444;
    border-color: #444444; }
    .btn-check:checked + .btn-outline-gray-800:focus,
    .btn-check:active + .btn-outline-gray-800:focus, .btn-outline-gray-800:active:focus, .btn-outline-gray-800.active:focus, .btn-outline-gray-800.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(68, 68, 68, 0.5); }
  .btn-outline-gray-800:disabled, .btn-outline-gray-800.disabled {
    color: #444444;
    background-color: transparent; }

.btn-outline-pink {
  color: #8B1F5A;
  border-color: #8B1F5A; }
  .btn-outline-pink:hover {
    color: #fff;
    background-color: #8B1F5A;
    border-color: #8B1F5A; }
  .btn-check:focus + .btn-outline-pink, .btn-outline-pink:focus {
    box-shadow: 0 0 0 0.25rem rgba(139, 31, 90, 0.5); }
  .btn-check:checked + .btn-outline-pink,
  .btn-check:active + .btn-outline-pink, .btn-outline-pink:active, .btn-outline-pink.active, .btn-outline-pink.dropdown-toggle.show {
    color: #fff;
    background-color: #8B1F5A;
    border-color: #8B1F5A; }
    .btn-check:checked + .btn-outline-pink:focus,
    .btn-check:active + .btn-outline-pink:focus, .btn-outline-pink:active:focus, .btn-outline-pink.active:focus, .btn-outline-pink.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(139, 31, 90, 0.5); }
  .btn-outline-pink:disabled, .btn-outline-pink.disabled {
    color: #8B1F5A;
    background-color: transparent; }

.btn-outline-success {
  color: #32B4B4;
  border-color: #32B4B4; }
  .btn-outline-success:hover {
    color: #000;
    background-color: #32B4B4;
    border-color: #32B4B4; }
  .btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
    box-shadow: 0 0 0 0.25rem rgba(50, 180, 180, 0.5); }
  .btn-check:checked + .btn-outline-success,
  .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
    color: #000;
    background-color: #32B4B4;
    border-color: #32B4B4; }
    .btn-check:checked + .btn-outline-success:focus,
    .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(50, 180, 180, 0.5); }
  .btn-outline-success:disabled, .btn-outline-success.disabled {
    color: #32B4B4;
    background-color: transparent; }

.btn-outline-info {
  color: #65afe2;
  border-color: #65afe2; }
  .btn-outline-info:hover {
    color: #000;
    background-color: #65afe2;
    border-color: #65afe2; }
  .btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
    box-shadow: 0 0 0 0.25rem rgba(101, 175, 226, 0.5); }
  .btn-check:checked + .btn-outline-info,
  .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
    color: #000;
    background-color: #65afe2;
    border-color: #65afe2; }
    .btn-check:checked + .btn-outline-info:focus,
    .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(101, 175, 226, 0.5); }
  .btn-outline-info:disabled, .btn-outline-info.disabled {
    color: #65afe2;
    background-color: transparent; }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5); }
  .btn-check:checked + .btn-outline-warning,
  .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-check:checked + .btn-outline-warning:focus,
    .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning:disabled, .btn-outline-warning.disabled {
    color: #ffc107;
    background-color: transparent; }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5); }
  .btn-check:checked + .btn-outline-danger,
  .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-check:checked + .btn-outline-danger:focus,
    .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger:disabled, .btn-outline-danger.disabled {
    color: #dc3545;
    background-color: transparent; }

.btn-outline-light {
  color: #fff;
  border-color: #fff; }
  .btn-outline-light:hover {
    color: #000;
    background-color: #fff;
    border-color: #fff; }
  .btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5); }
  .btn-check:checked + .btn-outline-light,
  .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
    color: #000;
    background-color: #fff;
    border-color: #fff; }
    .btn-check:checked + .btn-outline-light:focus,
    .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(255, 255, 255, 0.5); }
  .btn-outline-light:disabled, .btn-outline-light.disabled {
    color: #fff;
    background-color: transparent; }

.btn-outline-dark {
  color: #444444;
  border-color: #444444; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #444444;
    border-color: #444444; }
  .btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
    box-shadow: 0 0 0 0.25rem rgba(68, 68, 68, 0.5); }
  .btn-check:checked + .btn-outline-dark,
  .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
    color: #fff;
    background-color: #444444;
    border-color: #444444; }
    .btn-check:checked + .btn-outline-dark:focus,
    .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(68, 68, 68, 0.5); }
  .btn-outline-dark:disabled, .btn-outline-dark.disabled {
    color: #444444;
    background-color: transparent; }

.btn-outline-mobile {
  color: #dee2e6;
  border-color: #dee2e6; }
  .btn-outline-mobile:hover {
    color: #000;
    background-color: #dee2e6;
    border-color: #dee2e6; }
  .btn-check:focus + .btn-outline-mobile, .btn-outline-mobile:focus {
    box-shadow: 0 0 0 0.25rem rgba(222, 226, 230, 0.5); }
  .btn-check:checked + .btn-outline-mobile,
  .btn-check:active + .btn-outline-mobile, .btn-outline-mobile:active, .btn-outline-mobile.active, .btn-outline-mobile.dropdown-toggle.show {
    color: #000;
    background-color: #dee2e6;
    border-color: #dee2e6; }
    .btn-check:checked + .btn-outline-mobile:focus,
    .btn-check:active + .btn-outline-mobile:focus, .btn-outline-mobile:active:focus, .btn-outline-mobile.active:focus, .btn-outline-mobile.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(222, 226, 230, 0.5); }
  .btn-outline-mobile:disabled, .btn-outline-mobile.disabled {
    color: #dee2e6;
    background-color: transparent; }

.btn-outline-body {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-body:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-check:focus + .btn-outline-body, .btn-outline-body:focus {
    box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5); }
  .btn-check:checked + .btn-outline-body,
  .btn-check:active + .btn-outline-body, .btn-outline-body:active, .btn-outline-body.active, .btn-outline-body.dropdown-toggle.show {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-check:checked + .btn-outline-body:focus,
    .btn-check:active + .btn-outline-body:focus, .btn-outline-body:active:focus, .btn-outline-body.active:focus, .btn-outline-body.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(108, 117, 125, 0.5); }
  .btn-outline-body:disabled, .btn-outline-body.disabled {
    color: #6c757d;
    background-color: transparent; }

.btn-outline-footer {
  color: #10335A;
  border-color: #10335A; }
  .btn-outline-footer:hover {
    color: #fff;
    background-color: #10335A;
    border-color: #10335A; }
  .btn-check:focus + .btn-outline-footer, .btn-outline-footer:focus {
    box-shadow: 0 0 0 0.25rem rgba(16, 51, 90, 0.5); }
  .btn-check:checked + .btn-outline-footer,
  .btn-check:active + .btn-outline-footer, .btn-outline-footer:active, .btn-outline-footer.active, .btn-outline-footer.dropdown-toggle.show {
    color: #fff;
    background-color: #10335A;
    border-color: #10335A; }
    .btn-check:checked + .btn-outline-footer:focus,
    .btn-check:active + .btn-outline-footer:focus, .btn-outline-footer:active:focus, .btn-outline-footer.active:focus, .btn-outline-footer.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(16, 51, 90, 0.5); }
  .btn-outline-footer:disabled, .btn-outline-footer.disabled {
    color: #10335A;
    background-color: transparent; }

.btn-outline-error {
  color: #dee2e6;
  border-color: #dee2e6; }
  .btn-outline-error:hover {
    color: #000;
    background-color: #dee2e6;
    border-color: #dee2e6; }
  .btn-check:focus + .btn-outline-error, .btn-outline-error:focus {
    box-shadow: 0 0 0 0.25rem rgba(222, 226, 230, 0.5); }
  .btn-check:checked + .btn-outline-error,
  .btn-check:active + .btn-outline-error, .btn-outline-error:active, .btn-outline-error.active, .btn-outline-error.dropdown-toggle.show {
    color: #000;
    background-color: #dee2e6;
    border-color: #dee2e6; }
    .btn-check:checked + .btn-outline-error:focus,
    .btn-check:active + .btn-outline-error:focus, .btn-outline-error:active:focus, .btn-outline-error.active:focus, .btn-outline-error.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(222, 226, 230, 0.5); }
  .btn-outline-error:disabled, .btn-outline-error.disabled {
    color: #dee2e6;
    background-color: transparent; }

.btn-outline-burger {
  color: #444444;
  border-color: #444444; }
  .btn-outline-burger:hover {
    color: #fff;
    background-color: #444444;
    border-color: #444444; }
  .btn-check:focus + .btn-outline-burger, .btn-outline-burger:focus {
    box-shadow: 0 0 0 0.25rem rgba(68, 68, 68, 0.5); }
  .btn-check:checked + .btn-outline-burger,
  .btn-check:active + .btn-outline-burger, .btn-outline-burger:active, .btn-outline-burger.active, .btn-outline-burger.dropdown-toggle.show {
    color: #fff;
    background-color: #444444;
    border-color: #444444; }
    .btn-check:checked + .btn-outline-burger:focus,
    .btn-check:active + .btn-outline-burger:focus, .btn-outline-burger:active:focus, .btn-outline-burger.active:focus, .btn-outline-burger.dropdown-toggle.show:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(68, 68, 68, 0.5); }
  .btn-outline-burger:disabled, .btn-outline-burger.disabled {
    color: #444444;
    background-color: transparent; }

.btn-link {
  font-weight: 400;
  color: #fff;
  text-decoration: underline; }
  .btn-link:hover {
    color: #14355a; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 5px 20px 5px 20px; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.6rem; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 10px;
  box-shadow: 0 1rem 4rem 0 rgba(0, 0, 0, 0.05); }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px; }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px; }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  padding: 20px 20px; }

.card-title {
  margin-bottom: 0.5rem; }

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link + .card-link {
  margin-left: 20px; }

.card-header {
  padding: 10px 20px;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 0 solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: 10px 10px 0 0; }

.card-footer {
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 0 solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 10px 10px; }

.card-header-tabs {
  margin-right: -10px;
  margin-bottom: -10px;
  margin-left: -10px;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -10px;
  margin-left: -10px; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: 10px; }

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px; }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px; }

.card-group > .card {
  margin-bottom: 1.5rem; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 2px 5px 2px 5px; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 2px 5px 2px 5px; }

.list-group-numbered {
  list-style-type: none;
  counter-reset: section; }
  .list-group-numbered > li::before {
    content: counters(section, ".") ". ";
    counter-increment: section; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #EBF0F5; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0 0;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.3); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #194270;
    border-color: #194270; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 2px 5px 2px 5px;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 2px 5px 2px 5px;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 2px 5px 2px 5px;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 2px 5px 2px 5px;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 2px 5px 2px 5px;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 2px 5px 2px 5px;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 2px 5px 2px 5px;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 2px 5px 2px 5px;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 2px 5px 2px 5px;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 2px 5px 2px 5px;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl > .list-group-item:first-child {
      border-bottom-left-radius: 2px 5px 2px 5px;
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item:last-child {
      border-top-right-radius: 2px 5px 2px 5px;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #0f2843;
  background-color: #d1d9e2; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #0f2843;
    background-color: #bcc3cb; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #0f2843;
    border-color: #0f2843; }

.list-group-item-primary-2 {
  color: #223e5e;
  background-color: #d7e1eb; }
  .list-group-item-primary-2.list-group-item-action:hover, .list-group-item-primary-2.list-group-item-action:focus {
    color: #223e5e;
    background-color: #c2cbd4; }
  .list-group-item-primary-2.list-group-item-action.active {
    color: #fff;
    background-color: #223e5e;
    border-color: #223e5e; }

.list-group-item-primary-3 {
  color: #0a1f36;
  background-color: #cfd6de; }
  .list-group-item-primary-3.list-group-item-action:hover, .list-group-item-primary-3.list-group-item-action:focus {
    color: #0a1f36;
    background-color: #bac1c8; }
  .list-group-item-primary-3.list-group-item-action.active {
    color: #fff;
    background-color: #0a1f36;
    border-color: #0a1f36; }

.list-group-item-secondary {
  color: #1e6c6c;
  background-color: #d6f0f0; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #1e6c6c;
    background-color: #c1d8d8; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #1e6c6c;
    border-color: #1e6c6c; }

.list-group-item-secondary-2 {
  color: #505c52;
  background-color: #f4faf5; }
  .list-group-item-secondary-2.list-group-item-action:hover, .list-group-item-secondary-2.list-group-item-action:focus {
    color: #505c52;
    background-color: #dce1dd; }
  .list-group-item-secondary-2.list-group-item-action.active {
    color: #fff;
    background-color: #505c52;
    border-color: #505c52; }

.list-group-item-secondary-3 {
  color: #5e6062;
  background-color: #fbfcfd; }
  .list-group-item-secondary-3.list-group-item-action:hover, .list-group-item-secondary-3.list-group-item-action:focus {
    color: #5e6062;
    background-color: #e2e3e4; }
  .list-group-item-secondary-3.list-group-item-action.active {
    color: #fff;
    background-color: #5e6062;
    border-color: #5e6062; }

.list-group-item-secondary-4 {
  color: #434c55;
  background-color: #eef2f7; }
  .list-group-item-secondary-4.list-group-item-action:hover, .list-group-item-secondary-4.list-group-item-action:focus {
    color: #434c55;
    background-color: #d6dade; }
  .list-group-item-secondary-4.list-group-item-action.active {
    color: #fff;
    background-color: #434c55;
    border-color: #434c55; }

.list-group-item-gray-100 {
  color: #5e6062;
  background-color: #fbfcfd; }
  .list-group-item-gray-100.list-group-item-action:hover, .list-group-item-gray-100.list-group-item-action:focus {
    color: #5e6062;
    background-color: #e2e3e4; }
  .list-group-item-gray-100.list-group-item-action.active {
    color: #fff;
    background-color: #5e6062;
    border-color: #5e6062; }

.list-group-item-gray-800 {
  color: #292929;
  background-color: #dadada; }
  .list-group-item-gray-800.list-group-item-action:hover, .list-group-item-gray-800.list-group-item-action:focus {
    color: #292929;
    background-color: #c4c4c4; }
  .list-group-item-gray-800.list-group-item-action.active {
    color: #fff;
    background-color: #292929;
    border-color: #292929; }

.list-group-item-pink {
  color: #531336;
  background-color: #e8d2de; }
  .list-group-item-pink.list-group-item-action:hover, .list-group-item-pink.list-group-item-action:focus {
    color: #531336;
    background-color: #d1bdc8; }
  .list-group-item-pink.list-group-item-action.active {
    color: #fff;
    background-color: #531336;
    border-color: #531336; }

.list-group-item-success {
  color: #1e6c6c;
  background-color: #d6f0f0; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #1e6c6c;
    background-color: #c1d8d8; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #1e6c6c;
    border-color: #1e6c6c; }

.list-group-item-info {
  color: #3d6988;
  background-color: #e0eff9; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #3d6988;
    background-color: #cad7e0; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #3d6988;
    border-color: #3d6988; }

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #664d03;
    background-color: #e6dbb9; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #664d03;
    border-color: #664d03; }

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #842029;
    background-color: #dfc2c4; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #842029;
    border-color: #842029; }

.list-group-item-light {
  color: #666666;
  background-color: white; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #666666;
    background-color: #e6e6e6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #666666;
    border-color: #666666; }

.list-group-item-dark {
  color: #292929;
  background-color: #dadada; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #292929;
    background-color: #c4c4c4; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #292929;
    border-color: #292929; }

.list-group-item-mobile {
  color: #595a5c;
  background-color: #f8f9fa; }
  .list-group-item-mobile.list-group-item-action:hover, .list-group-item-mobile.list-group-item-action:focus {
    color: #595a5c;
    background-color: #dfe0e1; }
  .list-group-item-mobile.list-group-item-action.active {
    color: #fff;
    background-color: #595a5c;
    border-color: #595a5c; }

.list-group-item-body {
  color: #41464b;
  background-color: #e2e3e5; }
  .list-group-item-body.list-group-item-action:hover, .list-group-item-body.list-group-item-action:focus {
    color: #41464b;
    background-color: #cbccce; }
  .list-group-item-body.list-group-item-action.active {
    color: #fff;
    background-color: #41464b;
    border-color: #41464b; }

.list-group-item-footer {
  color: #0a1f36;
  background-color: #cfd6de; }
  .list-group-item-footer.list-group-item-action:hover, .list-group-item-footer.list-group-item-action:focus {
    color: #0a1f36;
    background-color: #bac1c8; }
  .list-group-item-footer.list-group-item-action.active {
    color: #fff;
    background-color: #0a1f36;
    border-color: #0a1f36; }

.list-group-item-error {
  color: #595a5c;
  background-color: #f8f9fa; }
  .list-group-item-error.list-group-item-action:hover, .list-group-item-error.list-group-item-action:focus {
    color: #595a5c;
    background-color: #dfe0e1; }
  .list-group-item-error.list-group-item-action.active {
    color: #fff;
    background-color: #595a5c;
    border-color: #595a5c; }

.list-group-item-burger {
  color: #292929;
  background-color: #dadada; }
  .list-group-item-burger.list-group-item-action:hover, .list-group-item-burger.list-group-item-action:focus {
    color: #292929;
    background-color: #c4c4c4; }
  .list-group-item-burger.list-group-item-action.active {
    color: #fff;
    background-color: #292929;
    border-color: #292929; }

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 2px 5px 2px 5px;
  opacity: 0.5; }
  .btn-close:hover {
    color: #000;
    text-decoration: none;
    opacity: 0.75; }
  .btn-close:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(25, 66, 112, 0.25);
    opacity: 1; }
  .btn-close:disabled, .btn-close.disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.25; }

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%); }

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 100%;
  padding: 0.8rem 0.8rem;
  margin: 0;
  font-size: 1.5rem;
  color: #32B4B4;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 2px 5px 2px 5px;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.05); }
  .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: 0.125rem; }

.dropdown-menu-start {
  --bs-position: start; }
  .dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0; }

.dropdown-menu-end {
  --bs-position: end; }
  .dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start; }
    .dropdown-menu-sm-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-sm-end {
    --bs-position: end; }
    .dropdown-menu-sm-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start; }
    .dropdown-menu-md-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-md-end {
    --bs-position: end; }
    .dropdown-menu-md-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start; }
    .dropdown-menu-lg-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-lg-end {
    --bs-position: end; }
    .dropdown-menu-lg-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start; }
    .dropdown-menu-xl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xl-end {
    --bs-position: end; }
    .dropdown-menu-xl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start; }
    .dropdown-menu-xxl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xxl-end {
    --bs-position: end; }
    .dropdown-menu-xxl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropend .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-toggle::after {
  vertical-align: 0; }

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropstart .dropdown-toggle::after {
  display: none; }

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropstart .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15); }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.8rem 2rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #194270;
    background-color: #EBF0F5; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #194270; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.8rem 2rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #32B4B4;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.8rem 2rem;
  color: #212529; }

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #444444;
  border-color: rgba(0, 0, 0, 0.15); }
  .dropdown-menu-dark .dropdown-item {
    color: #dee2e6; }
    .dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
      color: #fff;
      background-color: rgba(255, 255, 255, 0.15); }
    .dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
      color: #fff;
      background-color: #194270; }
    .dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
      color: #adb5bd; }
  .dropdown-menu-dark .dropdown-divider {
    border-color: rgba(0, 0, 0, 0.15); }
  .dropdown-menu-dark .dropdown-item-text {
    color: #dee2e6; }
  .dropdown-menu-dark .dropdown-header {
    color: #adb5bd; }

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .accordion-button {
      transition: none; } }
  .accordion-button:not(.collapsed) {
    color: #173b65;
    background-color: transparent;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125); }
    .accordion-button:not(.collapsed)::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      transform: rotate(-180deg); }
  .accordion-button::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform 0.2s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .accordion-button::after {
        transition: none; } }
  .accordion-button:hover {
    z-index: 2; }
  .accordion-button:focus {
    z-index: 3;
    border-color: #8ca1b8;
    outline: 0;
    box-shadow: unset; }

.accordion-header {
  margin-bottom: 0; }

.accordion-item {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .accordion-item:first-of-type {
    border-top-left-radius: 2px 5px 2px 5px;
    border-top-right-radius: 2px 5px 2px 5px; }
    .accordion-item:first-of-type .accordion-button {
      border-top-left-radius: calc(2px 5px 2px 5px - 1px);
      border-top-right-radius: calc(2px 5px 2px 5px - 1px); }
  .accordion-item:not(:first-of-type) {
    border-top: 0; }
  .accordion-item:last-of-type {
    border-bottom-right-radius: 2px 5px 2px 5px;
    border-bottom-left-radius: 2px 5px 2px 5px; }
    .accordion-item:last-of-type .accordion-button.collapsed {
      border-bottom-right-radius: calc(2px 5px 2px 5px - 1px);
      border-bottom-left-radius: calc(2px 5px 2px 5px - 1px); }
    .accordion-item:last-of-type .accordion-collapse {
      border-bottom-right-radius: 2px 5px 2px 5px;
      border-bottom-left-radius: 2px 5px 2px 5px; }

.accordion-body {
  padding: 1rem 1.25rem; }

.accordion-flush .accordion-collapse {
  border-width: 0; }

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .accordion-flush .accordion-item:first-child {
    border-top: 0; }
  .accordion-flush .accordion-item:last-child {
    border-bottom: 0; }
  .accordion-flush .accordion-item .accordion-button {
    border-radius: 0; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.collapse-horizontal {
        transition: none; } }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }
  .navbar > .container,
  .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static; }

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 2px 5px 2px 5px;
  transition: box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
      transition: none; } }
  .navbar-toggler:hover {
    text-decoration: none; }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0.25rem; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%; }

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto; }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .offcanvas-header {
      display: none; }
    .navbar-expand-sm .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-sm .offcanvas-top,
    .navbar-expand-sm .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-sm .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .offcanvas-header {
      display: none; }
    .navbar-expand-md .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-md .offcanvas-top,
    .navbar-expand-md .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-md .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .offcanvas-header {
      display: none; }
    .navbar-expand-lg .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-lg .offcanvas-top,
    .navbar-expand-lg .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-lg .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .offcanvas-header {
      display: none; }
    .navbar-expand-xl .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-xl .offcanvas-top,
    .navbar-expand-xl .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-xl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; }
    .navbar-expand-xxl .offcanvas-header {
      display: none; }
    .navbar-expand-xxl .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-xxl .offcanvas-top,
    .navbar-expand-xxl .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-xxl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .offcanvas-header {
    display: none; }
  .navbar-expand .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none; }
  .navbar-expand .offcanvas-top,
  .navbar-expand .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0; }
  .navbar-expand .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55); }
  .navbar-light .navbar-text a,
  .navbar-light .navbar-text a:hover,
  .navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55); }
  .navbar-dark .navbar-text a,
  .navbar-dark .navbar-text a:hover,
  .navbar-dark .navbar-text a:focus {
    color: #fff; }

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #194270;
  background-clip: padding-box;
  outline: 0;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .offcanvas {
      transition: none; } }

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #194270; }
  .offcanvas-backdrop.fade {
    opacity: 0; }
  .offcanvas-backdrop.show {
    opacity: 0.5; }

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px; }
  .offcanvas-header .btn-close {
    padding: 10px 15px;
    margin-top: -10px;
    margin-right: -15px;
    margin-bottom: -10px; }

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5; }

.offcanvas-body {
  flex-grow: 1;
  padding: 20px 30px;
  overflow-y: auto; }

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 0 solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%); }

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 0 solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%); }

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 0 solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%); }

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 0 solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%); }

.offcanvas.show {
  transform: none; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.2);
  border-radius: 5px 20px 5px 20px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #194270; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 0 solid #dee2e6;
  border-top-left-radius: calc(5px 20px 5px 20px - 0);
  border-top-right-radius: calc(5px 20px 5px 20px - 0); }
  .modal-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin: -0.5rem -0.5rem -0.5rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 0 solid #dee2e6;
  border-bottom-right-radius: calc(5px 20px 5px 20px - 0);
  border-bottom-left-radius: calc(5px 20px 5px 20px - 0); }
  .modal-footer > * {
    margin: 0.25rem; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
  .modal-content {
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.05); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0; }
  .modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen .modal-header {
    border-radius: 0; }
  .modal-fullscreen .modal-body {
    overflow-y: auto; }
  .modal-fullscreen .modal-footer {
    border-radius: 0; }

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-sm-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-sm-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-md-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-md-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-lg-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-lg-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-xl-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xxl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-xxl-down .modal-footer {
      border-radius: 0; } }

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #194270;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #194270;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #194270;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #194270;
  vertical-align: top;
  border-color: #38689d; }
  .table > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg); }
  .table > tbody {
    vertical-align: inherit; }
  .table > thead {
    vertical-align: bottom; }
  .table > :not(:first-child) {
    border-top: 2px solid currentColor; }

.caption-top {
  caption-side: top; }

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem; }

.table-bordered > :not(caption) > * {
  border-width: 1px 0; }
  .table-bordered > :not(caption) > * > * {
    border-width: 0 1px; }

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0; }

.table-borderless > :not(:first-child) {
  border-top-width: 0; }

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color); }

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color); }

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color); }

.table-primary {
  --bs-table-bg: #d1d9e2;
  --bs-table-striped-bg: #c7ced7;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcc3cb;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1c9d1;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bcc3cb; }

.table-secondary {
  --bs-table-bg: #d6f0f0;
  --bs-table-striped-bg: #cbe4e4;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c1d8d8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c6dede;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #c1d8d8; }

.table-success {
  --bs-table-bg: #d6f0f0;
  --bs-table-striped-bg: #cbe4e4;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c1d8d8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c6dede;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #c1d8d8; }

.table-info {
  --bs-table-bg: #e0eff9;
  --bs-table-striped-bg: #d5e3ed;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cad7e0;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cfdde6;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #cad7e0; }

.table-warning {
  --bs-table-bg: #fff3cd;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6dbb9; }

.table-danger {
  --bs-table-bg: #f8d7da;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfc2c4; }

.table-light {
  --bs-table-bg: #EBF0F5;
  --bs-table-striped-bg: #dfe4e9;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #d4d8dd;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d9dee3;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #d4d8dd; }

.table-dark {
  --bs-table-bg: #444444;
  --bs-table-striped-bg: #4d4d4d;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #575757;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #525252;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #575757; }

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  color: #A8BED5;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .page-link {
      transition: none; } }
  .page-link:hover {
    z-index: 2;
    color: #14355a;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    color: #14355a;
    background-color: #e9ecef;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(25, 66, 112, 0.25); }

.page-item:not(:first-child) .page-link {
  margin-left: -1px; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #194270;
  border-color: #194270; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6; }

.page-link {
  padding: 0.9rem 1.4rem; }

.page-item:first-child .page-link {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px; }

.page-item:last-child .page-link {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 5px 20px 5px 20px;
  border-bottom-left-radius: 5px 20px 5px 20px; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 5px 20px 5px 20px;
  border-bottom-right-radius: 5px 20px 5px 20px; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.6rem;
  border-bottom-left-radius: 0.6rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.6rem;
  border-bottom-right-radius: 0.6rem; }

.link-primary {
  color: #194270; }
  .link-primary:hover, .link-primary:focus {
    color: #14355a; }

.link-primary-2 {
  color: #38689d; }
  .link-primary-2:hover, .link-primary-2:focus {
    color: #2d537e; }

.link-primary-3 {
  color: #10335A; }
  .link-primary-3:hover, .link-primary-3:focus {
    color: #0d2948; }

.link-secondary {
  color: #32B4B4; }
  .link-secondary:hover, .link-secondary:focus {
    color: #5bc3c3; }

.link-secondary-2 {
  color: #C8E6CD; }
  .link-secondary-2:hover, .link-secondary-2:focus {
    color: #d3ebd7; }

.link-secondary-3 {
  color: #EBF0F5; }
  .link-secondary-3:hover, .link-secondary-3:focus {
    color: #eff3f7; }

.link-secondary-4 {
  color: #A8BED5; }
  .link-secondary-4:hover, .link-secondary-4:focus {
    color: #b9cbdd; }

.link-gray-100 {
  color: #EBF0F5; }
  .link-gray-100:hover, .link-gray-100:focus {
    color: #eff3f7; }

.link-gray-800 {
  color: #444444; }
  .link-gray-800:hover, .link-gray-800:focus {
    color: #363636; }

.link-pink {
  color: #8B1F5A; }
  .link-pink:hover, .link-pink:focus {
    color: #6f1948; }

.link-success {
  color: #32B4B4; }
  .link-success:hover, .link-success:focus {
    color: #5bc3c3; }

.link-info {
  color: #65afe2; }
  .link-info:hover, .link-info:focus {
    color: #84bfe8; }

.link-warning {
  color: #ffc107; }
  .link-warning:hover, .link-warning:focus {
    color: #ffcd39; }

.link-danger {
  color: #dc3545; }
  .link-danger:hover, .link-danger:focus {
    color: #b02a37; }

.link-light {
  color: #fff; }
  .link-light:hover, .link-light:focus {
    color: white; }

.link-dark {
  color: #444444; }
  .link-dark:hover, .link-dark:focus {
    color: #363636; }

.link-mobile {
  color: #dee2e6; }
  .link-mobile:hover, .link-mobile:focus {
    color: #e5e8eb; }

.link-body {
  color: #6c757d; }
  .link-body:hover, .link-body:focus {
    color: #565e64; }

.link-footer {
  color: #10335A; }
  .link-footer:hover, .link-footer:focus {
    color: #0d2948; }

.link-error {
  color: #dee2e6; }
  .link-error:hover, .link-error:focus {
    color: #e5e8eb; }

.link-burger {
  color: #444444; }
  .link-burger:hover, .link-burger:focus {
    color: #363636; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""; }

/* ====== obligatoire
*/
/* ====== optionnel
*/
/* ========================================================================
   Component: Icon
 ========================================================================== */
/*
 * Note: 1. - 7. is required for `button` elements. Needed for Close and Form Icon component.
 * 1. Remove margins in Chrome, Safari and Opera.
 * 2. Remove borders for `button`.
 * 3. Remove border-radius in Chrome.
 * 4. Address `overflow` set to `hidden` in IE.
 * 5. Correct `font` properties and `color` not being inherited for `button`.
 * 6. Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 7. Remove default `button` padding and background color
 * 8. Style
 * 9. Fill all SVG elements with the current text color if no `fill` attribute is set
 * 10. Let the container fit the height of the icon
 */
.uk-icon {
  /* 1 */
  margin: 0;
  /* 2 */
  border: none;
  /* 3 */
  border-radius: 0;
  /* 4 */
  overflow: visible;
  /* 5 */
  font: inherit;
  color: inherit;
  /* 6 */
  text-transform: none;
  /* 7. */
  padding: 0;
  background-color: transparent;
  /* 8 */
  display: inline-block;
  /* 9 */
  fill: currentcolor;
  /* 10 */
  line-height: 0; }

/* Required for `button`. */
button.uk-icon:not(:disabled) {
  cursor: pointer; }

/*
 * Remove the inner border and padding in Firefox.
 */
.uk-icon::-moz-focus-inner {
  border: 0;
  padding: 0; }

/*
 * Set the fill and stroke color of all SVG elements to the current text color
 */
.uk-icon:not(.uk-preserve) [fill*='#']:not(.uk-preserve) {
  fill: currentcolor; }

.uk-icon:not(.uk-preserve) [stroke*='#']:not(.uk-preserve) {
  stroke: currentcolor; }

/*
 * Fix Firefox blurry SVG rendering: https://bugzilla.mozilla.org/show_bug.cgi?id=1046835
 */
.uk-icon > * {
  transform: translate(0, 0); }

/* Image modifier
 ========================================================================== */
/*
 * Display images in icon dimensions
 */
.uk-icon-image {
  width: 20px;
  height: 20px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  vertical-align: middle; }

/* Style modifiers
 ========================================================================== */
/*
 * Link
 */
.uk-icon-link {
  color: #999; }

.uk-icon-link:hover {
  color: #666; }

/* OnClick + Active */
.uk-icon-link:active,
.uk-active > .uk-icon-link {
  color: #595959; }

/*
 * Button
 * 1. Center icon vertically and horizontally
 */
.uk-icon-button {
  box-sizing: border-box;
  width: 36px;
  height: 36px;
  border-radius: 500px;
  background: #f8f8f8;
  color: #999;
  vertical-align: middle;
  /* 1 */
  display: inline-flex;
  justify-content: center;
  align-items: center; }

/* Hover */
.uk-icon-button:hover {
  background-color: #ebebeb;
  color: #666; }

/* OnClick + Active */
.uk-icon-button:active,
.uk-active > .uk-icon-button {
  background-color: #dfdfdf;
  color: #666; }

/* ========================================================================
   Component: Grid
 ========================================================================== */
/*
 * 1. Allow cells to wrap into the next line
 * 2. Reset list
 */
.uk-grid {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin: 0;
  padding: 0;
  list-style: none; }

/*
 * Grid cell
 * Note: Space is allocated solely based on content dimensions, but shrinks: 0 1 auto
 * Reset margin for e.g. paragraphs
 */
.uk-grid > * {
  margin: 0; }

/*
 * Remove margin from the last-child
 */
.uk-grid > * > :last-child {
  margin-bottom: 0; }

/* Gutter
 ========================================================================== */
/*
 * Default
 */
/* Horizontal */
.uk-grid {
  margin-left: -30px; }

.uk-grid > * {
  padding-left: 30px; }

/* Vertical */
.uk-grid + .uk-grid,
.uk-grid > .uk-grid-margin,
* + .uk-grid-margin {
  margin-top: 30px; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Horizontal */
  .uk-grid {
    margin-left: -40px; }
  .uk-grid > * {
    padding-left: 40px; }
  /* Vertical */
  .uk-grid + .uk-grid,
  .uk-grid > .uk-grid-margin,
  * + .uk-grid-margin {
    margin-top: 40px; } }

/*
 * Small
 */
/* Horizontal */
.uk-grid-small,
.uk-grid-column-small {
  margin-left: -15px; }

.uk-grid-small > *,
.uk-grid-column-small > * {
  padding-left: 15px; }

/* Vertical */
.uk-grid + .uk-grid-small,
.uk-grid + .uk-grid-row-small,
.uk-grid-small > .uk-grid-margin,
.uk-grid-row-small > .uk-grid-margin,
* + .uk-grid-margin-small {
  margin-top: 15px; }

/*
 * Medium
 */
/* Horizontal */
.uk-grid-medium,
.uk-grid-column-medium {
  margin-left: -30px; }

.uk-grid-medium > *,
.uk-grid-column-medium > * {
  padding-left: 30px; }

/* Vertical */
.uk-grid + .uk-grid-medium,
.uk-grid + .uk-grid-row-medium,
.uk-grid-medium > .uk-grid-margin,
.uk-grid-row-medium > .uk-grid-margin,
* + .uk-grid-margin-medium {
  margin-top: 30px; }

/*
 * Large
 */
/* Horizontal */
.uk-grid-large,
.uk-grid-column-large {
  margin-left: -40px; }

.uk-grid-large > *,
.uk-grid-column-large > * {
  padding-left: 40px; }

/* Vertical */
.uk-grid + .uk-grid-large,
.uk-grid + .uk-grid-row-large,
.uk-grid-large > .uk-grid-margin,
.uk-grid-row-large > .uk-grid-margin,
* + .uk-grid-margin-large {
  margin-top: 40px; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Horizontal */
  .uk-grid-large,
  .uk-grid-column-large {
    margin-left: -70px; }
  .uk-grid-large > *,
  .uk-grid-column-large > * {
    padding-left: 70px; }
  /* Vertical */
  .uk-grid + .uk-grid-large,
  .uk-grid + .uk-grid-row-large,
  .uk-grid-large > .uk-grid-margin,
  .uk-grid-row-large > .uk-grid-margin,
  * + .uk-grid-margin-large {
    margin-top: 70px; } }

/*
 * Collapse
 */
/* Horizontal */
.uk-grid-collapse,
.uk-grid-column-collapse {
  margin-left: 0; }

.uk-grid-collapse > *,
.uk-grid-column-collapse > * {
  padding-left: 0; }

/* Vertical */
.uk-grid + .uk-grid-collapse,
.uk-grid + .uk-grid-row-collapse,
.uk-grid-collapse > .uk-grid-margin,
.uk-grid-row-collapse > .uk-grid-margin {
  margin-top: 0; }

/* Divider
 ========================================================================== */
.uk-grid-divider > * {
  position: relative; }

.uk-grid-divider > :not(.uk-first-column)::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  border-left: 1px solid #e5e5e5; }

/* Vertical */
.uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  border-top: 1px solid #e5e5e5; }

/*
 * Default
 */
/* Horizontal */
.uk-grid-divider {
  margin-left: -60px; }

.uk-grid-divider > * {
  padding-left: 60px; }

.uk-grid-divider > :not(.uk-first-column)::before {
  left: 30px; }

/* Vertical */
.uk-grid-divider.uk-grid-stack > .uk-grid-margin {
  margin-top: 60px; }

.uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
  top: -30px;
  left: 60px; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Horizontal */
  .uk-grid-divider {
    margin-left: -80px; }
  .uk-grid-divider > * {
    padding-left: 80px; }
  .uk-grid-divider > :not(.uk-first-column)::before {
    left: 40px; }
  /* Vertical */
  .uk-grid-divider.uk-grid-stack > .uk-grid-margin {
    margin-top: 80px; }
  .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
    top: -40px;
    left: 80px; } }

/*
 * Small
 */
/* Horizontal */
.uk-grid-divider.uk-grid-small,
.uk-grid-divider.uk-grid-column-small {
  margin-left: -30px; }

.uk-grid-divider.uk-grid-small > *,
.uk-grid-divider.uk-grid-column-small > * {
  padding-left: 30px; }

.uk-grid-divider.uk-grid-small > :not(.uk-first-column)::before,
.uk-grid-divider.uk-grid-column-small > :not(.uk-first-column)::before {
  left: 15px; }

/* Vertical */
.uk-grid-divider.uk-grid-small.uk-grid-stack > .uk-grid-margin,
.uk-grid-divider.uk-grid-row-small.uk-grid-stack > .uk-grid-margin {
  margin-top: 30px; }

.uk-grid-divider.uk-grid-small.uk-grid-stack > .uk-grid-margin::before {
  top: -15px;
  left: 30px; }

.uk-grid-divider.uk-grid-row-small.uk-grid-stack > .uk-grid-margin::before {
  top: -15px; }

.uk-grid-divider.uk-grid-column-small.uk-grid-stack > .uk-grid-margin::before {
  left: 30px; }

/*
 * Medium
 */
/* Horizontal */
.uk-grid-divider.uk-grid-medium,
.uk-grid-divider.uk-grid-column-medium {
  margin-left: -60px; }

.uk-grid-divider.uk-grid-medium > *,
.uk-grid-divider.uk-grid-column-medium > * {
  padding-left: 60px; }

.uk-grid-divider.uk-grid-medium > :not(.uk-first-column)::before,
.uk-grid-divider.uk-grid-column-medium > :not(.uk-first-column)::before {
  left: 30px; }

/* Vertical */
.uk-grid-divider.uk-grid-medium.uk-grid-stack > .uk-grid-margin,
.uk-grid-divider.uk-grid-row-medium.uk-grid-stack > .uk-grid-margin {
  margin-top: 60px; }

.uk-grid-divider.uk-grid-medium.uk-grid-stack > .uk-grid-margin::before {
  top: -30px;
  left: 60px; }

.uk-grid-divider.uk-grid-row-medium.uk-grid-stack > .uk-grid-margin::before {
  top: -30px; }

.uk-grid-divider.uk-grid-column-medium.uk-grid-stack > .uk-grid-margin::before {
  left: 60px; }

/*
 * Large
 */
/* Horizontal */
.uk-grid-divider.uk-grid-large,
.uk-grid-divider.uk-grid-column-large {
  margin-left: -80px; }

.uk-grid-divider.uk-grid-large > *,
.uk-grid-divider.uk-grid-column-large > * {
  padding-left: 80px; }

.uk-grid-divider.uk-grid-large > :not(.uk-first-column)::before,
.uk-grid-divider.uk-grid-column-large > :not(.uk-first-column)::before {
  left: 40px; }

/* Vertical */
.uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin,
.uk-grid-divider.uk-grid-row-large.uk-grid-stack > .uk-grid-margin {
  margin-top: 80px; }

.uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin::before {
  top: -40px;
  left: 80px; }

.uk-grid-divider.uk-grid-row-large.uk-grid-stack > .uk-grid-margin::before {
  top: -40px; }

.uk-grid-divider.uk-grid-column-large.uk-grid-stack > .uk-grid-margin::before {
  left: 80px; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Horizontal */
  .uk-grid-divider.uk-grid-large,
  .uk-grid-divider.uk-grid-column-large {
    margin-left: -140px; }
  .uk-grid-divider.uk-grid-large > *,
  .uk-grid-divider.uk-grid-column-large > * {
    padding-left: 140px; }
  .uk-grid-divider.uk-grid-large > :not(.uk-first-column)::before,
  .uk-grid-divider.uk-grid-column-large > :not(.uk-first-column)::before {
    left: 70px; }
  /* Vertical */
  .uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin,
  .uk-grid-divider.uk-grid-row-large.uk-grid-stack > .uk-grid-margin {
    margin-top: 140px; }
  .uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin::before {
    top: -70px;
    left: 140px; }
  .uk-grid-divider.uk-grid-row-large.uk-grid-stack > .uk-grid-margin::before {
    top: -70px; }
  .uk-grid-divider.uk-grid-column-large.uk-grid-stack > .uk-grid-margin::before {
    left: 140px; } }

/* Match child of a grid cell
 ========================================================================== */
/*
 * Behave like a block element
 * 1. Wrap into the next line
 * 2. Take the full width, at least 100%. Only if no class from the Width component is set.
 * 3. Expand width even if larger than 100%, e.g. because of negative margin (Needed for nested grids)
 */
.uk-grid-match > *,
.uk-grid-item-match {
  display: flex;
  /* 1 */
  flex-wrap: wrap; }

.uk-grid-match > * > :not([class*='uk-width']),
.uk-grid-item-match > :not([class*='uk-width']) {
  /* 2 */
  box-sizing: border-box;
  width: 100%;
  /* 3 */
  flex: auto; }

/* ========================================================================
   Component: Close
 ========================================================================== */
/*
 * Adopts `uk-icon`
 */
.uk-close {
  color: #999; }

/* Hover */
.uk-close:hover {
  color: #666; }

/* ========================================================================
   Component: Totop
 ========================================================================== */
/*
 * Addopts `uk-icon`
 */
.uk-totop {
  padding: 5px;
  color: #999; }

/* Hover */
.uk-totop:hover {
  color: #666; }

/* OnClick */
.uk-totop:active {
  color: #333; }

/* ========================================================================
   Component: Alert
 ========================================================================== */
.uk-alert {
  position: relative;
  margin-bottom: 20px;
  padding: 15px 29px 15px 15px;
  background: #f8f8f8;
  color: #666; }

/* Add margin if adjacent element */
* + .uk-alert {
  margin-top: 20px; }

/*
 * Remove margin from the last-child
 */
.uk-alert > :last-child {
  margin-bottom: 0; }

/* Close
 * Adopts `uk-close`
 ========================================================================== */
.uk-alert-close {
  position: absolute;
  top: 20px;
  right: 15px; }

/*
 * Remove margin from adjacent element
 */
.uk-alert-close:first-child + * {
  margin-top: 0; }

/*
 * Hover
 */
/* Style modifiers
 ========================================================================== */
/*
 * Primary
 */
.uk-alert-primary {
  background: #d8eafc;
  color: #1e87f0; }

/*
 * Success
 */
.uk-alert-success {
  background: #edfbf6;
  color: #32d296; }

/*
 * Warning
 */
.uk-alert-warning {
  background: #fef5ee;
  color: #faa05a; }

/*
 * Danger
 */
.uk-alert-danger {
  background: #fef4f6;
  color: #f0506e; }

/* ========================================================================
   Component: Badge
 ========================================================================== */
/*
 * 1. Style
 * 2. Center child vertically and horizontally
 */
.uk-badge {
  box-sizing: border-box;
  min-width: 18px;
  height: 18px;
  padding: 0 5px;
  border-radius: 2px 5px 2px 5px;
  vertical-align: middle;
  /* 1 */
  background: #1e87f0;
  color: #fff !important;
  font-size: 0.75em;
  /* 2 */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 0; }

/*
 * Required for `a`
 */
.uk-badge:hover {
  text-decoration: none; }

/* ========================================================================
   Component: Overlay
 ========================================================================== */
.uk-overlay {
  padding: 30px 30px; }

/*
 * Remove margin from the last-child
 */
.uk-overlay > :last-child {
  margin-bottom: 0; }

/* Icon
 ========================================================================== */
/* Style modifiers
 ========================================================================== */
/*
 * Default
 */
.uk-overlay-default {
  background: rgba(255, 255, 255, 0.8); }

/*
 * Primary
 */
.uk-overlay-primary {
  background: rgba(73, 80, 87, 0.6); }

/* ========================================================================
   Component: Tab
 ========================================================================== */
/*
 * 1. Allow items to wrap into the next line
 * 2. Gutter
 * 3. Reset list
 */
.uk-tab {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin-left: -20px;
  /* 3 */
  padding: 0;
  list-style: none; }

/*
 * 1. Space is allocated solely based on content dimensions: 0 0 auto
 * 2. Gutter
 * 3. Create position context for dropdowns
 */
.uk-tab > * {
  /* 1 */
  flex: none;
  /* 2 */
  padding-left: 20px;
  /* 3 */
  position: relative; }

/* Items
 ========================================================================== */
/*
 * Items must target `a` elements to exclude other elements (e.g. dropdowns)
 * 1. Center content vertically, e.g. an icon
 * 2. Imitate white space gap when using flexbox
 * 3. Center content if a width is set
 * 4. Style
 */
.uk-tab > * > a {
  /* 1 */
  display: flex;
  align-items: center;
  /* 2 */
  column-gap: 0.25em;
  /* 3 */
  justify-content: center;
  /* 4 */
  padding: 5px 10px;
  color: #999; }

/* Hover */
.uk-tab > * > a:hover {
  color: #666;
  text-decoration: none; }

/* Active */
.uk-tab > .uk-active > a {
  color: #333; }

/* Disabled */
.uk-tab > .uk-disabled > a {
  color: #999; }

/* Position modifier
 ========================================================================== */
/*
 * Bottom
 */
/*
 * Left + Right
 * 1. Reset Gutter
 */
.uk-tab-left,
.uk-tab-right {
  flex-direction: column;
  /* 1 */
  margin-left: 0; }

/* 1 */
.uk-tab-left > *,
.uk-tab-right > * {
  padding-left: 0; }

.uk-tab-left > * > a {
  justify-content: left; }

.uk-tab-right > * > a {
  justify-content: left; }

/* ========================================================================
   Component: Slidenav
 ========================================================================== */
/*
 * Adopts `uk-icon`
 */
.uk-slidenav {
  padding: 5px 10px;
  color: rgba(102, 102, 102, 0.5); }

/* Hover */
.uk-slidenav:hover {
  color: rgba(102, 102, 102, 0.9); }

/* OnClick */
.uk-slidenav:active {
  color: rgba(102, 102, 102, 0.5); }

/* Icon modifier
 ========================================================================== */
/*
 * Previous
 */
/*
 * Next
 */
/* Size modifier
 ========================================================================== */
.uk-slidenav-large {
  padding: 10px 10px; }

/* Container
 ========================================================================== */
.uk-slidenav-container {
  display: flex; }

/* ========================================================================
   Component: Dotnav
 ========================================================================== */
/*
 * 1. Allow items to wrap into the next line
 * 2. Reset list
 * 3. Gutter
 */
.uk-dotnav {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin: 0;
  padding: 0;
  list-style: none;
  /* 3 */
  margin-left: -12px; }

/*
 * 1. Space is allocated solely based on content dimensions: 0 0 auto
 * 2. Gutter
 */
.uk-dotnav > * {
  /* 1 */
  flex: none;
  /* 2 */
  padding-left: 12px; }

/* Items
 ========================================================================== */
/*
 * Items
 * 1. Hide text if present
 */
.uk-dotnav > * > * {
  display: block;
  box-sizing: border-box;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: rgba(102, 102, 102, 0.2);
  /* 1 */
  text-indent: 100%;
  overflow: hidden;
  white-space: nowrap; }

/* Hover */
.uk-dotnav > * > :hover {
  background-color: rgba(102, 102, 102, 0.6); }

/* OnClick */
.uk-dotnav > * > :active {
  background-color: rgba(102, 102, 102, 0.2); }

/* Active */
.uk-dotnav > .uk-active > * {
  background-color: rgba(102, 102, 102, 0.6); }

/* Modifier: 'uk-dotnav-vertical'
 ========================================================================== */
/*
 * 1. Change direction
 * 2. Gutter
 */
.uk-dotnav-vertical {
  /* 1 */
  flex-direction: column;
  /* 2 */
  margin-left: 0;
  margin-top: -12px; }

/* 2 */
.uk-dotnav-vertical > * {
  padding-left: 0;
  padding-top: 12px; }

/* ========================================================================
   Component: Accordion
 ========================================================================== */
.uk-accordion {
  padding: 0;
  list-style: none; }

/* Item
 ========================================================================== */
.uk-accordion > :nth-child(n+2) {
  margin-top: 20px; }

/* Title
 ========================================================================== */
.uk-accordion-title {
  display: block;
  font-size: 1.25rem;
  line-height: 1.4;
  color: #333; }

/* Hover */
.uk-accordion-title:hover {
  color: #666;
  text-decoration: none; }

/* Content
 ========================================================================== */
.uk-accordion-content {
  display: flow-root;
  margin-top: 20px; }

/*
 * Remove margin from the last-child
 */
.uk-accordion-content > :last-child {
  margin-bottom: 0; }

/* ========================================================================
   Component: Dropdown
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Set a default width
 * 4. Style
 */
.uk-dropdown {
  /* 1 */
  display: none;
  /* 2 */
  position: absolute;
  z-index: 1020;
  /* 3 */
  box-sizing: border-box;
  min-width: 100%;
  /* 4 */
  padding: 15px;
  background: #f8f8f8;
  color: #32B4B4; }

/* Show */
.uk-dropdown.uk-open {
  display: block; }

/* Nav
 * Adopts `uk-nav`
 ========================================================================== */
.uk-dropdown-nav {
  white-space: nowrap; }

/*
 * Items
 */
.uk-dropdown-nav > li > a {
  color: #999; }

/* Hover + Active */
.uk-dropdown-nav > li > a:hover,
.uk-dropdown-nav > li.uk-active > a {
  color: #666; }

/*
 * Header
 */
.uk-dropdown-nav .uk-nav-header {
  color: #333; }

/*
 * Divider
 */
.uk-dropdown-nav .uk-nav-divider {
  border-top: 1px solid #e5e5e5; }

/*
 * Sublists
 */
.uk-dropdown-nav .uk-nav-sub a {
  color: #999; }

.uk-dropdown-nav .uk-nav-sub a:hover,
.uk-dropdown-nav .uk-nav-sub li.uk-active > a {
  color: #666; }

/* Direction / Alignment modifiers
 ========================================================================== */
/* Direction */
[class*='uk-dropdown-top'] {
  margin-top: -10px; }

[class*='uk-dropdown-bottom'] {
  margin-top: 10px; }

[class*='uk-dropdown-left'] {
  margin-left: -10px; }

[class*='uk-dropdown-right'] {
  margin-left: 10px; }

/* Grid modifiers
 ========================================================================== */
.uk-dropdown-stack .uk-dropdown-grid > * {
  width: 100% !important; }

/* ========================================================================
   Component: Modal
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Allow scrolling for the modal dialog
 * 4. Horizontal padding
 * 5. Mask the background page
 * 6. Fade-in transition
 */
.uk-modal {
  /* 1 */
  display: none;
  /* 2 */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  /* 3 */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  /* 4 */
  padding: 15px 15px;
  /* 5 */
  background: rgba(0, 0, 0, 0.6);
  /* 6 */
  opacity: 0;
  transition: opacity 0.15s linear; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-modal {
    padding: 50px 30px; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-modal {
    padding-left: 40px;
    padding-right: 40px; } }

/*
 * Open
 */
.uk-modal.uk-open {
  opacity: 1; }

/* Page
 ========================================================================== */
/*
 * Prevent scrollbars
 */
.uk-modal-page {
  overflow: hidden; }

/* Dialog
 ========================================================================== */
/*
 * 1. Create position context for spinner and close button
 * 2. Dimensions
 * 3. Fix `max-width: 100%` not working in combination with flex and responsive images in IE11
 *    `!important` needed to overwrite `uk-width-auto`. See `#modal-media-image` in tests
 * 4. Style
 * 5. Slide-in transition
 */
.uk-modal-dialog {
  /* 1 */
  position: relative;
  /* 2 */
  box-sizing: border-box;
  margin: 0 auto;
  width: 600px;
  /* 3 */
  max-width: calc(100% - 0.01px) !important;
  /* 4 */
  background: #fff;
  /* 5 */
  opacity: 0;
  transform: translateY(-100px);
  transition: 0.3s linear;
  transition-property: opacity, transform; }

/*
 * Open
 */
.uk-open > .uk-modal-dialog {
  opacity: 1;
  transform: translateY(0); }

/* Size modifier
 ========================================================================== */
/*
 * Container size
 * Take the same size as the Container component
 */
.uk-modal-container .uk-modal-dialog {
  width: 1200px; }

/*
 * Full size
 * 1. Remove padding and background from modal
 * 2. Reset all default declarations from modal dialog
 */
/* 1 */
.uk-modal-full {
  padding: 0;
  background: none; }

/* 2 */
.uk-modal-full .uk-modal-dialog {
  margin: 0;
  width: 100%;
  max-width: 100%;
  transform: translateY(0); }

/* Sections
 ========================================================================== */
.uk-modal-body {
  display: flow-root;
  padding: 30px 30px; }

.uk-modal-header {
  display: flow-root;
  padding: 15px 30px;
  background: #f8f8f8; }

.uk-modal-footer {
  display: flow-root;
  padding: 15px 30px;
  background: #f8f8f8; }

/*
 * Remove margin from the last-child
 */
.uk-modal-body > :last-child,
.uk-modal-header > :last-child,
.uk-modal-footer > :last-child {
  margin-bottom: 0; }

/* Title
 ========================================================================== */
.uk-modal-title {
  font-size: 2rem;
  line-height: 1.5; }

/* Close
 * Adopts `uk-close`
 ========================================================================== */
[class*='uk-modal-close-'] {
  position: absolute;
  z-index: 1010;
  top: 10px;
  right: 10px;
  padding: 5px; }

/*
 * Remove margin from adjacent element
 */
[class*='uk-modal-close-']:first-child + * {
  margin-top: 0; }

/*
 * Hover
 */
/*
 * Default
 */
/*
 * Outside
 * 1. Prevent scrollbar on small devices
 */
.uk-modal-close-outside {
  top: 0;
  /* 1 */
  right: -5px;
  transform: translate(0, -100%);
  color: white; }

.uk-modal-close-outside:hover {
  color: #fff; }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  /* 1 */
  .uk-modal-close-outside {
    right: 0;
    transform: translate(100%, -100%); } }

/*
 * Full
 */
/* ========================================================================
   Component: Lightbox
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Allow scrolling for the modal dialog
 * 4. Horizontal padding
 * 5. Mask the background page
 * 6. Fade-in transition
 * 7. Prevent cancellation of pointer events while dragging
 */
.uk-lightbox {
  /* 1 */
  display: none;
  /* 2 */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  /* 5 */
  background: #000;
  /* 6 */
  opacity: 0;
  transition: opacity 0.15s linear;
  /* 7 */
  touch-action: pinch-zoom; }

/*
 * Open
 * 1. Center child
 * 2. Fade-in
 */
.uk-lightbox.uk-open {
  display: block;
  /* 2 */
  opacity: 1; }

/*
 * Focus
 */
.uk-lightbox :focus {
  outline-color: rgba(255, 255, 255, 0.7); }

.uk-lightbox :focus-visible {
  outline-color: rgba(255, 255, 255, 0.7); }

/* Page
 ========================================================================== */
/*
 * Prevent scrollbars
 */
.uk-lightbox-page {
  overflow: hidden; }

/* Item
 ========================================================================== */
/*
 * 1. Center child within the viewport
 * 2. Not visible by default
 * 3. Color needed for spinner icon
 * 4. Optimize animation
 * 5. Responsiveness
 *    Using `vh` for `max-height` to fix image proportions after resize in Safari and Opera
 *    Using `vh` and `vw` to make responsive image work in IE11
 */
.uk-lightbox-items > * {
  /* 1 */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* 2 */
  display: none;
  justify-content: center;
  align-items: center;
  /* 3 */
  color: rgba(255, 255, 255, 0.7);
  /* 4 */
  will-change: transform, opacity; }

/* 5 */
.uk-lightbox-items > * > * {
  max-width: 100vw;
  max-height: 100vh; }

.uk-lightbox-items > * > :not(iframe) {
  width: auto;
  height: auto; }

.uk-lightbox-items > .uk-active {
  display: flex; }

/* Toolbar
 ========================================================================== */
.uk-lightbox-toolbar {
  padding: 10px 10px;
  background: rgba(0, 0, 0, 0.3);
  color: rgba(255, 255, 255, 0.7); }

.uk-lightbox-toolbar > * {
  color: rgba(255, 255, 255, 0.7); }

/* Toolbar Icon (Close)
 ========================================================================== */
.uk-lightbox-toolbar-icon {
  padding: 5px;
  color: rgba(255, 255, 255, 0.7); }

/*
 * Hover
 */
.uk-lightbox-toolbar-icon:hover {
  color: #fff; }

/* Button (Slidenav)
 ========================================================================== */
/*
 * 1. Center icon vertically and horizontally
 */
.uk-lightbox-button {
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.3);
  color: rgba(255, 255, 255, 0.7);
  /* 1 */
  display: inline-flex;
  justify-content: center;
  align-items: center; }

/* Hover */
.uk-lightbox-button:hover {
  color: #fff; }

/* OnClick */
/* Caption
 ========================================================================== */
.uk-lightbox-caption:empty {
  display: none; }

/* Iframe
 ========================================================================== */
.uk-lightbox-iframe {
  width: 80%;
  height: 80%; }

/* ========================================================================
   Component: Slideshow
 ========================================================================== */
/*
 * 1. Prevent tab highlighting on iOS.
 */
.uk-slideshow {
  /* 1 */
  -webkit-tap-highlight-color: transparent; }

/* Items
 ========================================================================== */
/*
 * 1. Create position and stacking context
 * 2. Reset list
 * 3. Clip child elements
 * 4. Prevent displaying the callout information on iOS.
 */
.uk-slideshow-items {
  /* 1 */
  position: relative;
  z-index: 0;
  /* 2 */
  margin: 0;
  padding: 0;
  list-style: none;
  /* 3 */
  overflow: hidden;
  /* 4 */
  -webkit-touch-callout: none; }

/* Item
 ========================================================================== */
/*
 * 1. Position items above each other
 * 2. Take the full width
 * 3. Clip child elements, e.g. for `uk-cover`
 * 4. Optimize animation
 * 5. Disable horizontal panning gestures in IE11 and Edge
 */
.uk-slideshow-items > * {
  /* 1 */
  position: absolute;
  top: 0;
  left: 0;
  /* 2 */
  right: 0;
  bottom: 0;
  /* 3 */
  overflow: hidden;
  /* 4 */
  will-change: transform, opacity;
  /* 5 */
  touch-action: pan-y; }

/*
 * Hide not active items
 */
.uk-slideshow-items > :not(.uk-active) {
  display: none; }

/* ========================================================================
   Component: Text
 ========================================================================== */
/* Style modifiers
 ========================================================================== */
.uk-text-lead {
  font-size: 1.5rem;
  line-height: 1.5;
  color: #333; }

.uk-text-meta {
  font-size: 0.875rem;
  line-height: 1.4;
  color: #999; }

/* Size modifiers
 ========================================================================== */
.uk-text-small {
  font-size: 0.875rem;
  line-height: 1.5; }

.uk-text-large {
  font-size: 1.5rem;
  line-height: 1.5; }

.uk-text-default {
  font-size: 16px;
  line-height: 1.5; }

/* Weight modifier
 ========================================================================== */
.uk-text-light {
  font-weight: 300; }

.uk-text-normal {
  font-weight: 400; }

.uk-text-bold {
  font-weight: 700; }

.uk-text-lighter {
  font-weight: lighter; }

.uk-text-bolder {
  font-weight: bolder; }

/* Style modifier
 ========================================================================== */
.uk-text-italic {
  font-style: italic; }

/* Transform modifier
 ========================================================================== */
.uk-text-capitalize {
  text-transform: capitalize !important; }

.uk-text-uppercase {
  text-transform: uppercase !important; }

.uk-text-lowercase {
  text-transform: lowercase !important; }

/* Decoration modifier
 ========================================================================== */
.uk-text-decoration-none {
  text-decoration: none !important; }

/* Color modifiers
 ========================================================================== */
.uk-text-muted {
  color: #999 !important; }

.uk-text-emphasis {
  color: #333 !important; }

.uk-text-primary {
  color: #1e87f0 !important; }

.uk-text-secondary {
  color: #222 !important; }

.uk-text-success {
  color: #32d296 !important; }

.uk-text-warning {
  color: #faa05a !important; }

.uk-text-danger {
  color: #f0506e !important; }

/* Background modifier
 ========================================================================== */
/*
 * 1. The background clips to the foreground text. Works in Chrome, Firefox, Safari, Edge and Opera
 *    Default color is set to transparent
 * 2. Container fits the text
 * 3. Fallback color for IE11
 */
.uk-text-background {
  /* 1 */
  -webkit-background-clip: text;
  /* 2 */
  display: inline-block;
  /* 3 */
  color: #1e87f0 !important; }

@supports (-webkit-background-clip: text) {
  .uk-text-background {
    background-color: #1e87f0;
    color: transparent !important; } }

/* Alignment modifiers
 ========================================================================== */
.uk-text-left {
  text-align: left !important; }

.uk-text-right {
  text-align: right !important; }

.uk-text-center {
  text-align: center !important; }

.uk-text-justify {
  text-align: justify !important; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-text-left\@s {
    text-align: left !important; }
  .uk-text-right\@s {
    text-align: right !important; }
  .uk-text-center\@s {
    text-align: center !important; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-text-left\@m {
    text-align: left !important; }
  .uk-text-right\@m {
    text-align: right !important; }
  .uk-text-center\@m {
    text-align: center !important; } }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-text-left\@l {
    text-align: left !important; }
  .uk-text-right\@l {
    text-align: right !important; }
  .uk-text-center\@l {
    text-align: center !important; } }

/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-text-left\@xl {
    text-align: left !important; }
  .uk-text-right\@xl {
    text-align: right !important; }
  .uk-text-center\@xl {
    text-align: center !important; } }

/*
 * Vertical
 */
.uk-text-top {
  vertical-align: top !important; }

.uk-text-middle {
  vertical-align: middle !important; }

.uk-text-bottom {
  vertical-align: bottom !important; }

.uk-text-baseline {
  vertical-align: baseline !important; }

/* Wrap modifiers
 ========================================================================== */
/*
 * Prevent text from wrapping onto multiple lines
 */
.uk-text-nowrap {
  white-space: nowrap; }

/*
 * 1. Make sure a max-width is set after which truncation can occur
 * 2. Prevent text from wrapping onto multiple lines, and truncate with an ellipsis
 * 3. Fix for table cells
 */
.uk-text-truncate {
  /* 1 */
  max-width: 100%;
  /* 2 */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

/* 2 */
th.uk-text-truncate,
td.uk-text-truncate {
  max-width: 0; }

/*
 * 1. Wrap long words onto the next line and break them if they are too long to fit
 * 2. Legacy `word-wrap` as fallback for `overflow-wrap`
 * 3. Fix `overflow-wrap` which doesn't work with table cells in Chrome, Opera, IE11 and Edge
 *    Must use `break-all` to support IE11 and Edge
 * Note: Not using `hyphens: auto;` because it hyphenates text even if not needed
 */
.uk-text-break {
  /* 1 */
  overflow-wrap: break-word;
  /* 2 */
  word-wrap: break-word; }

/* 3 */
th.uk-text-break,
td.uk-text-break {
  word-break: break-all; }

/* ========================================================================
   Component: Slider
 ========================================================================== */
/*
 * 1. Prevent tab highlighting on iOS.
 */
.uk-slider {
  /* 1 */
  -webkit-tap-highlight-color: transparent; }

/* Container
 ========================================================================== */
/*
 * Clip child elements
 */
.uk-slider-container {
  overflow: hidden; }

/*
 * Widen container to prevent box-shadows from clipping, `large-box-shadow`
 */
.uk-slider-container-offset {
  margin: -11px -25px -39px -25px;
  padding: 11px 25px 39px 25px; }

/* Items
 ========================================================================== */
/*
 * 1. Optimize animation
 * 2. Create a containing block. In Safari it's neither created by `transform` nor `will-change`.
 */
.uk-slider-items {
  /* 1 */
  will-change: transform;
  /* 2 */
  position: relative; }

/*
 * 1. Reset list style without interfering with grid
 * 2. Prevent displaying the callout information on iOS.
 */
.uk-slider-items:not(.uk-grid) {
  display: flex;
  /* 1 */
  margin: 0;
  padding: 0;
  list-style: none;
  /* 2 */
  -webkit-touch-callout: none; }

.uk-slider-items.uk-grid {
  flex-wrap: nowrap; }

/* Item
 ========================================================================== */
/*
 * 1. Let items take content dimensions (0 0 auto)
 *    `max-width` needed to keep image responsiveness and prevent content overflow
 * 3. Create position context
 * 4. Disable horizontal panning gestures in IE11 and Edge
 */
.uk-slider-items > * {
  /* 1 */
  flex: none;
  max-width: 100%;
  /* 3 */
  position: relative;
  /* 4 */
  touch-action: pan-y; }

/* ========================================================================
   Component: Sticky
 ========================================================================== */
/*
 * 1. Force new layer to resolve frame rate issues on devices with lower frame rates
 */
.uk-sticky-fixed {
  z-index: 980;
  box-sizing: border-box;
  margin: 0 !important;
  /* 1 */
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

/*
 * Faster animations
 */
.uk-sticky[class*='uk-animation-'] {
  animation-duration: 0.2s; }

.uk-sticky.uk-animation-reverse {
  animation-duration: 0.2s; }

/* ========================================================================
   Component: Off-canvas
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 */
.uk-offcanvas {
  /* 1 */
  display: none;
  /* 2 */
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1000; }

/*
 * Flip modifier
 */
.uk-offcanvas-flip .uk-offcanvas {
  right: 0;
  left: auto; }

/* Bar
 ========================================================================== */
/*
 * 1. Set position
 * 2. Size and style
 * 3. Allow scrolling
 */
.uk-offcanvas-bar {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: -270px;
  /* 2 */
  box-sizing: border-box;
  width: 270px;
  padding: 20px 20px;
  background: #222;
  /* 3 */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-offcanvas-bar {
    left: -350px;
    width: 350px;
    padding: 40px 40px; } }

/* Flip modifier */
.uk-offcanvas-flip .uk-offcanvas-bar {
  left: auto;
  right: -270px; }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-offcanvas-flip .uk-offcanvas-bar {
    right: -350px; } }

/*
 * Open
 */
.uk-open > .uk-offcanvas-bar {
  left: 0; }

.uk-offcanvas-flip .uk-open > .uk-offcanvas-bar {
  left: auto;
  right: 0; }

/*
 * Slide Animation (Used in slide and push mode)
 */
.uk-offcanvas-bar-animation {
  transition: left 0.3s ease-out; }

.uk-offcanvas-flip .uk-offcanvas-bar-animation {
  transition-property: right; }

/*
 * Reveal Animation
 * 1. Set position
 * 2. Clip the bar
 * 3. Animation
 * 4. Reset position
 */
.uk-offcanvas-reveal {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  /* 2 */
  width: 0;
  overflow: hidden;
  /* 3 */
  transition: width 0.3s ease-out; }

.uk-offcanvas-reveal .uk-offcanvas-bar {
  /* 4 */
  left: 0; }

.uk-offcanvas-flip .uk-offcanvas-reveal .uk-offcanvas-bar {
  /* 4 */
  left: auto;
  right: 0; }

.uk-open > .uk-offcanvas-reveal {
  width: 270px; }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-open > .uk-offcanvas-reveal {
    width: 350px; } }

/*
 * Flip modifier
 */
.uk-offcanvas-flip .uk-offcanvas-reveal {
  right: 0;
  left: auto; }

/* Close
 * Adopts `uk-close`
 ========================================================================== */
.uk-offcanvas-close {
  position: absolute;
  z-index: 1000;
  top: 20px;
  right: 20px;
  padding: 5px; }

/* Overlay
 ========================================================================== */
/*
 * Overlay the whole page. Needed for the `::before`
 * 1. Using `100vw` so no modification is needed when off-canvas is flipped
 * 2. Allow for closing with swipe gesture on devices with pointer events.
 */
.uk-offcanvas-overlay {
  /* 1 */
  width: 100vw;
  /* 2 */
  touch-action: none; }

/*
 * 1. Mask the whole page
 * 2. Fade-in transition
 */
.uk-offcanvas-overlay::before {
  /* 1 */
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.1);
  /* 2 */
  opacity: 0;
  transition: opacity 0.15s linear; }

.uk-offcanvas-overlay.uk-open::before {
  opacity: 1; }

/* Prevent scrolling
 ========================================================================== */
/*
 * Prevent horizontal scrollbar when the content is slide-out
 * Has to be on the `html` element too to make it work on the `body`
 */
.uk-offcanvas-page,
.uk-offcanvas-container {
  overflow-x: hidden; }

/* Container
 ========================================================================== */
/*
 * Prepare slide-out animation (Used in reveal and push mode)
 * Using `position: left` instead of `transform` because position `fixed` elements like sticky navbars
 * lose their fixed state and behaves like `absolute` within a transformed container
 * 1. Provide a fixed width and prevent shrinking
 */
.uk-offcanvas-container {
  position: relative;
  left: 0;
  transition: left 0.3s ease-out;
  /* 1 */
  box-sizing: border-box;
  width: 100%; }

/*
 * Activate slide-out animation
 */
:not(.uk-offcanvas-flip).uk-offcanvas-container-animation {
  left: 270px; }

.uk-offcanvas-flip.uk-offcanvas-container-animation {
  left: -270px; }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  :not(.uk-offcanvas-flip).uk-offcanvas-container-animation {
    left: 350px; }
  .uk-offcanvas-flip.uk-offcanvas-container-animation {
    left: -350px; } }

/* ========================================================================
   Component: Switcher
 ========================================================================== */
/*
 * Reset list
 */
.uk-switcher {
  margin: 0;
  padding: 0;
  list-style: none; }

/* Items
 ========================================================================== */
/*
 * Hide not active items
 */
.uk-switcher > :not(.uk-active) {
  display: none; }

/*
 * Remove margin from the last-child
 */
.uk-switcher > * > :last-child {
  margin-bottom: 0; }

/* ========================================================================
   Component: Column
 ========================================================================== */
[class*='uk-column-'] {
  column-gap: 30px; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  [class*='uk-column-'] {
    column-gap: 40px; } }

/*
 * Fix image 1px line wrapping into the next column in Chrome
 */
[class*='uk-column-'] img {
  transform: translate3d(0, 0, 0); }

/* Divider
 ========================================================================== */
/*
 * 1. Double the column gap
 */
.uk-column-divider {
  column-rule: 1px solid #e5e5e5;
  /* 1 */
  column-gap: 60px; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-column-divider {
    column-gap: 80px; } }

/* Width modifiers
 ========================================================================== */
.uk-column-1-2 {
  column-count: 2; }

.uk-column-1-3 {
  column-count: 3; }

.uk-column-1-4 {
  column-count: 4; }

.uk-column-1-5 {
  column-count: 5; }

.uk-column-1-6 {
  column-count: 6; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-column-1-2\@s {
    column-count: 2; }
  .uk-column-1-3\@s {
    column-count: 3; }
  .uk-column-1-4\@s {
    column-count: 4; }
  .uk-column-1-5\@s {
    column-count: 5; }
  .uk-column-1-6\@s {
    column-count: 6; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-column-1-2\@m {
    column-count: 2; }
  .uk-column-1-3\@m {
    column-count: 3; }
  .uk-column-1-4\@m {
    column-count: 4; }
  .uk-column-1-5\@m {
    column-count: 5; }
  .uk-column-1-6\@m {
    column-count: 6; } }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-column-1-2\@l {
    column-count: 2; }
  .uk-column-1-3\@l {
    column-count: 3; }
  .uk-column-1-4\@l {
    column-count: 4; }
  .uk-column-1-5\@l {
    column-count: 5; }
  .uk-column-1-6\@l {
    column-count: 6; } }

/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-column-1-2\@xl {
    column-count: 2; }
  .uk-column-1-3\@xl {
    column-count: 3; }
  .uk-column-1-4\@xl {
    column-count: 4; }
  .uk-column-1-5\@xl {
    column-count: 5; }
  .uk-column-1-6\@xl {
    column-count: 6; } }

/* Make element span across all columns
 * Does not work in Firefox yet
 ========================================================================== */
.uk-column-span {
  column-span: all; }

/* ========================================================================
   Component: Notification
 ========================================================================== */
/*
 * 1. Set position
 * 2. Dimensions
 */
.uk-notification {
  /* 1 */
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1040;
  /* 2 */
  box-sizing: border-box;
  width: 350px; }

/* Position modifiers
========================================================================== */
.uk-notification-top-right,
.uk-notification-bottom-right {
  left: auto;
  right: 10px; }

.uk-notification-top-center,
.uk-notification-bottom-center {
  left: 50%;
  margin-left: -175px; }

.uk-notification-bottom-left,
.uk-notification-bottom-right,
.uk-notification-bottom-center {
  top: auto;
  bottom: 10px; }

/* Responsiveness
========================================================================== */
/* Phones portrait and smaller */
@media (max-width: 639px) {
  .uk-notification {
    left: 10px;
    right: 10px;
    width: auto;
    margin: 0; } }

/* Message
========================================================================== */
.uk-notification-message {
  position: relative;
  padding: 15px;
  background: #f8f8f8;
  color: #666;
  font-size: 1.25rem;
  line-height: 1.4;
  cursor: pointer; }

* + .uk-notification-message {
  margin-top: 10px; }

/* Close
 * Adopts `uk-close`
 ========================================================================== */
.uk-notification-close {
  display: none;
  position: absolute;
  top: 20px;
  right: 15px; }

.uk-notification-message:hover .uk-notification-close {
  display: block; }

/* Style modifiers
 ========================================================================== */
/*
 * Primary
 */
.uk-notification-message-primary {
  color: #1e87f0; }

/*
 * Success
 */
.uk-notification-message-success {
  color: #32d296; }

/*
 * Warning
 */
.uk-notification-message-warning {
  color: #faa05a; }

/*
 * Danger
 */
.uk-notification-message-danger {
  color: #f0506e; }

/* ========================================================================
   Component: Tooltip
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Position
 * 3. Remove tooltip from document flow to keep the UIkit container from changing its size when injected into the document initially
 * 4. Dimensions
 * 5. Style
 */
.uk-tooltip {
  /* 1 */
  display: none;
  /* 2 */
  position: absolute;
  z-index: 1030;
  /* 3 */
  top: 0;
  /* 4 */
  box-sizing: border-box;
  max-width: 200px;
  padding: 3px 6px;
  /* 5 */
  background: #666;
  border-radius: 2px 5px 2px 5px;
  color: #fff;
  font-size: 0.875rem; }

/* Show */
.uk-tooltip.uk-active {
  display: block; }

/* Direction / Alignment modifiers
 ========================================================================== */
/* Direction */
[class*='uk-tooltip-top'] {
  margin-top: 0; }

[class*='uk-tooltip-bottom'] {
  margin-top: 0; }

[class*='uk-tooltip-left'] {
  margin-left: 0; }

[class*='uk-tooltip-right'] {
  margin-left: 0; }

/* ========================================================================
   Component: Animation
 ========================================================================== */
[class*='uk-animation-'] {
  animation: 0.5s ease-out both; }

/* Animations
 ========================================================================== */
/*
 * Fade
 */
.uk-animation-fade {
  animation-name: uk-fade;
  animation-duration: 0.8s;
  animation-timing-function: linear; }

/*
 * Scale
 */
.uk-animation-scale-up {
  animation-name: uk-fade, uk-scale-up; }

.uk-animation-scale-down {
  animation-name: uk-fade, uk-scale-down; }

/*
 * Slide
 */
.uk-animation-slide-top {
  animation-name: uk-fade, uk-slide-top; }

.uk-animation-slide-bottom {
  animation-name: uk-fade, uk-slide-bottom; }

.uk-animation-slide-left {
  animation-name: uk-fade, uk-slide-left; }

.uk-animation-slide-right {
  animation-name: uk-fade, uk-slide-right; }

/*
 * Slide Small
 */
.uk-animation-slide-top-small {
  animation-name: uk-fade, uk-slide-top-small; }

.uk-animation-slide-bottom-small {
  animation-name: uk-fade, uk-slide-bottom-small; }

.uk-animation-slide-left-small {
  animation-name: uk-fade, uk-slide-left-small; }

.uk-animation-slide-right-small {
  animation-name: uk-fade, uk-slide-right-small; }

/*
 * Slide Medium
 */
.uk-animation-slide-top-medium {
  animation-name: uk-fade, uk-slide-top-medium; }

.uk-animation-slide-bottom-medium {
  animation-name: uk-fade, uk-slide-bottom-medium; }

.uk-animation-slide-left-medium {
  animation-name: uk-fade, uk-slide-left-medium; }

.uk-animation-slide-right-medium {
  animation-name: uk-fade, uk-slide-right-medium; }

/*
 * Kenburns
 */
.uk-animation-kenburns {
  animation-name: uk-kenburns;
  animation-duration: 15s; }

/*
 * Shake
 */
.uk-animation-shake {
  animation-name: uk-shake; }

/*
 * SVG Stroke
 * The `--uk-animation-stroke` custom property contains the longest path length.
 * Set it manually or use `uk-svg="stroke-animation: true"` to set it automatically.
 * All strokes are animated by the same pace and doesn't end simultaneously.
 * To end simultaneously, `pathLength="1"` could be used, but it's not working in Safari yet.
 */
.uk-animation-stroke {
  animation-name: uk-stroke;
  animation-duration: 2s;
  stroke-dasharray: var(--uk-animation-stroke); }

/* Direction modifier
 ========================================================================== */
.uk-animation-reverse {
  animation-direction: reverse;
  animation-timing-function: ease-in; }

/* Duration modifier
 ========================================================================== */
.uk-animation-fast {
  animation-duration: 0.1s; }

/* Toggle animation based on the State of the Parent Element
 ========================================================================== */
.uk-animation-toggle:not(:hover):not(:focus) [class*='uk-animation-'] {
  animation-name: none; }

/* Keyframes used by animation classes
 ========================================================================== */
/*
 * Fade
 */
@keyframes uk-fade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/*
 * Scale
 */
@keyframes uk-scale-up {
  0% {
    transform: scale(0.9); }
  100% {
    transform: scale(1); } }

@keyframes uk-scale-down {
  0% {
    transform: scale(1.1); }
  100% {
    transform: scale(1); } }

/*
 * Slide
 */
@keyframes uk-slide-top {
  0% {
    transform: translateY(-100%); }
  100% {
    transform: translateY(0); } }

@keyframes uk-slide-bottom {
  0% {
    transform: translateY(100%); }
  100% {
    transform: translateY(0); } }

@keyframes uk-slide-left {
  0% {
    transform: translateX(-100%); }
  100% {
    transform: translateX(0); } }

@keyframes uk-slide-right {
  0% {
    transform: translateX(100%); }
  100% {
    transform: translateX(0); } }

/*
 * Slide Small
 */
@keyframes uk-slide-top-small {
  0% {
    transform: translateY(-10px); }
  100% {
    transform: translateY(0); } }

@keyframes uk-slide-bottom-small {
  0% {
    transform: translateY(10px); }
  100% {
    transform: translateY(0); } }

@keyframes uk-slide-left-small {
  0% {
    transform: translateX(-10px); }
  100% {
    transform: translateX(0); } }

@keyframes uk-slide-right-small {
  0% {
    transform: translateX(10px); }
  100% {
    transform: translateX(0); } }

/*
 * Slide Medium
 */
@keyframes uk-slide-top-medium {
  0% {
    transform: translateY(-50px); }
  100% {
    transform: translateY(0); } }

@keyframes uk-slide-bottom-medium {
  0% {
    transform: translateY(50px); }
  100% {
    transform: translateY(0); } }

@keyframes uk-slide-left-medium {
  0% {
    transform: translateX(-50px); }
  100% {
    transform: translateX(0); } }

@keyframes uk-slide-right-medium {
  0% {
    transform: translateX(50px); }
  100% {
    transform: translateX(0); } }

/*
 * Kenburns
 */
@keyframes uk-kenburns {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(1.2); } }

/*
 * Shake
 */
@keyframes uk-shake {
  0%, 100% {
    transform: translateX(0); }
  10% {
    transform: translateX(-9px); }
  20% {
    transform: translateX(8px); }
  30% {
    transform: translateX(-7px); }
  40% {
    transform: translateX(6px); }
  50% {
    transform: translateX(-5px); }
  60% {
    transform: translateX(4px); }
  70% {
    transform: translateX(-3px); }
  80% {
    transform: translateX(2px); }
  90% {
    transform: translateX(-1px); } }

/*
 * Stroke
 */
@keyframes uk-stroke {
  0% {
    stroke-dashoffset: var(--uk-animation-stroke); }
  100% {
    stroke-dashoffset: 0; } }

/* ========================================================================
   Component: Width
 ========================================================================== */
/* Equal child widths
 ========================================================================== */
[class*='uk-child-width'] > * {
  box-sizing: border-box;
  width: 100%; }

.uk-child-width-1-2 > * {
  width: 50%; }

.uk-child-width-1-3 > * {
  width: calc(100% * 1 / 3.001); }

.uk-child-width-1-4 > * {
  width: 25%; }

.uk-child-width-1-5 > * {
  width: 20%; }

.uk-child-width-1-6 > * {
  width: calc(100% * 1 / 6.001); }

.uk-child-width-auto > * {
  width: auto; }

/*
 * 1. Reset the `min-width`, which is set to auto by default, because
 *    flex items won't shrink below their minimum intrinsic content size.
 *    Using `1px` instead of `0`, so items still wrap into the next line,
 *    if they have zero width and padding and the predecessor is 100% wide.
 */
.uk-child-width-expand > :not([class*='uk-width']) {
  flex: 1;
  /* 1 */
  min-width: 1px; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-child-width-1-1\@s > * {
    width: 100%; }
  .uk-child-width-1-2\@s > * {
    width: 50%; }
  .uk-child-width-1-3\@s > * {
    width: calc(100% * 1 / 3.001); }
  .uk-child-width-1-4\@s > * {
    width: 25%; }
  .uk-child-width-1-5\@s > * {
    width: 20%; }
  .uk-child-width-1-6\@s > * {
    width: calc(100% * 1 / 6.001); }
  .uk-child-width-auto\@s > * {
    width: auto; }
  .uk-child-width-expand\@s > :not([class*='uk-width']) {
    flex: 1;
    min-width: 1px; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-child-width-1-1\@m > * {
    width: 100%; }
  .uk-child-width-1-2\@m > * {
    width: 50%; }
  .uk-child-width-1-3\@m > * {
    width: calc(100% * 1 / 3.001); }
  .uk-child-width-1-4\@m > * {
    width: 25%; }
  .uk-child-width-1-5\@m > * {
    width: 20%; }
  .uk-child-width-1-6\@m > * {
    width: calc(100% * 1 / 6.001); }
  .uk-child-width-auto\@m > * {
    width: auto; }
  .uk-child-width-expand\@m > :not([class*='uk-width']) {
    flex: 1;
    min-width: 1px; } }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-child-width-1-1\@l > * {
    width: 100%; }
  .uk-child-width-1-2\@l > * {
    width: 50%; }
  .uk-child-width-1-3\@l > * {
    width: calc(100% * 1 / 3.001); }
  .uk-child-width-1-4\@l > * {
    width: 25%; }
  .uk-child-width-1-5\@l > * {
    width: 20%; }
  .uk-child-width-1-6\@l > * {
    width: calc(100% * 1 / 6.001); }
  .uk-child-width-auto\@l > * {
    width: auto; }
  .uk-child-width-expand\@l > :not([class*='uk-width']) {
    flex: 1;
    min-width: 1px; } }

/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-child-width-1-1\@xl > * {
    width: 100%; }
  .uk-child-width-1-2\@xl > * {
    width: 50%; }
  .uk-child-width-1-3\@xl > * {
    width: calc(100% * 1 / 3.001); }
  .uk-child-width-1-4\@xl > * {
    width: 25%; }
  .uk-child-width-1-5\@xl > * {
    width: 20%; }
  .uk-child-width-1-6\@xl > * {
    width: calc(100% * 1 / 6.001); }
  .uk-child-width-auto\@xl > * {
    width: auto; }
  .uk-child-width-expand\@xl > :not([class*='uk-width']) {
    flex: 1;
    min-width: 1px; } }

/* Single Widths
 ========================================================================== */
/*
 * 1. `max-width` is needed for the pixel-based classes
 */
[class*='uk-width'] {
  box-sizing: border-box;
  width: 100%;
  /* 1 */
  max-width: 100%; }

/* Halves */
.uk-width-1-2 {
  width: 50%; }

/* Thirds */
.uk-width-1-3 {
  width: calc(100% * 1 / 3.001); }

.uk-width-2-3 {
  width: calc(100% * 2 / 3.001); }

/* Quarters */
.uk-width-1-4 {
  width: 25%; }

.uk-width-3-4 {
  width: 75%; }

/* Fifths */
.uk-width-1-5 {
  width: 20%; }

.uk-width-2-5 {
  width: 40%; }

.uk-width-3-5 {
  width: 60%; }

.uk-width-4-5 {
  width: 80%; }

/* Sixths */
.uk-width-1-6 {
  width: calc(100% * 1 / 6.001); }

.uk-width-5-6 {
  width: calc(100% * 5 / 6.001); }

/* Pixel */
.uk-width-small {
  width: 150px; }

.uk-width-medium {
  width: 300px; }

.uk-width-large {
  width: 450px; }

.uk-width-xlarge {
  width: 600px; }

.uk-width-2xlarge {
  width: 750px; }

/* Auto */
.uk-width-auto {
  width: auto; }

/* Expand */
.uk-width-expand {
  flex: 1;
  min-width: 1px; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  /* Whole */
  .uk-width-1-1\@s {
    width: 100%; }
  /* Halves */
  .uk-width-1-2\@s {
    width: 50%; }
  /* Thirds */
  .uk-width-1-3\@s {
    width: calc(100% * 1 / 3.001); }
  .uk-width-2-3\@s {
    width: calc(100% * 2 / 3.001); }
  /* Quarters */
  .uk-width-1-4\@s {
    width: 25%; }
  .uk-width-3-4\@s {
    width: 75%; }
  /* Fifths */
  .uk-width-1-5\@s {
    width: 20%; }
  .uk-width-2-5\@s {
    width: 40%; }
  .uk-width-3-5\@s {
    width: 60%; }
  .uk-width-4-5\@s {
    width: 80%; }
  /* Sixths */
  .uk-width-1-6\@s {
    width: calc(100% * 1 / 6.001); }
  .uk-width-5-6\@s {
    width: calc(100% * 5 / 6.001); }
  /* Pixel */
  .uk-width-small\@s {
    width: 150px; }
  .uk-width-medium\@s {
    width: 300px; }
  .uk-width-large\@s {
    width: 450px; }
  .uk-width-xlarge\@s {
    width: 600px; }
  .uk-width-2xlarge\@s {
    width: 750px; }
  /* Auto */
  .uk-width-auto\@s {
    width: auto; }
  /* Expand */
  .uk-width-expand\@s {
    flex: 1;
    min-width: 1px; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  /* Whole */
  .uk-width-1-1\@m {
    width: 100%; }
  /* Halves */
  .uk-width-1-2\@m {
    width: 50%; }
  /* Thirds */
  .uk-width-1-3\@m {
    width: calc(100% * 1 / 3.001); }
  .uk-width-2-3\@m {
    width: calc(100% * 2 / 3.001); }
  /* Quarters */
  .uk-width-1-4\@m {
    width: 25%; }
  .uk-width-3-4\@m {
    width: 75%; }
  /* Fifths */
  .uk-width-1-5\@m {
    width: 20%; }
  .uk-width-2-5\@m {
    width: 40%; }
  .uk-width-3-5\@m {
    width: 60%; }
  .uk-width-4-5\@m {
    width: 80%; }
  /* Sixths */
  .uk-width-1-6\@m {
    width: calc(100% * 1 / 6.001); }
  .uk-width-5-6\@m {
    width: calc(100% * 5 / 6.001); }
  /* Pixel */
  .uk-width-small\@m {
    width: 150px; }
  .uk-width-medium\@m {
    width: 300px; }
  .uk-width-large\@m {
    width: 450px; }
  .uk-width-xlarge\@m {
    width: 600px; }
  .uk-width-2xlarge\@m {
    width: 750px; }
  /* Auto */
  .uk-width-auto\@m {
    width: auto; }
  /* Expand */
  .uk-width-expand\@m {
    flex: 1;
    min-width: 1px; } }

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Whole */
  .uk-width-1-1\@l {
    width: 100%; }
  /* Halves */
  .uk-width-1-2\@l {
    width: 50%; }
  /* Thirds */
  .uk-width-1-3\@l {
    width: calc(100% * 1 / 3.001); }
  .uk-width-2-3\@l {
    width: calc(100% * 2 / 3.001); }
  /* Quarters */
  .uk-width-1-4\@l {
    width: 25%; }
  .uk-width-3-4\@l {
    width: 75%; }
  /* Fifths */
  .uk-width-1-5\@l {
    width: 20%; }
  .uk-width-2-5\@l {
    width: 40%; }
  .uk-width-3-5\@l {
    width: 60%; }
  .uk-width-4-5\@l {
    width: 80%; }
  /* Sixths */
  .uk-width-1-6\@l {
    width: calc(100% * 1 / 6.001); }
  .uk-width-5-6\@l {
    width: calc(100% * 5 / 6.001); }
  /* Pixel */
  .uk-width-small\@l {
    width: 150px; }
  .uk-width-medium\@l {
    width: 300px; }
  .uk-width-large\@l {
    width: 450px; }
  .uk-width-xlarge\@l {
    width: 600px; }
  .uk-width-2xlarge\@l {
    width: 750px; }
  /* Auto */
  .uk-width-auto\@l {
    width: auto; }
  /* Expand */
  .uk-width-expand\@l {
    flex: 1;
    min-width: 1px; } }

/* Large screen and bigger */
@media (min-width: 1600px) {
  /* Whole */
  .uk-width-1-1\@xl {
    width: 100%; }
  /* Halves */
  .uk-width-1-2\@xl {
    width: 50%; }
  /* Thirds */
  .uk-width-1-3\@xl {
    width: calc(100% * 1 / 3.001); }
  .uk-width-2-3\@xl {
    width: calc(100% * 2 / 3.001); }
  /* Quarters */
  .uk-width-1-4\@xl {
    width: 25%; }
  .uk-width-3-4\@xl {
    width: 75%; }
  /* Fifths */
  .uk-width-1-5\@xl {
    width: 20%; }
  .uk-width-2-5\@xl {
    width: 40%; }
  .uk-width-3-5\@xl {
    width: 60%; }
  .uk-width-4-5\@xl {
    width: 80%; }
  /* Sixths */
  .uk-width-1-6\@xl {
    width: calc(100% * 1 / 6.001); }
  .uk-width-5-6\@xl {
    width: calc(100% * 5 / 6.001); }
  /* Pixel */
  .uk-width-small\@xl {
    width: 150px; }
  .uk-width-medium\@xl {
    width: 300px; }
  .uk-width-large\@xl {
    width: 450px; }
  .uk-width-xlarge\@xl {
    width: 600px; }
  .uk-width-2xlarge\@xl {
    width: 750px; }
  /* Auto */
  .uk-width-auto\@xl {
    width: auto; }
  /* Expand */
  .uk-width-expand\@xl {
    flex: 1;
    min-width: 1px; } }

/* ========================================================================
   Component: Height
 ========================================================================== */
[class*='uk-height'] {
  box-sizing: border-box; }

/*
 * Only works if parent element has a height set
 */
.uk-height-1-1 {
  height: 100%; }

/*
 * Useful to create image teasers
 */
.uk-height-viewport {
  min-height: 100vh; }

/*
 * Pixel
 * Useful for `overflow: auto`
 */
.uk-height-small {
  height: 150px; }

.uk-height-medium {
  height: 300px; }

.uk-height-large {
  height: 450px; }

.uk-height-max-small {
  max-height: 150px; }

.uk-height-max-medium {
  max-height: 300px; }

.uk-height-max-large {
  max-height: 450px; }

/* ========================================================================
   Component: Transition
 ========================================================================== */
/* Transitions
 ========================================================================== */
/*
 * The toggle is triggered on touch devices by two methods:
 * 1. Using `:focus` and tabindex
 * 2. Using `:hover` and a `touchstart` event listener registered on the document
 *    (Doesn't work on Surface touch devices)
 */
:where(.uk-transition-fade),
:where([class*='uk-transition-scale']),
:where([class*='uk-transition-slide']) {
  --uk-position-translate-x: 0;
  --uk-position-translate-y: 0; }

.uk-transition-fade,
[class*='uk-transition-scale'],
[class*='uk-transition-slide'] {
  --uk-translate-x: 0;
  --uk-translate-y: 0;
  --uk-scale-x: 1;
  --uk-scale-y: 1;
  transform: translate(var(--uk-position-translate-x), var(--uk-position-translate-y)) translate(var(--uk-translate-x), var(--uk-translate-y)) scale(var(--uk-scale-x), var(--uk-scale-y));
  transition: 0.3s ease-out;
  transition-property: opacity, transform, filter;
  opacity: 0; }

/*
 * Fade
 */
.uk-transition-toggle:hover .uk-transition-fade,
.uk-transition-toggle:focus .uk-transition-fade,
.uk-transition-toggle .uk-transition-fade:focus-within,
.uk-transition-active.uk-active .uk-transition-fade {
  opacity: 1; }

/*
 * Scale
 * 1. Make image rendering the same during the transition as before and after. Prefixed because of Safari.
 */
/* 1 */
[class*='uk-transition-scale'] {
  -webkit-backface-visibility: hidden; }

.uk-transition-scale-up {
  --uk-scale-x: 1;
  --uk-scale-y: 1; }

.uk-transition-scale-down {
  --uk-scale-x: 1.03;
  --uk-scale-y: 1.03; }

/* Show */
.uk-transition-toggle:hover .uk-transition-scale-up,
.uk-transition-toggle:focus .uk-transition-scale-up,
.uk-transition-toggle .uk-transition-scale-up:focus-within,
.uk-transition-active.uk-active .uk-transition-scale-up {
  --uk-scale-x: 1.03;
  --uk-scale-y: 1.03;
  opacity: 1; }

.uk-transition-toggle:hover .uk-transition-scale-down,
.uk-transition-toggle:focus .uk-transition-scale-down,
.uk-transition-toggle .uk-transition-scale-down:focus-within,
.uk-transition-active.uk-active .uk-transition-scale-down {
  --uk-scale-x: 1;
  --uk-scale-y: 1;
  opacity: 1; }

/*
 * Slide
 */
.uk-transition-slide-top {
  --uk-translate-y: -100%; }

.uk-transition-slide-bottom {
  --uk-translate-y: 100%; }

.uk-transition-slide-left {
  --uk-translate-x: -100%; }

.uk-transition-slide-right {
  --uk-translate-x: 100%; }

.uk-transition-slide-top-small {
  --uk-translate-y: (-$transition-slide-small-translate); }

.uk-transition-slide-bottom-small {
  --uk-translate-y: 10px; }

.uk-transition-slide-left-small {
  --uk-translate-x: (-$transition-slide-small-translate); }

.uk-transition-slide-right-small {
  --uk-translate-x: 10px; }

.uk-transition-slide-top-medium {
  --uk-translate-y: (-$transition-slide-medium-translate); }

.uk-transition-slide-bottom-medium {
  --uk-translate-y: 50px; }

.uk-transition-slide-left-medium {
  --uk-translate-x: (-$transition-slide-medium-translate); }

.uk-transition-slide-right-medium {
  --uk-translate-x: 50px; }

/* Show */
.uk-transition-toggle:hover [class*='uk-transition-slide'],
.uk-transition-toggle:focus [class*='uk-transition-slide'],
.uk-transition-toggle [class*='uk-transition-slide']:focus-within,
.uk-transition-active.uk-active [class*='uk-transition-slide'] {
  --uk-translate-x: 0;
  --uk-translate-y: 0;
  opacity: 1; }

/* Opacity modifier
 ========================================================================== */
.uk-transition-opaque {
  opacity: 1; }

/* Duration modifiers
 ========================================================================== */
.uk-transition-slow {
  transition-duration: 0.7s; }

/* ========================================================================
   Component: Position
 ========================================================================== */
:root {
  --uk-position-margin-offset: 0px; }

/* Directions
 ========================================================================== */
/*
 * 1. Prevent content overflow.
 */
[class*='uk-position-top'],
[class*='uk-position-bottom'],
[class*='uk-position-left'],
[class*='uk-position-right'],
[class*='uk-position-center'] {
  position: absolute !important;
  /* 1 */
  max-width: calc(100% - (var(--uk-position-margin-offset) * 2));
  box-sizing: border-box; }

/*
 * Edges
 * Don't use `width: 100%` because it's wrong if the parent has padding.
 */
.uk-position-top {
  top: 0;
  left: 0;
  right: 0; }

.uk-position-bottom {
  bottom: 0;
  left: 0;
  right: 0; }

.uk-position-left {
  top: 0;
  bottom: 0;
  left: 0; }

.uk-position-right {
  top: 0;
  bottom: 0;
  right: 0; }

/*
 * Corners
 */
.uk-position-top-left {
  top: 0;
  left: 0; }

.uk-position-top-right {
  top: 0;
  right: 0; }

.uk-position-bottom-left {
  bottom: 0;
  left: 0; }

.uk-position-bottom-right {
  bottom: 0;
  right: 0; }

/*
 * Center
 * 1. Fix text wrapping if content is larger than 50% of the container.
 *    Using `max-content` requires `max-width` of 100% which is set generally.
 */
.uk-position-center {
  top: calc(50% - var(--uk-position-margin-offset));
  left: calc(50% - var(--uk-position-margin-offset));
  --uk-position-translate-x: -50%;
  --uk-position-translate-y: -50%;
  transform: translate(var(--uk-position-translate-x), var(--uk-position-translate-y));
  /* 1 */
  width: max-content; }

/* Vertical */
[class*='uk-position-center-left'],
[class*='uk-position-center-right'] {
  top: calc(50% - var(--uk-position-margin-offset));
  --uk-position-translate-y: -50%;
  transform: translate(0, var(--uk-position-translate-y)); }

.uk-position-center-left {
  left: 0; }

.uk-position-center-right {
  right: 0; }

.uk-position-center-left-out {
  right: 100%;
  width: max-content; }

.uk-position-center-right-out {
  left: 100%;
  width: max-content; }

/* Horizontal */
.uk-position-top-center,
.uk-position-bottom-center {
  left: calc(50% - var(--uk-position-margin-offset));
  --uk-position-translate-x: -50%;
  transform: translate(var(--uk-position-translate-x), 0);
  /* 1 */
  width: max-content; }

.uk-position-top-center {
  top: 0; }

.uk-position-bottom-center {
  bottom: 0; }

/*
 * Cover
 */
.uk-position-cover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

/* Margin
 ========================================================================== */
.uk-position-small {
  margin: 15px;
  --uk-position-margin-offset: 15px; }

.uk-position-medium {
  margin: 30px;
  --uk-position-margin-offset: 30px; }

.uk-position-large {
  margin: 30px;
  --uk-position-margin-offset: 30px; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-position-large {
    margin: 50px;
    --uk-position-margin-offset: 50px; } }

/* Schemes
 ========================================================================== */
.uk-position-relative {
  position: relative  !important; }

.uk-position-absolute {
  position: absolute  !important; }

.uk-position-fixed {
  position: fixed  !important; }

.uk-position-sticky {
  position: sticky  !important; }

/* Layer
 ========================================================================== */
.uk-position-z-index {
  z-index: 1; }

/* ========================================================================
   Component: Flex
 ========================================================================== */
.uk-flex {
  display: flex; }

.uk-flex-inline {
  display: inline-flex; }

/*
 * Remove pseudo elements created by micro clearfix as precaution
 */
.uk-flex::before,
.uk-flex::after,
.uk-flex-inline::before,
.uk-flex-inline::after {
  display: none; }

/* Alignment
 ========================================================================== */
/*
 * Align items along the main axis of the current line of the flex container
 * Row: Horizontal
 */
.uk-flex-left {
  justify-content: flex-start; }

.uk-flex-center {
  justify-content: center; }

.uk-flex-right {
  justify-content: flex-end; }

.uk-flex-between {
  justify-content: space-between; }

.uk-flex-around {
  justify-content: space-around; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-flex-left\@s {
    justify-content: flex-start; }
  .uk-flex-center\@s {
    justify-content: center; }
  .uk-flex-right\@s {
    justify-content: flex-end; }
  .uk-flex-between\@s {
    justify-content: space-between; }
  .uk-flex-around\@s {
    justify-content: space-around; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-flex-left\@m {
    justify-content: flex-start; }
  .uk-flex-center\@m {
    justify-content: center; }
  .uk-flex-right\@m {
    justify-content: flex-end; }
  .uk-flex-between\@m {
    justify-content: space-between; }
  .uk-flex-around\@m {
    justify-content: space-around; } }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-flex-left\@l {
    justify-content: flex-start; }
  .uk-flex-center\@l {
    justify-content: center; }
  .uk-flex-right\@l {
    justify-content: flex-end; }
  .uk-flex-between\@l {
    justify-content: space-between; }
  .uk-flex-around\@l {
    justify-content: space-around; } }

/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-flex-left\@xl {
    justify-content: flex-start; }
  .uk-flex-center\@xl {
    justify-content: center; }
  .uk-flex-right\@xl {
    justify-content: flex-end; }
  .uk-flex-between\@xl {
    justify-content: space-between; }
  .uk-flex-around\@xl {
    justify-content: space-around; } }

/*
 * Align items in the cross axis of the current line of the flex container
 * Row: Vertical
 */
.uk-flex-stretch {
  align-items: stretch; }

.uk-flex-top {
  align-items: flex-start; }

.uk-flex-middle {
  align-items: center; }

.uk-flex-bottom {
  align-items: flex-end; }

/* Direction
 ========================================================================== */
.uk-flex-row {
  flex-direction: row; }

.uk-flex-row-reverse {
  flex-direction: row-reverse; }

.uk-flex-column {
  flex-direction: column; }

.uk-flex-column-reverse {
  flex-direction: column-reverse; }

/* Wrap
 ========================================================================== */
.uk-flex-nowrap {
  flex-wrap: nowrap; }

.uk-flex-wrap {
  flex-wrap: wrap; }

.uk-flex-wrap-reverse {
  flex-wrap: wrap-reverse; }

/*
 * Aligns items within the flex container when there is extra space in the cross-axis
 * Only works if there is more than one line of flex items
 */
.uk-flex-wrap-stretch {
  align-content: stretch; }

.uk-flex-wrap-top {
  align-content: flex-start; }

.uk-flex-wrap-middle {
  align-content: center; }

.uk-flex-wrap-bottom {
  align-content: flex-end; }

.uk-flex-wrap-between {
  align-content: space-between; }

.uk-flex-wrap-around {
  align-content: space-around; }

/* Item ordering
 ========================================================================== */
/*
 * Default is 0
 */
.uk-flex-first {
  order: -1; }

.uk-flex-last {
  order: 99; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-flex-first\@s {
    order: -1; }
  .uk-flex-last\@s {
    order: 99; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-flex-first\@m {
    order: -1; }
  .uk-flex-last\@m {
    order: 99; } }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-flex-first\@l {
    order: -1; }
  .uk-flex-last\@l {
    order: 99; } }

/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-flex-first\@xl {
    order: -1; }
  .uk-flex-last\@xl {
    order: 99; } }

/* Item dimensions
 ========================================================================== */
/*
 * Initial: 0 1 auto
 * Content dimensions, but shrinks
 */
/*
 * No Flex: 0 0 auto
 * Content dimensions
 */
.uk-flex-none {
  flex: none; }

/*
 * Relative Flex: 1 1 auto
 * Space is allocated considering content
 */
.uk-flex-auto {
  flex: auto; }

/*
 * Absolute Flex: 1 1 0%
 * Space is allocated solely based on flex
 */
.uk-flex-1 {
  flex: 1; }

/* ========================================================================
   Component: Background
 ========================================================================== */
/* Color
 ========================================================================== */
.uk-background-default {
  background-color: #fff; }

.uk-background-muted {
  background-color: #f8f8f8; }

.uk-background-primary {
  background-color: #1e87f0; }

.uk-background-secondary {
  background-color: #222; }

/* Size
 ========================================================================== */
.uk-background-cover,
.uk-background-contain,
.uk-background-width-1-1,
.uk-background-height-1-1 {
  background-position: 50% 50%;
  background-repeat: no-repeat; }

.uk-background-cover {
  background-size: cover; }

.uk-background-contain {
  background-size: contain; }

.uk-background-width-1-1 {
  background-size: 100%; }

.uk-background-height-1-1 {
  background-size: auto 100%; }

/* Position
 ========================================================================== */
.uk-background-top-left {
  background-position: 0 0; }

.uk-background-top-center {
  background-position: 50% 0; }

.uk-background-top-right {
  background-position: 100% 0; }

.uk-background-center-left {
  background-position: 0 50%; }

.uk-background-center-center {
  background-position: 50% 50%; }

.uk-background-center-right {
  background-position: 100% 50%; }

.uk-background-bottom-left {
  background-position: 0 100%; }

.uk-background-bottom-center {
  background-position: 50% 100%; }

.uk-background-bottom-right {
  background-position: 100% 100%; }

/* Repeat
 ========================================================================== */
.uk-background-norepeat {
  background-repeat: no-repeat; }

/* Attachment
 ========================================================================== */
/*
 * 1. Fix bug introduced in Chrome 67: the background image is not visible if any element on the page uses `translate3d`
 */
.uk-background-fixed {
  background-attachment: fixed;
  /* 1 */
  backface-visibility: hidden; }

/*
 * Exclude touch devices because `fixed` doesn't work on iOS and Android
 */
@media (pointer: coarse) {
  .uk-background-fixed {
    background-attachment: scroll; } }

/* Image
 ========================================================================== */
/* Phone portrait and smaller */
@media (max-width: 639px) {
  .uk-background-image\@s {
    background-image: none !important; } }

/* Phone landscape and smaller */
@media (max-width: 959px) {
  .uk-background-image\@m {
    background-image: none !important; } }

/* Tablet landscape and smaller */
@media (max-width: 1199px) {
  .uk-background-image\@l {
    background-image: none !important; } }

/* Desktop and smaller */
@media (max-width: 1599px) {
  .uk-background-image\@xl {
    background-image: none !important; } }

/* Blend modes
 ========================================================================== */
.uk-background-blend-multiply {
  background-blend-mode: multiply; }

.uk-background-blend-screen {
  background-blend-mode: screen; }

.uk-background-blend-overlay {
  background-blend-mode: overlay; }

.uk-background-blend-darken {
  background-blend-mode: darken; }

.uk-background-blend-lighten {
  background-blend-mode: lighten; }

.uk-background-blend-color-dodge {
  background-blend-mode: color-dodge; }

.uk-background-blend-color-burn {
  background-blend-mode: color-burn; }

.uk-background-blend-hard-light {
  background-blend-mode: hard-light; }

.uk-background-blend-soft-light {
  background-blend-mode: soft-light; }

.uk-background-blend-difference {
  background-blend-mode: difference; }

.uk-background-blend-exclusion {
  background-blend-mode: exclusion; }

.uk-background-blend-hue {
  background-blend-mode: hue; }

.uk-background-blend-saturation {
  background-blend-mode: saturation; }

.uk-background-blend-color {
  background-blend-mode: color; }

.uk-background-blend-luminosity {
  background-blend-mode: luminosity; }

/* déclaration d'une fonction

@mixin truc($variable){
	color:$variable;
}

////* appel de la fonction

.class{
	@include truc(#000);
}

/* déclaration d'une procédure

%ma-fonction{
	color:#000;
}

////* appel de la procédure

.class{
	@extend %ma-fonction;
	border:0;
}


// fonctions déjà existantes
 
 - lighten($color, $amount) : Makes a color lighter.
 - darken($color, $amount) : Makes a color darker.
 - saturate($color, $amount) : Makes a color more saturated.
 - desaturate($color, $amount) : Makes a color less saturated.
 
Le tout sur ce site : http://sass-lang.com/documentation/Sass/Script/Functions.html
 
*/
/* ========= mixin ico utilisé pour faire fonctionner l'icons generated dans le 4
*/
/* ========= appel des fonts
*/
/* =======

  css à placer sur les balises html générale avant de rentrer dans le détail du dossier 5.layout et 6.blocs

======= */
html {
  font-size: .625rem; }

body {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  color: #000; }

.body {
  overflow: hidden; }

sup {
  font-size: 60%; }

.invalid {
  color: #dc3545;
  font-weight: bold; }

input.invalid {
  border: 1px solid #dc3545; }

a {
  text-decoration: none; }

em {
  color: #32B4B4; }

img,
svg {
  max-width: 100%; }

a img {
  border: 0; }

[class^="ico-"],
[class*=" ico-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: icons, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  line-height: 1.5;
  color: inherit;
  text-transform: none;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  [class^="ico-"]::before,
  [class*=" ico-"]::before {
    display: inline-block;
    font-family: icons, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    /* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
    text-decoration: none;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  [class^="ico-"]:hover,
  [class*=" ico-"]:hover {
    text-decoration: none; }

@font-face {
  font-family: "icons";
  src: url("../fonts/icons.eot");
  src: url("../fonts/icons.eot?#iefix") format("eot"), url("../fonts/icons.woff2") format("woff2"), url("../fonts/icons.woff") format("woff"), url("../fonts/icons.ttf") format("truetype"), url("../fonts/icons.svg#icons") format("svg"); }

.ico:before {
  font-family: "icons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  /* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
  text-decoration: none;
  text-transform: none; }

.ico-arrow-intro-bas:before {
  content: "\E001"; }

.ico-arrow-top:before {
  content: "\E002"; }

.ico-check-offre-hover:before {
  content: "\E003"; }

.ico-date-debut:before {
  content: "\E004"; }

.ico-diplome:before {
  content: "\E005"; }

.ico-experience:before {
  content: "\E006"; }

.ico-facebook:before {
  content: "\E007"; }

.ico-linkedin:before {
  content: "\E008"; }

.ico-localisation-blanc:before {
  content: "\E009"; }

.ico-localisation:before {
  content: "\E00A"; }

.ico-logo-accueil:before {
  content: "\E00B"; }

.ico-outils:before {
  content: "\E00C"; }

.ico-rythme:before {
  content: "\E00D"; }

.ico-teletravail:before {
  content: "\E00E"; }

.ico-type-blanc:before {
  content: "\E00F"; }

.ico-type:before {
  content: "\E010"; }

h1, .h1, .h1 {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 4rem;
  color: #fff; }

h2, .h2, .h2 {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 3.5rem;
  color: #194270; }

h3, .h3, .h3 {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 2.5rem;
  color: #194270; }

h4, .h4, .h4 {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 2.5rem;
  color: #32B4B4; }

h5, .h5, .h5 {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 2rem;
  color: #32B4B4; }

.card-ul-child .card-body ul {
  margin: 5px 0 0;
  padding-left: 15px; }
  .card-ul-child .card-body ul li {
    margin: 10px 0 0;
    position: relative; }
    .card-ul-child .card-body ul li:before {
      content: '';
      width: 6px;
      height: 6px;
      position: absolute;
      left: -15px;
      top: 9px;
      background-color: #194270;
      border-radius: 10px; }

.rsform-block .formControlLabel {
  display: none; }

.rsform-block.rsform-block-titre h2, .rsform-block.rsform-block-titre .h2 {
  font-size: 40px;
  margin-bottom: 25px; }

.rsform-block.rsform-block-adresse {
  font-size: 1.6rem;
  color: #38689d; }
  .rsform-block.rsform-block-adresse a {
    font-size: 1.6rem;
    color: #38689d; }
  .rsform-block.rsform-block-adresse strong {
    color: #194270;
    margin: 20px 0 5px;
    display: block; }
  @media (max-width: 991.98px) {
    .rsform-block.rsform-block-adresse iframe {
      display: none; } }

.rsform-block .form-control {
  padding: 15px;
  border-color: #A8BED5;
  border-radius: 10px; }
  .rsform-block .form-control::placeholder {
    color: #38689d; }

.rsform-block.rsform-block-rgpd .form-check-label, .rsform-block.rsform-block-rgpd a {
  font-size: 1.6rem;
  color: #38689d; }

.rsform-block.rsform-block-envoyer {
  padding-top: 30px; }
  .rsform-block.rsform-block-envoyer .btn-primary {
    background-color: #32B4B4;
    border-color: #32B4B4; }

.rsform-block.rsform-block-bandeau {
  margin-bottom: 50px;
  position: relative; }
  @media (min-width: 992px) {
    .rsform-block.rsform-block-bandeau {
      margin-bottom: 105px; } }
  .rsform-block.rsform-block-bandeau img {
    position: relative;
    z-index: 1; }
  .rsform-block.rsform-block-bandeau:after {
    content: '';
    display: block;
    position: absolute;
    width: 306px;
    height: 90px;
    left: 30px;
    top: 20px;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#194270+0,32b4b4+50,c8e6cd+100 */
    background: #194270;
    /* Old browsers */
    background: -moz-linear-gradient(left, #194270 0%, #32b4b4 50%, #c8e6cd 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #194270 0%, #32b4b4 50%, #c8e6cd 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #194270 0%, #32b4b4 50%, #c8e6cd 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#194270', endColorstr='#c8e6cd',GradientType=1 );
    /* IE6-9 fallback on horizontal gradient */
    border-radius: 5px 20px 5px 20px;
    z-index: 0; }
    @media (min-width: 576px) {
      .rsform-block.rsform-block-bandeau:after {
        width: 100%;
        height: 170px; } }
    @media (min-width: 992px) {
      .rsform-block.rsform-block-bandeau:after {
        width: 100%;
        height: 260px;
        left: 110px;
        top: 95px; } }

@media (max-width: 575.98px) {
  .rsform-block .rsform-submit-button {
    width: 100%; } }

.formContainer {
  margin-top: 30px;
  margin-bottom: 50px; }
  .formContainer .row {
    justify-content: center; }
    @media (min-width: 576px) {
      .formContainer .row .col-md-6 {
        flex: 0 0 auto;
        width: 100%; } }
    @media (min-width: 992px) {
      .formContainer .row .col-md-6 {
        flex: 0 0 auto;
        width: 41.66667%; } }
    @media (min-width: 768px) {
      .formContainer .row .col-md-12 {
        flex: 0 0 auto;
        width: 83.33333%; } }

.com_rsform #main-content > p {
  text-align: center;
  font-size: 1.6rem;
  color: #38689d; }

.rsform-thankyou-button {
  margin: 0 auto 100px;
  display: table; }

.btn-back-to-top {
  bottom: 15%;
  right: 20px; }

.nav.menu .parent:hover .dropdown-menu {
  display: block; }

.nav.menu .default .nav-link {
  padding-left: 0; }

.menu-burger span, .menu-burger span:after, .menu-burger span:before {
  display: block;
  width: 20px;
  height: 2.25px;
  background-color: #fff; }

.menu-burger span {
  position: relative;
  margin: 10px 0;
  width: 14px;
  height: 2px;
  background-color: #32B4B4; }
  .menu-burger span:before, .menu-burger span:after {
    content: '';
    position: absolute;
    left: 0; }
  .menu-burger span:before {
    bottom: 6px; }
  .menu-burger span:after {
    top: 6px; }

.menu-burger-close {
  cursor: pointer; }
  .menu-burger-close span, .menu-burger-close span:after, .menu-burger-close span:before {
    display: block;
    width: 30px;
    height: 2.25px;
    background-color: #fff; }
  .menu-burger-close span {
    position: relative;
    margin: 10px 0;
    width: 20px;
    height: 20px;
    background-color: transparent; }
    .menu-burger-close span:before, .menu-burger-close span:after {
      content: '';
      position: absolute;
      left: 0; }
    .menu-burger-close span:before {
      bottom: 6px;
      transform: rotate(45deg); }
    .menu-burger-close span:after {
      bottom: 6px;
      transform: rotate(-45deg); }

.picto-dropdown:after {
  content: '';
  display: inline-block;
  width: 17px;
  height: 17px;
  margin-left: auto;
  padding-left: 5px;
  background-image: url("data:image/svg+xml,%3csvg xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' xmlns='http://www.w3.org/2000/svg'%3e%3cg fill='none' fill-rule='evenodd'%3e%3cpath d='M0 0h25v25H0z'/%3e%3cpath fill='%23fff' fill-rule='nonzero' d='m12.5 15.625-4.42-4.42 1.474-1.473 2.946 2.947 2.946-2.947 1.474 1.473z'/%3e%3c/g%3e%3c/svg%3e");
  background-size: 25px;
  background-repeat: no-repeat; }

.nav-item.active .picto-dropdown:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' xmlns='http://www.w3.org/2000/svg'%3e%3cg fill='none' fill-rule='evenodd'%3e%3cpath d='M0 0h25v25H0z'/%3e%3cpath fill='%2332B4B4' fill-rule='nonzero' d='m12.5 15.625-4.42-4.42 1.474-1.473 2.946 2.947 2.946-2.947 1.474 1.473z'/%3e%3c/g%3e%3c/svg%3e"); }

.picto-link-light:after {
  content: '';
  display: inline-block;
  width: 20px;
  height: 6px;
  margin-left: 7PX;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='6.001' viewBox='2 9 20 6.001' xml:space='preserve'%3e%3cpath fill='%2338689d' d='M7.224 9c1.719.039 3.419.876 4.455 2.234l.144.197c.675.977 1.933 1.596 3.199 1.568.943.021 2.057-.562 3.07-1.512l.201-.195.291-.292H17a1 1 0 0 1-.993-.883L16 10a1 1 0 0 1 .883-.993L17 9h4.02c.023 0 .047.002.07.004L21 9a1.01 1.01 0 0 1 .265.035l.047.014.055.02.059.026.055.027a1.395 1.395 0 0 1 .136.09c.063.05.12.107.17.17l-.08-.09a1.024 1.024 0 0 1 .259.441l.015.063.009.054.005.032.003.03.002.056V14a1 1 0 0 1-1.993.117L20 14v-1.586l-.293.293c-1.34 1.34-2.894 2.23-4.43 2.291l-.256.002c-1.801.039-3.616-.813-4.701-2.234l-.144-.197c-.674-.977-1.932-1.596-3.197-1.569-1.223-.027-2.481.592-3.156 1.568a1 1 0 1 1-1.645-1.137C3.195 9.959 4.975 9.042 6.738 9h.486z'/%3e%3c/svg%3e");
  background-size: 20px;
  background-repeat: no-repeat; }

.picto-link-secondary:after {
  content: '';
  display: inline-block;
  width: 20px;
  height: 6px;
  margin-left: 7PX;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='6.001' viewBox='2 9 20 6.001' xml:space='preserve'%3e%3cpath fill='%2332B4B4' d='M7.224 9c1.719.039 3.419.876 4.455 2.234l.144.197c.675.977 1.933 1.596 3.199 1.568.943.021 2.057-.562 3.07-1.512l.201-.195.291-.292H17a1 1 0 0 1-.993-.883L16 10a1 1 0 0 1 .883-.993L17 9h4.02c.023 0 .047.002.07.004L21 9a1.01 1.01 0 0 1 .265.035l.047.014.055.02.059.026.055.027a1.395 1.395 0 0 1 .136.09c.063.05.12.107.17.17l-.08-.09a1.024 1.024 0 0 1 .259.441l.015.063.009.054.005.032.003.03.002.056V14a1 1 0 0 1-1.993.117L20 14v-1.586l-.293.293c-1.34 1.34-2.894 2.23-4.43 2.291l-.256.002c-1.801.039-3.616-.813-4.701-2.234l-.144-.197c-.674-.977-1.932-1.596-3.197-1.569-1.223-.027-2.481.592-3.156 1.568a1 1 0 1 1-1.645-1.137C3.195 9.959 4.975 9.042 6.738 9h.486z'/%3e%3c/svg%3e");
  background-size: 20px;
  background-repeat: no-repeat; }

.picto-primary:after {
  content: '';
  display: inline-block;
  width: 20px;
  height: 6px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='6.001' viewBox='2 9 20 6.001' xml:space='preserve'%3e%3cpath fill='%23194270' d='M7.224 9c1.719.039 3.419.876 4.455 2.234l.144.197c.675.977 1.933 1.596 3.199 1.568.943.021 2.057-.562 3.07-1.512l.201-.195.291-.292H17a1 1 0 0 1-.993-.883L16 10a1 1 0 0 1 .883-.993L17 9h4.02c.023 0 .047.002.07.004L21 9a1.01 1.01 0 0 1 .265.035l.047.014.055.02.059.026.055.027a1.395 1.395 0 0 1 .136.09c.063.05.12.107.17.17l-.08-.09a1.024 1.024 0 0 1 .259.441l.015.063.009.054.005.032.003.03.002.056V14a1 1 0 0 1-1.993.117L20 14v-1.586l-.293.293c-1.34 1.34-2.894 2.23-4.43 2.291l-.256.002c-1.801.039-3.616-.813-4.701-2.234l-.144-.197c-.674-.977-1.932-1.596-3.197-1.569-1.223-.027-2.481.592-3.156 1.568a1 1 0 1 1-1.645-1.137C3.195 9.959 4.975 9.042 6.738 9h.486z'/%3e%3c/svg%3e");
  background-size: 20px;
  background-repeat: no-repeat; }

.offres .bg-secondary-2 {
  opacity: 0;
  transition: all ease-in-out 0.2s; }

.offres .bulles {
  display: none;
  opacity: 0;
  transition: all ease-in-out 0.2s; }

.offres .offre {
  transition: all ease-in-out 0.2s; }

.offres:hover {
  z-index: 5; }
  .offres:hover .offre {
    background-color: #32B4B4 !important;
    transition: all ease-in-out 0.2s; }
  .offres:hover .bg-secondary-2 {
    opacity: 1;
    transition: all ease-in-out 0.2s; }
  .offres:hover .bulles {
    display: block;
    opacity: 1;
    transition: all ease-in-out 0.2s; }

.offres-slider .uk-active {
  position: relative; }
  .offres-slider .uk-active:after {
    content: '';
    width: 20px;
    height: 5px;
    background-color: #194270;
    display: block;
    position: absolute;
    right: -20px;
    top: 50%; }

.list-valeurs {
  width: 100%; }
  @media (min-width: 576px) {
    .list-valeurs {
      margin-left: 15px; } }
  @media (min-width: 992px) {
    .list-valeurs {
      width: 480px;
      padding: 0; } }
  .list-valeurs li:nth-child(1) {
    top: 0px;
    left: 0px; }
    @media (min-width: 992px) {
      .list-valeurs li:nth-child(1) {
        top: 60px;
        left: 0px; } }
  .list-valeurs li:nth-child(2) {
    top: 0px;
    left: 100px; }
    @media (min-width: 992px) {
      .list-valeurs li:nth-child(2) {
        transform: translate(-50%, -50%);
        top: 45px;
        left: 50%; } }
  .list-valeurs li:nth-child(3) {
    top: 0px;
    right: 0px; }
    @media (min-width: 992px) {
      .list-valeurs li:nth-child(3) {
        top: 60px;
        right: 0px; } }
  .list-valeurs li:nth-child(4) {
    top: 120px;
    right: 15%; }
    @media (max-width: 575.98px) {
      .list-valeurs li:nth-child(4) {
        margin-top: 40px; } }
    @media (min-width: 992px) {
      .list-valeurs li:nth-child(4) {
        top: 180px;
        right: 20%;
        margin: 0; } }
  .list-valeurs li:nth-child(5) {
    top: 120px;
    left: 15%; }
    @media (max-width: 575.98px) {
      .list-valeurs li:nth-child(5) {
        margin-top: 40px; } }
    @media (min-width: 992px) {
      .list-valeurs li:nth-child(5) {
        top: 180px;
        left: 20%;
        margin: 0; } }

.list-standard {
  margin-left: 15px; }
  .list-standard li {
    padding-bottom: 8px; }
    .list-standard li:before {
      content: '-';
      margin-right: 10px; }
  .list-standard .list-standard {
    padding-top: 10px;
    padding-left: 15px;
    font-size: 1.4rem; }
    .list-standard .list-standard li {
      position: relative;
      padding-left: 14px;
      padding-bottom: 0;
      color: #10335A; }
      .list-standard .list-standard li:before {
        content: '';
        width: 5px;
        height: 5px;
        background-color: #32B4B4;
        display: block;
        position: absolute;
        left: 0;
        top: 8px;
        transform: rotate(45deg); }

.equipe .fiche {
  display: none; }
  .equipe .fiche:hover {
    display: block; }

.equipe .btn-photo {
  z-index: 2; }
  .equipe .btn-photo:hover {
    z-index: 4; }
    .equipe .btn-photo:hover + .fiche {
      display: block; }

.btn-close {
  opacity: 1;
  padding: 7px;
  position: relative;
  background: #194270;
  /* Old browsers */
  background: -moz-linear-gradient(45deg, #194270 0%, #32b4b4 50%, #c8e6cd 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(45deg, #194270 0%, #32b4b4 50%, #c8e6cd 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(45deg, #194270 0%, #32b4b4 50%, #c8e6cd 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#194270', endColorstr='#c8e6cd',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */ }
  .btn-close:before {
    position: absolute;
    left: 7px;
    top: 13px;
    content: '';
    width: 16px;
    height: 2px;
    display: block;
    background-color: #fff;
    transform: rotate(45deg); }
  .btn-close:after {
    position: absolute;
    left: 7px;
    top: 13px;
    content: '';
    width: 16px;
    height: 2px;
    display: block;
    background-color: #fff;
    transform: rotate(-45deg); }

.bg-degrade-vertical {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#194270+0,32b4b4+50,c8e6cd+100 */
  background: #194270;
  /* Old browsers */
  background: -moz-linear-gradient(top, #194270 0%, #32b4b4 50%, #c8e6cd 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #194270 0%, #32b4b4 50%, #c8e6cd 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #194270 0%, #32b4b4 50%, #c8e6cd 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ }

.bg-degrade-diagonal {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#194270+0,32b4b4+50,c8e6cd+100 */
  background: #194270;
  /* Old browsers */
  background: -moz-linear-gradient(45deg, #194270 0%, #32b4b4 50%, #c8e6cd 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(45deg, #194270 0%, #32b4b4 50%, #c8e6cd 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(45deg, #194270 0%, #32b4b4 50%, #c8e6cd 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#194270', endColorstr='#c8e6cd',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */ }

.bg-degrade-horizontal {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#194270+0,32b4b4+50,c8e6cd+100 */
  background: #194270;
  /* Old browsers */
  background: -moz-linear-gradient(left, #194270 0%, #32b4b4 50%, #c8e6cd 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #194270 0%, #32b4b4 50%, #c8e6cd 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #194270 0%, #32b4b4 50%, #c8e6cd 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#194270', endColorstr='#c8e6cd',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */ }

.bg-logo-blanc-center {
  background: url(../images/wave-logo-blanche.svg) no-repeat center bottom; }

.bg-logo-blanc {
  background: url(../images/wave-logo-blanche.svg) no-repeat left bottom; }

.bg-logo-blanc-offre {
  background: url(../images/forme-logo-svg.svg) no-repeat left bottom; }

.bg-logo-blanc-offre-right {
  background: url(../images/forme-logo-svg.svg) no-repeat right bottom; }

.bg-logo-gris {
  background: url(../images/wave-logo-gris.svg) no-repeat right bottom;
  background-size: contain; }

.bg-logo-left {
  background: url(../images/wave-logo.svg) no-repeat right calc(100% + 40px); }
  @media (max-width: 575.98px) {
    .bg-logo-left {
      background-size: 180%; } }
  @media (min-width: 576px) {
    .bg-logo-left {
      background: url(../images/wave-logo.svg) no-repeat -100px 120px; } }

.bg-logo-right {
  background: url(../images/bg-pga.png) no-repeat calc(100% + 145px) bottom;
  background-size: 200% 100%; }
  @media (min-width: 576px) {
    .bg-logo-right {
      background: url(../images/bg-pga.png) no-repeat right bottom;
      background-size: cover; } }
  @media (min-width: 992px) {
    .bg-logo-right {
      background: url(../images/bg-pga.png) no-repeat right top;
      background-size: contain; } }

.bg-wave {
  background: url(../images/bg-wave.png) no-repeat left bottom;
  background-size: 200%; }
  @media (min-width: 992px) {
    .bg-wave {
      background-size: 100%; } }

.bg-quotes {
  background: url(../images/quote-1.svg) no-repeat center 30px; }
  @media (min-width: 992px) {
    .bg-quotes {
      background: url(../images/quote-1.svg) no-repeat 30px 30px, url(../images/quote-2.svg) no-repeat calc(100% - 30px) calc(100% - 30px); } }

.bg-quotes-blanc {
  background: url(../images/quote-1-blanc.svg) no-repeat center 30px; }
  @media (min-width: 992px) {
    .bg-quotes-blanc {
      background: url(../images/quote-1-blanc.svg) no-repeat 30px 30px, url(../images/quote-2-blanc.svg) no-repeat calc(100% - 30px) calc(100% - 30px); } }

.arrow-right {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6.1px 0 6.1px 6.78px;
  border-color: transparent transparent transparent #32B4B4;
  display: block; }

.arrow-left {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6.1px 6.78px 6.1px 0;
  border-color: transparent #32B4B4 transparent transparent;
  display: block; }

/*
  Bootstrap a déjà créé ces class :

  - fw-bold // 700
  - fw-bolder / bolder
  - fw-normal // 400
  - fw-light // 300
  - fw-lighter // lighter
  - fst-italic // font-style italic
  - fst-normal // font-style normal
  https://getbootstrap.com/docs/5.1/utilities/text/#font-weight-and-italics
 */
.fw-semi-bold {
  font-weight: 600; }

.fw-medium {
  font-weight: 500; }

.rounded-ovale {
  border-radius: 61% 39% 50% 50% / 60% 42% 58% 40%; }

/* ====================== Le responsive du vw-full de mobil vers desktop (vw-sm-full etc...)
 */
.vw-full {
  margin-left: -50vw;
  margin-right: -50vw;
  clear: both;
  position: relative;
  right: 50%;
  left: 50%;
  width: 100vw; }

.vw-full {
  margin-left: -50vw;
  margin-right: -50vw;
  clear: both;
  position: relative;
  right: 50%;
  left: 50%;
  width: 100vw; }

@media (min-width: 576px) {
  .vw-sm-full {
    margin-left: -50vw;
    margin-right: -50vw;
    clear: both;
    position: relative;
    right: 50%;
    left: 50%;
    width: 100vw; } }

@media (min-width: 768px) {
  .vw-md-full {
    margin-left: -50vw;
    margin-right: -50vw;
    clear: both;
    position: relative;
    right: 50%;
    left: 50%;
    width: 100vw; } }

@media (min-width: 992px) {
  .vw-lg-full {
    margin-left: -50vw;
    margin-right: -50vw;
    clear: both;
    position: relative;
    right: 50%;
    left: 50%;
    width: 100vw; } }

@media (min-width: 1200px) {
  .vw-xl-full {
    margin-left: -50vw;
    margin-right: -50vw;
    clear: both;
    position: relative;
    right: 50%;
    left: 50%;
    width: 100vw; } }

@media (min-width: 1400px) {
  .vw-xxl-full {
    margin-left: -50vw;
    margin-right: -50vw;
    clear: both;
    position: relative;
    right: 50%;
    left: 50%;
    width: 100vw; } }

@media (max-width: 991.98px) {
  .vw-md-full-under {
    margin-left: -50vw;
    margin-right: -50vw;
    clear: both;
    position: relative;
    right: 50%;
    left: 50%;
    width: 100vw; } }

/* =========  reprise des couleurs des boutons de base ici
*/
/* =========  mise en forme des nav
*/
.nav .nav-link {
  line-height: 20px; }

/* =========  mise en forme des boutons bootstrap
*/
.font-02 {
  font-family: "Caveat", cursive; }

.btn-secondary {
  color: #fff; }

.btn-secondary.active, .btn-secondary:hover {
  color: #194270; }

.btn-light {
  color: #194270; }

.btn-outline-secondary {
  background-color: #194270; }
  .btn-outline-secondary:hover {
    border-color: #194270;
    color: #194270; }

.link-light {
  transition: all .15s ease-in-out; }
  .link-light:hover {
    color: #32B4B4;
    transition: all .15s ease-in-out; }

.link-primary {
  transition: all .15s ease-in-out; }
  .link-primary:hover {
    opacity: 1 !important;
    transition: all .15s ease-in-out; }

.link-secondary {
  background: url(../images/arrow-lien.svg) no-repeat right -2px;
  padding-right: 30px;
  transition: all .15s ease-in-out; }
  .link-secondary:hover {
    opacity: 1 !important;
    transition: all .15s ease-in-out; }
  .link-secondary.link-secondary-inverse {
    background: url(../images/arrow-lien-inverse.svg) no-repeat left -2px;
    padding-left: 30px; }

.bg-degrade-diagonal.link-light:hover {
  color: #194270 !important; }

.list-group .list-group-item {
  background: none; }

.alert {
  color: #fff; }

.offcanvas-body .accordion-button {
  box-shadow: unset; }

.pagination .active {
  display: flex; }
  .pagination .active .page-link {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#194270+0,32b4b4+50,c8e6cd+100 */
    background: #194270;
    /* Old browsers */
    background: -moz-linear-gradient(45deg, #194270 0%, #32b4b4 50%, #c8e6cd 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #194270 0%, #32b4b4 50%, #c8e6cd 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, #194270 0%, #32b4b4 50%, #c8e6cd 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#194270', endColorstr='#c8e6cd',GradientType=1 );
    /* IE6-9 fallback on horizontal gradient */
    border: 0; }

.blockquote {
  background-color: #194270;
  color: #fff;
  padding: 20px;
  font-size: 1.8rem; }
  .blockquote p {
    margin: 0 !important; }

.text-justify {
  text-align: justify; }
