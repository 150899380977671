.btn-close{
  opacity: 1;
  padding: 7px;
  position: relative;
  background: #194270; /* Old browsers */
  background: -moz-linear-gradient(45deg,  #194270 0%, #32b4b4 50%, #c8e6cd 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(45deg,  #194270 0%,#32b4b4 50%,#c8e6cd 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(45deg,  #194270 0%,#32b4b4 50%,#c8e6cd 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#194270', endColorstr='#c8e6cd',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  &:before{
	position: absolute;
	left: 7px;
	top:13px;
	content:'';
	width: 16px;
	height: 2px;
	display: block;
	background-color: $white;
	transform: rotate(45deg);
  }
  &:after{
	position: absolute;
	left: 7px;
	top:13px;
	content:'';
	width: 16px;
	height: 2px;
	display: block;
	background-color: $white;
	transform: rotate(-45deg);
  }
}