// css pour bootstrap
/* ====== obligatoire
 */
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

/* ====== optionnel
 */

@import "../../../node_modules/bootstrap/scss/root";
@import "../../../node_modules/bootstrap/scss/reboot";
@import "../../../node_modules/bootstrap/scss/type";
@import "../../../node_modules/bootstrap/scss/utilities";
@import "../../../node_modules/bootstrap/scss/utilities/api";
// layout
@import "../../../node_modules/bootstrap/scss/containers";
@import "../../../node_modules/bootstrap/scss/grid"; //-> .container[-fluid] .row[-cols][-sm|-md|-lg|-xl][-1 ~ -12] .col[-sm|-md|-lg|-xl][-1 ~ -12][-auto]
// forms
@import "../../../node_modules/bootstrap/scss/alert"; //-> .alert[-primary|-secondary|-succes|-danger|-warning|-info|-light|-dark]
@import "../../../node_modules/bootstrap/scss/badge"; //-> .badge[-primary|-secondary|-succes|-danger|-warning|-info|-light|-dark|-pill]
@import "../../../node_modules/bootstrap/scss/forms"; //-> .form[-group|-control-file] .form-control[-sm|-md|-lg|-xl] .form-check[-input|-label]
@import "../../../node_modules/bootstrap/scss/button-group"; //-> .input-group[-append|-prepend|-text]
// navs
@import "../../../node_modules/bootstrap/scss/nav"; //-> .nav[-item|-link|-tabs|-pills|-fill|-justified] .active .disabled .tab[-content|-pane]
@import "../../../node_modules/bootstrap/scss/navbar"; //-> .navbar[-brand|-nav|-toggler|-text|-collapse] .form-inline .collapse
@import "../../../node_modules/bootstrap/scss/buttons";
@import "../../../node_modules/bootstrap/scss/card";
@import "../../../node_modules/bootstrap/scss/badge";
@import "../../../node_modules/bootstrap/scss/list-group";
@import "../../../node_modules/bootstrap/scss/close";
@import "../../../node_modules/bootstrap/scss/dropdown";
@import "../../../node_modules/bootstrap/scss/accordion";
@import "../../../node_modules/bootstrap/scss/transitions";
@import "../../../node_modules/bootstrap/scss/navbar";
@import "../../../node_modules/bootstrap/scss/offcanvas";
@import "../../../node_modules/bootstrap/scss/modal";
@import "../../../node_modules/bootstrap/scss/tables";
@import "../../../node_modules/bootstrap/scss/breadcrumb"; //-> .breadcrumb
@import "../../../node_modules/bootstrap/scss/pagination"; //-> .pagination .page[-item|-link]

@import "../../../node_modules/bootstrap/scss/helpers/colored-links";
@import "../../../node_modules/bootstrap/scss/helpers/stretched-link";
// css pour uikit
/* ====== obligatoire
*/
@import "../../../node_modules/uikit/src/scss/variables";
@import "../../../node_modules/uikit/src/scss/mixins";
@import "../../../node_modules/uikit/src/scss/components/variables";
@import "../../../node_modules/uikit/src/scss/components/mixin";
//@import "../../../node_modules/uikit/src/scss/components/base";

/* ====== optionnel
*/
// Elements
@import "../../../node_modules/uikit/src/scss/components/icon";
// layout
@import "../../../node_modules/uikit/src/scss/components/grid"; //-> uk-grid="[masonry:true]" uk-height-match="target: > div > .uk-card"
// common
@import "../../../node_modules/uikit/src/scss/components/close"; //-> uk-close
@import "../../../node_modules/uikit/src/scss/components/totop"; //-> uk-totop
@import "../../../node_modules/uikit/src/scss/components/alert"; //-> uk-alert .uk-alert-[primary|success|warning|danger]
@import "../../../node_modules/uikit/src/scss/components/badge"; //-> .uk-badge
@import "../../../node_modules/uikit/src/scss/components/overlay"; //-> .uk-overlay[-default] .uk-inline .uk-position-cover
// navs
@import "../../../node_modules/uikit/src/scss/components/tab"; //-> uk-tab
@import "../../../node_modules/uikit/src/scss/components/slidenav"; //-> uk-slidenav-[next|previous] .uk-slidenav-[large|container]
@import "../../../node_modules/uikit/src/scss/components/dotnav"; //-> .uk-dotnav[-vertical] .uk-active
// javascript
@import "../../../node_modules/uikit/src/scss/components/accordion"; //-> uk-accordion="collapsible:false" .uk-accordion-[title|content]
@import "../../../node_modules/uikit/src/scss/components/dropdown"; //-> uk-dropdown[="mode:click"|="pos: {top|bottom|left|right|center}-{right|left|bottom|top|center}"]
@import "../../../node_modules/uikit/src/scss/components/modal"; //-> uk-modal
@import "../../../node_modules/uikit/src/scss/components/lightbox"; //-> uk-lightbox="animation: [fade|slide|scale]"
@import "../../../node_modules/uikit/src/scss/components/slideshow"; //-> uk-slideshow .uk-slideshow-items
@import "../../../node_modules/uikit/src/scss/components/text";
@import "../../../node_modules/uikit/src/scss/components/slider"; //-> uk-slider .uk-slider-items
@import "../../../node_modules/uikit/src/scss/components/sticky"; //-> uk-sticky[="offset: 100"]
@import "../../../node_modules/uikit/src/scss/components/offcanvas"; //-> uk-offcanvas-bar
@import "../../../node_modules/uikit/src/scss/components/switcher"; //-> uk-switcher .uk-switcher
@import "../../../node_modules/uikit/src/scss/components/column";
// additional
@import "../../../node_modules/uikit/src/scss/components/notification"; //-> css pour les messages de notifications après validation de formulaire
@import "../../../node_modules/uikit/src/scss/components/tooltip"; //-> uk-tooltip="title: Hello world; pos: [top{-left|right}|bottom{-left|-right}|right|left]"
//@import "../../../node_modules/uikit/src/scss/components/countdown"; à utiliser quand besoin d'un compteur
// utilities
@import "../../../node_modules/uikit/src/scss/components/animation"; //-> .uk-animation-[fade|-scale-up|-scale-down|-slide-top|-slide-bottom|-slide-left|-slide-right][-small|-medium]
@import "../../../node_modules/uikit/src/scss/components/width"; //-> .uk-child-width-[1-{1 ~ 6}|2-{3 ~ 6}][@s|@m|@l] .uk-grid[-column|-row][-small|-medium|-large|-collapse]
@import "../../../node_modules/uikit/src/scss/components/height"; //-> .uk-height-1-1
@import "../../../node_modules/uikit/src/scss/components/transition"; //-> .uk-transition-[fade|-scale-up|-scale-down|-slide-top|-slide-bottom|-slide-left|-slide-right][-small|-medium]
@import "../../../node_modules/uikit/src/scss/components/position";
@import "../../../node_modules/uikit/src/scss/components/flex";
@import "../../../node_modules/uikit/src/scss/components/background";
