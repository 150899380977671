/* =======

  css à placer sur les balises html générale avant de rentrer dans le détail du dossier 5.layout et 6.blocs

======= */

html {
	font-size: .625rem; // 1rem = 10px with this line (easier calcul)
}
body {
	@include font($font-01, map-get($font-sizes,15), $font-weight-normal, $black);
}

.body{
  overflow: hidden;
}

sup {
	font-size: 60%;
}

ul {
  li{

  }
}

.invalid {
    color: $red;
    font-weight: bold;
}

input.invalid {
    border: 1px solid $red;
}

table{
  thead{
    tr{
      th{

      }
      td{

      }
    }
  }
  tbody{
    tr{
      th{

      }
      td{

      }
    }
  }
  tfooter{
    tr{
      th{

      }
      td{

      }
    }
  }
}


a{
  text-decoration: none;
}

em{
  color: $secondary;
}
