// override rsform class
.rsform-block{
  .formControlLabel{
    display: none;
  }
  &.rsform-block-titre{
    h2{
      font-size: 40px;
      margin-bottom: 25px;
    }
  }
  &.rsform-block-adresse{
    font-size:  map-get($font-sizes,16);
    color: $primary-2;
    a{
      font-size:  map-get($font-sizes,16);
      color: $primary-2;
    }
    strong{
      color: $blue;
      margin: 20px 0 5px;
      display: block;
    }
    @include media-breakpoint-down(lg) {
      iframe {
        display: none;
      }
    }
  }
  .form-control{
    padding: 15px;
    border-color: $secondary-4;
    border-radius: 10px;
    &::placeholder{
      color: $primary-2;
    }
  }
  &.rsform-block-rgpd{
    .form-check-label, a{
      font-size: map-get($font-sizes,16);
      color: $primary-2;
    }
  }
  &.rsform-block-envoyer{
    padding-top: 30px;
    .btn-primary{
      background-color: $secondary;
      border-color: $secondary;
    }
  }
  &.rsform-block-bandeau{
    margin-bottom: 50px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 105px;
    }
    position: relative;
    img{
      position: relative;
      z-index: 1;
    }
    &:after{
      content: '';
      display: block;
      position: absolute;
      width: 306px;
      height: 90px;
      left: 30px;
      top: 20px;
      @include media-breakpoint-up(sm) {
        width: 100%;
        height: 170px;
      }
      @include media-breakpoint-up(lg) {
        width: 100%;
        height: 260px;
        left: 110px;
        top: 95px;
      }
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#194270+0,32b4b4+50,c8e6cd+100 */
      background: #194270; /* Old browsers */
      background: -moz-linear-gradient(left,  #194270 0%, #32b4b4 50%, #c8e6cd 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left,  #194270 0%,#32b4b4 50%,#c8e6cd 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right,  #194270 0%,#32b4b4 50%,#c8e6cd 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#194270', endColorstr='#c8e6cd',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
      border-radius: 5px 20px 5px 20px;
      z-index: 0;
    }
  }
  .rsform-submit-button{
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
}

.formContainer{
  margin-top: 30px;
  margin-bottom: 50px;
  .row{
    justify-content: center;
    .col-md-6{
      @include media-breakpoint-up(sm) {
        @include make-col(12);
      }
      @include media-breakpoint-up(lg) {
        @include make-col(5);
      }
    }
    .col-md-12{
      @include media-breakpoint-up(md) {
        @include make-col(10);
      }
    }
  }
}

.com_rsform #main-content > p{
  text-align: center;
  font-size: map-get($font-sizes,16);
  color: $primary-2;
}

.rsform-thankyou-button{
  margin: 0 auto 100px;
  display: table;
}