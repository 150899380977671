
.list-valeurs{
  width: 100%;
  //padding-bottom: 150px;
  @include media-breakpoint-up(sm) {
	margin-left: 15px;
  }
  @include media-breakpoint-up(lg) {
	width: 480px;
	padding: 0;
  }
	li{
		&:nth-child(1){
		  top: 0px;
		  left: 0px;
		  @include media-breakpoint-up(lg) {
			top: 60px;
			left: 0px;
		  }
		}
		&:nth-child(2){
		  top:0px;
		  left:100px;
		  @include media-breakpoint-up(lg) {
			transform: translate(-50%, -50%);
			top:45px;
			left:50%;
		  }
		}
		&:nth-child(3){
		  top:0px;
		  right:0px;
		  @include media-breakpoint-up(lg) {
			top:60px;
			right:0px;
		  }
		}
		&:nth-child(4){
		  top:120px;
		  right:15%;
		  @include media-breakpoint-down(sm) {
			margin-top: 40px;
		  }
		  @include media-breakpoint-up(lg) {
			top: 180px;
			right: 20%;
			margin: 0;
		  }
		}
		&:nth-child(5){
		  top:120px;
		  left: 15%;
		  @include media-breakpoint-down(sm) {
			margin-top: 40px;
		  }
		  @include media-breakpoint-up(lg) {
			top: 180px;
			left: 20%;
			margin: 0;
		  }
		}
	}
}

.list-standard{
  margin-left: 15px;
  li {
	&:before {
	  content: '-';
	  margin-right: 10px;
	}
	padding-bottom: 8px;
  }
  .list-standard{
	padding-top: 10px;
	padding-left: 15px;
	font-size: map-get($font-sizes,14);
	li{
	  position: relative;
	  padding-left: 14px;
	  &:before {
		content: '';
		width: 5px;
		height: 5px;
		background-color: $secondary;
		display: block;
		position: absolute;
		left: 0;
		top: 8px;
		transform: rotate(45deg);
	  }
	  padding-bottom: 0;
	  color: $primary-3;
	}
  }
}