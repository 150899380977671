
// Gestion de l'apparition du menu déroulant
.nav.menu .parent:hover .dropdown-menu{
  display: block;
}

.nav.menu .default .nav-link{
  padding-left: 0;
}

.menu-burger{
  span, span:after, span:before{
    display: block;
    width: 20px;
    height: 2.25px;
    background-color: $white;
  }
  span{
    position: relative;
    margin: 10px 0;
    width:14px;
    height: 2px;
    background-color: $secondary;
    &:before, &:after{
      content: '';
      position: absolute;
      left: 0;
    }
    &:before{
      bottom: 6px;
    }
    &:after{
      top: 6px;
    }
  }
}

.menu-burger-close{
  cursor: pointer;
  span, span:after, span:before{
    display: block;
    width: 30px;
    height: 2.25px;
    background-color: $white;
  }
  span{
    position: relative;
    margin: 10px 0;
    width:20px;
    height: 20px;
    background-color: transparent;
    &:before, &:after{
      content: '';
      position: absolute;
      left: 0;
    }
    &:before{
      bottom: 6px;
      transform: rotate(45deg);
    }
    &:after{
      bottom: 6px;
      transform: rotate(-45deg);
    }
  }
}