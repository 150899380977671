.equipe{
  .fiche{
    display: none;
    &:hover{
      display: block;
    }
  }
  .btn-photo{
    z-index: 2;
    &:hover{
      z-index: 4;
      & + .fiche{
        display: block;
      }
    }
  }
}