.offres{
  .bg-secondary-2{
    opacity: 0;
    transition: all ease-in-out 0.2s;
  }
  .bulles{
    display: none;
    opacity: 0;
    transition: all ease-in-out 0.2s;
  }
  .offre {
    transition: all ease-in-out 0.2s;
  }
  &:hover{
    z-index:5;
    .offre {
      background-color: $secondary !important;
      transition: all ease-in-out 0.2s;
    }
    .bg-secondary-2{
      opacity: 1;
      transition: all ease-in-out 0.2s;
    }
    .bulles{
      display: block;
      opacity: 1;
      transition: all ease-in-out 0.2s;
    }
  }
}

.offres-slider{
  .uk-active{
    position: relative;
    &:after{
      content: '';
      width: 20px;
      height: 5px;
      background-color: $primary;
      display: block;
      position: absolute;
      right: -20px;
      top: 50%;
    }
  }
}