// Image

img,
svg {
	max-width: 100%;
}
a{
  img {
	border: 0;
  }
  //&:hover, &:focus, &:active {
	//img {
	//  opacity: 0.8;
	//}
  //}
}

