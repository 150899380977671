/* ======= fonts à déclarer
*/

$font-01 : 'Roboto', sans-serif;
$font-02 : 'Caveat', cursive;
//$font-03 : 'Open Sans', sans-serif;

$font-family-base: $font-01;

/* ======= fonts weight
*/

/* ======= variables de bootsrap à réutiliser
https://getbootstrap.com/docs/5.1/utilities/text/#font-weight-and-italics
*/

$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-bold:            700;
$font-weight-black:            900;
$font-weight-medium : 500;
$font-weight-semi-bold : 600;

/* ======= border-radius
https://getbootstrap.com/docs/5.1/utilities/borders/#variables
*/

$border-radius: 2px 5px 2px 5px;
$border-radius-sm: .6rem;
$border-radius-lg: 5px 20px 5px 20px;
$border-radius-pill: 50rem;

$radius: ();

$radius: (
	null: $border-radius,
	0: 0,
	1: $border-radius-sm,
	2: $border-radius,
	3: $border-radius-lg,
	4: 5px 100px 5px 100px,
	circle:50%,
	pill: $border-radius-pill,
	btn: 5px 30px 5px 5px,
	carte: 10px 25px 10px 25px,
);

$max-radius: 20;

@for $i from 5 through $max-radius {
	$radius: map-merge(
		(
			$i: ($i/10)+rem
		),
		$radius
	);
}

/* ======= spacers
https://getbootstrap.com/docs/5.1/utilities/spacing/#sass
*/

$spacer: 1rem;
$spacers: (
	0: 0,
	1: $spacer / 4,
	2: $spacer / 2,
	3: $spacer,
	4: $spacer * 1.5,
	5: 0.5rem,
	6: 0.6rem,
	8: 0.8rem,
	13: 1.3rem,
	40: 4rem,
	45: 4.5rem,
	50: 5rem,
	55: 5.5rem,
	60: 6rem,
	70: 7rem,
	80: 8rem,
	90: 9rem,
	100: 10rem,
	105: 10.5rem,
	110: 11rem,
	125: 12.5rem,
	130: 13rem,
	145: 14.5rem,
	170: 17rem,
	235: 23.5rem,
	n20: -2rem,
);

// renseigner à partir de combien lancer la boucle
$spacing-debut: 10;

// jusqu'à combien terminer la boucle
$spacing-fin: 35;

// permet de générer toutes les class spacing avec le responsive
@for $i from $spacing-debut through $spacing-fin {
  $spacers: map-merge(
	  (
		  $i: ($i/10)+rem
	  ),
	  $spacers
  );
}

/* ======= les gutters
https://getbootstrap.com/docs/5.1/layout/gutters/#change-the-gutters
*/

$grid-gutter-width: 3rem;
$gutters: (
	0: 0,
	1: $spacer * .25,
	2: $spacer * .5,
	3: $spacer,
	4: $spacer * 1.5,
	5: $spacer * 3,
	8: 8rem,
	20: 2rem,
	23: 2.3rem,
	30: 3rem,
	40: 4rem,
);

/* ======= position absolute
https://getbootstrap.com/docs/5.1/utilities/position/#sass
*/

$position-values: (
	0: 0,
	5: 5%,
	10: 10%,
	15: 15%,
	20: 20%,
	25: 25%,
	30: 30%,
	40: 40%,
	50: 50%,
	80: 80%,
	90: 90%,
	100: 100%,
	2px:2px,
	100px:100px,
	n30px:-30px,
	n60px:-60px,
	n130px:-130px,
	n150px:-150px,
	n170px:-170px,
	n300px:-300px
);

$positions: ( static relative absolute fixed sticky );

/* ======= z-index
*/

$zindexs: (1 2 3 4 5);

/* ======= width
https://getbootstrap.com/docs/5.1/utilities/sizing/#sass
*/

$widths: (
	20: 20%,
	25: 25%,
	30: 30%,
	40: 40%,
	50: 50%,
	70: 70%,
	75: 75%,
	80: 80%,
	85: 85%,
	90: 90%,
	100: 100%,
	26px: 26px,
	86px: 86px,
	125px: 125px,
	145px: 145px,
	161px: 161px,
	182px: 182px,
	205px: 205px,
	215px: 215px,
	235px: 235px,
	250px: 250px,
	260px: 260px,
	268px: 268px,
	283px: 283px,
	285px: 285px,
	300px: 300px,
	305px: 305px,
	310px: 310px,
	350px: 350px,
	410px: 410px,
	440px: 440px,
	460px: 460px,
	500px: 500px,
	520px: 520px,
	600px: 600px,
	657px: 657px,
	1015px: 1015px,
	1070px: 1070px,
	auto: auto
);

/* ======= utilities de bootstrap
*/

$utilities: ();
// stylelint-disable-next-line scss/dollar-variable-default
$utilities: map-merge(
	(
		"font-family": (
			property: font-family,
			class: font,
			responsive:true,
			values: (
					family-02: $font-02,
			)
		),
			"height": (
			property: height,
			class: h,
			responsive:true,
			values: $widths
		),
		"width": (
				property: width,
				class: w,
				responsive:true,
				values: $widths
		),
		"position": (
				property: position,
				responsive:true,
				values: $positions
		),
		"top": (
				property: top,
				responsive:true,
				values: $position-values
		),
		"bottom": (
				property: bottom,
				responsive:true,
				values: $position-values
		),
		"start": (
				property: left,
				class: start,
				responsive:true,
				values: $position-values
		),
		"end": (
				property: right,
				class: end,
				responsive:true,
				values: $position-values
		),
		"z-index": (
				property: z-index,
				responsive: true,
				values: $zindexs
		),
		"font-size": (
				rfs: true,
				property: font-size,
				responsive: true,
				class: fs,
				values: $font-sizes
		),
		"font-weight": (
				property: font-weight,
				class: fw,
				values: (
						light: $font-weight-light,
						normal: $font-weight-normal,
						medium: $font-weight-medium,
						semi-bold: $font-weight-semi-bold,
						bold: $font-weight-bold
				)
		),
		"line-height": (
				property: line-height,
				class: lh,
				values: $spacers
		),
		"rounded": (
				property: border-radius,
				class: rounded,
				responsive: true,
				values: ( $radius )
		),
		"rounded-top": (
				property: border-top-left-radius border-top-right-radius,
				class: rounded-top,
				responsive: true,
				values: ( $radius )
		),
		"rounded-end": (
				property: border-top-right-radius border-bottom-right-radius,
				class: rounded-end,
				responsive: true,
				values: ( $radius )
		),
		"rounded-bottom": (
				property: border-bottom-right-radius border-bottom-left-radius,
				class: rounded-bottom,
				responsive: true,
				values: ( $radius )
		),
		"rounded-start": (
				property: border-bottom-left-radius border-top-left-radius,
				class: rounded-start,
				responsive: true,
				values: ( $radius )
		),
	),
	$utilities
);

/* ======= alerts
https://getbootstrap.com/docs/5.1/components/alerts/#variables
*/

$alert-bg-scale: 0;
$alert-border-scale: 0;

/* ======= transitions
feinte à @hugo
*/

$transition: .25s ease-in-out;

/* ======= shadow générique
https://getbootstrap.com/docs/5.1/utilities/shadows/#variables
*/

$enable-shadows: true;
$box-shadow-sm: 0 .5rem 1rem rgba($black, .05);
$box-shadow: 0 1rem 2rem rgba($black, .05);
$box-shadow-lg: 0 2rem 4rem rgba($black, .15);

/* ======= card
https://getbootstrap.com/docs/5.1/components/card/#variables
*/

$card-spacer-y: 20px;
$card-spacer-x: 20px;
$card-border-width: 0;
$card-box-shadow: 0 1rem 4rem 0 rgba($black,0.05);
$card-border-radius:                10px;

/* =================== Taille des containers
https://getbootstrap.com/docs/5.1/layout/containers/#sass
 */

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px,
	xxl: 1320px
);

/* =================== Nav
https://getbootstrap.com/docs/5.1/components/navs-tabs/#variables
 */

$nav-link-padding-x: 1.5rem;
$nav-pills-border-radius: 0;
$nav-link-color:                    $white;
$nav-link-hover-color:              $secondary;
$nav-pills-link-active-color:       $secondary;
$nav-pills-link-active-bg:          none;

/* =================== Btn
https://getbootstrap.com/docs/5.1/components/buttons/#variables
 */

$btn-padding-y: 16px;
$btn-padding-x: 28px;
$btn-font-family: $font-01;
$btn-font-size: map-get($font-sizes,15);
$btn-font-weight: $font-weight-medium;
$btn-line-height: 18px;
$btn-box-shadow: unset;
$btn-border-radius:           $border-radius;
$btn-link-color:              $white;

/* =================== select
https://getbootstrap.com/docs/5.1/forms/select/#variables
 */

$form-select-padding-y:             1.3rem;
$form-select-padding-x:             2rem;
$form-select-font-family:           $font-01;
$form-select-font-size:             map-get($font-sizes,16);
$form-select-font-weight:           $font-weight-semi-bold;
$form-select-border-radius:       $border-radius;
$form-select-border-width:        0;
$form-select-indicator-color:       $primary;

/* ======= dropdown
https://getbootstrap.com/docs/5.1/components/dropdowns/#variables
*/

$dropdown-color: $secondary;
$dropdown-bg: $white;
$dropdown-header-color: $secondary;
$dropdown-padding-y: 0.8rem;
$dropdown-padding-x: 0.8rem;
$dropdown-item-padding-y:           0.8rem;
$dropdown-item-padding-x:           2rem;
$dropdown-min-width: 100%;
$dropdown-border-radius:            $border-radius;
$dropdown-border-width: 0;
$dropdown-font-size: map-get($font-sizes,15);
$dropdown-link-hover-color:         $primary;
$dropdown-link-hover-bg:            $light;
$dropdown-link-active-color:        $white;
$dropdown-link-active-bg:           $primary;
$dropdown-inner-border-radius:      0;

/* =================== input
https://getbootstrap.com/docs/5.1/forms/form-control/#variables
 */

$input-font-size:                       map-get($font-sizes,16);
$input-border-radius:                   0.5rem;

/* =================== breadcrumb
https://getbootstrap.com/docs/5.1/components/breadcrumb/#variables
 */

$breadcrumb-item-padding-x:         .5rem;
$breadcrumb-margin-bottom:          1rem;
$breadcrumb-divider-color:          $gray-600;
$breadcrumb-active-color:           $gray-600;
$breadcrumb-divider:                quote("/");
$breadcrumb-divider-flipped:        $breadcrumb-divider;

/* =================== offcanvas
https://getbootstrap.com/docs/5.1/components/offcanvas/#variables
 */

$offcanvas-horizontal-width:        400px;
$offcanvas-padding-y:               20px;
$offcanvas-padding-x:               30px;
$offcanvas-bg-color:                $primary;

/* =================== accordion
https://getbootstrap.com/docs/5.1/components/accordion/#variables
 */
$accordion-button-bg:                     transparent;
$accordion-button-active-bg:              transparent;
$accordion-button-focus-box-shadow:       unset;
$accordion-button-icon:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#fff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
$accordion-button-active-icon:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#fff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");

/* =================== list-group
https://getbootstrap.com/docs/5.1/components/list-group/#variables
 */

$list-group-item-padding-x:         0;
$list-group-item-padding-y:         0;
$list-group-border-color:           rgba($white,0.3);

/* =================== pagination
https://getbootstrap.com/docs/5.1/components/pagination/#variables
 */

$pagination-padding-y:              .9rem;
$pagination-padding-x:              1.4rem;
$pagination-border-radius:          4px;
$pagination-color:                  $secondary-4;

/* =================== modal
https://getbootstrap.com/docs/5.1/components/modal/#variables
 */

$modal-content-border-width:        0;

/* =================== tables
https://getbootstrap.com/docs/5.1/content/tables/#variables
 */
$table-color:                 $primary;
$table-border-color:          $primary-2;

/* =================== modal
https://getbootstrap.com/docs/5.1/components/modal/#variables
 */
$modal-backdrop-bg:                 $primary;